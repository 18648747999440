<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">TRANSFERÊNCIAS</h1>
            </div>
            <div class="menu_configuracoes">
                <router-link to="/Receitas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_missao_diplomatica">
                        RECEITAS 
                    </div>
                </router-link>
                <router-link to="/Despesas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_orgaos">
                        DESPESAS
                    </div>
                </router-link>
                <router-link to="/Transferencias" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_contas">
                        TRANSFERÊNCIAS
                    </div>
                </router-link>
                <router-link to="/Salario" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_salarios">
                        SALÁRIOS
                    </div>
                </router-link>
            </div>
        </div>
        <div>
            <router-link to="/FormularioTransferencia" style="text-decoration: none; color: inherit;"> 
                <span class="formulario_funcionario"><span class="dot_funcionario"></span>Registar Transferência</span>
            </router-link>
        </div>
        <div class="contas-list">
            <h2 class="dados_contas">LISTA DE TRANSFERÊNCIAS REGISTADAS</h2>
            <div class="table_contas">
                <table>
                    <thead>
                        <tr>
                        <th scope="col">Transferência Origem</th>
                        <th scope="col">Transferência Destino</th>
                        <th scope="col">Descrição</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Data de Confirmação</th>
                        <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody v-if="data && data.length > 0">
                        <tr v-for="f in data" :key="f.id">
                            <td>{{ f.orgao_origem_transferencia }}</td>
                            <td>{{ f.orgao_destino_transferencia}}</td>
                            <td>{{ f.descricao_transferencia }}</td>
                            <td>{{ f.valor_origem_transferencia }}</td>
                            <td>{{ f.data_registro_transferencia }}</td>
                            <td>
                                <router-link to="/TransferenciaInterna" style="text-decoration: none; color: inherit;">
                                    <span title="Transferencias internas" class="icon_relatorio"><i class="fa-solid fa-file"></i></span>
                                </router-link>
                                <span title="Eliminar" @click="handleDelete(f.id)" class="icon_delete"><i class="fas fa-trash-alt"></i></span>
                                <router-link :to="`/edittransferencias/${f.id}`" style="text-decoration: none; color: inherit;">
                                    <span title="Editar" class="icon_edit"><i class="fas fa-edit"></i></span>
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="error">{{ error2 }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'
import { projectFirestore } from '../firebase/config'
export default {
    components: { NavbarAppHome, Sidebar },

    setup() {
        const { addDoc, error } = useCollection('conta')
        const { error2, documents } = getCollection('transferencia')

        const { user } = getUser()
        const router = useRouter()
        const data = ref([])
        const filteredDocuments = ref([])
        let new_obj = ref();
        const isEdit = ref(false);
        const isEditingId = ref(null);

        const situacao_transferencia = ref('')
        const data_registro_transferencia = ref('')
        const orgao_origem_transferencia = ref('')
        const conta_origem_transferencia = ref('')
        const valor_origem_transferencia = ref('')
        const orgao_destino_transferencia = ref('')
        const conta_destino_transferencia = ref('')
        const valor_destino_transferencia = ref('')
        const descricao_transferencia = ref('')

        watch(user, () => {
            if  (!user.value) {
                router.push({ name: 'Login'})
            }
        })
        const filterDocuments = () => {
            if (documents.value) {
                filteredDocuments.value = documents.value.filter(
                    (doc) => doc.userId === user.value.uid
                );
                data.value = filteredDocuments.value;
            }
        };
        filterDocuments();
        watch([documents, user], () => {
            filterDocuments();
        });
        
        const handleSubmit = async () => {
            const transferencia = {
                situacao_transferencia: situacao_transferencia.value,
                data_registro_transferencia: data_registro_transferencia.value,
                orgao_origem_transferencia: orgao_origem_transferencia.value,
                conta_origem_transferencia: conta_origem_transferencia.value,
                valor_origem_transferencia: valor_origem_transferencia.value,
                orgao_destino_transferencia: orgao_destino_transferencia.value,
                conta_destino_transferencia: conta_destino_transferencia.value,
                valor_destino_transferencia: valor_destino_transferencia.value,
                descricao_transferencia: descricao_transferencia.value,
                userId: user.value.uid

            }
            await addDoc(transferencia)
            if (!error.value) { 
                situacao_transferencia.value = ''
                data_registro_transferencia.value = ''
                orgao_origem_transferencia.value = ''
                conta_origem_transferencia.value = ''
                valor_origem_transferencia.value = ''
                orgao_destino_transferencia.value = ''
                conta_destino_transferencia.value = ''
                valor_destino_transferencia.value = ''
                descricao_transferencia.value = ''
            }
            alert('DADOS INSERIDOS COM SUCESSO!')
        }

        

        
        const handleDelete = async (id) => {
            try {
                const docRef = projectFirestore.collection("transferencia").doc(id);
                await docRef.delete();
                console.log("Document successfully deleted.");
            } catch (error) {
                console.log("Error deleting document:", error.message);
            }
        };

        const handleEdit = async (id) => {
            try {
                const docRef = projectFirestore.collection("transferencia").doc(id);
                await docRef.get().then(async (doc) => {
                if (doc.exists) {
                    let id = await doc.id;
                    new_obj.value = await { ...doc.data(), id };
                    situacao_transferencia= new_obj.value.situacao_transferencia;
                    data_registro_transferencia= new_obj.value.data_registro_transferencia;
                    orgao_origem_transferencia= new_obj.value.orgao_origem_transferencia;
                    conta_origem_transferencia= new_obj.value.conta_origem_transferencia;
                    valor_origem_transferencia= new_obj.value.valor_origem_transferencia;
                    orgao_destino_transferencia= new_obj.value.orgao_destino_transferencia;
                    conta_destino_transferencia= new_obj.value.conta_destino_transferencia;
                    valor_destino_transferencia= new_obj.value.valor_destino_transferencia;
                    descricao_transferencia= new_obj.value.descricao_transferencia;
                }
                });
                isEdit.value = true;
                isEditingId.value = id;
            } catch (error) {
                console.log("Error deleting document:", error.message);
            }
            };

            const submitEdit = async () => {
            alert("submitEditsubmitEdit");
            const docRef = projectFirestore
                .collection("transferencia")
                .doc(isEditingId.value);
            await docRef.update({
                situacao_transferencia: situacao_transferencia.value,
                data_registro_transferencia: data_registro_transferencia.value,
                orgao_origem_transferencia: orgao_origem_transferencia.value,
                conta_origem_transferencia: conta_origem_transferencia.value,
                valor_origem_transferencia: valor_origem_transferencia.value,
                orgao_destino_transferencia: orgao_destino_transferencia.value,
                conta_destino_transferencia: conta_destino_transferencia.value,
                valor_destino_transferencia: valor_destino_transferencia.value,
                descricao_transferencia: descricao_transferencia.value,
                userId: user.value.uid
            });
            isEdit.value = false;
            situacao_transferencia = null;
            data_registro_transferencia = null;
            orgao_origem_transferencia = null;
            conta_origem_transferencia = null;
            valor_origem_transferencia = null;
            orgao_destino_transferencia = null;
            conta_destino_transferencia = null;
            valor_destino_transferencia = null;
            descricao_transferencia = null;
            };

        return { error, 
                 handleSubmit, 
                 error2, 
                 documents,
                 data,
                 situacao_transferencia,
                 data_registro_transferencia,
                 orgao_origem_transferencia,
                 conta_origem_transferencia,
                 valor_origem_transferencia,
                 orgao_destino_transferencia,
                 conta_destino_transferencia,
                 valor_destino_transferencia,
                 descricao_transferencia,
                 handleDelete,
                 handleEdit,
                 submitEdit,
                 filterDocuments,
                 handleSubmit, 
                }
    }
    
}
</script>

<style>
.formulario_funcionario{
    position: absolute;
    width: 236px;
    height: 24px;
    left: 235px;
    top: 290px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #333333;
}

.dot_funcionario{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 15px;
    top: 1px;
    background-color: rgb(25, 226, 25);
    border-radius: 15px;
}
.contas-list{
    position: absolute;
    width: 980px;
    height: auto;
    left: 231px;
    top: 400px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
    border: 1px solid rgb(198, 192, 192);
}
.dados_contas{
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    top: 10px;
    margin: 20px 10px 10px 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
}
.table_contas{
    position: absolute;
    top: 60px;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

/* general styling */
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.25;
}

.icon_delete{
    color:red;
    padding: 10px;
    cursor: pointer;
}

.icon_edit{
    color: rgb(31, 227, 31);
    cursor: pointer;
}
.menu_tabelas{
    padding: 20px;
    color: black;
    margin-bottom: 10px;
}
.btn_salarios{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 950px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.btn_salarios:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
</style>