<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">PROGRAMAÇÃO FINANCEIRA</h1>
            </div>
        </div>
        <div>
    
        <div>
            <span class="icon_download">
                <i class="fa-solid fa-download" @click="generatePDF"></i>
            </span>
            <span class="icon_print">
                <i class="fa-solid fa-print"></i>
            </span>
             <router-link to="/Relatorios" style="text-decoration: none; color: inherit;">
                <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
            </router-link>
            <div class="holderrelatorio">
                <table id="element-to-pdf">
                    <tbody>
                        <tr>
                            <img src="../assets/Insignia.jpg" alt="Insignia da Republica">
                            <p class="repubica">REPÚBLICA DE ANGOLA</p>
                            <p class="ministerio">MINISTÉRIO DAS RELAÇÕES EXTERIORES </p>
                            <p class="missaoDiplomatica">EMBAIXADA DA REPÚBLICA DE ANGOLA NO JAPÃO</p>
                        </tr>
                    </tbody>
                </table>
                <table class="mirex">
                    <tr>
                        <td><b>Mirex-034</b></td>
                    </tr>
                </table>
                <table class="mirex2">
                    <tr>
                        <td class="alinhamento"><b>Nome Conta:</b></td>
                    </tr>
                    <tr>
                        <td class="alinhamento"><b>N. Conta:</b></td>
                    </tr>
                    <tr>
                        <td class="alinhamento"><b>Câmbio:</b></td>
                    </tr>
                </table>
                <table class="titulodoc">
                    <tr>
                        <td><b>PROGRAMAÇÃO FINANCEIRA</b></td>
                    </tr>
                    <tr>
                        <td>Todas Contas</td>
                    </tr>
                </table>
                <table class="assinatura">
                    <tr>
                        <td class="embaixador_visto">Visto Embaixador</td>
                    </tr>
                    <tr>
                        <td>
                            
                        </td>
                    </tr>
                    <tr>
                        <td>
                            
                        </td>
                    </tr>
                    <tr>
                        <td>________________________</td>
                    </tr>
                </table>
                <table class="main_table">
                    <tr>
                        <td class="col_n_o">N/O</td>
                        <td class="col_seq">SEQ</td>
                        <td class="col_beneficiario">BENEFICIÁRIO</td>
                        <td class="col_desc">DESCRIÇÃO</td>
                        <td class="ml">DISPONIBILIDADE</td>
                        <td class="mg">IMPORTÂNCIA A PAGAR</td>
                        <td class="om">SALDO</td>
                    </tr>
                    <tr>
                        <td class="col_n_o"></td>
                        <td class="col_seq"></td>
                        <td class="col_beneficiario"></td>
                        <td class="col_desc"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="col_n_o"></td>
                        <td class="col_seq"></td>
                        <td class="col_beneficiario"></td>
                        <td class="col_desc"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="col_n_o"></td>
                        <td class="col_seq"></td>
                        <td class="col_beneficiario"></td>
                        <td class="col_desc"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="col_n_o"></td>
                        <td class="col_seq"></td>
                        <td class="col_beneficiario"></td>
                        <td class="col_desc"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="col_n_o"></td>
                        <td class="col_seq"></td>
                        <td class="col_beneficiario"></td>
                        <td class="col_desc"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="col_n_o"></td>
                        <td class="col_seq"></td>
                        <td class="col_beneficiario"></td>
                        <td class="col_desc"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="col_n_o"></td>
                        <td class="col_seq"></td>
                        <td class="col_beneficiario"></td>
                        <td class="col_desc"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="col_n_o"></td>
                        <td class="col_seq"></td>
                        <td class="col_beneficiario"></td>
                        <td class="col_desc"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="col_n_o"></td>
                        <td class="col_seq"></td>
                        <td class="col_beneficiario"></td>
                        <td class="col_desc"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>

                    <tr>
                        <td colspan="4" class="total">Total</td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                </table>
                
                <table class="adido">
                    <tr>
                        <td>Adido Financeiro</td>
                    </tr>
                    <tr>
                        <td>________________________</td>
                    </tr>
                </table>
                <table class="tabela_data">
                    <tr>
                        <td>Tóquio, 28 de Fevereiro de 2022</td>
                    </tr>
                </table>
            </div>
            
        </div>
    </div>
    </div>
</template>

<script>
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import RelatorioOrcamentoContent from '../components/RelatorioOrcamentoContent.vue'
export default {
    components: { NavbarAppHome, Sidebar, RelatorioOrcamentoContent },

}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 80%;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: -15%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.main-nav {
    text-align: center;
    margin: 80px 30px 20px 140px;
}
.main-nav a{
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
    color: #999;
    font-size: 18px;
}
a.router-link-active{
    border-bottom: 2px solid #00ce89;
    padding-bottom: 4px;
}

.titulorelatorio{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 180px;
    top: 233px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    text-decoration-line: underline;

    color: #4E4E4E;
}

.holderrelatorio{
    box-sizing: border-box;
    position: absolute;
    padding: 10px 33px;
    width: 1200px;
    height: 1091px;
    left: 251px;
    top: 310px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.icon_download{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 942px;
    top: 289px;
    cursor: pointer;
}

.icon_print{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 982px;
    top: 289px;
    cursor: pointer;
}
.icon_back{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 1020px;
    top: 289px;
    cursor: pointer;
}
p, ul, li{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.65em;
    text-align: justify;
}
.repubica{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
.ministerio{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
.missaoDiplomatica{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
img{
    width: 200px;
    height: 100px;
}
.titulorequisicao{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
table {
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .1em 0 .75em;
}

table tr {
  background-color: #ffffff;
  border: none;
  padding: .35em;
  line-height: 5px;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.unique{
     border: 1px solid #ccc;
     line-height: 5px;
     width: 300px;
}
.unique2{
     border: none;
     line-height: 5px;
     width: 450px;
     
}
.textright{
    text-align: right;
}
.mirex{
    position: absolute;
    left: 10px;
    top: 220px;
    width: 100px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
.mirex2{
    position: absolute;
    left: 920px;
    top: 220px;
    width: 100px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.00em;
    text-align: left;
}
.alinhamento{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: left;
}
.titulodoc{
    position: absolute;
    left: 500px;
    top: 220px;
    width: 200px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
.assinatura{
    position: absolute;
    left: 920px;
    top: 120px;
    width: 120px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    text-align: center;
}
.embaixador_visto{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.8em;
    text-align: center;
}
.main_table{
    position: absolute;
    left: 10px;
    top: 320px;
    border: 1px solid #0f0f0f;
    width: 1170px;
}
.col_n_o{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.7em;
    text-align: center;
    width: 15px;
    border: 1px solid #0f0f0f;
}
.col_seq{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.7em;
    text-align: center;
    width: 20px;
    border: 1px solid #0f0f0f;
}
.col_beneficiario{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.7em;
    text-align: center;
    width: 50px;
    border: 1px solid #0f0f0f;
}
.col_desc{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.7em;
    text-align: center;
    width: 80px;
    border: 1px solid #0f0f0f;
}
.ml{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.7em;
    text-align: center;
    width: 25px;
    border: 1px solid #0f0f0f;
}
.mg{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.7em;
    text-align: center;
    width: 25px;
    border: 1px solid #0f0f0f;
    line-height: 15px;
}
.om{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.7em;
    text-align: center;
    width: 25px;
    border: 1px solid #0f0f0f;
}
.subtable{
    position: absolute;
    width: 550px;
    border: 1px solid #0f0f0f;
    top: 540px;
    left: 10px;
}
.moeda{
    font-size: 0.6em;
    border: 1px solid #0f0f0f;
}
.valor_desp{
    font-size: 0.6em;
    border: 1px solid #0f0f0f;
}
.media_cambial{
    font-size: 0.6em;
    border: 1px solid #0f0f0f;
    line-height: 15px;
}
.equivalente_mg{
    font-size: 0.6em;
    border: 1px solid #0f0f0f;
}
.equivalente_om{
    font-size: 0.6em;
    border: 1px solid #0f0f0f;
}
.total_mg{
    font-size: 0.6em;
    border: 1px solid #0f0f0f;
}
.adido{
    position: absolute;
    top: 600px;
    left: 700px;
    width: 150px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.7em;
}
.tabela_data{
    position: absolute;
    top: 700px;
    left: 10px;
    width: 210px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.7em;
}
</style>