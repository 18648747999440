<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">ORÇAMENTO</h1>
            </div>
            <div class="menu_configuracoes">
               
            </div>
        </div>
        <div>
        <nav class="main-nav">
            <router-link :to="{ name: 'Orcamento' }">ORÇAMENTOS</router-link>
            <router-link :to="{ name: 'RegistarOrcamento' }">REGISTAR ORÇAMENTO</router-link>
            <router-link :to="{ name: 'RegistarVencimento' }">REGISTAR VENCIMENTO</router-link>
            <router-link :to="{ name: 'TabelaVencimento' }">TABELA VENCIMENTO</router-link>
            <router-link :to="{ name: 'RelatorioOrcamento' }">RELATÓRIO</router-link>
            <router-link :to="{ name: 'EditarOrcamento' }">EDITAR</router-link>
        </nav>
    </div>
    </div>
</template>

<script>
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
export default {
    components: { NavbarAppHome, Sidebar }
}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 80%;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: -15%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.main-nav {
    text-align: center;
    margin: 80px 30px 20px 140px;
}
.main-nav a{
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
    color: #999;
    font-size: 18px;
}
a.router-link-active{
    border-bottom: 2px solid #00ce89;
    padding-bottom: 4px;
}
</style>