<template>
  <div>
    <Navbar v-if="!['Login', 'AppHome', 'Configuracao', 'Fechamentos', 'GestaoDocumental',
      'Orcamento', 'Perfil', 'Relatorios', 'TribunalContas', 'MissaoDiplomatica', 'Orgaos',
      'Contas', 'FuncionariosEmpresas', 'Naturezas', 'Orcamento', 'RegistarOrcamento',
      'RegistarCabimentacao', 'RelatorioOrcamento', 'EditarOrcamento', 'Financas', 'Moedas',
      'Receitas', 'Despesas', 'Transferencias', 'Cambio', 'Modelos', 'Movimentos', 'FormularioContas',
      'EditarContas', 'FormularioFuncionario', 'FormularioEmpresa', 'FormularioContrato', 'EditarFuncionario', 'EditarFuncionarioLocal',
      'EditarEmpresa', 'EditarContrato', 'TabelaFuncionario', 'TabelaFuncionarioLocal', 'TabelaEmpresa', 'TabelaContrato','FormularioNaturezas', 'FormularioReceitas',
      'FormularioDespesas', 'FormularioTransferencia', 'FormularioCambio', 'RequisicaoDeVerbas','RequisicaoVerbas', 
      'TermoDeEntrega', 'AdicionarDocumento', 'FolhaSalQuadro', 'FolhaSalLocal', 'BalancoConta', 'ContaCorrenteBancoML',
      'ContaCorrenteBanco', 'MovimentoCaixa', 'RelRecuRec', 'DespesasPagar', 'ProgFinanceira',
      'BalMensal', 'CheqCirPagos', 'CheqCir', 'GuiaReceita', 'LivroControlo', 'LivroDocumentos', 'LivroAssentamentoFuncionarios',
      'TransferenciaInterna', 'RelDesPagCon', 'Janeiro', 'Fevereiro', 'Marco', 'Abril', 'RelDesPagasRecPro', 'RelDesPagTes', 'RelOutraSai',
      'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro', 'Modelo_1', 'Modelo_2',
      'Modelo_2A','Modelo_3', 'Modelo_4', 'Modelo_5', 'Modelo_6', 'Modelo_7', 'Modelo_8', 'Modelo_8A', 'Modelo_9',
      'Modelo_10', 'Modelo_11', 'Modelo_12', 'Modelo_13', 'Modelo_14', 'Modelo_15', 'Modelo_16', 'Modelo_17', 'Modelo_8B',
      'Anexo_1', 'Anexo_2', 'Anexo_3', 'Anexo_4', 'Anexo_5', 'Testes', 'FormularioFuncionarioLocal', 'SelecionarTipoNatureza',
      'FormularioNaturezasReceitas', 'UpdateProfile', 'TabelaNaturezasReceitas', 'TabelaNaturezasDespesas', 'EditarCambio', 'EditMissaoDiplomatica',
      'EditReceitas', 'EditDespesas', 'RegistarOrcamento', 'TabelaVencimento', 'Salario', 'FormularioSalarioPessoalCentral', 'FormularioSalarioPessoalLocal',
      'TabelaSalarioPessoalLocal', 'TabelaSalarioPessoalCentral', 'RegistarVencimento', 'ResumoFolhaSalarial', 'RelatorioModelo_4', 'EditarNaturezaDespesa', 'EditarNaturezaReceita', 'RelatorioPessoal', 'RelatorioContaCorrente',
      'RelatorioOperacaoTesouraria', 'OutrosRelatorios', 'OutrosRelatoriosIndividuais', 'RelatoriosPessoalLista', 'RelatorioContaCorrenteLista', 'RelatorioOperacaoTesourariaLista',
      'item', 'item2', 'item3', 'EditarSalarioFuncionarioCentral', 'EditarSalarioFuncionarioLocal'].includes($route.name)"
      />
    <router-view/>
  </div>  
</template>

<script>
import Navbar from './components/Navbar.vue'
export default {
  components: { Navbar }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
