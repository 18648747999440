import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBJg8vP49VkDKkjk6TyUfecxy8g1LfZB50",
    authDomain: "prescom-md.firebaseapp.com",
    projectId: "prescom-md",
    storageBucket: "prescom-md.appspot.com",
    messagingSenderId: "926591532700",
    appId: "1:926591532700:web:0e4441d187a6540c573759"
  };

  //init firebase

  firebase.initializeApp(firebaseConfig)

const projectAuth = firebase.auth()
const projectFirestore = firebase.firestore()
const timestamp = firebase.firestore.FieldValue.serverTimestamp
const storage = firebase.storage();

export { projectAuth ,projectFirestore, timestamp, storage }