<template>
  <div>
    <div>
      <NavbarAppHome />
    </div>
    <div>
      <Sidebar />
    </div>
    <div class="cabecalho_configuracoes">
      <div class="titulo_configuracoes">
        <h1 class="conteudo_titulo">RECEITAS</h1>
      </div>
      <div class="menu_configuracoes">
        <router-link
          to="/Receitas"
          style="text-decoration: none; color: inherit"
        >
          <div class="btn_missao_diplomatica">RECEITAS</div>
        </router-link>
        <router-link
          to="/Despesas"
          style="text-decoration: none; color: inherit"
        >
          <div class="btn_orgaos">DESPESAS</div>
        </router-link>
        <router-link
          to="/Transferencias"
          style="text-decoration: none; color: inherit"
        >
          <div class="btn_contas">TRANSFERÊNCIAS</div>
        </router-link>
        <router-link to="/Cambio" style="text-decoration: none; color: inherit">
          <div class="btn_funcionarios_empresas">CÂMBIO</div>
        </router-link>
      </div>
    </div>
    <div class="form_container">
      <h1 class="titulo_formulario">EDIT RECEITAS</h1>
      <router-link to="/Receitas" style="text-decoration: none; color: inherit">
        <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
      </router-link>
      <form @submit.prevent="handleSubmit">
        <label class="n_doc_receitas">Nº Doc</label>
        <input
          required
          v-model="n_doc_receita"
          class="input_n_doc_receitas"
          type="text"
        />
        <label class="beneficiario_receitas">Beneficiário</label>
        <input
          required
          v-model="beneficiario_receita"
          class="input_beneficiario_receitas"
          type="text"
        />
        <label class="natureza_receita">Natureza da Receita</label>
        <select
          required
          v-model="natureza_receita"
          class="input_natureza_receita"
        >
          <option disabled value="">--Selecione a Natureza--</option>
          <option value="Vencimento do pessoal civil do quadro">
            Recursos Ordinarios do Tesouro
          </option>
          <option value="Vencimentos de outro pessoal civil">
            Emolumento Consulares
          </option>
          <option value="Subsídios Do Pessoal Civil">IRT</option>
          <option value="Décimo terceiro mês do pessoal civil">
            Segurança Social
          </option>
          <option value="Outras remunerações variáveis do pessoal civil">
            Cativos
          </option>
          <option value="Contribuição do empregador">Juros</option>
          <option value="Contribuições do empregador p/ a segurança social">
            Outras Receitas
          </option>
        </select>
        <label class="data_entrada_receita">Data de Entrada</label>
        <input
          required
          v-model="data_entrada_receita"
          class="input_data_entrada_receita"
          type="date"
        />
        <label class="valor_mensal_contrato">Valor</label>
        <input
          required
          v-model="valor_receita"
          class="input_valor_mensal_contrato"
          type="text"
        />
        <label class="moeda_contrato">Moeda</label>
        <select required v-model="moeda_receita" class="input_moeda_contrato">
          <option disabled value="">--Escolha uma moeda--</option>
          <option value="USD">USD</option>
          <option value="Euro">Euro</option>
          <option value="KZ">KZ</option>
          <option value="Yen">Yen</option>
          <option value="Libra">Libra</option>
        </select>
        <label class="forma_cobranca_contrato">Câmbio</label>
        <input
          required
          v-model="cambio_receita"
          class="input_forma_cobranca_contrato"
          type="text"
        />
        <label class="tipo_valor_contrato">Moeda USD</label>
        <input
          required
          v-model="moeda_usd_receita"
          class="input_tipo_valor_contrato"
          type="text"
        />
        <label class="observacoes_contrato">Descrição</label>
        <textarea
          v-model="descricao_receita"
          class="input_observacoes_contrato"
          rows="4"
          cols="50"
        ></textarea>
        <label class="sigfe_despesa">SIGFE</label>
        <input
          v-model="sigfe_receita"
          type="checkbox"
          class="input_sigfe_despesas"
        />
        <label class="pago_estado">PAGO AO ESTADO</label>
        <input
          v-model="pago_estado"
          type="checkbox"
          class="input_pago_estado"
        />
        <button class="salvar_funcionario" @click.prevent="submitForm">
          SALVAR
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import NavbarAppHome from "../components/NavbarAppHome.vue";
import Sidebar from "../components/Sidebar.vue";
import useCollection from "../composables/useCollection";
import getCollection from "../composables/getCollection";
import getUser from "../composables/getUser";
import { useRouter } from "vue-router";
import { projectFirestore } from "../firebase/config";
import { onMounted } from "vue";

export default {
  components: { NavbarAppHome, Sidebar },

  setup() {
    const { addDoc, error } = useCollection("receitas");
    const { error2, documents } = getCollection("contrato");
    const { user } = getUser();
    const router = useRouter();
    const n_doc_receita = ref("");
    const beneficiario_receita = ref("");
    const natureza_receita = ref("");
    const data_entrada_receita = ref("");
    const valor_receita = ref("");
    const moeda_receita = ref("");
    const cambio_receita = ref("");
    const moeda_usd_receita = ref("");
    const descricao_receita = ref("");
    const sigfe_receita = ref("");
    const pago_estado = ref("");
    const user_update_id = ref("");
    const user_id = user.value.uid;
    const isEditingId = computed(() => route.value.params.id);

    const docRef = getCollection("receitas");
    if (docRef.value) {
      filteredDocuments.value = docRef.value.filter(
        isEditingId.value === user.value.uid
      );
      data.value = filteredDocuments.value;
    }

    const isEdit = ref(false);
    isEditingId.value = false;
    isEdit.value = false;

    const fetchCollectionData = async () => {
      try {
        const collectionRef = projectFirestore.collection("receitas");
        const snapshot = await collectionRef.get();
        const data = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        const filtered = data.filter((data) => data.userId === user_id);
        filtered.forEach((data) => {
          n_doc_receita.value = data.n_doc_receita;
          beneficiario_receita.value = data.beneficiario_receita;
          natureza_receita.value = data.natureza_receita;
          data_entrada_receita.value = data.data_entrada_receita;
          valor_receita.value = data.valor_receita;
          moeda_receita.value = data.moeda_receita;
          cambio_receita.value = data.cambio_receita;
          moeda_usd_receita.value = data.moeda_usd_receita;
          descricao_receita.value = data.descricao_receita;
          sigfe_receita.value = data.sigfe_receita;
          pago_estado.value = data.pago_estado;
          user_update_id.value = data.id;
        });
      } catch (error) {
        console.error("Error fetching collection data:", error);
      }
    };

    onMounted(fetchCollectionData);

    watch(user, () => {
      if (!user.value) {
        router.push({ name: "Login" });
      }
    });

    const handleEdit = async (id) => {
      try {
        const docRef = projectFirestore.collection("receitas").doc(id);
        await docRef.get().then(async (doc) => {
          if (doc.exists) {
            let id = await doc.id;
            new_obj.value = await { ...doc.data(), id };
            n_doc_receita.value = new_obj.value.n_doc_receita;
            beneficiario_receita.value = new_obj.value.beneficiario_receita;
            natureza_receita.value = new_obj.value.natureza_receita;
            data_entrada_receita.value = new_obj.value.data_entrada_receita;
            valor_receita.value = new_obj.value.valor_receita;
            moeda_receita.value = new_obj.value.moeda_receita;
            cambio_receita.value = new_obj.value.cambio_receita;
            moeda_usd_receita.value = new_obj.value.moeda_usd_receita;
            descricao_receita.value = new_obj.value.descricao_receita;
            sigfe_receita.value = new_obj.value.sigfe_receita;
            pago_estado.value = new_obj.value.pago_estado;
          }
        });
        isEdit.value = true;
        isEditingId.value = id;
      } catch (error) {
        console.log("Error deleting document:", error.message);
      }
    };

    const submitEdit = async () => {
      alert("submitEditsubmitEdit");
      const docRef = projectFirestore
        .collection("receitas")
        .doc(isEditingId.value);
      await docRef.update({
        n_doc_receita: n_doc_receita.value,
        beneficiario_receita: beneficiario_receita.value,
        natureza_receita: natureza_receita.value,
        data_entrada_receita: data_entrada_receita.value,
        valor_receita: valor_receita.value,
        moeda_receita: moeda_receita.value,
        cambio_receita: cambio_receita.value,
        moeda_usd_receita: moeda_usd_receita.value,
        descricao_receita: descricao_receita.value,
        sigfe_receita: sigfe_receita.value,
        pago_estado: pago_estado.value,
        userId: user.value.uid,
      });
      isEdit.value = false;
      n_doc_receita.value = null;
      beneficiario_receita.value = null;
      natureza_receita.value = null;
      data_entrada_receita.value = null;
      valor_receita.value = null;
      moeda_receita.value = null;
      cambio_receita.value = null;
      moeda_usd_receita.value = null;
      descricao_receita.value = null;
      sigfe_receita.value = null;
      pago_estado.value = null;
    };

    const submitForm = async () => {
      try {
        const docRef = projectFirestore
          .collection("receitas")
          .doc(user_update_id.value);
        await docRef.update({
          n_doc_receita: n_doc_receita.value,
          beneficiario_receita: beneficiario_receita.value,
          natureza_receita: natureza_receita.value,
          data_entrada_receita: data_entrada_receita.value,
          valor_receita: valor_receita.value,
          moeda_receita: moeda_receita.value,
          cambio_receita: cambio_receita.value,
          moeda_usd_receita: moeda_usd_receita.value,
          descricao_receita: descricao_receita.value,
          sigfe_receita: sigfe_receita.value,
          pago_estado: pago_estado.value,
        });

        router.push(`/receitas`);

        alert("updated successfully");
      } catch (err) {
        alert("updated ====");
        console.log("err==>", err);
      }
    };

    return {
      n_doc_receita,
      beneficiario_receita,
      natureza_receita,
      data_entrada_receita,
      valor_receita,
      moeda_receita,
      cambio_receita,
      moeda_usd_receita,
      descricao_receita,
      sigfe_receita,
      pago_estado,
      error,
      error2,
      documents,
      submitEdit,
      isEdit,
      isEditingId,
      user_id,
      submitForm,
    };
  },
};
</script>

<style scoped>
.cabecalho_configuracoes {
  width: 100%;
  left: 180px;
}
.titulo_configuracoes {
  position: absolute;
  color: white;
  width: 100%;
  height: 60px;
  left: 180px;
  top: 90px;
  background: #00344c;
}
.conteudo_titulo {
  position: absolute;
  width: 1271px;
  height: 20px;
  left: 0%;
  top: -10px;
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
}
.menu_configuracoes {
  width: 100%;
}

.btn_missao_diplomatica {
  position: absolute;
  width: 216px;
  height: 84px;
  left: 250px;
  top: 160px;
  background: #ffffff;
  border: 3px solid #51b8d5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #51b8d5;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  vertical-align: middle;
}
.btn_missao_diplomatica:hover {
  width: 216px;
  height: 84px;
  left: 250px;
  top: 160px;
  background: #00344c;
  border: 8px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: white;
}
.missao_diplomatica {
  position: absolute;
  width: 215px;
  height: 46px;
  left: 0px;
  top: 25%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #51b8d5;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.missao_diplomatica:hover {
  color: white;
}

.btn_orgaos {
  position: absolute;
  width: 216px;
  height: 84px;
  left: 485px;
  top: 160px;
  background: #ffffff;
  border: 3px solid #51b8d5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
  vertical-align: middle;
}

.btn_orgaos:hover {
  background: #00344c;
  color: white;
  background: #00344c;
  border: 8px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.orgaos {
  position: absolute;
  width: 209px;
  height: 20px;
  left: 0px;
  top: 40%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.orgaos:hover {
  color: white;
}

.btn_contas {
  position: absolute;
  width: 214px;
  height: 84px;
  left: 717px;
  top: 160px;
  background: #ffffff;
  border: 3px solid #51b8d5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.btn_contas:hover {
  background: #00344c;
  color: white;
  background: #00344c;
  border: 8px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.contas {
  position: absolute;
  width: 198px;
  height: 22px;
  left: 0px;
  top: 40%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.contas:hover {
  color: white;
}

.btn_funcionarios_empresas {
  position: absolute;
  width: 217px;
  height: 84px;
  left: 945px;
  top: 160px;
  background: #ffffff;
  border: 3px solid #51b8d5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.btn_funcionarios_empresas:hover {
  background: #00344c;
  color: white;
  background: #00344c;
  border: 8px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.funcionarios_empresas {
  position: absolute;
  width: 217px;
  height: 44px;
  left: 0px;
  top: 25%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.funcionarios_empresas:hover {
  color: white;
}

.btn_naturezas {
  position: absolute;
  width: 216px;
  height: 84px;
  left: 1176px;
  top: 160px;
  background: #ffffff;
  border: 3px solid #51b8d5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.btn_naturezas:hover {
  background: #00344c;
  color: white;
  background: #00344c;
  border: 8px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.naturezas {
  position: absolute;
  width: 216px;
  height: 22px;
  left: 0px;
  top: 40%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.naturezas:hover {
  color: white;
}

.form_container {
  box-sizing: border-box;
  position: absolute;
  width: 908px;
  height: 100%;
  left: 266px;
  top: 270px;
  background: #ffffff;
  border: 2px solid #51b8d5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.titulo_formulario {
  position: absolute;
  width: 907px;
  height: 20px;
  left: 1px;
  top: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: #4e4e4e;
}

.icon_back {
  position: absolute;
  width: 5px;
  height: 8px;
  left: 800px;
  top: 40px;
}

.n_doc_receitas {
  position: absolute;
  width: 444px;
  height: 32px;
  left: -158px;
  top: 70px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}

.beneficiario_receitas {
  position: absolute;
  width: 444px;
  height: 32px;
  left: 27px;
  top: 70px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_n_doc_receitas {
  box-sizing: border-box;
  position: absolute;
  width: 144px;
  height: 30px;
  left: 40px;
  top: 95px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.input_beneficiario_receitas {
  box-sizing: border-box;
  position: absolute;
  width: 562px;
  height: 30px;
  left: 210px;
  top: 95px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.natureza_receita {
  position: absolute;
  width: 444px;
  height: 32px;
  left: -120px;
  top: 140px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_natureza_receita {
  box-sizing: border-box;
  position: absolute;
  width: 490px;
  height: 30px;
  left: 40px;
  top: 165px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.data_entrada_receita {
  position: absolute;
  width: 444px;
  height: 32px;
  left: 378px;
  top: 140px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_data_entrada_receita {
  box-sizing: border-box;
  position: absolute;
  width: 223px;
  height: 30px;
  left: 550px;
  top: 165px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.valor_mensal_contrato {
  position: absolute;
  width: 444px;
  height: 32px;
  left: -140px;
  top: 205px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_valor_mensal_contrato {
  box-sizing: border-box;
  position: absolute;
  width: 220px;
  height: 30px;
  left: 40px;
  top: 230px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.moeda_contrato {
  position: absolute;
  width: 444px;
  height: 32px;
  left: 90px;
  top: 205px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_moeda_contrato {
  box-sizing: border-box;
  position: absolute;
  width: 482px;
  height: 30px;
  left: 290px;
  top: 230px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.forma_cobranca_contrato {
  position: absolute;
  width: 444px;
  height: 32px;
  left: -120px;
  top: 270px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_forma_cobranca_contrato {
  box-sizing: border-box;
  position: absolute;
  width: 250px;
  height: 30px;
  left: 40px;
  top: 295px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.tipo_valor_contrato {
  position: absolute;
  width: 444px;
  height: 32px;
  left: 135px;
  top: 270px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_tipo_valor_contrato {
  box-sizing: border-box;
  position: absolute;
  width: 200px;
  height: 30px;
  left: 315px;
  top: 295px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.caucao_contrato {
  position: absolute;
  width: 444px;
  height: 32px;
  left: 355px;
  top: 420px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_caucao_contrato {
  box-sizing: border-box;
  position: absolute;
  width: 220px;
  height: 30px;
  left: 550px;
  top: 445px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.data_inicio_contrato {
  position: absolute;
  width: 444px;
  height: 32px;
  left: -145px;
  top: 490px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_data_inicio_contrato {
  box-sizing: border-box;
  position: absolute;
  width: 250px;
  height: 30px;
  left: 40px;
  top: 515px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.data_termino_contrato {
  position: absolute;
  width: 444px;
  height: 32px;
  left: 375px;
  top: 490px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_data_termino_contrato {
  box-sizing: border-box;
  position: absolute;
  width: 223px;
  height: 30px;
  left: 550px;
  top: 515px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.observacoes_contrato {
  position: absolute;
  width: 330px;
  height: 32px;
  left: -80px;
  top: 330px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_observacoes_contrato {
  box-sizing: border-box;
  position: absolute;
  width: 730px;
  height: 100px;
  left: 40px;
  top: 357px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.salvar_funcionario {
  box-sizing: border-box;
  position: absolute;
  width: 132px;
  height: 30px;
  left: 641px;
  top: 540px;
  color: #fff;
  background: #51b8d5;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.sigfe_despesa {
  position: absolute;
  width: 59px;
  height: 32px;
  left: 80px;
  top: 480px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #00344c;
}
.input_sigfe_despesas {
  position: absolute;
  width: 15px;
  height: 15px;
  left: 53px;
  top: 480px;
  background: #51b8d5;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
}
.pago_estado {
  position: absolute;
  width: 207px;
  height: 32px;
  left: 190px;
  top: 480px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #00344c;
}
.input_pago_estado {
  position: absolute;
  width: 15px;
  height: 15px;
  left: 190px;
  top: 480px;
  background: #ffcc00;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
}
</style>
