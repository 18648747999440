<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="dashboard_main">
            <router-link to="/Configuracao" style="text-decoration: none; color: inherit;"> 
                <div class="btn_configuracao">
                    <p class="detalhe_configuracao">CONFIGURAÇÕES</p>
                    <img class="icon_config" src="../assets/Icons/config.png" alt="icon de configuracao">
                </div>
            </router-link>
            <router-link to="/Orcamento" style="text-decoration: none; color: inherit;"> 
                <div class="btn_orcamento">
                    <p class="detalhe_orcamento">ORÇAMENTO</p>
                    <img class="icon_orcamento" src="../assets/Icons/budget.png" alt="icon de configuracao">
                </div>
            </router-link>
            <router-link to="/Financas" style="text-decoration: none; color: inherit;"> 
                <div class="btn_Financas">
                    <p class="detalhe_financas">FINANÇAS</p>
                    <img class="icon_financas" src="../assets/Icons/moneybag-01.png" alt="icon de configuracao">
                </div>
            </router-link>
            <router-link to="/GestaoDocumental" style="text-decoration: none; color: inherit;"> 
            <div class="btn_gestao_documental">
                <p class="detalhe_gestao_documental">GESTÃO DOCUMENTAL</p>
                <img class="icon_gestao_documental" src="../assets/Icons/gestao_doc.png" alt="icon de configuracao">
            </div>
            </router-link>
            <router-link to="/Relatorios" style="text-decoration: none; color: inherit;"> 
                <div class="btn_relatorios">
                    <p class="detalhe_relatorios">RELATÓRIOS</p>
                    <img class="icon_relatorios" src="../assets/Icons/Filesa-03.png" alt="icon de configuracao">
                </div>
            </router-link>
            <router-link to="/TribunalContas" style="text-decoration: none; color: inherit;"> 
                <div class="btn_tribunal_de_contas">
                    <p class="detalhe_tribunal_de_contas">TRIBUNAL DE CONTAS</p>
                    <img class="icon_tribunal_de_contas" src="../assets/Icons/Icon_Tribunal.png" alt="icon de configuracao">
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import { watch } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'
export default {
    components: { NavbarAppHome, Sidebar },
    setup() {
        const { user } = getUser()
        const router = useRouter()

        watch(user, () => {
            if  (!user.value) {
                router.push({ name: 'Login'})
            }
        })
    }
}
</script>

<style>
.btn_configuracao{
    position: absolute;
    width: 272px;
    height: 120px;
    left: 315px;
    top: 114px;
    background: #51B8D5;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
}

.btn_orcamento{
    position: absolute;
    width: 272px;
    height: 120px;
    left: 650px;
    top: 114px;
    background: #51B8D5;
    border: 8px solid #FCFCFC;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
}

.btn_Financas{
    position: absolute;
    width: 272px;
    height: 120px;
    left: 980px;
    top: 114px;
    background: #51B8D5;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
}

.btn_gestao_documental{
    position: absolute;
    width: 436px;
    height: 111px;
    left: 315px;
    top: 300px;
    background: #51B8D5;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
}

.btn_relatorios{
    position: absolute;
    width: 436px;
    height: 111px;
    left: 315px;
    top: 420px;
    background: #51B8D5;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
}
.btn_tribunal_de_contas{
    position: absolute;
    width: 456px;
    height: 239px;
    left: 795px;
    top: 300px;
    background: #51B8D5;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
}

.detalhe_configuracao{
    position: absolute;
    width: 242px;
    height: 39px;
    left: 37px;
    top: 15px;
    font-family: Signika;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 37px;
    color: #ffffff;
    cursor: pointer;
}

.detalhe_orcamento{
    position: absolute;
    width: 241px;
    height: 39px;
    left: 45px;
    top: 15px;
    font-family: Signika;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 37px;
    color: #ffffff;
    cursor: pointer;
}

.detalhe_financas{
    position: absolute;
    width: 146px;
    height: 39px;
    left: 65px;
    top: 15px;
    font-family: Signika;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 37px;
    color: #ffffff;
    cursor: pointer;
}

.detalhe_gestao_documental{
    position: absolute;
    width: 576px;
    height: 42px;
    left: -60px;
    top: 20px;
    font-family: Signika;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 37px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

.detalhe_relatorios{
    position: absolute;
    width: 576px;
    height: 42px;
    left: -80px;
    top: 30px;
    font-family: Signika;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 37px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

.detalhe_tribunal_de_contas{
    position: absolute;
    width: 576px;
    height: 49px;
    left: -60px;
    top: 15px;
    font-family: Signika;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 37px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

.icon_config{
    position: absolute;
    width: 120px;
    height: 90px;
    left: -20px;
    top: 5px;
    cursor: pointer;
}

.icon_orcamento{
    position: absolute;
    width: 63px;
    height: 63px;
    left: 15px;
    top: 25px;
    mix-blend-mode: normal;
    cursor: pointer;
}

.icon_financas{
    position: absolute;
    width: 90px;
    height: 80px;
    left: 0px;
    top: 20px;
    cursor: pointer;
}

.icon_gestao_documental{
    position: absolute;
    width: 103px;
    height: 73px;
    left: -10px;
    top: 14px;
    mix-blend-mode: normal;
    cursor: pointer;
}

.icon_relatorios{
    position: absolute;
    width: 103px;
    height: 83px;
    left: -5px;
    top: 14px;
    mix-blend-mode: normal;
    cursor: pointer;
}

.icon_tribunal_de_contas{
    position: absolute;
    width: 194px;
    height: 137px;
    left: 120px;
    top: 70px;
    cursor: pointer;
}
</style>