<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">TRANSFERÊNCIAS</h1>
            </div>
            <div class="menu_configuracoes">
                <router-link to="/Receitas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_missao_diplomatica">
                        RECEITAS 
                    </div>
                </router-link>
                <router-link to="/Despesas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_orgaos">
                        DESPESAS
                    </div>
                </router-link>
                <router-link to="/Transferencias" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_contas">
                        TRANSFERÊNCIAS
                    </div>
                </router-link>
                <router-link to="/Salario" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_salarios">
                        SALÁRIOS
                    </div>
                </router-link>
            </div>
        </div>
        <div class="form_container">
            <h1 class="titulo_formulario">REGISTAR TRANSFERÊNCIA</h1>
            <router-link to="/Transferencias" style="text-decoration: none; color: inherit;">
                <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
            </router-link>
            <form @submit.prevent="handleSubmit">
                <label class="situacao_transferencia">Situação</label>
                <input required v-model="situacao_transferencia" class="input_situacao_transferencia" type="text">
                <label class="data_registro_transferencia">Data de Registro</label>
                <input required v-model="data_registro_transferencia" class="input_data_registro_transferencia" type="date">
                <label class="departamento_origem_transferencia">Departamento de Origem</label>
                <select required v-model="orgao_origem_transferencia" class="input_departamento_origem_transferencia">
                    <option disabled value="">--Selecione o Departamento--</option>
                    <option value="Teste 1">Teste 1</option>
                    <option value="Teste 2"> Teste 2</option>
                </select>
                <label class="conta_origem_transferencia">Nº Conta Origem</label>
                <select required v-model="conta_origem_transferencia" class="input_conta_origem_transferencia">
                    <option disabled value="">--Selecione a Conta--</option>
                    <option value="conta teste 1">conta teste 1</option>
                    <option value="conta teste 2">conta teste 2</option>
                </select>
                <label class="valor_origem_transferencia">Valor Origem</label>
                <input required v-model="valor_origem_transferencia" class="input_valor_origem_transferencia" type="text">
                <label class="departamento_destino_transferencia">Departamento de Destino</label>
                <select required v-model="orgao_destino_transferencia" class="input_departamento_destino_transferencia">
                    <option disabled value="">--Selecione o Departamento--</option>
                    <option value="Teste 3">Teste 3</option>
                    <option value="Teste 4">Teste 4</option>
                </select>
                <label class="conta_destino_transferencia">Nº Conta destino</label>
                <select required v-model="conta_destino_transferencia" class="input_conta_destino_transferencia">
                    <option disabled value="">--Selecione a Conta--</option>
                    <option value="conta teste 3">conta teste 3</option>
                    <option value="conta teste 4">conta teste 4</option>
                </select>
                <label class="valor_destino_transferencia">Valor Destino</label>
                <input required v-model="valor_destino_transferencia" class="input_valor_destino_transferencia" type="text">
                <label class="descricao_transferencia">Descrição</label>
                <textarea v-model="descricao_transferencia" class="input_descricao_transferencia"></textarea>
                <button class="salvar_funcionario">SALVAR</button>

            </form>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'
export default {
    components: { NavbarAppHome, Sidebar },

    setup() {
        const { addDoc, error } = useCollection('transferencia')
        const { error2, documents } = getCollection('contrato')

        const { user } = getUser()
        const router = useRouter()

        watch(user, () => {
            if  (!user.value) {
                router.push({ name: 'Login'})
            }
        })

        const situacao_transferencia = ref('')
        const data_registro_transferencia = ref('')
        const orgao_origem_transferencia = ref('')
        const conta_origem_transferencia = ref('')
        const valor_origem_transferencia = ref('')
        const orgao_destino_transferencia = ref('')
        const conta_destino_transferencia = ref('')
        const valor_destino_transferencia = ref('')
        const descricao_transferencia = ref('')

        const handleSubmit = async () => {
            const transferencia = {
                situacao_transferencia: situacao_transferencia.value,
                data_registro_transferencia: data_registro_transferencia.value,
                orgao_origem_transferencia: orgao_origem_transferencia.value,
                conta_origem_transferencia: conta_origem_transferencia.value,
                valor_origem_transferencia: valor_origem_transferencia.value,
                orgao_destino_transferencia: orgao_destino_transferencia.value,
                conta_destino_transferencia: conta_destino_transferencia.value,
                valor_destino_transferencia: valor_destino_transferencia.value,
                descricao_transferencia: descricao_transferencia.value,
                userId: user.value.uid

            }
            await addDoc(transferencia)
            if (!error.value) { 
                situacao_transferencia.value = ''
                data_registro_transferencia.value = ''
                orgao_origem_transferencia.value = ''
                conta_origem_transferencia.value = ''
                valor_origem_transferencia.value = ''
                orgao_destino_transferencia.value = ''
                conta_destino_transferencia.value = ''
                valor_destino_transferencia.value = ''
                descricao_transferencia.value = ''
            }
            alert('DADOS INSERIDOS COM SUCESSO!')
        }
        return { situacao_transferencia, data_registro_transferencia, orgao_origem_transferencia,
                conta_origem_transferencia, valor_origem_transferencia, orgao_destino_transferencia,
                conta_destino_transferencia, valor_destino_transferencia, descricao_transferencia, error, 
                handleSubmit, error2, documents }
    }
    
}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 100%;
    left: 180px;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: 0%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.menu_configuracoes{
    width: 100%;
}

.btn_missao_diplomatica{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    vertical-align: middle;
}
.btn_missao_diplomatica:hover {
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
}
.missao_diplomatica{
    position: absolute;
    width: 215px;
    height: 46px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.missao_diplomatica:hover {
  color: white;
}

.btn_orgaos{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 485px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
    vertical-align: middle;
}

.btn_orgaos:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.orgaos{
    position: absolute;
    width: 209px;
    height: 20px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.orgaos:hover {
  color: white;
}

.btn_contas{
    position: absolute;
    width: 214px;
    height: 84px;
    left: 717px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_contas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.contas{
    position: absolute;
    width: 198px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.contas:hover {
    color: white;
}

.btn_funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 945px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_funcionarios_empresas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.funcionarios_empresas:hover {
    color: white;
}

.btn_naturezas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1176px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_naturezas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.naturezas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.naturezas:hover {
    color: white;
}

.form_container{
    box-sizing: border-box;
    position: absolute;
    width: 908px;
    height: 100%;
    left: 266px;
    top: 270px;
    background: #FFFFFF;
    border: 2px solid #51B8D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.titulo_formulario{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 1px;
    top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
}

.icon_back{
    position: absolute;
    width: 5px;
    height: 8px;
    left: 800px;
    top: 40px;
}
.situacao_transferencia{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -105px;
    top: 108px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}

.input_situacao_transferencia{
    box-sizing: border-box;
    position: absolute;
    width: 441px;
    height: 30px;
    left: 90px;
    top: 135px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.data_registro_transferencia{
    position: absolute;
    width: 214px;
    height: 32px;
    left: 500px;
    top: 108px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_data_registro_transferencia{
    box-sizing: border-box;
    position: absolute;
    width: 227px;
    height: 30px;
    left: 555px;
    top: 135px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.departamento_origem_transferencia{
    position: absolute;
    width: 214px;
    height: 32px;
    left: 65px;
    top: 194px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}

.input_departamento_origem_transferencia{
    box-sizing: border-box;
    position: absolute;
    width: 695px;
    height: 30px;
    left: 90px;
    top: 220px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.conta_origem_transferencia{
    position: absolute;
    width: 251px;
    height: 32px;
    left: 20px;
    top: 262px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}

.input_conta_origem_transferencia{
    box-sizing: border-box;
    position: absolute;
    width: 439px;
    height: 30px;
    left: 90px;
    top: 288px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.valor_origem_transferencia{
    position: absolute;
    width: 176px;
    height: 32px;
    left: 508px;
    top: 262px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_valor_origem_transferencia{
    box-sizing: border-box;
    position: absolute;
    width: 227px;
    height: 30px;
    left: 555px;
    top: 288px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.departamento_destino_transferencia{
    position: absolute;
    width: 214px;
    height: 32px;
    left: 65px;
    top: 330px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}

.input_departamento_destino_transferencia{
    box-sizing: border-box;
    position: absolute;
    width: 690px;
    height: 30px;
    left: 90px;
    top: 355px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.conta_destino_transferencia{
    position: absolute;
    width: 251px;
    height: 32px;
    left: 20px;
    top: 400px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}

.input_conta_destino_transferencia{
    box-sizing: border-box;
    position: absolute;
    width: 439px;
    height: 30px;
    left: 90px;
    top: 425px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.valor_destino_transferencia{
    position: absolute;
    width: 176px;
    height: 32px;
    left: 508px;
    top: 400px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_valor_destino_transferencia{
    box-sizing: border-box;
    position: absolute;
    width: 227px;
    height: 30px;
    left: 555px;
    top: 425px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.descricao_transferencia{
    position: absolute;
    width: 133px;
    height: 32px;
    left: 55px;
    top: 470px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #51B8D5;
}

.input_descricao_transferencia{
    box-sizing: border-box;
    position: absolute;
    width: 690px;
    height: 54px;
    left: 90px;
    top: 493px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.salvar_funcionario{
    box-sizing: border-box;
    position: absolute;
    width: 132px;
    height: 30px;
    left: 650px;
    top: 580px;
    color: #fff;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

</style>