<template>
    <div>
      <div>
        <NavbarAppHome />
      </div>
      <div>
        <Sidebar />
      </div>
      <div class="cabecalho_configuracoes">
        <div class="titulo_configuracoes">
          <h1 class="conteudo_titulo">EDITAR CONTAS</h1>
        </div>
        <ConfiguracaoNav/>
      </div>
      <div class="form_container">
        <h1 class="titulo_formulario">EDITAR CONTA</h1>
        <router-link to="/Contas" style="text-decoration: none; color: inherit">
          <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
        </router-link>
        <form @submit.prevent="isEdit ? submitEdit() : handleSubmit">
          <label class="nome_conta">Nome</label>
          <input required v-model="nome_conta" class="input_nome_conta" type="text">
          <label class="tipo_conta">Tipo</label>
          <select required v-model="tipo_conta" class="input_tipo_conta">
              <option disabled value="">Por favor escolha uma opção</option>
              <option value="Banco">Banco</option>
              <option value="Caixa">Caixa</option>
          </select>
          <label class="numero_conta">Número</label>
          <input required v-model="numero_conta" class="input_numero_conta" type="text">
          <label class="banco_conta">Nome do Banco</label>
          <input required v-model="banco_conta" class="input_banco_conta" type="text">
          <label class="moeda_conta">Moeda</label>
          <select required v-model="moeda_conta" class="input_moeda_conta">
              <option disabled value="">Por favor escolha uma opção</option>
              <option v-for="moe in moedaDatas" :key="moe.id" :value="moe.moeda_local">{{ moe.moeda_local }}</option>
              <option v-for="moe in moedaDatas" :key="moe.id" :value="moe.moeda_gestao">{{ moe.moeda_gestao }}</option>
              <option v-for="moe in moedaDatas" :key="moe.id" :value="moe.outra_moeda">{{ moe.outra_moeda }}</option>
          </select>
          <input type="hidden" :value="moeda_conta">
          <label class="orgao_conta">Orgão</label>
          <select required v-model="orgao_conta" class="input_orgao_conta">
              <option disabled value="">Por favor escolha uma opção</option>
              <option v-for="orgao in filteredDocuments" :key="orgao.id" :value="orgao.nome_orgao" v-show="orgao.sigfe_orgao">{{ orgao.nome_orgao }}</option>
          </select>
          <input type="hidden" :value="orgao_conta">
          <label class="data_abertura_conta">Data de Abertura</label>
          <input required v-model="data_abertura_conta" class="input_data_abertura_conta" type="date">
          <label class="saldo_conta">Saldo Inicial {{ data }}</label>
          <input required v-model="saldo_conta" class="input_saldo_conta" type="text" @focusout="handleKeyUp" >
          <label class="data_saldo_inicial">Data de Saldo Inicial</label>
          <input required v-model="data_saldo_inicial" class="input_data_saldo_inicial" type="date">
          <label class="check_recebe_emolumento">Recebe Emolumentos</label>
          <input v-model="recebe_emolumentos_conta" type="checkbox" class="input_emolumentos_check">
          <label class="check_conta_conjunta_orgao">Conta Conjunta</label>
          <input v-model="conta_conjunta" type="checkbox" class="input_conta_conjunta_check">
          <button class="salvar_conta" @click.prevent="submitForm">SALVAR</button>
        </form>
      </div>
    </div>
  </template>
  
<script>
import { computed, ref, watch } from "vue";
import NavbarAppHome from "../components/NavbarAppHome.vue";
import Sidebar from "../components/Sidebar.vue";
import useCollection from "../composables/useCollection";
import getCollection from "../composables/getCollection";
import getUser from "../composables/getUser";
import { useRoute, useRouter } from "vue-router";
import { projectFirestore } from "../firebase/config";
import { onMounted } from "vue";
import ConfiguracaoNav from '../components/ConfiguracaoNav.vue'
import ListaMoeda from '../components/ListaMoeda.vue'
  
export default {
  components: { NavbarAppHome, Sidebar, ConfiguracaoNav,ListaMoeda },

  setup() {
    const { addDoc, error } = useCollection("conta");
    const { error2, documents } = getCollection("orgaos");
    const { error2 :error_moeda, documents: moeda } = getCollection("moeda");
    const { user } = getUser();
    const route = useRoute()
    const router = useRouter();
    const filteredDocuments = ref([]);
    const moedaDatas = ref([]);
    const user_update_id = ref("");
    const nome_conta = ref("");
    const tipo_conta = ref("");
    const data = ref("");
    const estado = ref(true);
    const user_id = user.value.uid;
    const numero_conta = ref("");
    const banco_conta = ref("");
    const moeda_conta = ref("");
    const orgao_conta = ref('');
    const data_abertura_conta = ref("");
    const saldo_conta = ref('00.00')
    const data_saldo_inicial = ref("");
    const recebe_emolumentos_conta = ref("");
    const conta_conjunta = ref("");
    const decimalValue = ref('')
  
    const handleKeyUp = ()=> {
        saldo_conta.value = parseFloat(saldo_conta.value).toFixed(2);
    }
    const isEditingId = computed(() => route.params.id);
    const docRef = getCollection("conta");
    if (docRef.value) {
      filteredDocuments.value = docRef.value.filter(
        isEditingId.value === user.value.uid
      );
      data.value = filteredDocuments.value;
    }

    const isEdit = ref(false);
    isEditingId.value = false;
    isEdit.value = false;

    const fetchCollectionData = async () => {
      try {
        const collectionRef = projectFirestore.collection("conta");
        const snapshot = await collectionRef.get();
        const dataaaa = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        const filtered = dataaaa.filter((dataaaa1) => dataaaa1.id === isEditingId.value);
        filtered.forEach((data1) => {
          nome_conta.value = data1.nome_conta;
          tipo_conta.value = data1.tipo_conta;
          numero_conta.value = data1.numero_conta;
          banco_conta.value = data1.banco_conta;
          moeda_conta.value = data1.moeda_conta;
          orgao_conta.value = data1.orgao_conta;
          data_abertura_conta.value = data1.data_abertura_conta;
          saldo_conta.value = data1.saldo_conta;
          data_saldo_inicial.value = data1.data_saldo_inicial;
          recebe_emolumentos_conta.value = data1.recebe_emolumentos_conta;
          conta_conjunta.value = data1.conta_conjunta;
          user_update_id.value = data1.id;
        });
      } catch (error) {
        console.error("Error fetching collection data:", error);
      }
    };

    onMounted(fetchCollectionData);

    watch(user, () => {
      if (!user.value) {
        router.push({ name: "Login" });
      }
    });
    const filterDocuments = () => {
          if (documents.value) {
          filteredDocuments.value = documents.value.filter(
              (doc) => {
              return doc.userId === user.value.uid
              }
          );
          }
      };
      filterDocuments();
  
      watch([documents, user], () => {
        filterDocuments();
      });
      //=================================== moeda data =================================== 
      const MoedaFilter = () => {
          if (moeda.value) {
          moedaDatas.value = moeda.value.filter(
              (doc) => {
              return doc.userId === user.value.uid
              }
          );
          }
      };
      MoedaFilter();
  
      watch([moeda, user], () => {
          MoedaFilter();
      });

    const handleEdit = async (id) => {
      try {
        const docRef = projectFirestore.collection("conta").doc(id);
        await docRef.get().then(async (doc) => {
          if (doc.exists) {
            let id = await doc.id;
            new_obj.value = await { ...doc.data(), id };
            nome_conta.value = new_obj.value.nome_conta;
            tipo_conta.value = new_obj.value.tipo_conta;
            numero_conta.value = new_obj.value.numero_conta;
            banco_conta.value = new_obj.value.banco_conta;
            moeda_conta.value = new_obj.value.moeda_conta;
            orgao_conta.value = new_obj.value.orgao_conta;
            data_abertura_conta.value = new_obj.value.data_abertura_conta;
            saldo_conta.value = new_obj.value.saldo_conta;
            data_saldo_inicial.value = new_obj.value.data_saldo_inicial;
            recebe_emolumentos_conta.value =
              new_obj.value.recebe_emolumentos_conta;
            conta_conjunta.value = new_obj.value.conta_conjunta;
          }
        });
        isEdit.value = true;
        isEditingId.value = id;
      } catch (error) {
        console.log("Error deleting document:", error.message);
      }
    };

    const submitEdit = async () => {
      alert("submitEditsubmitEdit");
      const docRef = projectFirestore
        .collection("conta")
        .doc(isEditingId.value);
      await docRef.update({
        nome_conta: nome_conta.value,
        tipo_conta: tipo_conta.value,
        numero_conta: numero_conta.value,
        banco_conta: banco_conta.value,
        moeda_conta: moeda_conta.value,
        orgao_conta: orgao_conta.value,
        data_abertura_conta: data_abertura_conta.value,
        saldo_conta: saldo_conta.value,
        data_saldo_inicial: data_saldo_inicial.value,
        recebe_emolumentos_conta: recebe_emolumentos_conta.value,
        conta_conjunta: conta_conjunta.value,
        userId: user.value.uid,
      });
      isEdit.value = false;
      nome_conta.value = null;
      tipo_conta.value = null;
      numero_conta.value = null;
      banco_conta.value = null;
      moeda_conta.value = null;
      orgao_conta.value = null;
      data_abertura_conta.value = null;
      saldo_conta.value = null;
      data_saldo_inicial.value = null;
      recebe_emolumentos_conta.value = null;
      conta_conjunta.value = null;
    };
    
    const submitForm = async () => {
      try {
        const docRef = projectFirestore
          .collection("conta")
          .doc(user_update_id.value);
        await docRef.update({
          nome_conta: nome_conta.value,
          tipo_conta: tipo_conta.value,
          numero_conta: numero_conta.value,
          banco_conta: banco_conta.value,
          moeda_conta: moeda_conta.value,
          orgao_conta: orgao_conta.value,
          data_abertura_conta: data_abertura_conta.value,
          saldo_conta: saldo_conta.value,
          data_saldo_inicial: data_saldo_inicial.value,
          recebe_emolumentos_conta: recebe_emolumentos_conta.value,
          conta_conjunta: conta_conjunta.value,
        });

        router.push(`/Contas`);

        alert("updated successfully");
      } catch (err) {
        alert("updated ====");
        console.log("err==>", err);
      }
    };

    return {
      estado,
      handleKeyUp, 
      decimalValue,
      nome_conta,
      tipo_conta,
      numero_conta,
      banco_conta,
      moeda_conta,
      orgao_conta,
      data_abertura_conta,
      saldo_conta,
      data_saldo_inicial,
      recebe_emolumentos_conta,
      conta_conjunta,
      error,
      handleEdit,
      error2,
      documents,
      submitEdit,
      isEdit,
      isEditingId,
      data,
      user_id,
      moeda,
      submitForm,
      filteredDocuments,
      moedaDatas
    };
  },
};
</script>
  
<style scoped>
  .cabecalho_configuracoes{
      width: 100%;
      left: 180px;
  }
  .titulo_configuracoes{
      position: absolute;
      color: white;
      width: 100%;
      height: 60px;
      left: 180px;
      top: 90px;
      background: #00344C;
  }
  .conteudo_titulo{
      position: absolute;
      width: 1271px;
      height: 20px;
      left: 0%;
      top: -10px;
      font-family: Signika;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 60px;
      text-align: center;
      color: #FFFFFF;
  }
  
  .missao_diplomatica{
      position: absolute;
      width: 215px;
      height: 46px;
      left: 0px;
      top: 25%;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #51B8D5;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
  }
  
  .missao_diplomatica:hover {
    color: white;
  }
  
  
  .orgaos{
      position: absolute;
      width: 209px;
      height: 20px;
      left: 0px;
      top: 40%;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #51B8D5;
      cursor: pointer;
  }
  
  .orgaos:hover {
    color: white;
  }
  
  .contas{
      position: absolute;
      width: 198px;
      height: 22px;
      left: 0px;
      top: 40%;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */
      text-align: center;
      color: #51B8D5;
      cursor: pointer;
  }
  
  .contas:hover {
      color: white;
  }
  .funcionarios_empresas{
      position: absolute;
      width: 217px;
      height: 44px;
      left: 0px;
      top: 25%;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #51B8D5;
      cursor: pointer;
  }
  
  .funcionarios_empresas:hover {
      color: white;
  }
  
  .naturezas{
      position: absolute;
      width: 216px;
      height: 22px;
      left: 0px;
      top: 40%;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */
      text-align: center;
      color: #51B8D5;
      cursor: pointer;
  }
  
  .naturezas:hover {
      color: white;
  }
  
  .form_container{
      box-sizing: border-box;
      position: absolute;
      width: 908px;
      height: 639px;
      left: 266px;
      top: 270px;
      background: #FFFFFF;
      border: 2px solid #51B8D5;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
  }
  
  .titulo_formulario{
      position: absolute;
      width: 907px;
      height: 20px;
      left: 1px;
      top: 10px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      text-decoration-line: underline;
      color: #4E4E4E;
  }
  
  .icon_back{
      position: absolute;
      width: 5px;
      height: 8px;
      left: 800px;
      top: 40px;
  }
  
  .nome_conta{
      position: absolute;
      width: 444px;
      height: 32px;
      left: -160px;
      top: 70px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.011em;
      color: #51B8D5;
  }
  
  .tipo_conta{
      position: absolute;
      width: 444px;
      height: 32px;
      left: 340px;
      top: 70px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.011em;
      color: #51B8D5;
  
  }
  .input_nome_conta{
      box-sizing: border-box;
      position: absolute;
      width: 444px;
      height: 30px;
      left: 40px;
      top: 95px;
      background: #FFFFFF;
      border: 1px solid #C4C4C4;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  .input_tipo_conta{
      box-sizing: border-box;
      position: absolute;
      width: 223px;
      height: 30px;
      left: 550px;
      top: 95px;
      background: #FFFFFF;
      border: 1px solid #C4C4C4;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .numero_conta{
      position: absolute;
      width: 444px;
      height: 32px;
      left: -150px;
      top: 140px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.011em;
      color: #51B8D5;
  }
  .input_numero_conta{
      box-sizing: border-box;
      position: absolute;
      width: 446px;
      height: 30px;
      left: 40px;
      top: 165px;
      background: #FFFFFF;
      border: 1px solid #C4C4C4;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .banco_conta{
      position: absolute;
      width: 444px;
      height: 32px;
      left: 380px;
      top: 140px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.011em;
      color: #51B8D5;
  }
  .input_banco_conta{
      box-sizing: border-box;
      position: absolute;
      width: 223px;
      height: 30px;
      left: 550px;
      top: 165px;
      background: #FFFFFF;
      border: 1px solid #C4C4C4;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .moeda_conta{
      position: absolute;
      width: 444px;
      height: 32px;
      left: 350px;
      top: 200px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.011em;
      color: #51B8D5;
  }
  .input_moeda_conta{
      box-sizing: border-box;
      position: absolute;
      width: 223px;
      height: 30px;
      left: 550px;
      top: 230px;
      background: #FFFFFF;
      border: 1px solid #C4C4C4;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .data_abertura_conta{
      position: absolute;
      width: 330px;
      height: 32px;
      left: -65px;
      top: 200px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.011em;
      color: #51B8D5;
  }
  .input_data_abertura_conta{
      box-sizing: border-box;
      position: absolute;
      width: 300px;
      height: 30px;
      left: 40px;
      top: 230px;
      background: #FFFFFF;
      border: 1px solid #C4C4C4;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .saldo_conta{
      position: absolute;
      width: 444px;
      height: 32px;
      left: -135px;
      top: 350px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.011em;
      color: #51B8D5;
  }
  .input_saldo_conta{
      box-sizing: border-box;
      position: absolute;
      width: 300px;
      height: 30px;
      left: 40px;
      top: 380px;
      background: #FFFFFF;
      border: 1px solid #C4C4C4;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .data_saldo_inicial{
      position: absolute;
      width: 332px;
      height: 32px;
      left: 320px;
      top: 350px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.011em;
      color: #51B8D5;
  }
  
  .input_data_saldo_inicial{
      box-sizing: border-box;
      position: absolute;
      width: 300px;
      height: 30px;
      left: 420px;
      top: 380px;
      background: #FFFFFF;
      border: 1px solid #C4C4C4;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .orgao_conta{
      position: absolute;
      width: 332px;
      height: 32px;
      left: -100px;
      top: 270px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.011em;
      color: #51B8D5;
  }
  .input_orgao_conta{
      box-sizing: border-box;
      position: absolute;
      width: 500px;
      height: 30px;
      left: 40px;
      top: 300px;
      background: #FFFFFF;
      border: 1px solid #C4C4C4;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .input_emolumentos_check{
      position: absolute;
      width: 15px;
      height: 15px;
      left: 40px;
      top: 450px;
      background: #51B8D5;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
  }
  .check_recebe_emolumento{
      position: absolute;
      width: 396px;
      height: 32px;
      left: -55px;
      top: 450px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.011em;
      color: #00344C;
  }
  .check_conta_conjunta_orgao{
      position: absolute;
      width: 396px;
      height: 32px;
      left: 145px;
      top: 450px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.011em;
      color: #00344C;
  }
  .input_conta_conjunta_check{
      position: absolute;
      width: 15px;
      height: 15px;
      left: 260px;
      top: 450px;
      background: #51B8D5;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
  }
  .salvar_conta{
      box-sizing: border-box;
      position: absolute;
      width: 132px;
      height: 30px;
      left: 641px;
      top: 470px;
      color: #fff;
      background: #51B8D5;
      border: 1px solid #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .moedas{
      position: absolute;
      width: 216px;
      height: 22px;
      left: 0px;
      top: 40%;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */
      text-align: center;
      color: #51B8D5;
      cursor: pointer;
  }
  .moedas:hover {
      color: white;
  }
  </style>