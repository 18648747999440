<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">MODELO 2A - MAPA ADICIONAL DA CONTA DE GESTÃO</h1>
            </div>
        </div>
        <div>
    
        <div>
            <span class="icon_download">
                <i class="fa-solid fa-download" @click="generatePDF"></i>
            </span>
            <span class="icon_print">
                <i class="fa-solid fa-print"></i>
            </span>
             <router-link to="/Modelos" style="text-decoration: none; color: inherit;">
                <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
            </router-link>
            <div class="holderrelatorio">
               <table>
                   <table>
                       <tr>
                       <td class="numero_modelo" colspan="4">Modelo Nº 2A</td>
                        </tr>
                        <tr>
                            <td class="nome_modelo" colspan="4"><b>MAPA ADICIONAL DA CONTA DE GESTÃO</b></td>
                        </tr>
                        <tr>
                            <td class="modelo_top" colspan="4">EMBAIXADA DA REPUBLICA DE ANGOLA NO JAPÃO</td>
                        </tr>
                        <tr>
                            <td class="modelo_top" colspan="4">Gestão de: 1 de Janeiro a 31 de Dezembro 2020</td>
                        </tr>
                   </table>
                   <table>
                        <tr>
                            <td class="dados_tabela"><b>ESPECIFICAÇÕES</b></td>
                            <td class="dados_tabela_titulo_right"><b>USD</b></td>
                            <td class="dados_tabela_titulo_right"><b>JPY</b></td>
                            <td class="dados_tabela_titulo_right"><b>EUR</b></td>
                            <td class="dados_tabela"><b>ESPECIFICAÇÕES</b></td>
                            <td class="dados_tabela_titulo_right"><b>USD</b></td>
                            <td class="dados_tabela_titulo_right"><b>JPY</b></td>
                            <td class="dados_tabela_titulo_right"><b>EUR</b></td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">1+H15:J47. Saldo da Gestão Anterior (1.1+1.2+1.3+1.4)</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">1. Saidas da Gestão Corrente (1.1+1.2+1.3+1.4)</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                            <tr>
                            <td class="dados_tabela">1.1 Dotações Orçamentais</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">1.1 Despesas Orçamentais Pagas</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                            <tr>
                            <td class="dados_tabela">1.2 Receitas Próprias</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">Pessoal (Valor Iliquido)</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                            <tr>
                            <td class="dados_tabela">Receitas Emolumentares</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">Contribuição do Empregador</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">Outras Receitas (Especificar em Anexo)</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">Bens e Serviços</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">Descontos em Vencimento e Salários:</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">Subsídios e transferências Correntes</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">IRT</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">Capital</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">S.Social</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">Outras (Especificar em Anexo)</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">10% Cativos</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">1.2 Despesas Pagas com Receitas Proprias (especificar em anexo)</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">1.4 Outros (Especificar em anexo)</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">1.3 Importancias Entregues ao Estado ou Outras Entes (1.3.1+1.3.2)</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">2. Entradas da Gestão Corrente (2.1+2.2+2.3+2.4)</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">1.3.1 Receitas do Estado</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">2.1 Receitas Orcamentais Recebidas, Destinadas a Pagar as Seguintes Despesas:</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">Receitas Emolumentares</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">Pessoal (Valor Iliquido)</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">Outras Receitas (Especificar em Anexo)</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">Contribuição do Empregador</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">1.3.2 Descontos em Vencimentos e Salarios</td>
                            <td class="dados_tabela_right">0</td>
                             <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">Bens e Serviços</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">IRT</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">Subsídios e Transferências Correntes</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">S.Social</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">Capital</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">10% Cativos</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">Aquisição de Moeda</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">Aquisição de Moeda</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">Outras Entradas (Especificar em Anexo)</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">2. Saldo da Gestão Seguinte ((2.1+2.2+2.3+2.4))</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                            <tr>
                            <td class="dados_tabela">2.2 Receitas Próprias Arrecadadas </td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">2.1 Dotação Orçamentais</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">Taxas </td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">2.2 Receitas Próprias</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">Juros</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">Receitas Emolumentares</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">Emolumentos</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">Outras receitas</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">Outras (Especificar em Anexo)</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">2.3 Descontos em Vencimentos e Salário</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                            <tr>
                            <td class="dados_tabela">2.3 Importâncias Recebidas para Entrega ao Estado ou Outras Entes</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">IRT</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">IRT</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">S.Social</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">S.Social</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">10% Cativos</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">10% Cativos</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">2.4 Outros (Especificar em anexo)</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                            <tr>
                            <td class="dados_tabela">2.4 Diferença de Câmbios</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela"></td>
                            <td class="dados_tabela_right"></td>
                            <td class="dados_tabela_right"></td>
                            <td class="dados_tabela_right"></td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">Total Recebimentos/Débito (1+2)</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">Total Pagamentos/Crédito</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">Saldo de Gestão Anterior (1.1+1.2)</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">Saldo de Gestão Seguinte (2.1+2.2)</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">Saldo de Gestão Anterior (1.1+1.2)</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">Saldo de Gestão Seguinte (2.1+2.2)</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">1.1 Em Cofre</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">2.1 Em Cofre)</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                        <tr>
                            <td class="dados_tabela">1.2 Em Deposito</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela">2.2 Em Deposito</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                            <td class="dados_tabela_right">0</td>
                        </tr>
                   </table>
                   
                   
                   <tr>
                       <td class="dados_tabela_footer" colspan="2">
                           <p><b>OBS:</b></p>
                           <p>12 Dezembro 2022</p>
                            <p>&emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; Elaborado por &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; O Responsavel</p>
                            <p>&emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; _________________________ &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;&emsp; &emsp; &emsp; &emsp; &emsp;  ________________________</p>
                        </td>
                   </tr>
               </table>
            </div>
            
        </div>
    </div>
    </div>
</template>

<script>
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import RelatorioOrcamentoContent from '../components/RelatorioOrcamentoContent.vue'
export default {
    components: { NavbarAppHome, Sidebar, RelatorioOrcamentoContent },

}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 80%;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: -15%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.main-nav {
    text-align: center;
    margin: 80px 30px 20px 140px;
}
.main-nav a{
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
    color: #999;
    font-size: 18px;
}
a.router-link-active{
    border-bottom: 2px solid #00ce89;
    padding-bottom: 4px;
}

.titulorelatorio{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 180px;
    top: 233px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    text-decoration-line: underline;

    color: #4E4E4E;
}

.holderrelatorio{
    box-sizing: border-box;
    position: absolute;
    padding: 10px 33px;
    width: 100%;
    height: 1500px;
    left: 251px;
    top: 310px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.icon_download{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 1270px;
    top: 289px;
    cursor: pointer;
}

.icon_print{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 1220px;
    top: 289px;
    cursor: pointer;
}
.icon_back{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 1320px;
    top: 289px;
    cursor: pointer;
}
p, ul, li{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.65em;
    text-align: justify;
}
.repubica{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
.ministerio{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
.missaoDiplomatica{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
img{
    width: 200px;
    height: 100px;
}
.titulorequisicao{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}

.unique{
     border: 1px solid #ccc;
     line-height: 5px;
     width: 300px;
}
.unique2{
     border: none;
     line-height: 5px;
     width: 450px;
     
}
.textright{
    text-align: right;
}

.numero_modelo {
    text-align: right;
    background: white;
    font-family: 'Inter';
    font-style: normal;
    border: 1px solid #000;
}

.nome_modelo{
    background: rgb(217, 217, 217);
    font-family: 'Inter';
    font-style: normal;
    border: 1px solid #000;
    }
.modelo_top{
    border: 1px solid #000;
    text-align: left;
    background: white;
    font-family: 'Inter';
    font-style: normal;
}
.endereco_left{
    text-align: left;
    background: white;
    width: 10px;
    border: 1px solid #000;
    font-family: 'Inter';
    font-style: normal;
}
.endereco{
    height: 10px;
    text-align: left;
    background: white;
    border: 1px solid #000;
    font-family: 'Inter';
    font-style: normal;
}
.dados_tabela_right{
    width: 200px;
    text-align: right;
    font-size: 0.7em;
    background: white;
    border: 1px solid #000;
    font-family: 'Inter';
    font-style: normal;
}
.dados_tabela_titulo{
    height: 10px;
    text-align: center;
    background: white;
    width: 100px;
    border: 1px solid #000;
    font-family: 'Inter';
    font-style: normal;
}
.dados_tabela_titulo_right{
    height: 10px;
    text-align: center;
    background: white;
    width: 50px;
    border: 1px solid #000;
    font-family: 'Inter';
    font-style: normal;
}
.dados_tabela{
    height: 4px;
    text-align: left;
    font-size: 0.8em;
    background: white;
    width: 200px;
    border: 1px solid #000;
    font-family: 'Inter';
    font-style: normal;
}
.dados_tabela_footer{
    text-align: center;
    background: white;
    width: 5px;
    font-family: 'Inter';
    font-style: normal;
}
</style>