<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">MODELO 4 - MAPA DE RECEITAS EMOLUMENTARES</h1>
            </div>
        </div>
        <div>
    
        <div>
            <span class="icon_download">
                <i class="fa-solid fa-download" @click="generatePDF"></i>
            </span>
            <span class="icon_print">
                <i class="fa-solid fa-print"></i>
            </span>
             <router-link to="/Modelos" style="text-decoration: none; color: inherit;">
                <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
            </router-link>
            <div class="holderrelatorio">
               <table>
                   <table>
                       <tr>
                       <td class="numero_modelo" colspan="4">Modelo Nº 4</td>
                        </tr>
                        <tr>
                            <td class="nome_modelo" colspan="4"><b>MAPA DE RECEITAS EMOLUMENTARES</b></td>
                        </tr>
                        <tr>
                            <td class="modelo_top" colspan="4">EMBAIXADA DA REPUBLICA DE ANGOLA NO JAPÃO</td>
                        </tr>
                        <tr>
                            <td class="modelo_top" colspan="4">Gestão de: 1 de Janeiro a 31 de Dezembro 2020</td>
                        </tr>
                   </table>
                   <table class="mes">
                        <tr class="mes">
                            <td rowspan="2" class="mes"><b>MÊS</b></td>
                            <td colspan="3"><b>VALOR</b></td>
                        </tr>
                        <tr>
                            <td class="dados_tabela_top"><b>USD</b></td>
                            <td class="dados_tabela_top"><b>TAXA CAMBIO</b></td>
                            <td class="dados_tabela_top"><b>KWANZAS</b></td>
                        </tr>
                        <tr>
                            <td class="dados_tabela_titulo"><b>JANEIRO</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                        </tr>
                        <tr>
                            <td class="dados_tabela_titulo"><b>FEVEREIRO</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                        </tr>
                        <tr>
                            <td class="dados_tabela_titulo"><b>MARÇO</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                        </tr>
                        <tr>
                            <td class="dados_tabela_titulo"><b>ABRIL</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                        </tr>
                        <tr>
                            <td class="dados_tabela_titulo"><b>MAIO</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                        </tr>
                        <tr>
                            <td class="dados_tabela_titulo"><b>JUNHO</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                        </tr>
                        <tr>
                            <td class="dados_tabela_titulo"><b>JULHO</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                        </tr>
                        <tr>
                            <td class="dados_tabela_titulo"><b>AGOSTO</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                        </tr>
                        <tr>
                            <td class="dados_tabela_titulo"><b>SETEMBRO</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                        </tr>
                        <tr>
                            <td class="dados_tabela_titulo"><b>OUTUBRO</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                        </tr>
                        <tr>
                            <td class="dados_tabela_titulo"><b>NOVEMBRO</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                        </tr>
                        <tr>
                            <td class="dados_tabela_titulo"><b>DEZEMBRO</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                            <td class="dados_tabela_titulo"><b>0</b></td>
                        </tr>
                   </table>
                   
                   
                   <tr>
                       <td class="dados_tabela_footer" colspan="2">
                           <p><b>OBS:</b></p>
                           <p>12 Dezembro 2022</p>
                            <p>&emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; Elaborado por &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; O Responsavel</p>
                            <p>&emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; _________________________ &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;&emsp; &emsp; &emsp; &emsp; &emsp;  ________________________</p>
                        </td>
                   </tr>
               </table>
            </div>
            
        </div>
    </div>
    </div>
</template>

<script>
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import RelatorioOrcamentoContent from '../components/RelatorioOrcamentoContent.vue'
export default {
    components: { NavbarAppHome, Sidebar, RelatorioOrcamentoContent },

}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 80%;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: -15%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.main-nav {
    text-align: center;
    margin: 80px 30px 20px 140px;
}
.main-nav a{
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
    color: #999;
    font-size: 18px;
}
a.router-link-active{
    border-bottom: 2px solid #00ce89;
    padding-bottom: 4px;
}

.titulorelatorio{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 180px;
    top: 233px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    text-decoration-line: underline;

    color: #4E4E4E;
}

.holderrelatorio{
    box-sizing: border-box;
    position: absolute;
    padding: 10px 33px;
    width: 100%;
    height: 1500px;
    left: 251px;
    top: 310px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.icon_download{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 1270px;
    top: 289px;
    cursor: pointer;
}

.icon_print{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 1220px;
    top: 289px;
    cursor: pointer;
}
.icon_back{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 1320px;
    top: 289px;
    cursor: pointer;
}
p, ul, li{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.65em;
    text-align: justify;
}
.repubica{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
.ministerio{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
.missaoDiplomatica{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
img{
    width: 200px;
    height: 100px;
}
.titulorequisicao{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}

.unique{
     border: 1px solid #ccc;
     line-height: 5px;
     width: 300px;
}
.unique2{
     border: none;
     line-height: 5px;
     width: 450px;
     
}
.textright{
    text-align: right;
}

.numero_modelo {
    text-align: right;
    background: white;
    font-family: 'Inter';
    font-style: normal;
    border: 1px solid #000;
}

.nome_modelo{
    background: rgb(217, 217, 217);
    font-family: 'Inter';
    font-style: normal;
    border: 1px solid #000;
    }
.modelo_top{
    border: 1px solid #000;
    text-align: left;
    background: white;
    font-family: 'Inter';
    font-style: normal;
}
.endereco_left{
    text-align: left;
    background: white;
    width: 10px;
    border: 1px solid #000;
    font-family: 'Inter';
    font-style: normal;
}
.endereco{
    height: 10px;
    text-align: left;
    background: white;
    border: 1px solid #000;
    font-family: 'Inter';
    font-style: normal;
}
.dados_tabela_right{
    width: 200px;
    text-align: right;
    font-size: 0.7em;
    background: white;
    border: 1px solid #000;
    font-family: 'Inter';
    font-style: normal;
}
.dados_tabela_top{
    height: 10px;
    text-align: center;
    background: rgb(168, 167, 167);
    width: 100px;
    border: 1px solid #000;
    font-family: 'Inter';
    font-style: normal;
}
.dados_tabela_titulo{
    height: 10px;
    text-align: center;
    background: white;
    width: 100px;
    border: 1px solid #000;
    font-family: 'Inter';
    font-style: normal;
}
.dados_tabela_titulo_right{
    height: 10px;
    text-align: center;
    background: white;
    width: 50px;
    border: 1px solid #000;
    font-family: 'Inter';
    font-style: normal;
}
.dados_tabela{
    height: 4px;
    text-align: left;
    font-size: 0.8em;
    background: white;
    width: 200px;
    border: 1px solid #000;
    font-family: 'Inter';
    font-style: normal;
}
.dados_tabela_footer{
    text-align: center;
    background: white;
    width: 5px;
    font-family: 'Inter';
    font-style: normal;
}

.mes{
    background: rgb(168, 167, 167);
    border: 1px solid #000;
    font-family: 'Inter';
    font-style: normal;
}
</style>