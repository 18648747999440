<template>
  <div class="Cont_Comprar_Prescon">
    <div class="contactos_container">
      <h3 class="titulo_contactos">
        Contactos
      </h3>
      <form action="">
        <label class="nome_contactos">Nome</label>
        <input class="Rec_nome" type="text">
        <label class="email">Endereço de e-mail</label>
        <input class="Rec_email" type="text">
        <label class="mensagem">Mensagem</label>
        <input class="Rec_mensagem" type="text">
        <input class="Rectangle_enviar" type="button" value="ENVIAR">
      </form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Apoio',

    data: () => ({
      
    }),
  }
</script>

<style>
.Cont_Comprar_Prescon{
  position: relative;
  width: 100%;
  height: 860px;
  margin: 0px 0px 0px 0px;
  align-items: center;
}

.contactos_container{
  position: relative;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 10%;
  height: 100%;
}

.titulo_contactos{
  position: absolute;
  left: 50%;
  right: 42.8%;
  top: 5%;
  bottom: 96.66%;
}

.Vector_1{
  position: absolute;
  left: 14.51%;
  right: 69.53%;
  top: 10.51%;
  bottom: 83.49%;
  border: 1px solid #51B8D5;
}
.nome_contactos{
  position: absolute;
  left: 33%;
  top: 21.47%;
  bottom: 77.56%;
  font-family: Signika;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.Rec_nome{
  position: absolute;
  left: 33%;
  top: 25.37%;
  bottom: 71.74%;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 3px;
  width: 650px;
  height: 50px;
}

.Rec_email{
  position: absolute;
  left: 33%;
  top: 37%;
  bottom: 61.86%;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 3px;
  width: 650px;
  height: 50px;
}
.Rec_mensagem{ 
  position: absolute;
  left: 33%;
  top: 49.14%;
  bottom: 51.98%;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 3px;
  width: 650px;
  height: 250px;
}

.email {
  position: absolute;
  left: 32%;
  top: 33.35%;
  bottom: 67.67%;
  font-family: Signika;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4E4E4E;
  width: 150px;
}


.mensagem{
  position: absolute;
  left: 33%;
  top: 45.23%;
  bottom: 57.79%;
  font-family: Signika;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4E4E4E;
}

.Rectangle_enviar{
  position: absolute;
  left: 49%;
  right: 14.73%;
  top: 82.42%;
  bottom: 10.7%;
  background: #00344C;
  border-radius: 3px;
  color: white;
  width: 150px;
}
</style>