<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">FUNCIONÁRIOS E EMPRESAS</h1>
            </div>
            <ConfiguracaoNav/>
            <FuncionariosList/>
        </div>
        <div class="contas-list">
            <h2 class="dados_contas">LISTA DE EMPRESAS</h2>
            <div class="table_contas">
                <table>
                <thead>
                    <tr>
                    <th scope="col">Tipo</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Estado</th>
                    <th scope="col"></th>
                    </tr>
                </thead>
                <tbody v-if="data && data.length > 0">
                    <tr v-for="e in data" :key="e.id">
                        <td>{{ e.referencia_empresa }}</td>
                        <td>{{ e.nome_empresa }}</td>
                        <div @click="togglActive(e.id, e.estado)" style="text-align: -webkit-center;">
                        <td v-if="e.estado==true"><button type="button" class="btn btn-success">Activo</button></td>
                        <td v-else><button class="btn btn-danger"  type="button">Inactivo</button></td>
                        </div>
                        <!-- <td>{{ e.moeda_conta }}</td> -->
                        <td>
                            <span title="Eliminar" class="icon_delete" @click="handleDelete(e.id)"><i class="fas fa-trash-alt"></i></span>
                             <router-link :to="`/EditarEmpresa/${e.id}`" style="text-decoration: none; color: inherit;">
                                <span title="Editar" class="icon_edit"><i class="fas fa-edit"></i></span>
                             </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="error">{{ error2 }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'
import { projectFirestore } from "../firebase/config";
import ConfiguracaoNav from '../components/ConfiguracaoNav.vue'
import FuncionariosList from '../components/FuncionariosList.vue'

export default {
    components: { NavbarAppHome, Sidebar,ConfiguracaoNav,FuncionariosList },

    setup() {
        const { addDoc, error } = useCollection('conta')
        const { error2, documents } = getCollection('empresa')
        const { user } = getUser()
        const data = ref([]);
        const router = useRouter();

        const filteredDocuments = ref([]);

    const filterDocuments = () => {
      if (documents.value) {
        filteredDocuments.value = documents.value.filter(
          (doc) => doc.userId === user.value.uid
        );
        data.value = filteredDocuments.value;
      }
    };
    const togglActive = async (item, dataActive) => {
      try {
        let collectionRef = await projectFirestore
          .collection("empresa")
          .doc(item)
          .update({ estado: !dataActive});
      } catch (error) {
        console.log("Error updating document:", error);
      }
    };

    filterDocuments();

    watch([documents, user], () => {
      filterDocuments();
    });

    const handleDelete = async (id) => {
      try {
        const docRef = projectFirestore.collection("empresa").doc(id);
        await docRef.delete();
        console.log("Document successfully deleted.");
      } catch (error) {
        console.log("Error deleting document:", error.message);
      }
    };


        // watch(user, () => {
        //     if  (!user.value) {
        //         router.push({ name: 'Login'})
        //     }
        // })
        const nome_funcionario = ref('')
        const tipo_funcionario = ref('')
        const data_nascimento_funcionario = ref('')
        const dias_trabalho = ref('')
        const orgao_funcionario = ref('')
        const referencia_funcionario = ref('')
        const salario_base = ref('')
        const irt_funcionario = ref('')
        const suplemento_salario = ref('')
        const s_social_funcionario = ref('')
        const salario_iliquido = ref('')
        const cativos_funcionario = ref('')
        const vencimento_funcionario = ref('')
        const outros_funcionario = ref('')
        const total_descontos_funcionario = ref('')
        const contribuicao_funcionario = ref('')
        const abono_familiar_funcionario = ref('')
        const fonte_recurso_funcionario = ref('')
        const moeda_funcionario = ref('')
        const data_funcionario = ref('')
        const cambio_mg_funcionario = ref('')
        const cambio_kz_funcionario = ref('')
        const observacoes_funcionario = ref('')
        const check_pessoal_central = ref('')
        const check_pessoal_local = ref('')

        const handleSubmit = async () => {
            const funcionario = {
                nome_funcionario: nome_funcionario.value,
                tipo_funcionario: tipo_funcionario.value,
                data_nascimento_funcionario: data_nascimento_funcionario.value,
                dias_trabalho: dias_trabalho.value,
                orgao_funcionario: orgao_funcionario.value,
                referencia_funcionario: referencia_funcionario.value,
                salario_base: salario_base.value,
                irt_funcionario: irt_funcionario.value,
                suplemento_salario: suplemento_salario.value,
                s_social_funcionario: suplemento_salario.value,
                salario_iliquido: salario_iliquido.value,
                cativos_funcionario: cativos_funcionario.value,
                vencimento_funcionario: vencimento_funcionario.value,
                outros_funcionario: outros_funcionario.value,
                total_descontos_funcionario: total_descontos_funcionario.value,
                contribuicao_funcionario: contribuicao_funcionario.value,
                abono_familiar_funcionario: contribuicao_funcionario.value,
                fonte_recurso_funcionario: fonte_recurso_funcionario.value,
                moeda_funcionario: moeda_funcionario.value,
                data_funcionario: data_funcionario.value,
                cambio_mg_funcionario: cambio_mg_funcionario.value,
                cambio_kz_funcionario: cambio_kz_funcionario.value,
                observacoes_funcionario: observacoes_funcionario.value,
                check_pessoal_central: check_pessoal_central.value,
                check_pessoal_local: check_pessoal_local.value,
                userId: user.value.uid

            }
            await addDoc(funcionario)
            if (!error.value) {
                nome_funcionario.value = ''
                tipo_funcionario.value = ''
                data_nascimento_funcionario.value = ''
                dias_trabalho.value = ''
                orgao_funcionario.value = ''
                referencia_funcionario.value = ''
                salario_base.value = ''
                irt_funcionario.value = ''
                suplemento_salario.value = ''
                s_social_funcionario.value = ''
                salario_iliquido.value = ''
                cativos_funcionario.value = ''
                vencimento_funcionario.value = ''
                outros_funcionario.value = ''
                total_descontos_funcionario.value = ''
                contribuicao_funcionario.value = ''
                abono_familiar_funcionario.value = ''
                fonte_recurso_funcionario.value = ''
                moeda_funcionario.value = ''
                data_funcionario.value = ''
                cambio_mg_funcionario.value = ''
                cambio_kz_funcionario.value = ''
                observacoes_funcionario.value = ''
                check_pessoal_central.value = ''
                check_pessoal_local.value = ''
            }
            const empresa = {
                nome_empresa: nome_empresa.value,
                tipo_actividades_empresas: tipo_actividades_empresas.value,
                referencia_empresa: referencia_empresa.value,
                data_registro_empresas: data_registro_empresas.value,
                observacoes_empresas: observacoes_empresas.value,
                userId: user.value.uid

            }
            await addDoc(empresa)
            if (!error.value) {
                nome_empresa.value = ''
                tipo_actividades_empresas.value = ''
                referencia_empresa.value = ''
                data_registro_empresas.value = ''
                observacoes_empresas.value = ''
            }
            const contrato = {
                beneficiario_contrato: beneficiario_contrato.value,
                departamento_contrato: departamento_contrato.value,
                contrato_afecto: contrato_afecto.value,
                tipo_contrato: tipo_contrato.value,
                descricoes_contrato: descricoes_contrato.value,
                valor_mensal_contrato: valor_mensal_contrato.value,
                moeda_contrato: moeda_contrato.value,
                forma_cobranca_contrato: forma_cobranca_contrato.value,
                tipo_valor_contrato: tipo_valor_contrato.value,
                caucao_contrato: caucao_contrato.value,
                data_inicio_contrato: data_inicio_contrato.value,
                data_termino_contrato: data_termino_contrato.value,
                observacoes_contrato: observacoes_contrato.value,
                userId: user.value.uid

            }
            await addDoc(contrato)
            if (!error.value) {
                beneficiario_contrato.value = ''
                departamento_contrato.value = ''
                contrato_afecto.value = ''
                tipo_contrato.value = ''
                descricoes_contrato.value = ''
                valor_mensal_contrato.value = ''
                moeda_contrato.value = ''
                forma_cobranca_contrato.value = ''
                tipo_valor_contrato.value = ''
                caucao_contrato.value = ''
                data_inicio_contrato.value = ''
                data_termino_contrato.value = ''
                observacoes_contrato.value = ''
            }
        }
        const nome_empresa = ref('')
        const tipo_actividades_empresas = ref('')
        const data_registro_empresas= ref('')
        const observacoes_empresas = ref('')
        const referencia_empresa = ref('')

        const beneficiario_contrato = ref('')
        const departamento_contrato = ref('')
        const contrato_afecto = ref('')
        const tipo_contrato = ref('')
        const descricoes_contrato = ref('')
        const valor_mensal_contrato = ref('')
        const moeda_contrato = ref('')
        const forma_cobranca_contrato = ref('')
        const tipo_valor_contrato = ref('')
        const caucao_contrato = ref('')
        const data_inicio_contrato = ref('')
        const data_termino_contrato = ref('')
        const observacoes_contrato = ref('')
        

        return {togglActive, nome_funcionario, tipo_funcionario, data_nascimento_funcionario, dias_trabalho,
                orgao_funcionario, salario_base, irt_funcionario, suplemento_salario, s_social_funcionario,
                salario_iliquido, cativos_funcionario, vencimento_funcionario, outros_funcionario,
                total_descontos_funcionario, contribuicao_funcionario, abono_familiar_funcionario,
                fonte_recurso_funcionario, moeda_funcionario, data_funcionario, cambio_mg_funcionario,
                cambio_kz_funcionario, observacoes_funcionario, check_pessoal_central, check_pessoal_local,
                nome_empresa, tipo_actividades_empresas, data_registro_empresas, referencia_funcionario,
                observacoes_empresas, beneficiario_contrato, departamento_contrato, contrato_afecto,
                tipo_contrato, descricoes_contrato, valor_mensal_contrato, referencia_empresa,
                moeda_contrato, forma_cobranca_contrato, tipo_valor_contrato,
                caucao_contrato, data_inicio_contrato, data_termino_contrato,
                observacoes_contrato, error, handleSubmit, error2, filteredDocuments, handleDelete,
      data,}
    }
    
}
</script>

<style scoped>

.cabecalho_configuracoes{
    width: 100%;
    left: 180px;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: 0%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}

.missao_diplomatica{
    position: absolute;
    width: 215px;
    height: 46px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.missao_diplomatica:hover {
  color: white;
}
.orgaos{
    position: absolute;
    width: 209px;
    height: 20px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.orgaos:hover {
  color: white;
}

.contas{
    position: absolute;
    width: 198px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.contas:hover {
    color: white;
}
.funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.funcionarios_empresas:hover {
    color: white;
}
.naturezas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.naturezas:hover {
    color: white;
}
.contas-list{
    position: absolute;
    width: 980px;
    height: auto;
    left: 231px;
    top: 400px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
    border: 1px solid rgb(198, 192, 192);
}
.dados_contas{
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    top: 90px;
    margin: 20px 10px 10px 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
}
.table_contas{
    position: absolute;
    top: 174px;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

/* general styling */
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.25;
}

.icon_delete{
    color:red;
    padding: 10px;
    cursor: pointer;
}

.icon_edit{
    color: rgb(31, 227, 31);
    cursor: pointer;
}

.btn_moedas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1410px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.btn_moedas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.moedas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.moedas:hover {
    color: white;
}
.btn {
    --bs-btn-padding-x: 0.75rem;
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: var(--bs-border-radius);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 hsla(0,0%,100%,0.15),0 1px 1px rgba(0,0,0,0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb),.5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color)
}

.btn-check+.btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color)
}

.btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg)
}

.btn-check:focus-visible+.btn,.btn:focus-visible {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow)
}

.btn-check:checked+.btn,.btn.active,.btn.show,.btn:first-child:active,:not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color)
}

.btn-check:checked+.btn:focus-visible,.btn.active:focus-visible,.btn.show:focus-visible,.btn:first-child:active:focus-visible,:not(.btn-check)+.btn:active:focus-visible {
    box-shadow: var(--bs-btn-focus-box-shadow)
}

.btn.disabled,.btn:disabled,fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity)
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #0d6efd;
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd
}

.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #6c757d;
    --bs-btn-border-color: #6c757d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130,138,145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d
}
.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #198754;
    --bs-btn-border-color: #198754;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #157347;
    --bs-btn-hover-border-color: #146c43;
    --bs-btn-focus-shadow-rgb: 60,153,110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #146c43;
    --bs-btn-active-border-color: #13653f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #198754;
    --bs-btn-disabled-border-color: #198754
}
.btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: #dc3545;
    --bs-btn-border-color: #dc3545;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #bb2d3b;
    --bs-btn-hover-border-color: #b02a37;
    --bs-btn-focus-shadow-rgb: 225,83,97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #b02a37;
    --bs-btn-active-border-color: #a52834;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #dc3545;
    --bs-btn-disabled-border-color: #dc3545
}
</style>