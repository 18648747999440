<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">TRIBUNAL DE CONTAS - MODELOS</h1>
            </div>
            <div class="menu_configuracoes">
                <router-link to="/Modelos" style="text-decoration: none; color: inherit;">
                    <div class="btn_missao_diplomatica">
                        MODELOS
                    </div>
                </router-link>
                <router-link to="/Movimentos" style="text-decoration: none; color: inherit;">
                    <div class="btn_orgaos">
                        MOVIMENTOS
                    </div>
                </router-link>
            </div>
        </div>
        <div class="container_modelos">
            <router-link to="/Modelo_1" style="text-decoration: none; color: inherit;">
                <div class="modelo_1"><b>MODELO 1 <br> GUIA DE REMESSA </b></div>
            </router-link>
            <router-link to="/Modelo_2" style="text-decoration: none; color: inherit;">
                <div class="modelo_2"><b>MODELO 2 <br>MAPA DA CONTA DE GESTÃO</b></div>
            </router-link>
            <router-link to="/Modelo_2A" style="text-decoration: none; color: inherit;">
                <div class="modelo_2A"><b>MODELO 2A <br>MAPA ADICIONAL DA CONTA DE GESTÃO</b></div>
            </router-link>
            <router-link to="/Modelo_3" style="text-decoration: none; color: inherit;">
                <div class="modelo_3"><b>MODELO 3 <br>MAPA DE RECEBIMENTO DE DUODECIMO</b></div>
            </router-link>
            <router-link to="/Modelo_4" style="text-decoration: none; color: inherit;">
                <div class="modelo_4"><b>MODELO 4 <br>MAPA DE RECEITAS EMOLUMENTARES</b></div>
            </router-link>
            <router-link to="/Modelo_5" style="text-decoration: none; color: inherit;">
                <div class="modelo_5">MODELO 5 <br>MAPA DE IMPORTANCIAS RECEBIDAS PARA A ENTREGA AO ESTADO...</div>
            </router-link>
            <router-link to="/Modelo_6" style="text-decoration: none; color: inherit;">
                <div class="modelo_6">MODELO 6 <br>MAPA DE OUTRAS RECEITAS</div>
            </router-link>
            <router-link to="/Modelo_7" style="text-decoration: none; color: inherit;">
                <div class="modelo_7">MODELO 7 <br>MAPA DE DESPESAS DE BENS, SERVIÇOS E OUTROS</div>
            </router-link>
            <router-link to="/Modelo_8" style="text-decoration: none; color: inherit;">
            <div class="modelo_8">MODELO 8 <br>MAPA DE DESPESAS COM O PESSOAL DE NOMEAÇÃO CENTRAL-SALARIOS</div>
            </router-link>
            <router-link to="/Modelo_8A" style="text-decoration: none; color: inherit;">
                <div class="modelo_8A">MODELO 8A <br>MODELO 8A MAPA DE DESPESAS COM O PESSOAL LOCAL-SALÁRIO</div>
            </router-link>
            <router-link to="/Modelo_9" style="text-decoration: none; color: inherit;">
                <div class="modelo_9">MODELO 9 <br>MAPA DE SUBSÍDIOS E TRANSFERÊNCIAS ATRIBUÍDOS</div>
            </router-link>
            <router-link to="/Modelo_10" style="text-decoration: none; color: inherit;">
                <div class="modelo_10">MODELO 10 <br>MAPA DOS BENS DE CAPITAL(ACTIVO PATRIMONIAL) ADQUIRIDOSS</div>
            </router-link>
            <router-link to="/Modelo_11" style="text-decoration: none; color: inherit;">
                <div class="modelo_11">MODELO 11 <br>MAPA DE DESPESAS NÃO PAGAS NO EXERCÍCIO ECONÓMICO</div>
            </router-link>
            <router-link to="/Modelo_12" style="text-decoration: none; color: inherit;">
                <div class="modelo_12">MODELO 12 <br>MAPA DE CONTRATOS</div>
            </router-link>
            <router-link to="/Modelo_13" style="text-decoration: none; color: inherit;">
                <div class="modelo_13">MODELO 13 <br>MAPA DE OPERAÇÕES CÂMBIAIS REALIZADAS</div>
            </router-link>
            <router-link to="/Modelo_14" style="text-decoration: none; color: inherit;">
                <div class="modelo_14">MODELO 14 <br>SALDO DE ABERTURA E ENCERRAMENTO DA CONTA</div>
            </router-link>
            <router-link to="/Modelo_15" style="text-decoration: none; color: inherit;">
                <div class="modelo_15">MODELO 15 <br>MAPA DE DOAÇÕES</div>
            </router-link>
            <router-link to="/Modelo_16" style="text-decoration: none; color: inherit;">
                <div class="modelo_16">MODELO 16 <br>CERTIDÃO DO AUTO DE TRANSMISSÃO DE GESTÃO</div>
            </router-link>
            <router-link to="/Modelo_17" style="text-decoration: none; color: inherit;">
                <div class="modelo_17">MODELO 17 <br>RELAÇÃO NOMINAL DOS RESPONSAVEIS</div>
            </router-link>
            <router-link to="/Modelo_8B" style="text-decoration: none; color: inherit;">
                <div class="modelo_8B">MODELO 8B <br>MAPA DE OUTRAS DESPESAS DO PESSOAL</div>
            </router-link>
        </div>
        <div>
            <router-link to="/Anexo_1" style="text-decoration: none; color: inherit;">
                <div class="anexo_1">ANEXO 1</div>
            </router-link>
            <router-link to="/Anexo_2" style="text-decoration: none; color: inherit;">
                <div class="anexo_2">ANEXO 2</div>
            </router-link>
            <router-link to="/Anexo_3" style="text-decoration: none; color: inherit;">
                <div class="anexo_3">ANEXO 3</div>
            </router-link>
            <router-link to="/Anexo_4" style="text-decoration: none; color: inherit;">
                <div class="anexo_4">ANEXO 4</div>
            </router-link>
            <router-link to="/Anexo_5" style="text-decoration: none; color: inherit;">
                <div class="anexo_5">ANEXO 5</div>
            </router-link>
        </div>
    </div>
</template>

<script>
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
export default {
    components: { NavbarAppHome, Sidebar }
}
</script>

<style>
.container_modelos{
    box-sizing: border-box;
    position: absolute;
    width: 925px;
    height: 510px;
    left: 220px;
    top: 270px;
    background: #FFFFFF;
    border: 1px solid #51B8D5;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_1{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 10px;
    top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_2{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 190px;
    top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;  
}
.modelo_2A{
    position: absolute;
    width: 170px;
    height: 58px;
    left: 370px;
    top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_3{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 553px;
    top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_4{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 733px;
    top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_5{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 10px;
    top: 90px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_6{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 190px;
    top: 90px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_7{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 370px;
    top: 90px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_8{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 553px;
    top: 90px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_8A{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 733px;
    top: 90px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_9{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 10px;
    top: 170px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_10{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 190px;
    top: 170px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_11{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 370px;
    top: 170px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_12{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 553px;
    top: 170px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_13{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 733px;
    top: 170px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_14{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 10px;
    top: 250px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_15{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 190px;
    top: 250px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modelo_16{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 370px;
    top: 250px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modelo_17{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 553px;
    top: 250px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modelo_8B{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 60px;
    left: 733px;
    top: 250px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    background: #FF0000;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.anexo_1{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 40px;
    left: 230px;
    top: 640px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #51B8D5;
    background: #FFFFFF;
    border: 1px solid #51B8D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.anexo_2{
box-sizing: border-box;
position: absolute;
width: 170px;
height: 40px;
left: 410px;
top: 640px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
text-align: center;
letter-spacing: -0.011em;
color: #51B8D5;
background: #FFFFFF;
border: 1px solid #51B8D5;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.anexo_3{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 40px;
    left: 590px;
    top: 640px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #51B8D5;
    background: #FFFFFF;
    border: 1px solid #51B8D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.anexo_4{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 40px;
    left: 773px;
    top: 640px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #51B8D5;
    background: #FFFFFF;
    border: 1px solid #51B8D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.anexo_5{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 40px;
    left: 953px;
    top: 640px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #51B8D5;
    background: #FFFFFF;
    border: 1px solid #51B8D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.dados_mdc{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 40px;
    left: 230px;
    top: 700px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #51B8D5;
    background: #FFFFFF;
    border: 1px solid #51B8D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.dados_bancarios{
box-sizing: border-box;
position: absolute;
width: 170px;
height: 40px;
left: 410px;
top: 700px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
text-align: center;
letter-spacing: -0.011em;
color: #51B8D5;
background: #FFFFFF;
border: 1px solid #51B8D5;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>