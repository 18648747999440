<template>
  <div class="principal">
    <h1>Testes</h1>
    <div>{{ orgao.nome_orgao }}</div>
  </div>
</template>

<script>
import getUser from '../composables/getUser'
import NavbarAppHome from "../components/NavbarAppHome.vue";
import { useRouter } from "vue-router";
import { ref, watch } from "vue";
import getCollection from "../composables/getCollection";
export default {
    components: { NavbarAppHome },
    setup() {
        const router = useRouter();
        const { user } = getUser()
        const { error2, documents } = getCollection("funcionariocentral");
        const { error3 : error_orgaos, documents: orgao } = getCollection('orgaos');

        
        watch(user, () => {
        if (!user.value) {
          router.push({ name: "Login" });
        }
      });
      
        return{
          documents,
          error2,
          orgao,
        }
      }
    }
</script>

<style scoped>
.principal {
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialised;
    -moz-osx-font-smoothing: grayscale;
    max-width: 600px;
    margin: 0 auto;
    color: #2c3e50;
}

</style>