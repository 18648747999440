<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">TRIBUNAL DE CONTAS - MOVIMENTOS</h1>
            </div>
            <div class="menu_configuracoes">
                <router-link to="/Modelos" style="text-decoration: none; color: inherit;">
                    <div class="btn_missao_diplomatica">
                        MODELOS
                    </div>
                </router-link>
                <router-link to="/Movimentos" style="text-decoration: none; color: inherit;">
                    <div class="btn_orgaos">
                        MOVIMENTOS
                    </div>
                </router-link>
            </div>
            <div class="linha"></div>
        </div>
        <div>
             <nav class="main-nav">
                <router-link :to="{ name: 'Janeiro' }">JANEIRO</router-link>
                <router-link :to="{ name: 'Fevereiro' }">FEVEREIRO</router-link>
                <router-link :to="{ name: 'Marco' }">MARÇO</router-link>
                <router-link :to="{ name: 'Abril' }">ABRIL</router-link>
                <router-link :to="{ name: 'Maio' }">MAIO</router-link>
                <router-link :to="{ name: 'Junho' }">JUNHO</router-link>
                <router-link :to="{ name: 'Julho' }">JULHO</router-link>
                <router-link :to="{ name: 'Agosto' }">AGOSTO</router-link>
                <router-link :to="{ name: 'Setembro' }">SETEMBRO</router-link>
                <router-link :to="{ name: 'Outubro' }">OUTUBRO</router-link>
                <router-link :to="{ name: 'Novembro' }">NOVEMBRO</router-link>
                <router-link :to="{ name: 'Dezembro' }">DEZEMBRO</router-link>
            </nav>
        </div>
    </div>
</template>

<script>
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
export default {
    components: { NavbarAppHome, Sidebar }
}
</script>

<style scoped>
.main-nav {
    position: absolute;
    text-align: center;
    left: 247px;
    top: 260px;
}
.main-nav a{
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
    color: #51B8D5;
    font-size: 0.8em;
    top: 280px;
}
a.router-link-active{
    border-bottom: 2px solid #51B8D5;
    padding-bottom: 4px;
}
.linha{
    position: absolute;
    width: 1100px;
    height: 0px;
    left: 252px;
    top: 255px;
    border: 1px solid #51B8D5;
}

.janeiro{
    position: absolute;
    width: 100px;
    height: 35px;
    left: 247px;
    top: 290px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #51B8D5;
}

.fevereiro{
    position: absolute;
    width: 100px;
    height: 35px;
    left: 337px;
    top: 290px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #51B8D5;
}

.marco{
    position: absolute;
    width: 100px;
    height: 35px;
    left: 420px;
    top: 290px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #51B8D5;
}

.abril{
    position: absolute;
    width: 100px;
    height: 35px;
    left: 490px;
    top: 290px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #51B8D5;
}

.maio{
    position: absolute;
    width: 100px;
    height: 35px;
    left: 560px;
    top: 290px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #51B8D5;
}

.junho{
    position: absolute;
    width: 100px;
    height: 35px;
    left: 635px;
    top: 290px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #51B8D5;
}

.julho{
    position: absolute;
    width: 100px;
    height: 35px;
    left: 710px;
    top: 290px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #51B8D5;
}

.agosto{
    position: absolute;
    width: 100px;
    height: 35px;
    left: 790px;
    top: 290px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #51B8D5;
}
.setembro{
    position: absolute;
    width: 100px;
    height: 35px;
    left: 890px;
    top: 290px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #51B8D5;
}
.outubro{
    position: absolute;
    width: 100px;
    height: 35px;
    left: 995px;
    top: 290px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #51B8D5;
}
.novembro{
    position: absolute;
    width: 100px;
    height: 35px;
    left: 1100px;
    top: 290px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #51B8D5;
}
.dezembro{
    position: absolute;
    width: 100px;
    height: 35px;
    left: 1210px;
    top: 290px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #51B8D5;
}
</style>