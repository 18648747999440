<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">FORMULÁRIO EDITAR EMPRESAS</h1>
            </div>
            <div class="menu_configuracoes">
                <router-link to="/MissaoDiplomatica" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_missao_diplomatica">
                        MISSÃO<br> DIPLOMATICA 
                    </div>
                </router-link>
                <router-link to="/Orgaos" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_orgaos">
                        ORGÃOS
                    </div>
                </router-link>
                <router-link to="/Contas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_contas">
                        CONTAS
                    </div>
                </router-link>
                <router-link to="/FuncionariosEmpresas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_funcionarios_empresas">
                        FUNCIONÁRIOS E<br> EMPRESAS
                    </div>
                </router-link>
                <router-link to="/Naturezas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_naturezas">
                        NATUREZAS
                    </div>
                </router-link>
                <router-link to="/Moedas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_moedas">
                        MOEDAS
                    </div>
                </router-link>
            </div>
        </div>
        <div class="form_container">
            <h1 class="titulo_formulario">EDITAR EMPRESAS</h1>
            <router-link to="/FuncionariosEmpresas" style="text-decoration: none; color: inherit;">
                <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
            </router-link>
            <form @submit.prevent="isEdit ? submitEdit() : handleSubmit">
                <label class="nome_empresa">Nome</label>
                <input required v-model="nome_empresa" class="input_nome_empresa" type="text">
                <label class="tipo_actividades_empresas">Actividades</label>
                <input required v-model="tipo_actividades_empresas" class="input_actividades_empresas" type="text">
                <label class="data_registro_empresas">Data de Registro</label>
                <input required v-model="data_registro_empresas" class="input_data_registro_empresas" type="date">
                <label class="referencia_funcionario">Tipo</label>
                <select required v-model="referencia_empresa" class="input_referencia_funcionario">
                    <option disabled value="">Por favor um tipo</option>
                    <option value="FUNCIONARIO">Funcionario</option>
                    <option value="EMPRESA">Empresa</option>
                    <option value="CONTRATO">Contrato</option>
                </select>
                <label class="observacoes_empresas">Observações</label>
                <textarea v-model="observacoes_empresas" class="input_observacoes_empresas" rows="4" cols="50"></textarea>
                <button class="salvar_funcionario" @click.prevent="submitForm">SALVAR</button>

            </form>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getUser from '../composables/getUser'
import { useRoute, useRouter } from 'vue-router'
import { projectFirestore } from "../firebase/config";
import { onMounted } from "vue";
import ConfiguracaoNav from '../components/ConfiguracaoNav.vue'
  
export default {
    components: { NavbarAppHome, Sidebar, ConfiguracaoNav },

    setup() {
        const { addDoc, error } = useCollection('empresa')
        const { error2, documents } = getCollection('empresa')
        const { user } = getUser()
        const route = useRoute()
        const router = useRouter()
        const user_update_id = ref("");
        const user_id = user.value.uid;
        const data = ref("");
        const nome_empresa = ref('')
        const tipo_actividades_empresas = ref('')
        const data_registro_empresas = ref('')
        const referencia_empresa = ref('')
        const observacoes_empresas = ref('')
        const isEditingId = computed(() => route.params.id);
        const docRef = getCollection("empresa");
        if (docRef.value) {
            filteredDocuments.value = docRef.value.filter(
            isEditingId.value === user.value.uid
            );
            data.value = filteredDocuments.value;
        }
        const isEdit = ref(false);
        isEditingId.value = false;
        isEdit.value = false;

        const fetchCollectionData = async () => {
            try {
            const collectionRef = projectFirestore.collection("empresa");
            const snapshot = await collectionRef.get();
            const dataaaa = snapshot.docs.map((doc) => {
                return { ...doc.data(), id: doc.id };
            });
            const filtered = dataaaa.filter((dataaaa1) => dataaaa1.id === isEditingId.value);
            filtered.forEach((data1) => {
                nome_empresa.value = data1.nome_empresa;
                data_registro_empresas.value = data1.data_registro_empresas;
                referencia_empresa.value = data1.referencia_empresa;
                observacoes_empresas.value = data1.observacoes_empresas;
                tipo_actividades_empresas.value = data1.tipo_actividades_empresas;
                user_update_id.value = data1.id;
                
            });
            } catch (error) {
            console.error("Error fetching collection data:", error);
            }
        };
    
        onMounted(fetchCollectionData);
            watch(user, () => {
                if  (!user.value) {
                    router.push({ name: 'Login'})
                }
            })
            const handleEdit = async (id) => {
                try {
                    const docRef = projectFirestore.collection("empresa").doc(id);
                    await docRef.get().then(async (doc) => {
                        if (doc.exists) {
                        let id = await doc.id;
                        new_obj.value = await { ...doc.data(), id };
                        nome_empresa.value = new_obj.value.nome_empresa;
                        data_registro_empresas.value = new_obj.value.data_registro_empresas;
                        referencia_empresa.value = new_obj.value.referencia_empresa;
                        observacoes_empresas.value = new_obj.value.observacoes_empresas;
                        tipo_actividades_empresas.value = new_obj.value.tipo_actividades_empresas;
                        }
                    });
                    isEdit.value = true;
                    isEditingId.value = id;
                    } catch (error) {
                    console.log("Error deleting document:", error.message);
                }
            };
            const submitEdit = async () => {
                alert("submitEditsubmitEdit");
                const docRef = projectFirestore
                .collection("conta")
                .doc(isEditingId.value);
                await docRef.update({
                    nome_empresa: nome_empresa.value,
                    data_registro_empresas: data_registro_empresas.value,
                    referencia_empresa: referencia_empresa.value,
                    observacoes_empresas: observacoes_empresas.value,
                    tipo_actividades_empresas: tipo_actividades_empresas.value,
                    userId: user.value.uid,
             });
                    isEdit.value = false;
                    nome_empresa.value = null;
                    data_registro_empresas.value = null;
                    referencia_empresa.value = null;
                    observacoes_empresas.value = null;
                    tipo_actividades_empresas.value = null;
        };
        const submitForm = async () => {
            try {
                const docRef = projectFirestore
                    .collection("empresa")
                    .doc(user_update_id.value);
                await docRef.update({
                    nome_empresa: nome_empresa.value,
                    data_registro_empresas: data_registro_empresas.value,
                    referencia_empresa: referencia_empresa.value,
                    observacoes_empresas: observacoes_empresas.value,
                    tipo_actividades_empresas: tipo_actividades_empresas.value,
                });
        
                router.push(`/TabelaEmpresa`);
        
                alert("updated successfully");
                } catch (err) {
                alert("updated ====");
                console.log("err==>", err);
            }
        };

        return { nome_empresa, tipo_actividades_empresas, referencia_empresa, data_registro_empresas, 
                observacoes_empresas, error, error2, documents, handleEdit, documents, submitEdit,
                isEdit, isEditingId, data, user_id, submitForm,}
    }
    
}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 100%;
    left: 180px;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: 0%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.menu_configuracoes{
    width: 100%;
}

.btn_missao_diplomatica{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    vertical-align: middle;
}
.btn_missao_diplomatica:hover {
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
}
.missao_diplomatica{
    position: absolute;
    width: 215px;
    height: 46px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.missao_diplomatica:hover {
  color: white;
}

.btn_orgaos{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 485px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
    vertical-align: middle;
}

.btn_orgaos:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.orgaos{
    position: absolute;
    width: 209px;
    height: 20px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.orgaos:hover {
  color: white;
}

.btn_contas{
    position: absolute;
    width: 214px;
    height: 84px;
    left: 717px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_contas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.contas{
    position: absolute;
    width: 198px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.contas:hover {
    color: white;
}

.btn_funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 945px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_funcionarios_empresas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.funcionarios_empresas:hover {
    color: white;
}

.btn_naturezas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1176px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_naturezas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.naturezas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.naturezas:hover {
    color: white;
}

.form_container{
    box-sizing: border-box;
    position: absolute;
    width: 908px;
    height: 500px;
    left: 266px;
    top: 270px;
    background: #FFFFFF;
    border: 2px solid #51B8D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.titulo_formulario{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 1px;
    top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
}

.icon_back{
    position: absolute;
    width: 5px;
    height: 8px;
    left: 800px;
    top: 40px;
}

.nome_empresa{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -160px;
    top: 70px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}

.tipo_actividades_empresas{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 365px;
    top: 70px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;

}
.input_nome_empresa{
    box-sizing: border-box;
    position: absolute;
    width: 444px;
    height: 30px;
    left: 40px;
    top: 95px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.input_actividades_empresas{
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 95px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.data_registro_empresas{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -125px;
    top: 140px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_data_registro_empresas{
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 165px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.referencia_funcionario{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 342px;
    top: 140px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_referencia_funcionario{
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 165px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.observacoes_empresas{
    position: absolute;
    width: 330px;
    height: 32px;
    left: -80px;
    top: 205px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_observacoes_empresas{
    box-sizing: border-box;
    position: absolute;
    width: 730px;
    height: 100px;
    left: 40px;
    top: 230px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.salvar_funcionario{
    box-sizing: border-box;
    position: absolute;
    width: 132px;
    height: 30px;
    left: 641px;
    top: 350px;
    color: #fff;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>