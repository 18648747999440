<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">ORÇAMENTO</h1>
            </div>
            <div class="menu_configuracoes">
               
            </div>
        </div>
        <div>
            <nav class="main-nav">
                <router-link :to="{ name: 'Orcamento' }">ORÇAMENTOS</router-link>
                <router-link :to="{ name: 'RegistarOrcamento' }">REGISTAR ORÇAMENTO</router-link>
                <router-link :to="{ name: 'RegistarVencimento' }">REGISTAR VENCIMENTO</router-link>
                <router-link :to="{ name: 'TabelaVencimento' }">TABELA VENCIMENTO</router-link>
                <router-link :to="{ name: 'RelatorioOrcamento' }">RELATÓRIO</router-link>
                <router-link :to="{ name: 'EditarOrcamento' }">EDITAR</router-link>
            </nav>
        </div>
        <div class="form_container">
            <h1 class="titulo_formulario">REGISTAR ORÇAMENTO</h1>
            <form @submit.prevent="handleSubmit">
                <label class="natureza_mae">Fonte de Recurso</label>
                <select v-model="fonte_recurso" class="input_natureza_mae">
                    <option disabled value="">Fundos a</option>
                    <option value="Recursos ordinarios do tesouro">Recursos ordinarios do Tesouro</option>
                    <option value="Recursos próprios">Recursos próprios</option>
                </select>
                <label class="categoria_natureza">Natureza de despesa</label>
                <select v-model="categoria_natureza" class="input_categoria_natureza">
                    <option disabled value="">Selecione a categoria</option>
                    <option disabled value="pessoal">Pessoal</option>
                    <option value="Vencimento do pessoal civil do quadro">Vencimento do pessoal civil do quadro</option>
                    <option value="Vencimentos de outro pessoal civil">Vencimentos de outro pessoal civil</option>
                    <option value="Subsídios Do Pessoal Civil">Subsídios Do Pessoal Civil</option>
                    <option value="Décimo terceiro mês do pessoal civil">Décimo terceiro mês do pessoal civil</option>
                    <option value="Outras remunerações variáveis do pessoal civil">Outras remunerações variáveis do pessoal civil</option>
                    <option disabled value="Contribuição do empregador">Contribuição do empregador</option>
                    <option value="Contribuições do empregador p/ a segurança social">Contribuições do empregador p/ a segurança social</option>
                    <option value="Transferências correntes para segurança social">Transferências correntes para segurança social</option>
                    <option disabled value="Bens e Serviços">Bens e Serviços</option>
                    <option value="Combustíveis E Lubrificantes">Combustíveis E Lubrificantes</option>
                    <option value="Viveres E Géneros Alimentícios">Viveres E Géneros Alimentícios</option>
                    <option value="Outros materiais De Consumo Corrente<">Outros materiais De Consumo Corrente</option>
                    <option value="Outros Materiais E Utensílios Duradouros">Outros Materiais E Utensílios Duradouros</option>
                    <option value="Serviços De Telecomunicação">Serviços De Telecomunicação</option>
                    <option value="Serviços De Saúde">Serviços De Saúde</option>
                    <option value="Serviços De Ensino E Formação">Serviços De Ensino E Formação</option>
                    <option value="Serviços De Água e Electricidade">Serviços De Água e Electricidade</option>
                    <option value="Serviços de Hospedagem e Alimentação">Serviços de Hospedagem e Alimentação</option>
                    <option value="Serviços de Estudo, Fiscalização e Consultoria">Serviços de Estudo, Fiscalização e Consultoria</option>
                    <option value="Serviço de Protecção e Vigilância">Serviço de Protecção e Vigilância</option>
                    <option value="Serviços Bancários">Serviços Bancários</option>
                    <option value="Serviços de Processamento de Dados">Serviços de Processamento de Dados</option>
                    <option value="Serviços de Limpeza e Saneamento">Serviços de Limpeza e Saneamento</option>
                    <option value="Serviços de Manutenção e Conservação">Serviços de Manutenção e Conservação</option>
                    <option value="Bilhete de Passagem">Bilhete de Passagem</option>
                    <option value="Subsidio de Deslocação">Subsidio de Deslocação</option>
                    <option value="Serviços de Transportação de Pessoas e Bens">Serviços de Transportação de Pessoas e Bens</option>
                    <option value="Encargos Aduanerios e Portuarios">Encargos Aduanerios e Portuarios</option>
                    <option value="Renda de Imoveis">Renda de Imoveis</option>
                    <option value="Outros Serviços">Outros Serviços</option>
                </select>
                <label class="aprovado_kz">Aprovado KZ</label>
                <input v-model="aprovado_kz" class="input_aprovado_kz" type="text">
                <label class="cativacao_kz">Cativação KZ</label>
                <input v-model="cativacao_kz" class="input_cativacao_kz" type="text">
                <label class="em_execucao">Em execução KZ</label>
                <input v-model="em_execucao" class="input_em_execucao" type="text">
                <button class="salvar_funcionario">SALVAR</button>            </form>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'
export default {
    components: { NavbarAppHome, Sidebar },

    setup() {
        const { addDoc, error } = useCollection('orcamento')
        const { error2, documents } = getCollection('orcamento')
        

        const { user } = getUser()
        const router = useRouter()

        watch(user, () => {
            if  (!user.value) {
                router.push({ name: 'Login'})
            }
        })

        
        const fonte_recurso = ref('')
        const categoria_natureza = ref('')
        const aprovado_kz = ref('')
        const cativacao_kz = ref('')
        const em_execucao = ref('')

        const handleSubmit = async () => {
            const orcamento = {
                fonte_recurso: fonte_recurso.value,
                categoria_natureza: categoria_natureza.value,
                aprovado_kz: aprovado_kz.value,
                cativacao_kz: cativacao_kz.value,
                em_execucao: em_execucao.value,
                userId: user.value.uid

            }
            await addDoc(orcamento)
            if (!error.value) {
                fonte_recurso.value = ''
                categoria_natureza.value = ''
                aprovado_kz.value = ''
                cativacao_kz.value = ''
                em_execucao.value = ''
            }
        }
        return { fonte_recurso, categoria_natureza, aprovado_kz, cativacao_kz, em_execucao, error, handleSubmit, error2, documents }
    }
    
}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 80%;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: 0%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.main-nav {
    text-align: center;
    margin: 80px 30px 20px 140px;
}
.main-nav a{
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
    color: #999;
    font-size: 18px;
}
a.router-link-active{
    border-bottom: 2px solid #00ce89;
    padding-bottom: 4px;
}
.form_container{
    box-sizing: border-box;
    position: absolute;
    width: 908px;
    height: 50%;
    left: 266px;
    top: 270px;
    background: #FFFFFF;
    border: 2px solid #51B8D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.titulo_formulario{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 1px;
    top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
}

.icon_back{
    position: absolute;
    width: 5px;
    height: 8px;
    left: 800px;
    top: 40px;
}

.natureza_mae{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -130px;
    top: 70px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}


.input_natureza_mae{
    box-sizing: border-box;
    position: absolute;
    width: 733px;
    height: 30px;
    left: 40px;
    top: 95px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.data_registro_empresas{
    position: absolute;
    width: 733px;
    height: 32px;
    left: -125px;
    top: 140px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_data_registro_empresas{
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 165px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.categoria_natureza{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -120px;
    top: 140px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_categoria_natureza{
    box-sizing: border-box;
    position: absolute;
    width: 733px;
    height: 30px;
    left: 40px;
    top: 165px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.observacoes_empresas{
    position: absolute;
    width: 330px;
    height: 32px;
    left: -80px;
    top: 205px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_observacoes_empresas{
    box-sizing: border-box;
    position: absolute;
    width: 730px;
    height: 100px;
    left: 40px;
    top: 230px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.aprovado_kz{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -140px;
    top: 205px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;

}
.input_aprovado_kz{
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 40px;
    top: 230px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.cativacao_kz{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 117px;
    top: 205px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;

}
.input_cativacao_kz{
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 295px;
    top: 230px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.em_execucao{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 382px;
    top: 205px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;

}
.input_em_execucao{
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 230px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.salvar_funcionario{
    box-sizing: border-box;
    position: absolute;
    width: 132px;
    height: 30px;
    left: 641px;
    top: 350px;
    color: #fff;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>