import { ref } from 'vue'
import { projectAuth } from '../firebase/config'
 
const error = ref(null)

const signup = async (email, primeiroNome, ultimoNome, nTelefone, password, confirmarPassword) => {
    error.value = null
    let displayName = primeiroNome+" "+ultimoNome
    let phoneNumber = nTelefone
    try {
        const res = await projectAuth.createUserWithEmailAndPassword(email, password)
        if (!res) {
            throw new Error('Não foi possível concluir a inscrição')
        }
        await res.user.updateProfile({ displayName, phoneNumber })
        error.value = null

        console.log(res.user)

        return res
    } catch(err) {
        console.log(err.message)
        error.value = err.message

    }
}

const useSignup = () => {
    return { error, signup }
}

export default useSignup