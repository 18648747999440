<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">CONTAS</h1>
            </div>
          <ConfiguracaoNav/>
            <!-- <div class="menu_configuracoes">
                <router-link to="/MissaoDiplomatica" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_missao_diplomatica">
                        MISSÃO<br> DIPLOMATICA 
                    </div>
                </router-link>
                <router-link to="/Orgaos" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_orgaos">
                        ORGÃOS
                    </div>
                </router-link>
                <router-link to="/Moedas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_moedas">
                        MOEDAS
                    </div>
                </router-link>
                <router-link to="/Cambio" style="text-decoration: none; color: inherit;"> 
                    <div class="cambio">
                        CÂMBIO
                    </div>
                </router-link>
                <router-link to="/Contas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_contas">
                        CONTAS
                    </div>
                </router-link>
                <router-link to="/FuncionariosEmpresas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_funcionarios_empresas">
                        FUNCIONÁRIOS E<br> EMPRESAS
                    </div>
                </router-link>
                <router-link to="/Naturezas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_naturezas">
                        NATUREZAS
                    </div>
                </router-link>
            </div> -->
        </div>
        <div>
            <router-link to="/FormularioContas" style="text-decoration: none; color: inherit;"> 
                <span class="formulario_contas"><span class="dot_contas"></span> Registar</span>
            </router-link>
        </div>
        <div class="contas-list">
            <h2 class="dados_contas">LISTA DE CONTAS REGISTADOS</h2>
            <div class="table_contas">
                <table>
                <thead>
                    <tr>
                    <th scope="col">Tipo</th>
                    <th scope="col">Número</th>
                    <th scope="col">Moeda</th>
                    <th scope="col">Emolumento</th>
                    <th scope="col">Estado</th>
                    <th scope="col"></th>
                    </tr>
                </thead>
                <tbody v-if="data && data.length > 0">
                    <tr v-for="c in data" :key="c.id">
                        <td>{{ c.tipo_conta }}</td>
                        <td>{{ c.numero_conta }}</td>
                        <td>{{ c.moeda_conta }}</td>
  
                        <td v-if="c.recebe_emolumentos_conta ==true">Sim</td>
                        <td v-else> Não</td>
                        <!-- <td>{{ c.conta_conjunta }}</td> -->
                        <div @click="toggleStatus(c.id, c.conta_conjunta)" style="text-align:-webkit-center;">
                          <td v-if="c.conta_conjunta==true"><button type="button" class="btn btn-success">Activo</button></td>
                          <td v-else><button class="btn btn-danger"  type="button">Inactivo</button></td>
                        </div>
                        <td>
                            <span title="Eliminar" @click="handleDelete(c.id)" class="icon_delete"><i class="fas fa-trash-alt"></i></span>
                             <router-link :to="`editarcontas/${c.id}`" style="text-decoration: none; color: inherit;">
                                <span title="Editar" @click="handleEdit(c.id)" class="icon_edit"><i class="fas fa-edit"></i></span>
                             </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="error">{{ error2 }}</div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { ref, watch } from 'vue'
  import NavbarAppHome from '../components/NavbarAppHome.vue'
  import Sidebar from '../components/Sidebar.vue'
  import useCollection from '../composables/useCollection'
  import getCollection from '../composables/getCollection'
  import getUser from '../composables/getUser'
  import { useRouter } from 'vue-router'
  import { projectFirestore } from '../firebase/config'
  import ConfiguracaoNav from '../components/ConfiguracaoNav.vue'
  export default {
    components: { NavbarAppHome, Sidebar,ConfiguracaoNav },
  
    setup() {
        const { user } = getUser()
        console.log(user, "useruser");
        const { addDoc, error } = useCollection('conta')
        const { error2, documents } = getCollection('conta')
        const data = ref([])
        const router = useRouter()
        const filteredDocuments = ref([]);
        let new_obj = ref();
        const isEdit = ref(false);
        const isEditingId = ref(null);
        const filterDocuments = () => {
            if (documents.value) {
                filteredDocuments.value = documents.value.filter(
                (doc) => doc.userId === user.value.uid
                );
                data.value = filteredDocuments.value;
            }
        };
        filterDocuments();
  
        watch([documents, user], () => {
            filterDocuments();
        });
        
  
        const nome_conta = ref('')
        const tipo_conta = ref('')
        const numero_conta = ref('')
        const banco_conta = ref('')
        const moeda_conta = ref('')
        const data_abertura_conta = ref('')
        const saldo_conta = ref('')
        const data_saldo_inicial = ref('')
        const recebe_emolumentos_conta = ref('')
        const conta_conjunta = ref('')
        const handleDelete = async (id) => {
            try {
                const docRef = projectFirestore.collection("conta").doc(id);
                await docRef.delete();
                console.log("Document successfully deleted.");
            } catch (error) {
                console.log("Error deleting document:", error.message);
            }
        };
  
        const toggleStatus = async (id, status) => {
        try {
          let collectionRef = await projectFirestore
            .collection("conta")
            .doc(id)
            .update({ conta_conjunta: !status});
        } catch (error) {
          console.log("Error updating document:", error);
        }
      };
        const handleSubmit = async () => {
            const conta = {
                nome_conta: nome_conta.value,
                tipo_conta: tipo_conta.value,
                numero_conta: numero_conta.value,
                banco_conta: banco_conta.value,
                moeda_conta: moeda_conta.value,
                data_abertura_conta: data_abertura_conta.value,
                saldo_conta: saldo_conta.value,
                data_saldo_inicial: data_saldo_inicial.value,
                recebe_emolumentos_conta: recebe_emolumentos_conta.value,
                conta_conjunta: conta_conjunta.value,
                userId: user.value.uid,
            }
            await addDoc(conta)
            if (!error.value) {
                nome_conta.value = ''
                tipo_conta.value = ''
                numero_conta.value = ''
                banco_conta.value = ''
                moeda_conta.value = ''
                data_abertura_conta.value = ''
                saldo_conta.value = ''
                data_saldo_inicial.value = ''
                recebe_emolumentos_conta.value = ''
                conta_conjunta.value = ''
            }
        }
        const handleEdit = async (id) => {
            try {
                const docRef = projectFirestore.collection("conta").doc(id);
                await docRef.get().then(async (doc) => {
                if (doc.exists) {
                    let id = await doc.id;
                    new_obj.value = await { ...doc.data(), id };
                    nome_conta.value = new_obj.value.nome_conta;
                    tipo_conta.value = new_obj.value.tipo_conta;
                    numero_conta.value = new_obj.value.numero_conta;
                    banco_conta.value = new_obj.value.banco_conta;
                    moeda_conta.value = new_obj.value.moeda_conta;
                    data_abertura_conta.value = new_obj.value.data_abertura_conta;
                    saldo_conta.value = new_obj.value.saldo_conta;
                    data_saldo_inicial.value = new_obj.value.data_saldo_inicial;
                    recebe_emolumentos_conta.value = new_obj.value.recebe_emolumentos_conta;
                    conta_conjunta.value = new_obj.value.conta_conjunta;
                }
                });
                isEdit.value = true;
                isEditingId.value = id;
            } catch (error) {
                console.log("Error deleting document:", error.message);
            }
            };
  
        const submitEdit = async () => {
            alert("submitEditsubmitEdit");
            const docRef = projectFirestore
                .collection("conta")
                .doc(isEditingId.value);
            await docRef.update({
                nome_conta: nome_conta.value,
                tipo_conta: tipo_conta.value,
                numero_conta: numero_conta.value,
                banco_conta: banco_conta.value,
                moeda_conta: moeda_conta.value,
                data_abertura_conta: data_abertura_conta.value,
                saldo_conta: saldo_conta.value,
                data_saldo_inicial: data_saldo_inicial.value,
                recebe_emolumentos_conta: recebe_emolumentos_conta.value,
                conta_conjunta: conta_conjunta.value,
                userId: user.value.uid,
            });
            isEdit.value = false;
            nome_conta.value = null;
            tipo_conta.value = null;
            numero_conta.value = null;
            banco_conta.value = null;
            moeda_conta.value = null;
            data_abertura_conta.value = null;
            saldo_conta.value = null;
            data_saldo_inicial.value = null;
            recebe_emolumentos_conta.value = null;
            conta_conjunta.value = null;
            };
        return {
                nome_conta,
                tipo_conta,
                numero_conta,
                banco_conta,
                moeda_conta,
                data_abertura_conta,
                saldo_conta,
                data_saldo_inicial,
                recebe_emolumentos_conta,
                conta_conjunta,
                error,
                handleSubmit,
                error2,
                filteredDocuments,
                data,
                handleDelete,
                handleEdit,
                submitEdit,
                toggleStatus
        };
    }, 
  };
  </script>
  
  <style scoped>
  .cabecalho_configuracoes{
    width: 100%;
    left: 180px;
  }
  .titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
  }
  .conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: 0%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
  }
  .menu_configuracoes{
    width: 100%;
  }
  
  .btn_missao_diplomatica{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    vertical-align: middle;
  }
  .btn_missao_diplomatica:hover {
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
  }
  .missao_diplomatica{
    position: absolute;
    width: 215px;
    height: 46px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  
  .missao_diplomatica:hover {
  color: white;
  }
  
  .btn_orgaos{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 485px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
    vertical-align: middle;
  }
  
  .btn_orgaos:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .orgaos{
    position: absolute;
    width: 209px;
    height: 20px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
  }
  
  .orgaos:hover {
  color: white;
  }
  
  .btn_contas{
    position: absolute;
    width: 214px;
    height: 84px;
    left: 1190px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
  }
  .btn_contas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  .contas{
    position: absolute;
    width: 198px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
  }
  
  .contas:hover {
    color: white;
  }
  .btn_funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 1425px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
  }
  
  .btn_funcionarios_empresas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .cambio{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
  }
  
  .cambio:hover {
    color: white;
  }
  .cambio{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 955px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
  }
  
  .cambio:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
  }
  
  .funcionarios_empresas:hover {
    color: white;
  }
  
  .btn_naturezas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1660px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
  }
  
  .btn_naturezas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .naturezas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
  }
  
  .naturezas:hover {
    color: white;
  }
  .btn_moedas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 720px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
  }
  .btn_moedas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .moedas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
  }
  .moedas:hover {
    color: white;
  }
  
  .formulario_contas{
    position: absolute;
    width: 136px;
    height: 24px;
    left: 285px;
    top: 300px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #333333;
  }
  .dot_contas{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 5px;
    top: 1px;
    background-color: rgb(25, 226, 25);
    border-radius: 15px;
  }
  
  .contas-list{
    position: absolute;
    width: 980px;
    height: auto;
    left: 231px;
    top: 400px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
    border: 1px solid rgb(198, 192, 192);
  }
  .dados_contas{
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    top: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
  }
  .table_contas{
    position: absolute;
    top: 60px;
  }
  
  table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  }
  
  table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
  }
  
  table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
  }
  
  table th,
  table td {
  padding: .625em;
  text-align: center;
  }
  
  table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
  }
  
  @media screen and (max-width: 600px) {
  table {
    border: 0;
  }
  
  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
  }
  /* general styling */
  body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.25;
  }
  
  .icon_delete{
    color:red;
    padding: 10px;
    cursor: pointer;
  }
  
  .icon_edit{
    color: rgb(31, 227, 31);
    cursor: pointer;
  }
  .button{
    padding: 10px;
    cursor: pointer;
  }
  .btn {
    --bs-btn-padding-x: 0.75rem;
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: var(--bs-border-radius);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 hsla(0,0%,100%,0.15),0 1px 1px rgba(0,0,0,0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb),.5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color)
}

.btn-check+.btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color)
}

.btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg)
}

.btn-check:focus-visible+.btn,.btn:focus-visible {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow)
}

.btn-check:checked+.btn,.btn.active,.btn.show,.btn:first-child:active,:not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color)
}

.btn-check:checked+.btn:focus-visible,.btn.active:focus-visible,.btn.show:focus-visible,.btn:first-child:active:focus-visible,:not(.btn-check)+.btn:active:focus-visible {
    box-shadow: var(--bs-btn-focus-box-shadow)
}

.btn.disabled,.btn:disabled,fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity)
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #0d6efd;
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd
}

.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #6c757d;
    --bs-btn-border-color: #6c757d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130,138,145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d
}
.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #198754;
    --bs-btn-border-color: #198754;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #157347;
    --bs-btn-hover-border-color: #146c43;
    --bs-btn-focus-shadow-rgb: 60,153,110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #146c43;
    --bs-btn-active-border-color: #13653f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #198754;
    --bs-btn-disabled-border-color: #198754
}
.btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: #dc3545;
    --bs-btn-border-color: #dc3545;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #bb2d3b;
    --bs-btn-hover-border-color: #b02a37;
    --bs-btn-focus-shadow-rgb: 225,83,97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #b02a37;
    --bs-btn-active-border-color: #a52834;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #dc3545;
    --bs-btn-disabled-border-color: #dc3545
}
  </style>