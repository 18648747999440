import { createRouter, createWebHistory } from 'vue-router'
import { projectAuth } from '../firebase/config'
import LandingPage from '../views/LandingPage.vue'
import Apoio from '../views/Apoio.vue'
import Login from '../views/Login'
import Price from '../views/Price.vue'
import SignupForm from '../components/SignupForm.vue'
import AppHome from '../views/AppHome.vue'
import Configuracao from '../views/Configuracao.vue'
import Financas from '../views/Financas.vue'
import GestaoDocumental from '../views/GestaoDocumental.vue'
import Orcamento from '../views/Orcamento.vue'
import Relatorios from '../views/Relatorios.vue'
import TribunalContas from '../views/TribunalContas.vue'
import Perfil from '../views/Perfil.vue'
import Fechamentos from '../views/Fechamentos.vue'
import MissaoDiplomatica from '../views/MissaoDiplomatica.vue'
import Orgaos from '../views/Orgaos.vue'
import Contas from '../views/Contas.vue'
import FuncionariosEmpresas from '../views/FuncionariosEmpresas.vue'
import Naturezas from '../views/Naturezas.vue'
import RegistarOrcamento from '../views/RegistarOrcamento.vue'
import RegistarVencimento from '../views/RegistarVencimento.vue'
import TabelaVencimento from '../views/TabelaVencimento.vue'
import RegistarCabimentacao from '../views/RegistarCabimentacao.vue'
import RelatorioOrcamento from '../views/RelatorioOrcamento.vue'
import EditarOrcamento from '../views/EditarOrcamento.vue'
import Receitas from '../views/Receitas.vue'
import Despesas from '../views/Despesas.vue'
import Transferencias from '../views/Transferencias.vue'
import Cambio from '../views/Cambio.vue'
import Modelos from '../views/Modelos.vue'
import Movimentos from '../views/Movimentos.vue'
import FormularioContas from '../views/FormularioContas.vue'
import FormularioFuncionario from '../views/FormularioFuncionario.vue'
import FormularioFuncionarioLocal from '../views/FormularioFuncionarioLocal.vue'
import FormularioEmpresa from '../views/FormularioEmpresa.vue'
import FormularioContrato from '../views/FormularioContrato.vue'
import EditarContas from '../views/EditarContas.vue'
import EditarCambio from '../views/EditarCambio.vue'
import TabelaFuncionario from '../views/TabelaFuncionario.vue'
import TabelaFuncionarioLocal from '../views/TabelaFuncionarioLocal.vue'
import EditarFuncionario from '../views/EditarFuncionario.vue'
import EditarFuncionarioLocal from '../views/EditarFuncionarioLocal.vue'
import EditarEmpresa from '../views/EditarEmpresa.vue'
import EditarContrato from '../views/EditarContrato.vue'
import TabelaEmpresa from '../views/TabelaEmpresa.vue'
import TabelaContrato from '../views/TabelaContrato.vue'
import FormularioNaturezas from '../views/FormularioNaturezas.vue'
import FormularioReceitas from '../views/FormularioReceitas.vue'
import FormularioDespesas from '../views/FormularioDespesas.vue'
import FormularioTransferencia from '../views/FormularioTransferencia.vue'
import FormularioCambio from '../views/FormularioCambio.vue'
import RequisicaoDeVerbas from '../views/RequisicaoDeVerbas.vue'
import TermoDeEntrega from '../views/TermoDeEntrega.vue'
import AdicionarDocumento from '../views/AdicionarDocumento.vue'
import FolhaSalQuadro from '../views/FolhaSalQuadro.vue'
import FolhaSalLocal from '../views/FolhaSalLocal.vue'
import BalancoConta from '../views/BalancoConta.vue'
import ContaCorrenteBancoML from '../views/ContaCorrenteBancoML.vue'
import ContaCorrenteBanco from '../views/ContaCorrenteBanco.vue'
import MovimentoCaixa from '../views/MovimentoCaixa.vue'
import RelRecuRec from '../views/RelRecuRec.vue'
import DespesasPagar from '../views/DespesasPagar.vue'
import ProgFinanceira from '../views/ProgFinanceira.vue'
import BalMensal from '../views/BalMensal.vue'
import CheqCirPagos from '../views/CheqCirPagos.vue'
import CheqCir from '../views/CheqCir.vue'
import GuiaReceita from '../views/GuiaReceita.vue'
import LivroControlo from '../views/LivroControlo.vue'
import LivroDocumentos from '../views/LivroDocumentos.vue'
import LivroAssentamentoFuncionarios from '../views/LivroAssentamentoFuncionarios.vue'
import RequisicaoVerbas from '../views/RequisicaoVerbas.vue'
import TransferenciaInterna from '../views/TransferenciaInterna.vue'
import RelDesPagCon from '../views/RelDesPagCon.vue'
import RelDesPagasRecPro from '../views/RelDesPagasRecPro.vue'
import RelDesPagTes from '../views/RelDesPagTes.vue'
import RelOutraSai from '../views/RelOutraSai.vue'
import Moedas from '../views/Moedas.vue'
import Janeiro from '../views/Janeiro.vue'
import Fevereiro from '../views/Fevereiro.vue'
import Marco from '../views/Marco.vue'
import Abril from '../views/Abril.vue'
import Maio from '../views/Maio.vue'
import Junho from '../views/Junho.vue'
import Julho from '../views/Julho.vue'
import Agosto from '../views/Agosto.vue'
import Setembro from '../views/Setembro.vue'
import Outubro from '../views/Outubro.vue'
import Novembro from '../views/Novembro.vue'
import Dezembro from '../views/Dezembro.vue'
import Modelo_1 from '../views/Modelo_1.vue'
import Modelo_2 from '../views/Modelo_2.vue'
import Modelo_2A from '../views/Modelo_2A.vue'
import Modelo_3 from '../views/Modelo_3.vue'
import Modelo_4 from '../views/Modelo_4.vue'
import Modelo_5 from '../views/Modelo_5.vue'
import Modelo_6 from '../views/Modelo_6.vue'
import Modelo_7 from '../views/Modelo_7.vue'
import Modelo_8 from '../views/Modelo_8.vue'
import Modelo_8A from '../views/Modelo_8A.vue'
import Modelo_9 from '../views/Modelo_9.vue'
import Modelo_10 from '../views/Modelo_10.vue'
import Modelo_11 from '../views/Modelo_11.vue'
import Modelo_12 from '../views/Modelo_12.vue'
import Modelo_13 from '../views/Modelo_13.vue'
import Modelo_14 from '../views/Modelo_14.vue'
import Modelo_15 from '../views/Modelo_15.vue'
import Modelo_16 from '../views/Modelo_16.vue'
import Modelo_17 from '../views/Modelo_17.vue'
import Modelo_8B from '../views/Modelo_8B.vue'
import Anexo_1 from '../views/Anexo_1.vue'
import Anexo_2 from '../views/Anexo_2.vue'
import Anexo_3 from '../views/Anexo_3.vue'
import Anexo_4 from '../views/Anexo_4.vue'
import Anexo_5 from '../views/Anexo_5.vue'
import Testes from '../views/Testes.vue'
import SelecionarTipoNatureza from '../views/SelecionarTipoNatureza.vue'
import FormularioNaturezasReceitas from '../views/FormularioNaturezasReceitas.vue'
import UpdateProfile from '../views/UpdateProfile.vue'
import TabelaNaturezasReceitas from '../views/TabelaNaturezasReceitas.vue'
import TabelaNaturezasDespesas from '../views/TabelaNaturezasDespesas.vue'
import EditReceitas from '../views/EditReceitas.vue'
import EditDespesas from '../views/EditDespesas.vue'
import EditTransferencias from '../views/EditTransferencias.vue'
import Salario from '../views/Salario.vue'
import FormularioSalarioPessoalLocal from '../views/FormularioSalarioPessoalLocal.vue'
import FormularioSalarioPessoalCentral from '../views/FormularioSalarioPessoalCentral.vue'
import TabelaSalarioPessoalLocal from '../views/TabelaSalarioPessoalLocal.vue'
import TabelaSalarioPessoalCentral from '../views/TabelaSalarioPessoalCentral.vue'
import ResumoFolhaSalarial from '../views/ResumoFolhaSalarial.vue'
import RelatorioModelo_4 from '../views/RelatorioModelo_4.vue'
import EditarNaturezaDespesa from '../views/EditarNaturezaDespesa.vue'
import EditarNaturezaReceita from '../views/EditarNaturezaReceita.vue'
import RelatorioPessoal from '../views/RelatorioPessoal.vue'
import RelatorioContaCorrente from '../views/RelatorioContaCorrente.vue'
import RelatorioOperacaoTesouraria from '../views/RelatorioOperacaoTesouraria.vue'
import OutrosRelatorios from '../views/OutrosRelatorios.vue'
import OutrosRelatoriosIndividuais from '../views/OutrosRelatoriosIndividuais.vue'
import RelatoriosPessoalLista from '../views/RelatoriosPessoalLista.vue'
import RelatorioContaCorrenteLista from '../views/RelatorioContaCorrenteLista.vue'
import RelatorioOperacaoTesourariaLista from '../views/RelatorioOperacaoTesourariaLista.vue'
import ListaMensalFolhaSalarioQuadro from '../views/ListaMensalFolhaSalarioQuadro.vue'
import ListaMensalFolhaSalarioLocal from '../views/ListaMensalFolhaSalarioLocal.vue'
import ListaMensalFolhaSalarioResumo from '../views/ListaMensalFolhaSalarioResumo.vue'
import EditarSalarioFuncionarioCentral from '../views/EditarSalarioFuncionarioCentral.vue'
import EditarSalarioFuncionarioLocal from '../views/EditarSalarioFuncionarioLocal.vue'

ListaMensalFolhaSalarioLocal
// auth guard
const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  console.log('current user in auth guard: ', user)
  if (!user) {
    next({ name: 'Login'})
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/apoio',
    name: 'Apoio',
    component: Apoio
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/price',
    name: 'Price',
    component: Price
  },
  {
    path: '/signupform',
    name: 'SignupForm',
    component: SignupForm
  },
  {
    path: '/apphome',
    name: 'AppHome',
    component: AppHome,
    beforeEnter: requireAuth
  },
  {
    path: '/configuracao',
    name: 'Configuracao',
    component: Configuracao,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/financas',
    name: 'Financas',
    component: Financas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/gestaodocumental',
    name: 'GestaoDocumental',
    component: GestaoDocumental,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/orcamento',
    name: 'Orcamento',
    component: Orcamento,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/relatorios',
    name: 'Relatorios',
    component: Relatorios,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/tribunalcontas',
    name: 'TribunalContas',
    component: TribunalContas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/fechamentos',
    name: 'Fechamentos',
    component: Fechamentos,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/missaodiplomatica',
    name: 'MissaoDiplomatica',
    component: MissaoDiplomatica,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/orgaos',
    name: 'Orgaos',
    component: Orgaos,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/contas',
    name: 'Contas',
    component: Contas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/funcionariosempresas',
    name: 'FuncionariosEmpresas',
    component: FuncionariosEmpresas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/naturezas',
    name: 'Naturezas',
    component: Naturezas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: Perfil,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/registarorcamento',
    name: 'RegistarOrcamento',
    component: RegistarOrcamento,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/registarcabimentacao',
    name: 'RegistarCabimentacao',
    component: RegistarCabimentacao,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/relatorioorcamento',
    name: 'RelatorioOrcamento',
    component: RelatorioOrcamento,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/editarorcamento',
    name: 'EditarOrcamento',
    component: EditarOrcamento,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/receitas',
    name: 'Receitas',
    component: Receitas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/editreceitas/:id',
    name: 'EditReceitas',
    component: EditReceitas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/despesas',
    name: 'Despesas',
    component: Despesas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/editdespesas/:id',
    name: 'EditDespesas',
    component: EditDespesas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/transferencias',
    name: 'Transferencias',
    component: Transferencias,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/edittransferencias/:id',
    name: 'EditTransferencias',
    component: EditTransferencias,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/cambio',
    name: 'Cambio',
    component: Cambio,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/movimentos',
    name: 'Movimentos',
    component: Movimentos,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelos',
    name: 'Modelos',
    component: Modelos,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/formulariocontas',
    name: 'FormularioContas',
    component: FormularioContas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/formulariofuncionario',
    name: 'FormularioFuncionario',
    component: FormularioFuncionario,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/formulariofuncionariolocal',
    name: 'FormularioFuncionarioLocal',
    component: FormularioFuncionarioLocal,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/editarfuncionario/:id',
    name: 'EditarFuncionario',
    component: EditarFuncionario,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/editarfuncionariolocal/:id',
    name: 'EditarFuncionarioLocal',
    component: EditarFuncionarioLocal,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/editarempresa/:id',
    name: 'EditarEmpresa',
    component: EditarEmpresa,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/editarcontrato/:id',
    name: 'EditarContrato',
    component: EditarContrato,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/formularioempresa',
    name: 'FormularioEmpresa',
    component: FormularioEmpresa,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/formulariocontrato',
    name: 'FormularioContrato',
    component: FormularioContrato,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/editarcontas/:id',
    name: 'EditarContas',
    component: EditarContas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/editarcambio/:id',
    name: 'EditarCambio',
    component: EditarCambio,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/tabelafuncionario',
    name: 'TabelaFuncionario',
    component: TabelaFuncionario,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/tabelafuncionariolocal',
    name: 'TabelaFuncionarioLocal',
    component: TabelaFuncionarioLocal,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/tabelaempresa',
    name: 'TabelaEmpresa',
    component: TabelaEmpresa,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/tabelacontrato',
    name: 'TabelaContrato',
    component: TabelaContrato,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/formularionaturezas',
    name: 'FormularioNaturezas',
    component: FormularioNaturezas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/formularioreceitas',
    name: 'FormularioReceitas',
    component: FormularioReceitas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/formulariodespesas',
    name: 'FormularioDespesas',
    component: FormularioDespesas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/formulariotransferencia',
    name: 'FormularioTransferencia',
    component: FormularioTransferencia,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/formulariocambio',
    name: 'FormularioCambio',
    component: FormularioCambio,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/requisicaodeverbas',
    name: 'RequisicaoDeVerbas',
    component: RequisicaoDeVerbas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/termodeentrega',
    name: 'TermoDeEntrega',
    component: TermoDeEntrega,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/adicionardocumentos',
    name: 'AdicionarDocumento',
    component: AdicionarDocumento,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/relatoriospessoallista/:id/item/:itemId/folhasalquadro/:month/:year',
    name: 'FolhaSalQuadro',
    component: FolhaSalQuadro,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/relatoriospessoallista/:id/item2/:itemId/folhasallocal/:month/:year',
    name: 'FolhaSalLocal',
    component: FolhaSalLocal,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/balancoconta',
    name: 'BalancoConta',
    component: BalancoConta,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/contacorrentebancoml',
    name: 'ContaCorrenteBancoML',
    component: ContaCorrenteBancoML,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/contacorrentebanco',
    name: 'ContaCorrenteBanco',
    component: ContaCorrenteBanco,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/movimentocaixa',
    name: 'MovimentoCaixa',
    component: MovimentoCaixa,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/relrecurec',
    name: 'RelRecuRec',
    component: RelRecuRec,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/despesaspagar',
    name: 'DespesasPagar',
    component: DespesasPagar,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/progfinanceira',
    name: 'ProgFinanceira',
    component: ProgFinanceira,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/balmensal',
    name: 'BalMensal',
    component: BalMensal,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/cheqcirpagos',
    name: 'CheqCirPagos',
    component: CheqCirPagos,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/cheqcir',
    name: 'CheqCir',
    component: CheqCir,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/guiareceita',
    name: 'GuiaReceita',
    component: GuiaReceita,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/livrocontrolo',
    name: 'LivroControlo',
    component: LivroControlo,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/livrodocumentos',
    name: 'LivroDocumentos',
    component: LivroDocumentos,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/livroassentamentofuncionarios',
    name: 'LivroAssentamentoFuncionarios',
    component: LivroAssentamentoFuncionarios,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/requisicaoverbas',
    name: 'RequisicaoVerbas',
    component: RequisicaoVerbas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/transferenciainterna',
    name: 'TransferenciaInterna',
    component: TransferenciaInterna,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/reldespagcon',
    name: 'RelDesPagCon',
    component: RelDesPagCon,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/reldespagasrecpro',
    name: 'RelDesPagasRecPro',
    component: RelDesPagasRecPro,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/reldespagtes',
    name: 'RelDesPagTes',
    component: RelDesPagTes,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/reloutrasai',
    name: 'RelOutraSai',
    component: RelOutraSai,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/moedas',
    name: 'Moedas',
    component: Moedas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/janeiro',
    name: 'Janeiro',
    component: Janeiro,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/fevereiro',
    name: 'Fevereiro',
    component: Fevereiro,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/marco',
    name: 'Marco',
    component: Marco,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/abril',
    name: 'Abril',
    component: Abril,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/maio',
    name: 'Maio',
    component: Maio,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/junho',
    name: 'Junho',
    component: Junho,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/julho',
    name: 'Julho',
    component: Julho,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/agosto',
    name: 'Agosto',
    component: Agosto,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/setembro',
    name: 'Setembro',
    component: Setembro,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/outubro',
    name: 'Outubro',
    component: Outubro,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/novembro',
    name: 'Novembro',
    component: Novembro,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/dezembro',
    name: 'Dezembro',
    component: Dezembro,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_1',
    name: 'Modelo_1',
    component: Modelo_1,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_2',
    name: 'Modelo_2',
    component: Modelo_2,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_2A',
    name: 'Modelo_2A',
    component: Modelo_2A,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_3',
    name: 'Modelo_3',
    component: Modelo_3,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_4',
    name: 'Modelo_4',
    component: Modelo_4,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_5',
    name: 'Modelo_5',
    component: Modelo_5,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_6',
    name: 'Modelo_6',
    component: Modelo_6,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_7',
    name: 'Modelo_7',
    component: Modelo_7,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_8',
    name: 'Modelo_8',
    component: Modelo_8,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_8A',
    name: 'Modelo_8A',
    component: Modelo_8A,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_9',
    name: 'Modelo_9',
    component: Modelo_9,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_10',
    name: 'Modelo_10',
    component: Modelo_10,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_11',
    name: 'Modelo_11',
    component: Modelo_11,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_12',
    name: 'Modelo_12',
    component: Modelo_12,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_13',
    name: 'Modelo_13',
    component: Modelo_13,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_14',
    name: 'Modelo_14',
    component: Modelo_14,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_15',
    name: 'Modelo_15',
    component: Modelo_15,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_16',
    name: 'Modelo_16',
    component: Modelo_16,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_17',
    name: 'Modelo_17',
    component: Modelo_17,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/modelo_8B',
    name: 'Modelo_8B',
    component: Modelo_8B,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/anexo_1',
    name: 'Anexo_1',
    component: Anexo_1,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/anexo_2',
    name: 'Anexo_2',
    component: Anexo_2,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/anexo_3',
    name: 'Anexo_3',
    component: Anexo_3,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/anexo_4',
    name: 'Anexo_4',
    component: Anexo_4,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/anexo_5',
    name: 'Anexo_5',
    component: Anexo_5,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/testes',
    name: 'Testes',
    component: Testes,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/selecionartiponatureza',
    name: 'SelecionarTipoNatureza',
    component: SelecionarTipoNatureza,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/formularionaturezasreceitas',
    name: 'FormularioNaturezasReceitas',
    component: FormularioNaturezasReceitas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/updateprofile/:id',
    name: 'UpdateProfile',
    component: UpdateProfile,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/tabelanaturezasdespesas',
    name: 'TabelaNaturezasDespesas',
    component: TabelaNaturezasDespesas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/tabelanaturezasreceitas',
    name: 'TabelaNaturezasReceitas',
    component: TabelaNaturezasReceitas,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/registarvencimento',
    name: 'RegistarVencimento',
    component: RegistarVencimento,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/tabelavencimento',
    name: 'TabelaVencimento',
    component: TabelaVencimento,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/salario',
    name: 'Salario',
    component: Salario,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/formulariosalariopessoalcentral',
    name: 'FormularioSalarioPessoalCentral',
    component: FormularioSalarioPessoalCentral,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/formulariosalariopessoallocal',
    name: 'FormularioSalarioPessoalLocal',
    component: FormularioSalarioPessoalLocal,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/tabelasalariopessoalcentral',
    name: 'TabelaSalarioPessoalCentral',
    component: TabelaSalarioPessoalCentral,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/tabelasalariopessoallocal',
    name: 'TabelaSalarioPessoalLocal',
    component: TabelaSalarioPessoalLocal,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/resumofolhasalarial',
    name: 'ResumoFolhaSalarial',
    component: ResumoFolhaSalarial,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/relatoriomodelo_4',
    name: 'RelatorioModelo_4',
    component: RelatorioModelo_4,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/editarnaturezadespesa/:id',
    name: 'EditarNaturezaDespesa',
    component: EditarNaturezaDespesa,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/editarnaturezareceita/:id',
    name: 'EditarNaturezaReceita',
    component: EditarNaturezaReceita,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/editarsalariofuncionariocentral/:id',
    name: 'EditarSalarioFuncionarioCentral',
    component: EditarSalarioFuncionarioCentral,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/editarsalariofuncionariolocal/:id',
    name: 'EditarSalarioFuncionarioLocal',
    component: EditarSalarioFuncionarioLocal,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/relatoriopessoal',
    name: 'RelatorioPessoal',
    component: RelatorioPessoal,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/relatoriocontacorrente',
    name: 'RelatorioContaCorrente',
    component: RelatorioContaCorrente,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/relatoriooperacaotesouraria',
    name: 'RelatorioOperacaoTesouraria',
    component: RelatorioOperacaoTesouraria,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/outrosrelatorios',
    name: 'OutrosRelatorios',
    component: OutrosRelatorios,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/outrosrelatoriosindividuais/:id',
    name: 'OutrosRelatoriosIndividuais',
    component: OutrosRelatoriosIndividuais,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/relatoriospessoallista/:id',
    name: 'RelatoriosPessoalLista',
    component: RelatoriosPessoalLista,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/relatoriospessoallista/:id/item/:itemId',
    name: 'item',
    component: ListaMensalFolhaSalarioQuadro,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/relatoriospessoallista/:id/item2/:itemId',
    name: 'item2',
    component: ListaMensalFolhaSalarioLocal,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/relatoriospessoallista/:id/item3/:itemId',
    name: 'item3',
    component: ListaMensalFolhaSalarioResumo,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/relatoriocontacorrentelista/:id',
    name: 'RelatorioContaCorrenteLista',
    component: RelatorioContaCorrenteLista,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/relatoriooperacaotesourarialista/:id',
    name: 'RelatorioOperacaoTesourariaLista',
    component: RelatorioOperacaoTesourariaLista,
    beforeEnter: requireAuth,
    props: true,
  },
  
  {
    path: '/listamensalfolhasalariolocal',
    name: 'ListaMensalFolhaSalarioLocal',
    component: ListaMensalFolhaSalarioLocal,
    beforeEnter: requireAuth,
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
