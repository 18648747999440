<template>
  <div>
      <div class="cabecalho_home">
          <div class="cabecalho_titulo">
              A solução contablistica para às Missões Diplomáticas!
          </div>
      </div>
      <div class="tipo_servico">
          SOFTWARE DE CONTABILIDADE | TRIBUNAL DE CONTAS
      </div>
      <div class="detalhes_servicos">
          <h3>Software de <br>contabilidade on-line <br>simples e inteligente <br>para as Missões <br>Diplomáticas</h3>
      </div>
      <div class="imagem_home">
          <img src="../assets/contabilidade.png" alt="imagem contabilidade">
      </div>
      <div class="envio_compra">
          <p>Rastreie suas despesas e obtenha seus relatórios, <br> todos no mesmo lugar.</p>
          <button class="butao_compra">Comprar</button>
      </div>
      <div>
          <PriceComponent />
      </div>
  </div>
</template>

<script>
import PriceComponent from '../components/PriceComponent.vue'
export default {
    components: { PriceComponent }

}
</script>

<style>
.cabecalho_home {
    position: relative;
    width: 100%;
    height: 100px;
    left: 0px;
    top: 0px;
    background: linear-gradient(270deg, #51B8D5 24.97%, #00344C 114.31%);
}

.cabecalho_titulo {
    position: absolute;
    height: 50px;
    left: 12.36%;
    right: 12.08%;
    top: 25.96%;
    bottom: 5.11%;
    background: #00344C;
    border-radius: 15px;
    align-items: center;
    color: #fff;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 700;
    font-size: 2.0vw;
    text-align: center;

}
.tipo_servico{
    position: relative;
    width: 813px;
    height: 92px;
    left: 10px;
    top: 50px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 1vw;
    line-height: 28px;
    color: #000000
}
.detalhes_servicos{
    position: absolute;
    width: 542px;
    height: 325px;
    left: 220px;
    top: 250px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 700;
    font-size: 2vw;
    align-items: initial;
    line-height: 35px;
    color: #000000;
}
.detalhes_servicos h3{
    text-align: left;
}
.envio_compra {
    position: absolute;
    width: 490px;
    height: 67px;
    left: 220px;
    top: 470px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9vw;
    line-height: 20px;
    color: #000000;
    text-align: left;
}
.butao_compra {
    position: absolute;
    width: 100px;
    height: 30px;
    left: 0px;
    right: 0%;
    top: 70px;
    bottom: 0%;
    background: #00344C;
    border-radius: 20px;
    color: #fff;
}
.imagem_home {
    position: relative;
    width: 50%;
    height: 400px;
    left: 650px;
    top: 0px;
}
.imagem_home img {
    position: relative;
    width: 100%;
    height: 300px;    
}
.escolha_plano {
    width: 100%;
    align-items: center;
    position: relative;
    height: 68px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 1.2vw;
    color: #000000;
}
.PriceComponent{
    margin-bottom: 20px;
}
</style>