<template>
  <div class="login_principal">
    <div class="login_box">
      <div><img class="logo_tipo" src="../assets/logo_prescom.jpg" alt="Logo_prescon"></div>
      <span class="title_login">Login</span>
      <form @submit.prevent="handleSubmit">
        <label class="login_id">Endereço de e-mail</label>
        <input class="rec_login_id" required type="email" v-model="email">
        <label class="palavrapasse">Palavra de Passe</label>
        <input class="rec_palavra_passe" required type="password" v-model="password">
        <input class="rec_lembre" type="checkbox" value="Lembre-se de mim">
        <label class="lembre">Lembre-se de mim</label>
        <div class="error">{{ error }}</div>
        <button class="butao_login">Login</button>
      </form>
      <span class="regressar_inicio"><router-link to="/SignupForm" style="text-decoration: none; color: inherit;">Ainda não tem uma conta? Increva-se já!</router-link></span>
      <span class="nota_login">Ao aceitar fazer o login, demonstra que aceita os nossos termos e tem o conhecimento global dos termos de privacidade.</span>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import useLogin from '../composables/useLogin'


  export default {
    setup(props, context) {
      // refs
      const email = ref('')
      const password = ref('')

      const { error, login } = useLogin()

      const handleSubmit = async() => {
        await login(email.value, password.value)
        if (!error.value) {
            context.emit('login')
        }
      }

      return { email, password, handleSubmit, error }
    }
  }
</script>

<style scoped>
.login_principal{
  position: absolute;
  width: 100%;
  height: 1024px;
  left: 0px;
  top: 0px;
  background: #00344C;
}

.login_box{
  position: relative;
  width: 446px;
  height: 700px;
  align-items: center;
  margin: 50px 40%;
  background: #FFFFFF;
  border: 1px solid rgba(229, 229, 229, 0.68);
}

.logo_tipo{
  position: absolute;
  width: 220px;
  height: 74px;
  left: 110px;
  top: 52px;
}

.line_divisor{
  position: absolute;
  width: 316px;
  height: 0px;
  left: 50px;
  top: 152px;
  border: 1px solid #51B8D5;
}

.title_login{
  position: absolute;
  width: 205px;
  height: 42px;
  left: 100px;
  top: 203px;
  font-family: Signika;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}

.login_id{
  position: absolute;
  width: 114px;
  height: 17px;
  left: 62px;
  top: 300px;
  font-family: Signika;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4E4E4E;
}
.rec_login_id{
  position: absolute;
  width: 315px;
  height: 42px;
  left: 62px;
  top: 326px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 3px;
}
.palavrapasse{
  position: absolute;
  width: 98px;
  height: 34px;
  left: 62px;
  top: 385px;
  font-family: Signika;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4E4E4E;
}
.rec_palavra_passe{
  position: absolute;
  width: 315px;
  height: 42px;
  left: 62px;
  top: 410px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 3px;
}
.rec_lembre{
  position: absolute;
  width: 18px;
  height: 18px;
  left: 62px;
  top: 468px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 3px;
}
.lembre{
  position: absolute;
  width: 260px;
  height: 17px;
  left: 85px;
  top: 472px;
  font-family: Signika;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4E4E4E;
}
.butao_login{
  position: absolute;
  width: 315px;
  height: 42px;
  left: 62px;
  top: 543px;
  background: #51B8D5;
  border-radius: 3px;
  color: white;
  cursor: pointer;
}

.nota_login{
  position: absolute;
  width: 314px;
  height: 18px;
  left: 62px;
  top: 603px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.regressar_inicio{
  position: absolute;
  width: 314px;
  height: 18px;
  left: 62px;
  top: 515px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color:  #51B8D5;
  cursor: pointer;
}
</style>