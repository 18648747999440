<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">REGISTAR CÂMBIO</h1>
            </div>
            <div class="menu_configuracoes">
                <router-link to="/MissaoDiplomatica" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_missao_diplomatica">
                        MISSÃO<br> DIPLOMATICA 
                    </div>
                </router-link>
                <router-link to="/Orgaos" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_orgaos">
                        ORGÃOS
                    </div>
                </router-link>
                <router-link to="/Moedas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_moedas">
                        MOEDAS
                    </div>
                </router-link>
                <router-link to="/Cambio" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_cambio">
                        CÂMBIO
                    </div>
                </router-link>
                <router-link to="/Contas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_contas">
                        CONTAS
                    </div>
                </router-link>
                <router-link to="/FuncionariosEmpresas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_funcionarios_empresas">
                        FUNCIONÁRIOS E<br> EMPRESAS
                    </div>
                </router-link>
                <router-link to="/Naturezas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_naturezas">
                        NATUREZAS
                    </div>
                </router-link>
            </div>
        </div>
        <div class="form_container">
            <h1 class="titulo_formulario">EDITAR CÂMBIO</h1>
            <router-link to="/Cambio" style="text-decoration: none; color: inherit;">
                <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
            </router-link>
            <form @submit.prevent="isEdit ? submitEdit() : handleSubmit">
                <label class="moeda_cambio">Moeda De</label>
                <div class="moeda1">
                    <select
                        required
                        v-model="moeda_cambio"
                        class="input_moeda_cambio">
                        <option disabled value="">Por favor escolha uma opção</option>
                        <option
                            v-for="moe in moedaDatas"
                            :key="moe.id"
                            :value="moe.moeda_local"
                            >
                            {{ moe.moeda_local }}
                            </option>
                            <option
                                v-for="moe in moedaDatas"
                                :key="moe.id"
                                :value="moe.moeda_gestao"
                                >
                                {{ moe.moeda_gestao }}
                                </option>
                                <option
                                v-for="moe in moedaDatas"
                                :key="moe.id"
                                :value="moe.outra_moeda"
                                >
                                {{ moe.outra_moeda }}
                                </option>
                    </select>
                </div>
                <input type="hidden" :value="moeda_para" class="moeda_data">
                <label class="moeda_cambio1">Moeda Para</label>
                <div class="moeda2">
                    <select
                        required
                        v-model="moeda_para"
                        class="input_moeda_cambio_para">
                        <option disabled value="">Por favor escolha uma opção</option>
                        <option
                            v-for="moe in moedaDatas"
                            :key="moe.id"
                            :value="moe.moeda_local"
                            >
                            {{ moe.moeda_local }}
                            </option>
                            <option
                                v-for="moe in moedaDatas"
                                :key="moe.id"
                                :value="moe.moeda_gestao"
                                >
                                {{ moe.moeda_gestao }}
                                </option>
                                <option
                                v-for="moe in moedaDatas"
                                :key="moe.id"
                                :value="moe.outra_moeda"
                                >
                                {{ moe.outra_moeda }}
                                </option>
                    </select>
                </div>
                <input type="hidden" :value="moeda_para" class="moeda_data">
                <label class="cambio">Câmbio</label>
                <input ref="inputField" v-model="cambio_dados" class="input_cambio" type="text" @input="moveCursorToStart">
                <label class="data_operacao_cambio">Data da Operação</label>
                <input v-model="data_operacao_cambio" class="input_operacao_cambio" type="date">
                <button class="salvar_funcionario" @click.prevent="submitForm">SALVAR</button>
            </form>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getUser from '../composables/getUser'
import { useRoute, useRouter } from 'vue-router'
import { projectFirestore } from "../firebase/config"
import { onMounted } from "vue"
import ListaMoeda from '../components/ListaMoeda.vue'
import ConfiguracaoNav from '../components/ConfiguracaoNav.vue'

export default {
    components: { NavbarAppHome, Sidebar, ListaMoeda, ConfiguracaoNav },

    setup() {
        const { addDoc, error } = useCollection('cambio')
        const { error2, documents } = getCollection('cambio')
        const { error2 :error_moeda, documents: moeda } = getCollection("moeda");
        const { user } = getUser()
        const route = useRoute()
        const router = useRouter()
        const user_update_id = ref("");
        const filteredDocuments = ref([]);
        const moedaDatas = ref([]);
        const data = ref([]);
        const user_id = user.value.uid;
        const estado = ref(true);
        const isEditingId = computed(() => route.params.id);
        const docRef = getCollection("cambio");
        const moeda_cambio = ref('')
        const moeda_para = ref('')
        const cambio_dados = ref('')
        const data_operacao_cambio = ref('')
        

        if (docRef.value) {
            filteredDocuments.value = docRef.value.filter(
            isEditingId.value === user.value.uid
            );
            data.value = filteredDocuments.value;
        }
        const isEdit = ref(false);
        isEditingId.value = false;
        isEdit.value = false;
        const fetchCollectionData = async () => {
            try {
                const collectionRef = projectFirestore.collection("cambio");
                const snapshot = await collectionRef.get();
                const dataaaa = snapshot.docs.map((doc) => {
                    return { ...doc.data(), id: doc.id };
            });
            const filtered = dataaaa.filter((dataaaa1) => dataaaa1.id === isEditingId.value);
            filtered.forEach((data1) => {
                moeda_cambio.value = data1.moeda_cambio;
                moeda_para.value = data1.moeda_para;
                cambio_dados.value = data1.cambio_dados;
                data_operacao_cambio.value = data1.data_operacao_cambio;
                user_update_id.value = data1.id;
            });
            } catch (error) {
                console.error("Error fetching collection data:", error);
            }
        };

        onMounted(fetchCollectionData);

        watch(user, () => {
            if  (!user.value) {
                router.push({ name: 'Login'})
            }
        });

        const handleEdit = async (id) => {
            try {
            const docRef = projectFirestore.collection("cambio").doc(id);
            await docRef.get().then(async (doc) => {
                if (doc.exists) {
                let id = await doc.id;
                new_obj.value = await { ...doc.data(), id };
                moeda_cambio.value = new_obj.value.moeda_cambio;
                moeda_para.value = new_obj.value.moeda_para;
                cambio_dados.value = new_obj.value.cambio_dados;
                data_operacao_cambio.value = new_obj.value.data_operacao_cambio;
                }
            });
            isEdit.value = true;
            isEditingId.value = id;
            } catch (error) {
            console.log("Error deleting document:", error.message);
            }
        };

        const submitEdit = async () => {
            alert("submitEditsubmitEdit");
            const docRef = projectFirestore
            .collection("cambio")
            .doc(isEditingId.value);
            await docRef.update({
                moeda_cambio: moeda_cambio.value,
                moeda_para: moeda_para.value,
                cambio_dados: cambio_dados.value,
                data_operacao_cambio: data_operacao_cambio.value,
            });
            isEdit.value = false;
            moeda_cambio.value = null;
            moeda_para.value = null;
            cambio_dados.value = null;
            data_operacao_cambio.value = null;
        };
        const submitForm = async () => {
        try {
          const docRef = projectFirestore
            .collection("cambio")
            .doc(user_update_id.value);
          await docRef.update({
            moeda_cambio: moeda_cambio.value,
                moeda_para: moeda_para.value,
                cambio_dados: cambio_dados.value,
                data_operacao_cambio: data_operacao_cambio.value,
          });
          router.push(`/Cambio`);
  
          alert("updated successfully");
        } catch (err) {
          alert("updated ====");
          console.log("err==>", err);
        }
      };

        const filterDocuments = () => {
            if (documents.value) {
            filteredDocuments.value = documents.value.filter(
                (doc) => {
                return doc.userId === user.value.uid
                }
            );
            }
        };
        filterDocuments();
        
        watch([documents, user], () => {
            filterDocuments();
        });
    //=================================== moeda data =================================== 
        const MoedaFilter = () => {
            if (moeda.value) {
            moedaDatas.value = moeda.value.filter(
                (doc) => {
                return doc.userId === user.value.uid
                }
            );
            }
        };
        MoedaFilter();
    
        watch([moeda, user], () => {
            MoedaFilter();
        });

        return {  estado, moeda_cambio, moeda_para, cambio_dados, data_operacao_cambio,
                  error, error2, documents, moeda, filteredDocuments, moedaDatas,
                  handleEdit, submitEdit, isEdit, isEditingId, data, user_id, submitForm, };
    },
    
};
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 100%;
    left: 180px;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: 0%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.menu_configuracoes{
    width: 100%;
}
.btn_missao_diplomatica{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    vertical-align: middle;
}
.btn_missao_diplomatica:hover {
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
}
.missao_diplomatica{
    position: absolute;
    width: 215px;
    height: 46px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.missao_diplomatica:hover {
  color: white;
}

.btn_orgaos{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 485px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
    vertical-align: middle;
}

.btn_orgaos:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.orgaos{
    position: absolute;
    width: 209px;
    height: 20px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.orgaos:hover {
  color: white;
}

.btn_contas{
    position: absolute;
    width: 214px;
    height: 84px;
    left: 1190px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.btn_contas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.contas{
    position: absolute;
    width: 198px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.contas:hover {
    color: white;
}
.btn_funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 1425px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_funcionarios_empresas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.cambio{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.cambio:hover {
    color: white;
}
.btn_cambio{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 955px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_cambio:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.funcionarios_empresas:hover {
    color: white;
}

.btn_naturezas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1660px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_naturezas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.naturezas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.naturezas:hover {
    color: white;
}
.btn_moedas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 720px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.btn_moedas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.moedas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.moedas:hover {
    color: white;
}
.form_container{
    box-sizing: border-box;
    position: absolute;
    width: 908px;
    height: 100%;
    left: 266px;
    top: 270px;
    background: #FFFFFF;
    border: 2px solid #51B8D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.titulo_formulario{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 1px;
    top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
}

.icon_back{
    position: absolute;
    width: 5px;
    height: 8px;
    left: 800px;
    top: 40px;
}
.moeda_cambio{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -160px;
    top: 77px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.moeda_cambio1{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 99px;
    top: 77px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_moeda_cambio{
    position: absolute;
    width: 200px;
    height: 30px;
    left: 76px;
    top: 99px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.cambio{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -165px;
    top: 156px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #51B8D5;
}
.input_cambio{
    position: absolute;
    width: 301px;
    height: 30px;
    left: 28px;
    top: 183px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.moeda_cambio_para{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 120px;
    top: 77px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_moeda_cambio_para{
    position: absolute;
    width: 200px;
    height: 30px;
    left: 550px;
    top: 230px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.data_operacao_cambio{
    position: absolute;
    width: 215px;
    height: 32px;
    left: 550px;
    top: 75px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_operacao_cambio{
    position: absolute;
    width: 216px;
    height: 30px;
    left: 600px;
    top: 103px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.salvar_funcionario{
    box-sizing: border-box;
    position: absolute;
    width: 132px;
    height: 30px;
    left: 690px;
    top: 180px;
    color: #fff;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.moeda1 {
    position: absolute;
    width: 444px;
    height: 32px;
    left: -50px;
    top: 7px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
  }
  .moeda2 {
    position: absolute;
    top: -126px;
    right: 1177px;
  }

</style>