<template>
  <div>
    <div>
      <NavbarAppHome />
    </div>
    <div>
      <Sidebar />
    </div>
    <div class="cabecalho_configuracoes">
      <div class="titulo_configuracoes">
        <h1 class="conteudo_titulo">DESPESAS</h1>
      </div>
      <div class="menu_configuracoes">
        <router-link
          to="/Receitas"
          style="text-decoration: none; color: inherit"
        >
          <div class="btn_missao_diplomatica">RECEITAS</div>
        </router-link>
        <router-link
          to="/Despesas"
          style="text-decoration: none; color: inherit"
        >
          <div class="btn_orgaos">DESPESAS</div>
        </router-link>
        <router-link
          to="/Transferencias"
          style="text-decoration: none; color: inherit"
        >
          <div class="btn_contas">TRANSFERÊNCIAS</div>
        </router-link>
        <router-link to="/Cambio" style="text-decoration: none; color: inherit">
          <div class="btn_funcionarios_empresas">CÂMBIO</div>
        </router-link>
      </div>
    </div>
    <div class="form_container">
      <h1 class="titulo_formulario">EDIT DESPESAS</h1>
      <router-link to="/Despesas" style="text-decoration: none; color: inherit">
        <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
      </router-link>
      <form @submit.prevent="isEdit ? submitEdit() : handleSubmit">
        <label class="orgao_despesas">Orgão</label>
        <select v-model="orgao_despesas" class="input_orgao_despesas">
          <option disabled value="">--Selecione Orgão--</option>
          <option value="Pendente">Teste 1</option>
          <option value="Confirmado">Teste 2</option>
        </select>
        <label class="movimentos_despesas">Movimento</label>
        <select
          
          class="input_movimento_despesas"
        >
          <option disabled value="">--Selecione o Movimento--</option>
          <option value="Pendente">Pendente</option>
          <option value="Confirmado">Confirmado</option>
        </select>
        <label class="contas_despesas">Conta</label>
        <select required class="input_contas_despesas">
          <option disabled value="">--Selecione a Natureza--</option>
          <option value="Pendente">Conta 1</option>
          <option value="Confirmado">Conta 2</option>
        </select>
        <label class="n_doc_receitas">Nº Fatura</label>
        <input v-model="n_fatura" class="input_n_doc_receitas" type="text" />
        <label class="funcionario_despesas">Funcionario/Empresa</label>
        <select
          v-model="funcionario_empresa_despesa"
          class="input_funcionario_despesas"
        >
          <option value="">--Escolha o Funcionario/Empresa--</option>
          <option
            v-for="f in documents"
            :key="f.id"
            :value="f.nome_funcionario"
          >
            {{ f.nome_funcionario }}
          </option>
        </select>
        <label class="afecto_a_despesas">Afecto a</label>
        <input
          required
          v-model="afecto_a_despesas"
          class="input_afecto_a_despesas"
          type="text"
        />
        <label class="observacoes_contrato">Descrição</label>
        <textarea
          v-model="descricao_despesas"
          class="input_observacoes_contrato"
          rows="4"
          cols="50"
        ></textarea>
        <label class="natureza_receita">Natureza da Despesa</label>
        <select
          required
          v-model="natureza_despesa"
          class="input_natureza_receita"
        >
          <option disabled value="">--Selecione a Natureza--</option>
          <option disabled value="pessoal">Pessoal</option>
          <option value="Vencimento do pessoal civil do quadro">
            Vencimento do pessoal civil do quadro
          </option>
          <option value="Vencimentos de outro pessoal civil">
            Vencimentos de outro pessoal civil
          </option>
          <option value="Subsídios Do Pessoal Civil">
            Subsídios Do Pessoal Civil
          </option>
          <option value="Décimo terceiro mês do pessoal civil">
            Décimo terceiro mês do pessoal civil
          </option>
          <option value="Outras remunerações variáveis do pessoal civil">
            Outras remunerações variáveis do pessoal civil
          </option>
          <option disabled value="Contribuição do empregador">
            Contribuição do empregador
          </option>
          <option value="Contribuições do empregador p/ a segurança social">
            Contribuições do empregador p/ a segurança social
          </option>
          <option value="Transferências correntes para segurança social">
            Transferências correntes para segurança social
          </option>
          <option disabled value="Bens e Serviços">Bens e Serviços</option>
          <option value="Combustíveis E Lubrificantes">
            Combustíveis E Lubrificantes
          </option>
          <option value="Viveres E Géneros Alimentícios">
            Viveres E Géneros Alimentícios
          </option>
          <option value="Outros materiais De Consumo Corrente<">
            Outros materiais De Consumo Corrente
          </option>
          <option value="Outros Materiais E Utensílios Duradouros">
            Outros Materiais E Utensílios Duradouros
          </option>
          <option value="Serviços De Telecomunicação">
            Serviços De Telecomunicação
          </option>
          <option value="Serviços De Saúde">Serviços De Saúde</option>
          <option value="Serviços De Ensino E Formação">
            Serviços De Ensino E Formação
          </option>
          <option value="Serviços De Água e Electricidade">
            Serviços De Água e Electricidade
          </option>
          <option value="Serviços de Hospedagem e Alimentação">
            Serviços de Hospedagem e Alimentação
          </option>
          <option value="Serviços de Estudo, Fiscalização e Consultoria">
            Serviços de Estudo, Fiscalização e Consultoria
          </option>
          <option value="Serviço de Protecção e Vigilância">
            Serviço de Protecção e Vigilância
          </option>
          <option value="Serviços Bancários">Serviços Bancários</option>
          <option value="Serviços de Processamento de Dados">
            Serviços de Processamento de Dados
          </option>
          <option value="Serviços de Limpeza e Saneamento">
            Serviços de Limpeza e Saneamento
          </option>
          <option value="Serviços de Manutenção e Conservação">
            Serviços de Manutenção e Conservação
          </option>
          <option value="Bilhete de Passagem">Bilhete de Passagem</option>
          <option value="Subsidio de Deslocação">Subsidio de Deslocação</option>
          <option value="Serviços de Transportação de Pessoas e Bens">
            Serviços de Transportação de Pessoas e Bens
          </option>
          <option value="Encargos Aduanerios e Portuarios">
            Encargos Aduanerios e Portuarios
          </option>
          <option value="Renda de Imoveis">Renda de Imoveis</option>
          <option value="Outros Serviços">Outros Serviços</option>
        </select>
        <label class="fonte_recurso_despesa">Fonte de Recurso</label>
        <select
          required
          v-model="fonte_recurso_despesa"
          class="input_fonte_recurso_despesa"
        >
          <option disabled value="">--Escolha uma opção--</option>
          <option value="Recursos ordinarios do tesouro">
            Recursos ordinarios do tesouro
          </option>
          <option value="Recursos próprios">Recursos próprios</option>
        </select>
        <label class="data_registo_despesa">Data de Registo</label>
        <input
          required
          v-model="data_registo_despesa"
          class="input_data_registo_despesa"
          type="date"
        />
        <label class="valor_mensal_despesa">Valor</label>
        <input
          required
          v-model="valor_mensal_despesa"
          class="input_valor_mensal_despesa"
          type="text"
        />
        <label class="moeda_despesa">Moeda</label>
        <select required v-model="moeda_despesa" class="input_moeda_despesa">
          <option disabled value="">--Escolha uma moeda--</option>
          <option value="USD">USD</option>
          <option value="Euro">Euro</option>
          <option value="KZ">KZ</option>
          <option value="Yen">Yen</option>
          <option value="Libra">Libra</option>
        </select>
        <label class="cambio_despesa">Câmbio</label>
        <input
          required
          v-model="cambio_despesa"
          class="input_cambio_despesa"
          type="text"
        />
        <label class="moeda_usd_despesa">Moeda USD</label>
        <input
          v-model="moeda_usd_despesa"
          class="input_moeda_usd_despesa"
          type="text"
        />
        <label class="termo_entrega_despesa">Termos de Entrega</label>
        <input
          required
          v-model="termo_entrega_despesa"
          type="checkbox"
          class="input_termo_entrega_despesa"
        />
        <button class="salvar_funcionario" @click.prevent="submitForm">
          SALVAR
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import NavbarAppHome from "../components/NavbarAppHome.vue";
import Sidebar from "../components/Sidebar.vue";
import useCollection from "../composables/useCollection";
import getCollection from "../composables/getCollection";
import getUser from "../composables/getUser";
import { useRouter } from "vue-router";
import { projectFirestore } from "../firebase/config";
import { onMounted } from "vue";

export default {
  components: { NavbarAppHome, Sidebar },

  setup() {
    const { addDoc, error } = useCollection("despesa");
    const { error2, documents } = getCollection("funcionario");
    const { user } = getUser();
    const router = useRouter();
    const orgao_despesas = ref("")
    const n_fatura = ref("");
    const funcionario_empresa_despesa = ref("");
    const afecto_a_despesas = ref("");
    const descricao_despesas = ref("");
    const natureza_despesa = ref("");
    const fonte_recurso_despesa = ref("");
    const data_registo_despesa = ref("");
    const valor_mensal_despesa = ref("");
    const moeda_despesa = ref("");
    const cambio_despesa = ref("");
    const moeda_usd_despesa = ref("");
    const termo_entrega_despesa = ref("");
    const user_update_id = ref("");
    const user_id = user.value.uid;
    const isEditingId = computed(() => route.value.params.id);

    const docRef = getCollection("despesa");
    if (docRef.value) {
      filteredDocuments.value = docRef.value.filter(
        isEditingId.value === user.value.uid
      );
      data.value = filteredDocuments.value;
    }

    const isEdit = ref(false);
    isEditingId.value = false;
    isEdit.value = false;

    const fetchCollectionData = async () => {
      try {
        const collectionRef = projectFirestore.collection("despesa");
        const snapshot = await collectionRef.get();
        const data = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        const filtered = data.filter((data) => data.userId === user_id);
        filtered.forEach((data) => {
          n_fatura.value = data.n_fatura;
          orgao_despesas = data.orgao_despesas;
          funcionario_empresa_despesa.value = data.funcionario_empresa_despesa;
          afecto_a_despesas.value = data.afecto_a_despesas;
          descricao_despesas.value = data.descricao_despesas;
          natureza_despesa.value = data.natureza_despesa;
          fonte_recurso_despesa.value = data.fonte_recurso_despesa;
          data_registo_despesa.value = data.data_registo_despesa;
          valor_mensal_despesa.value = data.valor_mensal_despesa;
          moeda_despesa.value = data.moeda_despesa;
          cambio_despesa.value = data.cambio_despesa;
          moeda_usd_despesa.value = data.moeda_usd_despesa;
          termo_entrega_despesa.value = data.termo_entrega_despesa;
          user_update_id.value = data.id;
        });
      } catch (error) {
        console.error("Error fetching collection data:", error);
      }
    };

    onMounted(fetchCollectionData);

    watch(user, () => {
      if (!user.value) {
        router.push({ name: "Login" });
      }
    });

    const handleEdit = async (id) => {
      try {
        const docRef = projectFirestore.collection("despesa").doc(id);
        await docRef.get().then(async (doc) => {
          if (doc.exists) {
            let id = await doc.id;
            new_obj.value = await { ...doc.data(), id };
            n_fatura.value = new_obj.value.n_fatura;
            funcionario_empresa_despesa.value =
            orgao_despesas = new_obj.value.orgao_despesas;
            new_obj.value.funcionario_empresa_despesa;
            afecto_a_despesas.value = new_obj.value.afecto_a_despesas;
            descricao_despesas.value = new_obj.value.descricao_despesas;
            natureza_despesa.value = new_obj.value.natureza_despesa;
            fonte_recurso_despesa.value = new_obj.value.fonte_recurso_despesa;
            data_registo_despesa.value = new_obj.value.data_registo_despesa;
            valor_mensal_despesa.value = new_obj.value.valor_mensal_despesa;
            moeda_despesa.value = new_obj.value.moeda_despesa;
            cambio_despesa.value = new_obj.value.cambio_despesa;
            moeda_usd_despesa.value = new_obj.value.moeda_usd_despesa;
            termo_entrega_despesa.value = new_obj.value.termo_entrega_despesa;
          }
        });
        isEdit.value = true;
        isEditingId.value = id;
      } catch (error) {
        console.log("Error deleting document:", error.message);
      }
    };

    const submitEdit = async () => {
      alert("submitEditsubmitEdit");
      const docRef = projectFirestore
        .collection("despesa")
        .doc(isEditingId.value);
      await docRef.update({
        n_fatura: n_fatura.value,
        orgao_despesas: orgao_despesas.value,
        funcionario_empresa_despesa: funcionario_empresa_despesa.value,
        afecto_a_despesas: afecto_a_despesas.value,
        descricao_despesas: descricao_despesas.value,
        natureza_despesa: natureza_despesa.value,
        fonte_recurso_despesa: fonte_recurso_despesa.value,
        data_registo_despesa: data_registo_despesa.value,
        valor_mensal_despesa: valor_mensal_despesa.value,
        moeda_despesa: moeda_despesa.value,
        cambio_despesa: cambio_despesa.value,
        moeda_usd_despesa: moeda_usd_despesa.value,
        termo_entrega_despesa: termo_entrega_despesa.value,
        userId: user.value.uid,
      });
      isEdit.value = false;
      n_fatura.value = null;
      orgao_despesas = null;
      funcionario_empresa_despesa.value = null;
      afecto_a_despesas.value = null;
      descricao_despesas.value = null;
      natureza_despesa.value = null;
      fonte_recurso_despesa.value = null;
      data_registo_despesa.value = null;
      valor_mensal_despesa.value = null;
      moeda_despesa.value = null;
      cambio_despesa.value = null;
      moeda_usd_despesa.value = null;
      termo_entrega_despesa.value = null;
    };

    const submitForm = async () => {
      try {
        const docRef = projectFirestore
          .collection("despesa")
          .doc(user_update_id.value);
        await docRef.update({
          n_fatura: n_fatura.value,
          orgao_despesas: orgao_despesas.value,
          funcionario_empresa_despesa: funcionario_empresa_despesa.value,
          afecto_a_despesas: afecto_a_despesas.value,
          descricao_despesas: descricao_despesas.value,
          natureza_despesa: natureza_despesa.value,
          fonte_recurso_despesa: fonte_recurso_despesa.value,
          data_registo_despesa: data_registo_despesa.value,
          valor_mensal_despesa: valor_mensal_despesa.value,
          moeda_despesa: moeda_despesa.value,
          cambio_despesa: cambio_despesa.value,
          moeda_usd_despesa: moeda_usd_despesa.value,
          termo_entrega_despesa: termo_entrega_despesa.value,
        });

        router.push(`/Despesas`);

        alert("updated successfully");
      } catch (err) {
        alert("updated ====");
        console.log("err==>", err);
      }
    };

    return {
      n_fatura,
      orgao_despesas,
      funcionario_empresa_despesa,
      natureza_despesa,
      afecto_a_despesas,
      fonte_recurso_despesa,
      data_registo_despesa,
      valor_mensal_despesa,
      descricao_despesas,
      termo_entrega_despesa,
      moeda_usd_despesa,
      moeda_despesa,
      error,
      error2,
      cambio_despesa,
      documents,
      submitEdit,
      isEdit,
      isEditingId,
      user_id,
      submitForm,
    };
  },
};
</script>

<style scoped>
.cabecalho_configuracoes {
  width: 100%;
  left: 180px;
}
.titulo_configuracoes {
  position: absolute;
  color: white;
  width: 100%;
  height: 60px;
  left: 180px;
  top: 90px;
  background: #00344c;
}
.conteudo_titulo {
  position: absolute;
  width: 1271px;
  height: 20px;
  left: 0%;
  top: -10px;
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
}
.menu_configuracoes {
  width: 100%;
}

.btn_missao_diplomatica {
  position: absolute;
  width: 216px;
  height: 84px;
  left: 250px;
  top: 160px;
  background: #ffffff;
  border: 3px solid #51b8d5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #51b8d5;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  vertical-align: middle;
}
.btn_missao_diplomatica:hover {
  width: 216px;
  height: 84px;
  left: 250px;
  top: 160px;
  background: #00344c;
  border: 8px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: white;
}
.missao_diplomatica {
  position: absolute;
  width: 215px;
  height: 46px;
  left: 0px;
  top: 25%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #51b8d5;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.missao_diplomatica:hover {
  color: white;
}

.btn_orgaos {
  position: absolute;
  width: 216px;
  height: 84px;
  left: 485px;
  top: 160px;
  background: #ffffff;
  border: 3px solid #51b8d5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
  vertical-align: middle;
}

.btn_orgaos:hover {
  background: #00344c;
  color: white;
  background: #00344c;
  border: 8px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.orgaos {
  position: absolute;
  width: 209px;
  height: 20px;
  left: 0px;
  top: 40%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.orgaos:hover {
  color: white;
}

.btn_contas {
  position: absolute;
  width: 214px;
  height: 84px;
  left: 717px;
  top: 160px;
  background: #ffffff;
  border: 3px solid #51b8d5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.btn_contas:hover {
  background: #00344c;
  color: white;
  background: #00344c;
  border: 8px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.contas {
  position: absolute;
  width: 198px;
  height: 22px;
  left: 0px;
  top: 40%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.contas:hover {
  color: white;
}

.btn_funcionarios_empresas {
  position: absolute;
  width: 217px;
  height: 84px;
  left: 945px;
  top: 160px;
  background: #ffffff;
  border: 3px solid #51b8d5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.btn_funcionarios_empresas:hover {
  background: #00344c;
  color: white;
  background: #00344c;
  border: 8px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.funcionarios_empresas {
  position: absolute;
  width: 217px;
  height: 44px;
  left: 0px;
  top: 25%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.funcionarios_empresas:hover {
  color: white;
}

.btn_naturezas {
  position: absolute;
  width: 216px;
  height: 84px;
  left: 1176px;
  top: 160px;
  background: #ffffff;
  border: 3px solid #51b8d5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.btn_naturezas:hover {
  background: #00344c;
  color: white;
  background: #00344c;
  border: 8px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.naturezas {
  position: absolute;
  width: 216px;
  height: 22px;
  left: 0px;
  top: 40%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.naturezas:hover {
  color: white;
}

.form_container {
  box-sizing: border-box;
  position: absolute;
  width: 908px;
  height: 100%;
  left: 266px;
  top: 270px;
  background: #ffffff;
  border: 2px solid #51b8d5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.titulo_formulario {
  position: absolute;
  width: 907px;
  height: 20px;
  left: 1px;
  top: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: #4e4e4e;
}

.icon_back {
  position: absolute;
  width: 5px;
  height: 8px;
  left: 800px;
  top: 40px;
}

.n_doc_receitas {
  position: absolute;
  width: 444px;
  height: 32px;
  left: -152px;
  top: 150px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}

.funcionario_despesas {
  position: absolute;
  width: 444px;
  height: 32px;
  left: 55px;
  top: 150px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.orgao_despesas {
  position: absolute;
  width: 444px;
  height: 32px;
  left: -160px;
  top: 60px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_orgao_despesas {
  box-sizing: border-box;
  position: absolute;
  width: 144px;
  height: 30px;
  left: 40px;
  top: 85px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.movimentos_despesas {
  position: absolute;
  width: 444px;
  height: 32px;
  left: 25px;
  top: 60px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_movimento_despesas {
  box-sizing: border-box;
  position: absolute;
  width: 320px;
  height: 30px;
  left: 210px;
  top: 85px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.contas_despesas {
  position: absolute;
  width: 444px;
  height: 32px;
  left: 354px;
  top: 60px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_contas_despesas {
  box-sizing: border-box;
  position: absolute;
  width: 223px;
  height: 30px;
  left: 550px;
  top: 85px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.input_n_doc_receitas {
  box-sizing: border-box;
  position: absolute;
  width: 144px;
  height: 30px;
  left: 40px;
  top: 175px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.input_funcionario_despesas {
  box-sizing: border-box;
  position: absolute;
  width: 320px;
  height: 30px;
  left: 210px;
  top: 175px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.natureza_receita {
  position: absolute;
  width: 444px;
  height: 32px;
  left: -115px;
  top: 325px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_natureza_receita {
  box-sizing: border-box;
  position: absolute;
  width: 220px;
  height: 30px;
  left: 40px;
  top: 350px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.fonte_recurso_despesa {
  position: absolute;
  width: 444px;
  height: 32px;
  left: 130px;
  top: 325px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_fonte_recurso_despesa {
  box-sizing: border-box;
  position: absolute;
  width: 220px;
  height: 30px;
  left: 295px;
  top: 350px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.data_registo_despesa {
  position: absolute;
  width: 444px;
  height: 32px;
  left: 378px;
  top: 325px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_data_registo_despesa {
  box-sizing: border-box;
  position: absolute;
  width: 225px;
  height: 30px;
  left: 550px;
  top: 350px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.afecto_a_despesas {
  position: absolute;
  width: 444px;
  height: 32px;
  left: 354px;
  top: 150px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_afecto_a_despesas {
  box-sizing: border-box;
  position: absolute;
  width: 223px;
  height: 30px;
  left: 550px;
  top: 175px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.valor_mensal_despesa {
  position: absolute;
  width: 444px;
  height: 32px;
  left: -165px;
  top: 405px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_valor_mensal_despesa {
  box-sizing: border-box;
  position: absolute;
  width: 220px;
  height: 30px;
  left: 40px;
  top: 430px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.moeda_despesa {
  position: absolute;
  width: 444px;
  height: 32px;
  left: 95px;
  top: 405px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_moeda_despesa {
  box-sizing: border-box;
  position: absolute;
  width: 482px;
  height: 30px;
  left: 295px;
  top: 430px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.cambio_despesa {
  position: absolute;
  width: 444px;
  height: 32px;
  left: -155px;
  top: 490px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_cambio_despesa {
  box-sizing: border-box;
  position: absolute;
  width: 220px;
  height: 30px;
  left: 40px;
  top: 515px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.moeda_usd_despesa {
  position: absolute;
  width: 444px;
  height: 32px;
  left: 115px;
  top: 490px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_moeda_usd_despesa {
  box-sizing: border-box;
  position: absolute;
  width: 200px;
  height: 30px;
  left: 295px;
  top: 515px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.caucao_contrato {
  position: absolute;
  width: 444px;
  height: 32px;
  left: 355px;
  top: 420px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_caucao_contrato {
  box-sizing: border-box;
  position: absolute;
  width: 220px;
  height: 30px;
  left: 550px;
  top: 445px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.data_inicio_contrato {
  position: absolute;
  width: 444px;
  height: 32px;
  left: -145px;
  top: 490px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_data_inicio_contrato {
  box-sizing: border-box;
  position: absolute;
  width: 250px;
  height: 30px;
  left: 40px;
  top: 515px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.data_termino_contrato {
  position: absolute;
  width: 444px;
  height: 32px;
  left: 375px;
  top: 490px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_data_termino_contrato {
  box-sizing: border-box;
  position: absolute;
  width: 223px;
  height: 30px;
  left: 550px;
  top: 515px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.observacoes_contrato {
  position: absolute;
  width: 330px;
  height: 32px;
  left: -90px;
  top: 220px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_observacoes_contrato {
  box-sizing: border-box;
  position: absolute;
  width: 735px;
  height: 60px;
  left: 40px;
  top: 245px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.salvar_funcionario {
  box-sizing: border-box;
  position: absolute;
  width: 132px;
  height: 30px;
  left: 641px;
  top: 650px;
  color: #fff;
  background: #51b8d5;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.termo_entrega_despesa {
  position: absolute;
  width: 159px;
  height: 32px;
  left: 50px;
  top: 599px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #00344c;
}
.input_termo_entrega_despesa {
  position: absolute;
  width: 15px;
  height: 15px;
  left: 40px;
  top: 600px;
  background: #51b8d5;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
}
</style>
