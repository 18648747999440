<template>
    <div v-if="loader" class="loader"></div>
    <div>
      <div>
        <NavbarAppHome />
      </div>
      <div>
        <Sidebar />
      </div>
      <div class="padding">
        <h1 class="margin_left" v-for="data in userdetails" :key="data.userId">
          {{ data.userId }}Profile
        </h1>
        <form class="container margin_left">
          <div class="row justify-content-center profile_update">
            <div class="col-10">
              <label for="primeiroNome">primeiroNome :</label>
            </div>
            <div class="col-90">
              <input
                type="text"
                id="primeiroNome"
                v-model="primeiroNome"
                name="primeiroNome"
                placeholder="Enter your full name"
              />
            </div>
          </div>
          <div class="row justify-content-center profile_update">
            <div class="col-10">
              <label for="ultimoNome">ultimoNome :</label>
            </div>
            <div class="col-90">
              <input
                type="text"
                id="ultimoNome"
                v-model="ultimoNome"
                name="ultimoNome"
                placeholder="Enter your full name"
              />
            </div>
          </div>
          <div
            class="row justify-content-center align-items-center profile_update"
          >
            <div class="col-10">
              <label for="Telefone">Telefone:</label>
            </div>
            <div class="col-90">
              <input
                type="text"
                id="Telefone"
                v-model="telefone"
                name="telefone"
                placeholder="only 10 digits are allowed"
              />
            </div>
          </div>
          <div class="row justify-content-center profile_update">
            <div class="col-10">
              <label for="Endereco">Endereco:</label>
            </div>
            <div class="col-90">
              <textarea
                name="Endereco"
                id="Endereco"
                cols="30"
                v-model="endereco"
                rows="10"
              ></textarea>
            </div>
          </div>
          <div class="row justify-content-center profile_update">
            <div class="col-10">
              <label for="photo" class="image">Image :</label>
            </div>
            <div class="col-90 text-align-initial">
              <input
                type="file"
                id="photo"
                @change="handleFileChange"
                accept="image/*"
              />
            </div>
          </div>
          <div class="row justify-content-center submit_button">
            <input type="submit" value="Submit" @click.prevent="submitForm" />
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import NavbarAppHome from "../components/NavbarAppHome.vue";
  import Sidebar from "../components/Sidebar.vue";
  import { useRouter } from "vue-router";
  import { onMounted, ref, watch } from "vue";
  import { getFirestore, collection, addDoc } from "firebase/firestore";
  import { projectFirestore, firebase, storage } from "../firebase/config";
  
  import getUser from "../composables/getUser";
  
  export default {
    components: { NavbarAppHome, Sidebar },
    name: "UpdateProfile",
    setup() {
      const router = useRouter();
      const { user } = getUser();
      const data = ref([]);
      const filteredDocuments = ref([]);
      const email = ref("");
      const telefone = ref("");
      const user_id = user.value.uid;
      const imageUrl = ref(null);
      const primeiroNome = ref(null);
      const ultimoNome = ref(null);
      const endereco = ref(null);
      const isEditingId = ref(null);
      const imageData = ref("");
      const url = window.location.href;
      const Param = url.split("/").slice(-1)[0];
      const documentId = Param;
      const uploadValue = ref(0);
      const user_update_id = ref("");
      const loader = ref(false);
  
      const fetchCollectionData = async () => {
        try {
          const collectionRef = projectFirestore.collection("userdata");
          const snapshot = await collectionRef.get();
          const data = snapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          });
          const filtered = data.filter((data) => data.userId === user_id);
          filtered.forEach((data) => {
            console.log(data, "itemitem");
            primeiroNome.value = data.primeiroNome;
            ultimoNome.value = data.ultimoNome;
            telefone.value = data.telefone;
            email.value = data.email;
            endereco.value = data.endereco;
            user_update_id.value = data.id;
            imageData.value = data.imageUrl;
          });
        } catch (error) {
          console.error("Error fetching collection data:", error);
        }
      };
  
      watch(loader);
  
      onMounted(fetchCollectionData);
  
      const handleFileChange = (event) => {
        loader.value = true;
        const file = event.target.files[0];
        const storageRef = storage.ref();
        const fileRef = storageRef.child(file.name);
        fileRef
          .put(file)
          .then((snapshot) => {
            console.log(snapshot);
            fileRef
              .getDownloadURL()
              .then((url) => {
                console.log("File download URL:", url);
                imageData.value = url;
                loader.value = false;
              })
              .catch((error) => {
                loader.value = false;
                console.error("Error getting download URL:", error);
              });
          })
          .catch((error) => {
            loader.value = false;
            console.error("Error uploading file:", error);
          });
      };
  
      const submitForm = async () => {
        loader.value = true;
        try {
          const docRef = projectFirestore
            .collection("userdata")
            .doc(user_update_id.value);
          await docRef.update({
            primeiroNome: primeiroNome.value,
            ultimoNome: ultimoNome.value,
            telefone: telefone.value,
            email: email.value,
            endereco: endereco.value,
            imageUrl: imageData.value,
          });
  
          router.push(`/perfil`);
          
          alert("updated successfully");
          loader.value = false;
        } catch (err) {
          alert("updated ====");
          loader.value = false;
          console.log("err==>", err);
        }
      };
  
      return {
        email,
        telefone,
        filteredDocuments,
        data,
        user_id,
        primeiroNome,
        ultimoNome,
        imageUrl,
        endereco,
        isEditingId,
        handleFileChange,
        imageData,
        uploadValue,
        submitForm,
        loader,
      };
    },
  };
  </script>
  
  <style scoped>
  .profile_form {
    width: 85%;
    text-align: center;
  }
  
  .update_profile {
    width: 30%;
    text-align: center;
    padding: 25px;
    border-radius: 13px;
    background: aquamarine;
    margin-top: 60px;
  }
  
  * {
    box-sizing: border-box;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="select"],
  input[type="date"],
  input[type="select"],
  input[type="password"],
  input[type="tel"] {
    width: 100%;
    padding: 12px;
    border: 1px solid rgb(168, 166, 166);
    border-radius: 4px;
    resize: vertical;
  }
  
  textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid rgb(168, 166, 166);
    border-radius: 4px;
    resize: vertical;
  }
  
  input[type="radio"],
  input[type="checkbox"] {
    width: 1%;
    padding-left: 0%;
    border: 1px solid rgb(168, 166, 166);
    border-radius: 4px;
    resize: vertical;
  }
  
  h1 {
    font-family: Arial;
    font-size: medium;
    font-style: normal;
    font-weight: bold;
    color: brown;
    text-align: center;
    text-decoration: underline;
  }
  
  label {
    padding: 12px 12px 12px 0;
    display: inline-block;
  }
  
  input[type="submit"] {
    background-color: #4caf50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: left;
  }
  
  input[type="submit"]:hover {
    background-color: #32a336;
  }
  
  .container {
    border-radius: 5px;
    /* background-color: #f2f2f2; */
    padding: 20px;
    /* margin-left: 350px; */
  }
  
  .col-10 {
    float: left;
    width: 15%;
    margin-top: 6px;
  }
  
  .col-90 {
    float: left;
    width: 85%;
    margin-top: 6px;
  }
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  @media screen and (max-width: 600px) {
    .col-10,
    .col-90,
    input[type="submit"] {
      width: 100%;
      margin-top: 0;
    }
  }
  .submit_button {
    width: 0%;
    margin: auto;
  }
  
  .padding {
    padding-top: 50px;
    /* width: 90%; */
    /* margin-left: 182px; */
  }
  
  .image {
    padding: 0px 12px 12px 0px;
  }
  
  .profile_update {
    width: 50%;
    margin: auto;
  }
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loader::after {
    border: 16px solid #ac1a1a;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    content: "";
    border-color: #fff transparent #fff transparent;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>
  