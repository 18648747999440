<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <ConfiguracaoNav/>
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">CONFIGURAÇÕES - MISSÃO DIPLOMATICA</h1>
            </div>
        </div>
        <div class="missao_container">            
            <div class="formulario_missao">
                <h1>INSERIR DADOS DA MISSÃO DIPLOMÁTICA</h1>
                <form @submit.prevent="isEdit ? submitEdit() : handleSubmit()">
                    <label class="nome_missao">Nome</label>
                    <input required v-model="nome_missao" type="text" class="input_misao">
                    <label class="cidade_missao">Cidade</label>
                    <input required v-model="cidade_missao" class="input_cidade_missao" type="text">
                    <label class="endereco_missao">Endereço</label>
                    <input required v-model="endereco_missao" class="input_endereco_missao" type="text">
                    <label class="inicio_gestao">Inicio de Gestão:</label>
                    <input required v-model="inicio_gestao" class="input_inicio_gestao" type="date">
                    <label class="fim_gestao">Fim de Gestão:</label>
                    <input required v-model="fim_gestao" class="input_fim_gestao" type="date">
                    <button class="salvar_missao" @click="showElement">SALVAR</button>
                </form>
            </div>
            <div class="missao-list">
                <h2 class="dados_misso">DADOS DA MISSÃO DIPLOMÁTICA</h2>
                <div class="table_missao">
                    <table>
                    <thead>
                        <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">Cidade</th>
                        <th scope="col">Endereço</th>
                        <th scope="col">Início de Gestão</th>
                        <th scope="col">Fim de Gestão</th>
                        </tr>
                    </thead>
                    <tbody v-if="data && data.length > 0">
                        <tr v-for="m in data" :key="m.id">
                            <td>{{ m.nome_missao }}</td>
                            <td>{{ m.cidade_missao }}</td>
                            <td>{{ m.endereco_missao }}</td>
                            <td>{{ m.inicio_gestao }}</td>
                            <td>{{ m.fim_gestao }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="error">{{ error2 }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import Gravado from '../components/Gravado.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getUser from '../composables/getUser'
import ConfiguracaoNav from '../components/ConfiguracaoNav.vue'
import { useRouter } from 'vue-router'
import { projectFirestore } from "../firebase/config";
export default {
    components: { NavbarAppHome, Sidebar, Gravado, ConfiguracaoNav },
    setup() {
        const { user } = getUser()
        const { addDoc, error } = useCollection('missao_diplomatica')
        const { error2, documents} = getCollection('missao_diplomatica')
        const data = ref([]);
        const router = useRouter()
        const filteredDocuments = ref([]);
        const nome_missao = ref('')
        const cidade_missao = ref('')
        const endereco_missao = ref('')
        const inicio_gestao = ref('')
        const fim_gestao = ref('')
        const today_date = ref();
        var date = new Date();
        var dd = String(date.getDate()).padStart(2, "0");
        var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = date.getFullYear();
        var time = date.toLocaleTimeString();
        today_date.value = `${dd}/${mm}/${yyyy} ${time}`;
        let new_obj = ref();
        const isEdit = ref(false);
        const isEditingId = ref(null);

        const filterDocuments = () => {
            if (documents.value) {
                filteredDocuments.value = documents.value.filter(
                    (doc) => doc.userId === user.value.uid
                );
                data.value = filteredDocuments.value;
            }
        };

        filterDocuments();
        watch([documents, user], () => {
            filterDocuments();
        });
        const handleDelete = async (id) => {
            try {
                const docRef = projectFirestore
                .collection("missao_diplomatica")
                .doc(id);
                await docRef.delete();
                console.log("Document successfully deleted.");
            } catch (error) {
                console.log("Error deleting document:", error.message);
            }
        };
        const handleSubmit = async () => {
            const missao = {
                nome_missao: nome_missao.value,
                cidade_missao: cidade_missao.value,
                endereco_missao: endereco_missao.value,
                inicio_gestao: inicio_gestao.value,
                fim_gestao: fim_gestao.value,
                userId: user.value.uid,
                today_date: today_date.value,
            }
            await addDoc(missao)
            if (!error.value) {
                nome_missao.value = ''
                cidade_missao.value = ''
                endereco_missao.value = ''
                inicio_gestao.value = ''
                fim_gestao.value = ''
            }
            alert('DADOS INSERIDOS COM SUCESSO!')
        };
        const handleEdit = async (id) => {
      try {
        var docRef = projectFirestore.collection("missao_diplomatica").doc(id);
        await docRef.get().then(async (doc) => {
          if (doc.exists) {
            let id = await doc.id;
            new_obj.value = await { ...doc.data(), id };
            nome_missao.value = new_obj.value.nome_missao;
            cidade_missao.value = new_obj.value.cidade_missao;
            endereco_missao.value = new_obj.value.endereco_missao;
            inicio_gestao.value = new_obj.value.inicio_gestao;
            fim_gestao.value = new_obj.value.fim_gestao;
          }
        });
        isEdit.value = true;
        isEditingId.value = id;
      } catch (error) {
        console.log("Error document:", error.message);
      }
    };

    const submitEdit = async () => {
      alert("submitEditsubmitEdit");
      const docRef = projectFirestore
        .collection("missao_diplomatica")
        .doc(isEditingId.value);
      await docRef.update({
        nome_missao: nome_missao.value,
        cidade_missao: cidade_missao.value,
        endereco_missao: endereco_missao.value,
        inicio_gestao: inicio_gestao.value,
        fim_gestao: fim_gestao.value,
        userId: user.value.uid,
        today_date: today_date.value,
      });
      isEdit.value = false;
      nome_missao.value = null;
      cidade_missao.value = null;
      endereco_missao.value = null;
      inicio_gestao.value = null;
      fim_gestao.value = null;
      today_date.value = null;
    };
        return { nome_missao, cidade_missao, endereco_missao, inicio_gestao, fim_gestao, error, handleSubmit, handleSubmit, handleDelete,
                error2, filteredDocuments, data, handleEdit, submitEdit, isEdit, isEditingId };
    },
    
};
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 100%;
    left: 180px;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: 0%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.menu_configuracoes{
    width: 100%;
}
.btn_missao_diplomatica{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    vertical-align: middle;
}
.btn_missao_diplomatica:hover {
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
}
.missao_diplomatica{
    position: absolute;
    width: 215px;
    height: 46px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.missao_diplomatica:hover {
  color: white;
}

.btn_orgaos{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 485px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
    vertical-align: middle;
}

.btn_orgaos:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.orgaos{
    position: absolute;
    width: 209px;
    height: 20px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.orgaos:hover {
  color: white;
}

.btn_contas{
    position: absolute;
    width: 214px;
    height: 84px;
    left: 1190px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.btn_contas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.contas{
    position: absolute;
    width: 198px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.contas:hover {
    color: white;
}
.btn_funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 1425px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_funcionarios_empresas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.cambio{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.cambio:hover {
    color: white;
}
.cambio{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 955px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.cambio:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.funcionarios_empresas:hover {
    color: white;
}

.btn_naturezas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1660px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_naturezas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.naturezas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.naturezas:hover {
    color: white;
}
.btn_moedas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 720px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.btn_moedas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.moedas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.moedas:hover {
    color: white;
}

.missao_container{
    display: inline-block;
    margin: 170px 0 0 0;
}
form{
    max-width: 480px;
    text-align: left;
}
input, textarea {
    display: block;
    margin: 10px 0;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #eee;
}

.nome_missao{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 220px;
    top: 350px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_misao{
    box-sizing: border-box;

    position: absolute;
    width: 562px;
    height: 30px;
    left: 220px;
    top: 365px;

    background: #FFFFFF;
    border: 0.5px solid #C4C4C4;
}
.cidade_missao{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 220px;
    top: 420px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_cidade_missao{
    box-sizing: border-box;
    position: absolute;
    width: 562px;
    height: 30px;
    left: 220px;
    top: 435px;
    background: #FFFFFF;
    border: 0.5px solid #C4C4C4;
}
.endereco_missao{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 220px;
    top: 485px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_endereco_missao{
    box-sizing: border-box;
    position: absolute;
    width: 562px;
    height: 30px;
    left: 220px;
    top: 505px;
    background: #FFFFFF;
    border: 0.5px solid #C4C4C4;
}

.inicio_gestao{
    position: absolute;
    width: 522px;
    height: 32px;
    left: 959px;
    top: 350px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_inicio_gestao{
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    position: absolute;
    width: 262px;
    height: 30px;
    left: 959px;
    top: 365px;
    background: #FFFFFF;
    color: rgba(85, 82, 82, 0.25);
    font-size: 18px;
    border: 0.5px solid #C4C4C4;
}
.fim_gestao{
    position: absolute;
    width: 522px;
    height: 32px;
    left: 959px;
    top: 485px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_fim_gestao {
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    position: absolute;
    width: 262px;
    height: 30px;
    left: 959px;
    top: 505px;
    background: #FFFFFF;
    color: rgba(85, 82, 82, 0.25);
    font-size: 18px;
    border: 0.5px solid #C4C4C4;
}
.salvar_missao{
    box-sizing: border-box;
    position: absolute;
    width: 132px;
    height: 30px;
    left: 800px;
    top: 590px;
    color: #fff;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: bold;
}
.missao-list{
    position: absolute;
    width: 980px;
    height: auto;
    left: 231px;
    top: 650px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
    border: 1px solid rgb(198, 192, 192);
}
.dados_misso{
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    top: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;

    text-align: center;
    text-decoration-line: underline;

    color: #4E4E4E;
}
.table_missao{
    position: absolute;
    top: 60px;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}


/* general styling */
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.25;
}
.icon_delete {
  color: red;
  padding: 10px;
  cursor: pointer;
  border: none;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
}

.icon_edit {
  color: rgb(31, 227, 31);
  border: none;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
}
</style>