<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">FORMULÁRIO CONTRATOS</h1>
            </div>
            <ConfiguracaoNav/>
            <!-- <div class="menu_configuracoes">
                <router-link to="/MissaoDiplomatica" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_missao_diplomatica">
                        MISSÃO<br> DIPLOMATICA 
                    </div>
                </router-link>
                <router-link to="/Orgaos" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_orgaos">
                        ORGÃOS
                    </div>
                </router-link>
                <router-link to="/Contas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_contas">
                        CONTAS
                    </div>
                </router-link>
                <router-link to="/FuncionariosEmpresas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_funcionarios_empresas">
                        FUNCIONÁRIOS E<br> EMPRESAS
                    </div>
                </router-link>
                <router-link to="/Naturezas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_naturezas">
                        NATUREZAS
                    </div>
                </router-link>
                <router-link to="/Moedas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_moedas">
                        MOEDAS
                    </div>
                </router-link>
            </div> -->
        </div>
        <div class="form_container">
            <h1 class="titulo_formulario">REGISTAR CONTRATOS</h1>
            <router-link to="/FuncionariosEmpresas" style="text-decoration: none; color: inherit;">
                <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
            </router-link>
            <form @submit.prevent="isEdit ? submitEdit() : handleSubmit">
                <label class="beneficiario_contrato">Beneficiário</label>
                <input required v-model="beneficiario_contrato" class="input_beneficiario_contrato" type="text">
                <label class="departamento_contrato">Departamento</label>
                <input required v-model="departamento_contrato" class="input_departamento_contrato" type="text">
                <label class="contrato_afecto">Afecto a</label>
                <input required v-model="contrato_afecto" class="input_contrato_afecto" type="text">
                <label class="tipo_contrato">Tipo de Contrato</label>
                <input required v-model="tipo_contrato" class="input_tipo_contrato" type="text">
                <label class="descricoes_contrato">Descrição</label>
                <textarea v-model="descricoes_contrato" class="input_descricoes_contrato" rows="4" cols="50"></textarea>
                <label class="valor_mensal_contrato">Valor Mensal</label>
                <input required v-model="valor_mensal_contrato" class="input_valor_mensal_contrato" type="text" @focusout="handleKeyUp">
                <label class="moeda_contrato">Moeda</label>
                <select required v-model="moeda_contrato" class="input_moeda_contrato">
                    <option disabled value="">Por favor escolha uma opção</option>
                    <option v-for="moe in moedaDatas" :key="moe.id" :value="moe.moeda_local">{{ moe.moeda_local }}</option>
                    <option v-for="moe in moedaDatas" :key="moe.id" :value="moe.moeda_gestao">{{ moe.moeda_gestao }}</option>
                    <option v-for="moe in moedaDatas" :key="moe.id" :value="moe.outra_moeda">{{ moe.outra_moeda }}</option>
                </select>
                <!--
                <input type="hidden" :value="moeda_contrato" class="moeda_data">
                <input v-model="estado" type="hidden" class="input_estado_check" true-value="Activo" false-value="Inactivo"/>
                <label class="moeda_contrato">Moeda</label>
                <input required v-model="moeda_contrato" class="input_moeda_contrato"> -->
                <label class="forma_cobranca_contrato">Forma de Cobrança</label>
                <input required v-model="forma_cobranca_contrato" class="input_forma_cobranca_contrato" type="text">
                <label class="tipo_valor_contrato">Tipo de Valor</label>
                <input v-model="tipo_valor_contrato" class="input_tipo_valor_contrato" type="text">
                <label class="caucao_contrato">Caução</label>
                <input required v-model="caucao_contrato" class="input_caucao_contrato" type="text">
                <label class="data_inicio_contrato">Data Início</label>
                <input required v-model="data_inicio_contrato" class="input_data_inicio_contrato" type="date">
                <label required class="data_termino_contrato">Data Término</label>
                <input v-model="data_termino_contrato" class="input_data_termino_contrato" type="date">
                <label class="observacoes_contrato">Observações</label>
                <textarea v-model="observacoes_contrato" class="input_observacoes_contrato" rows="4" cols="50"></textarea>
                <label class="input_file_data_lable">File</label>
                <input @change="handleFileChange" class="input_file_data" type="file" />
                <button class="salvar_funcionario" @click.prevent="submitForm">SALVAR</button>
            </form>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getUser from '../composables/getUser'
import { useRoute, useRouter } from 'vue-router'
import { projectFirestore } from "../firebase/config";
import { storage } from '../firebase/config';
import { onMounted } from "vue";
import ConfiguracaoNav from '../components/ConfiguracaoNav.vue'
import ListaMoeda from '../components/ListaMoeda.vue'

export default {
    components: { NavbarAppHome, Sidebar, ConfiguracaoNav, ListaMoeda },

    setup() {
        const { addDoc, error } = useCollection('contrato')
        const { error2, documents } = getCollection('contrato')
        const { error2 :error_moeda, documents: moeda } = getCollection("moeda");
        const { user } = getUser()
        const route = useRoute()
        const router = useRouter()
        const filteredDocuments = ref([]);
        const moedaDatas = ref([]);
        const data = ref("");
        const user_id = user.value.uid;
        const user_update_id = ref("");
        const beneficiario_contrato = ref('')
        const departamento_contrato = ref('')
        const contrato_afecto = ref('')
        const tipo_contrato = ref('')
        const descricoes_contrato = ref('')
        const valor_mensal_contrato = ref('00.00')
        const moeda_contrato = ref('')
        const forma_cobranca_contrato = ref('')
        const tipo_valor_contrato = ref('')
        const caucao_contrato = ref('')
        const data_inicio_contrato = ref('')
        const data_termino_contrato = ref('')
        const observacoes_contrato = ref('')
        const file_input = ref(null);
        const loader = ref(false);
        const estado = ref(true);
        const handleKeyUp = ()=> {
            valor_mensal_contrato.value = parseFloat(valor_mensal_contrato.value).toFixed(2);
        }
        const handleFileChange = (event) => {
            loader.value = true;
            const file = event.target.files[0];
            const storageRef = storage.ref();
            const fileRef = storageRef.child(file.name);
            console.log('fileRef:', fileRef.fullPath, 'Uploaded file:', file.name);

            fileRef.put(file.name)
                .then((snapshot) => {
                fileRef.getDownloadURL()
                    .then((url) => {
                    file_input.value = url;
                    console.log("url", url);
                    loader.value = false;
                    })
                    .catch((error) => {
                    loader.value = false;
                    console.error("Error getting download URL:", error);
                    });
                })
                .catch((error) => {
                loader.value = false;
                console.error("Error uploading file:", error);
                });
        };
        const isEditingId = computed(() => route.params.id);
        const docRef = getCollection("contrato");
        if (docRef.value) {
            filteredDocuments.value = docRef.value.filter(
                isEditingId.value === user.value.uid
            );
            data.value = filteredDocuments.value;
        }

        const isEdit = ref(false);
        isEditingId.value = false;
        isEdit.value = false;

        const fetchCollectionData = async () => {
        try {
            const collectionRef = projectFirestore.collection("contrato");
            const snapshot = await collectionRef.get();
            const dataaaa = snapshot.docs.map((doc) => {
                return { ...doc.data(), id: doc.id };
            });
            const filtered = dataaaa.filter((dataaaa1) => dataaaa1.id === isEditingId.value);
            filtered.forEach((data1) => {
                beneficiario_contrato.value = data1.beneficiario_contrato;
                departamento_contrato.value = data1.departamento_contrato;
                contrato_afecto.value = data1.contrato_afecto;
                tipo_contrato.value = data1.tipo_contrato;
                descricoes_contrato.value = data1.descricoes_contrato;
                valor_mensal_contrato.value = data1.valor_mensal_contrato;
                moeda_contrato.value = data1.moeda_contrato;
                forma_cobranca_contrato.value = data1.forma_cobranca_contrato;
                tipo_valor_contrato.value = data1.tipo_valor_contrato;
                caucao_contrato.value = data1.caucao_contrato;
                data_inicio_contrato.value = data1.data_inicio_contrato;
                data_termino_contrato.value = data1.data_termino_contrato;
                observacoes_contrato.value = data1.observacoes_contrato;
                file_input.value = data1.file_input;
            });
        } catch (error) {
            console.error("Error fetching collection data:", error);
        }
        };

        onMounted(fetchCollectionData);

        watch(user, () => {
            if  (!user.value) {
                router.push({ name: 'Login'})
            }
        })

        const filterDocuments = () => {
            if (documents.value) {
            filteredDocuments.value = documents.value.filter(
                (doc) => {
                return doc.userId === user.value.uid
                }
            );
            }
        };
        filterDocuments();
    
        watch([documents, user], () => {
            filterDocuments();
        });

//=================================== moeda data =================================== 
        const MoedaFilter = () => {
                if (moeda.value) {
                moedaDatas.value = moeda.value.filter(
                    (doc) => {
                    return doc.userId === user.value.uid
                    }
                );
                }
            };
            MoedaFilter();
        
            watch([moeda, user], () => {
                MoedaFilter();
            });
        
        const handleEdit = async (id) => {
            try {
                const docRef = projectFirestore.collection("contrato").doc(id);
                await docRef.get().then(async (doc) => {
                    if (doc.exists) {
                        let id = await doc.id;
                        new_obj.value = await { ...doc.data(), id };
                        beneficiario_contrato.value = new_obj.value.beneficiario_contrato;
                        departamento_contrato.value = new_obj.value.departamento_contrat;
                        contrato_afecto.value = new_obj.value.contrato_afecto;
                        tipo_contrato.value = new_obj.value.tipo_contrato;
                        descricoes_contrato.value = new_obj.value.descricoes_contrato;
                        valor_mensal_contrato.value = new_obj.value.valor_mensal_contrato;
                        moeda_contrato.value = new_obj.value.moeda_contrato;
                        forma_cobranca_contrato.value = new_obj.value.forma_cobranca_contrato;
                        tipo_valor_contrato.value = new_obj.value.tipo_valor_contrato;
                        caucao_contrato.value = new_obj.value.caucao_contrato;
                        data_inicio_contrato.value = new_obj.value.data_inicio_contrato;
                        data_termino_contrato.value = new_obj.value.data_termino_contrato;
                        observacoes_contrato.value = new_obj.value.observacoes_contrato;
                        file_input.value = new_obj.value.file_input;
                    }
                });
                isEdit.value = true;
                isEditingId.value = id;
            } catch (error) {
                console.log("Error deleting document:", error.message);
            }
        };
        const submitEdit = async () => {
            alert("submitEditsubmitEdit");
            const docRef = projectFirestore
                .collection("contrato")
                .doc(isEditingId.value);
            await docRef.update({
                beneficiario_contrato: beneficiario_contrato.value,
                departamento_contrato: departamento_contrato.value,
                contrato_afecto: contrato_afecto.value,
                tipo_contrato: tipo_contrato.value,
                descricoes_contrato: descricoes_contrato.value,
                valor_mensal_contrato: valor_mensal_contrato.value,
                moeda_contrato: moeda_contrato.value,
                forma_cobranca_contrato: forma_cobranca_contrato.value,
                tipo_valor_contrato: tipo_valor_contrato.value,
                caucao_contrato: caucao_contrato.value,
                data_inicio_contrato: data_inicio_contrato.value,
                data_termino_contrato: data_termino_contrato.value,
                observacoes_contrato: observacoes_contrato.value,
                file_input: file_input.value,
                userId: user.value.uid,
            });
            isEdit.value = false;
            beneficiario_contrato.value = null;
            departamento_contrato.value = null;
            contrato_afecto.value = null;
            tipo_contrato.value = null;
            descricoes_contrato.value = null;
            valor_mensal_contrato.value = null;
            moeda_contrato.value = null;
            caucao_contrato.value = null;
            data_inicio_contrato.value = null;
            data_termino_contrato.value = null;
            observacoes_contrato.value = null;
            file_input.value = null;
        };

        const submitForm = async () => {
            try {
                const docRef = projectFirestore
                .collection("contrato")
                .doc(user_update_id.value);
                await docRef.update({
                    beneficiario_contrato: beneficiario_contrato.value,
                    departamento_contrato: departamento_contrato.value,
                    contrato_afecto: contrato_afecto.value,
                    tipo_contrato: tipo_contrato.value,
                    descricoes_contrato: descricoes_contrato.value,
                    valor_mensal_contrato: valor_mensal_contrato.value,
                    moeda_contrato: moeda_contrato.value,
                    forma_cobranca_contrato: forma_cobranca_contrato.value,
                    tipo_valor_contrato: tipo_valor_contrato.value,
                    caucao_contrato: caucao_contrato.value,
                    data_inicio_contrato: data_inicio_contrato.value,
                    data_termino_contrato: data_termino_contrato.value,
                    observacoes_contrato: observacoes_contrato.value,
                    file_input: file_input.value,
                });

                router.push(`/TabelaContrato`);

                alert("updated successfully");
            } catch (err) {
                alert("updated ====");
                console.log("err==>", err);
            }
        };

        return {handleFileChange, handleKeyUp, estado, beneficiario_contrato, 
                departamento_contrato, contrato_afecto, tipo_contrato, 
                descricoes_contrato, valor_mensal_contrato, moeda_contrato, 
                forma_cobranca_contrato, tipo_valor_contrato, caucao_contrato,
                data_inicio_contrato, data_termino_contrato, observacoes_contrato,
                error, error2, documents, handleEdit, documents, submitEdit, isEdit,
                isEditingId, data, user_id, moeda, submitForm, filteredDocuments, moedaDatas }
    }
    
}
</script>

<style scoped>

.input_file_data_lable {
    position: absolute;
    width: 444px;
    height: 32px;
    left: -170px;
    top: 702px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
}
.input_file_data {
  box-sizing: border-box;
  position: absolute;
  width: 444px;
  height: 30px;
  left: 40px;
  top: 734px;
  font-family: "Inter";
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.cabecalho_configuracoes{
    width: 100%;
    left: 180px;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: 0%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.menu_configuracoes{
    width: 100%;
}

.btn_missao_diplomatica{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    vertical-align: middle;
}
.btn_missao_diplomatica:hover {
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
}
.missao_diplomatica{
    position: absolute;
    width: 215px;
    height: 46px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.missao_diplomatica:hover {
  color: white;
}

.btn_orgaos{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 485px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
    vertical-align: middle;
}

.btn_orgaos:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.orgaos{
    position: absolute;
    width: 209px;
    height: 20px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.orgaos:hover {
  color: white;
}

.btn_contas{
    position: absolute;
    width: 214px;
    height: 84px;
    left: 717px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_contas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.contas{
    position: absolute;
    width: 198px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.contas:hover {
    color: white;
}

.btn_funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 945px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_funcionarios_empresas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.funcionarios_empresas:hover {
    color: white;
}

.btn_naturezas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1176px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_naturezas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.naturezas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.naturezas:hover {
    color: white;
}

.form_container{
    box-sizing: border-box;
    position: absolute;
    width: 908px;
    height: 900px;
    left: 266px;
    top: 270px;
    background: #FFFFFF;
    border: 2px solid #51B8D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.titulo_formulario{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 1px;
    top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
}

.icon_back{
    position: absolute;
    width: 5px;
    height: 8px;
    left: 800px;
    top: 40px;
}

.beneficiario_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -140px;
    top: 70px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}

.departamento_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 375px;
    top: 70px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;

}
.input_beneficiario_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 444px;
    height: 30px;
    left: 40px;
    top: 95px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.input_departamento_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 95px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.contrato_afecto{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -150px;
    top: 140px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_contrato_afecto{
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 165px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.tipo_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 385px;
    top: 140px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_tipo_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 165px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.descricoes_contrato{
    position: absolute;
    width: 330px;
    height: 32px;
    left: -90px;
    top: 277px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_descricoes_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 730px;
    height: 100px;
    left: 40px;
    top: 311px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.valor_mensal_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -140px;
    top: 200px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_valor_mensal_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 444px;
    height: 30px;
    left: 40px;
    top: 231px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.moeda_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 350px;
    top: 200px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_moeda_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 231px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.forma_cobranca_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -115px;
    top: 420px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_forma_cobranca_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 200px;
    height: 30px;
    left: 40px;
    top: 445px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.tipo_valor_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 115px;
    top: 420px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_tipo_valor_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 200px;
    height: 30px;
    left: 295px;
    top: 445px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.caucao_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 355px;
    top: 420px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_caucao_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 220px;
    height: 30px;
    left: 550px;
    top: 445px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.data_inicio_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -145px;
    top: 490px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_data_inicio_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 250px;
    height: 30px;
    left: 40px;
    top: 515px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.data_termino_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 375px;
    top: 490px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_data_termino_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 515px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.observacoes_contrato{
    position: absolute;
    width: 330px;
    height: 32px;
    left: -80px;
    top: 560px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_observacoes_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 730px;
    height: 100px;
    left: 40px;
    top: 590px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.salvar_funcionario{
    box-sizing: border-box;
    position: absolute;
    width: 132px;
    height: 30px;
    left: 641px;
    top: 800px;
    color: #fff;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>