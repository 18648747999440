<template>
    <form @submit.prevent="handleSubmit">
        <select 
            required 
            class="input_orgao_conta"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)">
            <option disabled value="">Por favor escolha uma opção</option>
            <option v-for="orgao in documents" :key="orgao.id" :value="orgao.nome_orgao">{{ orgao.nome_orgao }}</option>
        </select>
    </form>
</template>

<script>
import { ref, watch, computed, reactive } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getDocument from '../composables/getDocument'
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'
export default {
    props: ['modelValue'],
    emits: ['update:modelValue'],
    components: { NavbarAppHome, Sidebar },

    setup() {
        const { addDoc, error } = useCollection('conta')
        const { error2, documents } = getCollection('orgaos')

        const { user } = getUser()
        const router = useRouter()

        watch(user, () => {
            if  (!user.value) {
                router.push({ name: 'Login'})
            }
        })

        const orgao_conta = ref('')
        

        const handleSubmit = async () => {
            const conta = {
               
                orgao_conta: orgao_conta.value,
                userId: user.value.uid

            }
            await addDoc(conta)
            if (!error.value) {
                orgao_conta.value = ''
            }
            alert('DADOS INSERIDOS COM SUCESSO!')
        }
       
        return { handleSubmit, orgao_conta, error2, documents }
    }
    
}
</script>

<style scoped>
.input_orgao_conta{
    box-sizing: border-box;
    position: absolute;
    width: 500px;
    height: 30px;
    left: 40px;
    top: 300px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>