<template>
    <div class="notify">
        INFORMAÇÃO GRAVADA COM SUCESSO!
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.notify{
  position: fixed;
  background-color:#4FC1E9;
  width:750px;
  padding: 20px 25px;
  top:-50px;
  left:-850px;
  color: #fff;
  border-bottom: 3px solid #3BAFDA;
}
</style>