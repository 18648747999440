<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">RELATÓRIOS</h1>
            </div>
        </div>
        <div>
            <nav class="main-nav">
                <router-link :to="{ name: 'RelatorioPessoal' }">RELATORIOS PESSOAL</router-link>
                <router-link :to="{ name: 'RelatorioContaCorrente' }">RELATÓRIOS CONTA CORRENTE</router-link>
                <router-link :to="{ name: 'RelatorioOperacaoTesouraria' }">RELATÓRIOS OPERAÇAO DE TESOURARIA</router-link>
                <router-link :to="{ name: 'OutrosRelatorios' }">OUTROS RELATÓRIOS</router-link>
            </nav>
        </div>
        <div>
            <div>
                <h1 class="dados_contas" v-if="documentField !== null">Lista de relatorios pessoais {{ documentField }}</h1>
                <h1 v-else>No such document exists.</h1>
            </div>
            <div class="container_rel">
                <router-link :to="generateRouterLink(item)" style="text-decoration: none; color: inherit;">
                    <div class="folha_sal_pessoal_quadro">Folha de Salario Pessoal do Quadro</div>
                </router-link>
                <router-link :to="`/RelatoriosPessoalLista/${documentId}/item2/${item}`" style="text-decoration: none; color: inherit;">
                    <div class="folha_sal_pessoal_local">Folha de Salario Pessoal Local</div>
                </router-link>
                <router-link :to="`/RelatoriosPessoalLista/${documentId}/item3/${item}`" style="text-decoration: none; color: inherit;">
                    <div class="resumo_folha_sal">Resumo Folha de Salario</div>
                </router-link>
            </div>
        </div>

    </div>
</template>

<script>
import { ref, watch, computed, reactive, onMounted } from "vue";
import NavbarAppHome from "../components/NavbarAppHome.vue";
import Sidebar from "../components/Sidebar.vue";
import useCollection from "../composables/useCollection";
import getCollection from "../composables/getCollection";
import getDocument from "../composables/getDocument";
import getUser from "../composables/getUser";
import { useRouter, useRoute } from "vue-router";
import { storage } from '../firebase/config';
import ConfiguracaoNav from '../components/ConfiguracaoNav.vue'
import { projectFirestore } from "../firebase/config";

export default {
    components: { NavbarAppHome, Sidebar },
    setup() {
        /*const { addDoc, error } = useCollection("funcionariocentral");*/
        const { error2, documents } = getCollection("orgaos");
        const { user } = getUser();
        const router = useRouter();
        const route = useRoute();
        const filteredDocuments = ref([]);
        const documentField = ref(null);
        const documentId = ref(route.params.id);


        watch(user, () => {
            if (!user.value) {
            router.push({ name: "Login" });
            }
        });
    
        const filterDocuments = () => {
            if (documents.value) {
            filteredDocuments.value = documents.value.filter(
                (doc) => {
                return doc.userId === user.value.uid
                }
            );
            }
        };
        filterDocuments();
    
        watch([documents, user], () => {
            filterDocuments();
        });
        const filterOrgao = () => {
            if (filterDocuments.value) {
                filteredDocuments.value = documents.value.filter(
                    (doc) => {
                        return doc.nome_orgao
                    }
                )
            }
        };
        filterOrgao();
    
        watch([documents, user], () => {
            filterOrgao();
        });
        const documentData = ref(null);
        
        const getDocumentData = async (documentId) => {
        try {
            const docRef = projectFirestore.collection('orgaos').doc(documentId);
            const doc = await docRef.get();
            if (doc.exists) {
            // Access the specific field you want
            documentField.value = doc.data().nome_orgao; // Replace 'fieldName' with your field name
            } else {
            console.log('Document does not exist.');
            }
        } catch (error) {
            console.error('Error getting document:', error);
        }
        };
        

        onMounted(() => {
            documentId.value = route.params.id;
            getDocumentData(documentId.value);
        });
        const item = getDocumentData(documentId.value);
        const item2 = getDocumentData(documentId.value)

        // Define a method to generate the router link based on item
            const generateRouterLink = (item) => {
                return `/RelatoriosPessoalLista/${documentId.value}/item/${item}`;
            };
            const generateRouterLink1= (item2) => {
                return `/RelatoriosPessoalLista/${documentId.value}/item2/${item2}`;
            };
        // Access the ID from the route parameter
        //const idx = ref(route.params.id);
        // Verify the ID or perform any other logic
        //const isValidId = computed(() => {
        //    const numericId = parseInt(id.value, 10);
        //    return !isNaN(numericId) && numericId > 0;
        //    });
        return{
            error2,
            documents,
            filteredDocuments,
            filterOrgao,
            documentData,
            documentId,
            documentField,
            item,
            generateRouterLink,
            item2,
            generateRouterLink1
        }
    }
}
</script>

<style scoped>
.main-nav {
    text-align: center;
    margin: 80px 30px 20px 140px;
}
.main-nav a{
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
    color: #999;
    font-size: 18px;
}
a.router-link-active{
    border-bottom: 2px solid #00ce89;
    padding-bottom: 4px;
}
.lista_relatorios{
    position: absolute;
    width: 321px;
    height: 20px;
    left: 266px;
    top: 200px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #4E4E4E;
}
.dados_contas{
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    top: 195px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
  }

.container_rel{
    box-sizing: border-box;
    position: absolute;
    width: 925px;
    height: 780px;
    left: 265px;
    top: 250px;
    background: #FFFFFF;
    border: 1px solid #4E4E4E;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.folha_sal_pessoal_quadro{
    position: absolute;
    width: 240px;
    height: 25px;
    left: 40px;
    top: 30px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #4E4E4E;
    box-sizing: border-box;
    border: 1px solid #51B8D5;
    border-radius: 50px;
    }

.folha_sal_pessoal_local{
    box-sizing: border-box;
    position: absolute;
    width: 220px;
    height: 25px;
    left: 40px;
    top: 60px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.resumo_folha_sal{
    box-sizing: border-box;
    position: absolute;
    width: 180px;
    height: 25px;
    left: 40px;
    top: 90px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.balanco_das_contas{
    box-sizing: border-box;
    position: absolute;
    width: 160px;
    height: 25px;
    left: 40px;
    top: 90px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}

.operacoes_do_caixa{
    box-sizing: border-box;
    position: absolute;
    width: 280px;
    height: 25px;
    left: 40px;
    top: 120px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.relacoes_despesas_pagas{
    box-sizing: border-box;
    position: absolute;
    width: 205px;
    height: 25px;
    left: 40px;
    top: 150px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.conta_corrente_banco{
    box-sizing: border-box;
    position: absolute;
    width: 205px;
    height: 25px;
    left: 40px;
    top: 180px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.movimento_do_caixa{
    box-sizing: border-box;
    position: absolute;
    width: 165px;
    height: 25px;
    left: 40px;
    top: 210px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.relacao_recursos_recebidos{
    box-sizing: border-box;
    position: absolute;
    width: 230px;
    height: 25px;
    left: 40px;
    top: 240px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.despesas_a_pagar{
    box-sizing: border-box;
    position: absolute;
    width: 150px;
    height: 25px;
    left: 40px;
    top: 270px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.programacao_financeira{
    box-sizing: border-box;
    position: absolute;
    width: 190px;
    height: 25px;
    left: 40px;
    top: 300px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.balanco_mensal{
    box-sizing: border-box;
    position: absolute;
    width: 140px;
    height: 25px;
    left: 40px;
    top: 330px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.relacao_de_cheques_cir_pagos{
    box-sizing: border-box;
    position: absolute;
    width: 290px;
    height: 25px;
    left: 40px;
    top: 360px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.relacao_de_cheques_cir{
    box-sizing: border-box;
    position: absolute;
    width: 250px;
    height: 25px;
    left: 40px;
    top: 390px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.conta_corrente_ML{
    box-sizing: border-box;
    position: absolute;
    width: 145px;
    height: 25px;
    left: 40px;
    top: 420px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.conta_corrente_MG{
    box-sizing: border-box;
    position: absolute;
    width: 440px;
    height: 25px;
    left: 40px;
    top: 450px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.livro_controlo{
    box-sizing: border-box;
    position: absolute;
    width: 260px;
    height: 25px;
    left: 40px;
    top: 480px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.requiscao_verbas{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 25px;
    left: 40px;
    top: 510px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.transferencia_interna{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 25px;
    left: 40px;
    top: 540px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.reldespagascon{
    box-sizing: border-box;
    position: absolute;
    width: 295px;
    height: 25px;
    left: 40px;
    top: 570px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.reldespagasrecpro{
    box-sizing: border-box;
    position: absolute;
    width: 305px;
    height: 25px;
    left: 40px;
    top: 600px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.reldespagastes{
    box-sizing: border-box;
    position: absolute;
    width: 265px;
    height: 25px;
    left: 40px;
    top: 630px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.reloutrassaidas{
    box-sizing: border-box;
    position: absolute;
    width: 210px;
    height: 25px;
    left: 40px;
    top: 660px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
    }
.livroassentamentofuncionarios{
    box-sizing: border-box;
    position: absolute;
    width: 250px;
    height: 25px;
    left: 40px;
    top: 690px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.relatoriomodelo_4{
    box-sizing: border-box;
    position: absolute;
    width: 112px;
    height: 25px;
    left: 40px;
    top: 720px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
</style>