<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">RELAÇÃO DAS DESPESAS PAGAS TESOURO</h1>
            </div>
        </div>
        <div>
    
        <div>
            <span class="icon_download">
                <i class="fa-solid fa-download" @click="generatePDF"></i>
            </span>
            <span class="icon_print">
                <i class="fa-solid fa-print"></i>
            </span>
             <router-link to="/Relatorios" style="text-decoration: none; color: inherit;">
                <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
            </router-link>
            <div class="holderrelatorio">
                <table class="tabela_superior">
                    <tr>
                        <td class="left_corner"><b> MINISTÉRIO DAS FINANÇAS <br> DIREÇÃO NACIONAL DE CONTABILIDADE</b></td>
                        <td class="right_top"><b>RELAÇÃO DAS DESPESAS PAGAS TESOURO</b> <br> Mês de Março de 2022</td>
                    </tr>
                    <tr>
                        <td class="left_bottom"><b>EMBAIXADA DA REPÚBLICA DE ANGOLA NO JAPÃO / Sector de Imprensa</b></td>
                        <td class="right_bottom">Câmbio: YJP:114,38</td>
                    </tr>
                </table>
                <table class="tabela_inferior">
                    <tr>
                        <td colspan="5" class="detalhes">CLASSIFICAÇÃO ORÇAMENTAL</td>
                        <td colspan="3" class="mid_detalhes">MOEDAS</td>
                        <td rowspan="2" class="right_detalhes">KWANZAS</td>
                    </tr>
                    <tr>
                        <td colspan="2" class="detalhes">NATUREZA DA DESPESA</td>
                        <td rowspan="2" class="mid_detalhes">UO</td>
                        <td rowspan="2" class="mid_detalhes">FR</td>
                        <td rowspan="2" class="mid_detalhes">p/at</td>
                        <td class="mid_detalhes">LOCAL</td>
                        <td class="mid_detalhes" colspan="2">OUTRAS</td>
                    </tr>
                    <tr>
                        <td class="mid_detalhes">CÓDIGO</td>
                        <td class="mid_detalhes">DESCRIÇÃO</td>
                        <td class="mid_detalhes">YJP</td>
                        <td class="mid_detalhes">USD</td>
                        <td class="mid_detalhes">EUR</td>
                        <td class="mid_detalhes">AKZ</td>
                    </tr>
                    <tr>
                        <td colspan="5" class="mid_detalhes">Sem lançamento</td>
                        <td class="mid_detalhes"></td>
                        <td class="mid_detalhes"></td>
                        <td class="mid_detalhes"></td>
                        <td class="mid_detalhes"></td>
                    </tr>
                    <tr>
                        <td colspan="5" class="mid_detalhes">TOTAL</td>
                        <td class="mid_detalhes"></td>
                        <td class="mid_detalhes"></td>
                        <td class="mid_detalhes"></td>
                        <td class="mid_detalhes"></td>
                    </tr>
                </table>
                <table class="subtabela7">
                    <tr>
                        <td rowspan="2">Data:       /     /      </td>
                        <td>A Adida Financeira</td>
                        <td>O Embaixador</td>
                    </tr>
                    <tr>
                        <td>___________________________________</td>
                        <td>___________________________________</td>
                    </tr>
                </table>
            </div>
            
        </div>
    </div>
    </div>
</template>

<script>
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import RelatorioOrcamentoContent from '../components/RelatorioOrcamentoContent.vue'
export default {
    components: { NavbarAppHome, Sidebar, RelatorioOrcamentoContent },

}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 80%;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: -15%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.main-nav {
    text-align: center;
    margin: 80px 30px 20px 140px;
}
.main-nav a{
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
    color: #999;
    font-size: 18px;
}
a.router-link-active{
    border-bottom: 2px solid #00ce89;
    padding-bottom: 4px;
}

.titulorelatorio{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 180px;
    top: 233px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    text-decoration-line: underline;

    color: #4E4E4E;
}

.holderrelatorio{
    box-sizing: border-box;
    position: absolute;
    padding: 10px 33px;
    width: 75%;
    height: 1091px;
    left: 251px;
    top: 310px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.icon_download{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 942px;
    top: 289px;
    cursor: pointer;
}

.icon_print{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 982px;
    top: 289px;
    cursor: pointer;
}
.icon_back{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 1020px;
    top: 289px;
    cursor: pointer;
}
p, ul, li{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.65em;
    text-align: justify;
}
.repubica{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
.ministerio{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
.missaoDiplomatica{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
img{
    width: 200px;
    height: 100px;
}
.titulorequisicao{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
table {
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .1em 0 .75em;
}

table tr {
  background-color: #ffffff;
  border: none;
  padding: .35em;
  line-height: 5px;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.unique{
     border: 1px solid #ccc;
     line-height: 5px;
     width: 300px;
}
.unique2{
     border: none;
     line-height: 5px;
     width: 450px;
     
}
.textright{
    text-align: right;
}
.tabela_superior{
    border: 1px solid rgb(0, 0, 0);
    height: 50px;
}
.left_corner{
    border: 1px solid rgb(0, 0, 0);
    font-size: .8em;
    line-height: 15px;
    height: 50px;
}
.right_top{
    font-size: .8em;
    line-height: 15px;
    height: 50px;
    text-align: center;
}
.left_bottom{
    font-size: .6em;
    line-height: 15px;
    text-align: left;
}
.right_bottom{
    font-size: .5em;
    line-height: 15px;
    text-align: right;
}
.detalhes{
    border: 1px solid rgb(0, 0, 0);
    width: 400px;
    font-size: .6em;
    line-height: 15px;
    text-align: left;
}
.mid_detalhes{
    border: 1px solid rgb(0, 0, 0);
    font-size: .6em;
    line-height: 15px;
    width: 250px;
}
.right_detalhes{
    border: 1px solid rgb(0, 0, 0);
    font-size: .6em;
    line-height: 15px;
}
.subtabela7{
    position: absolute;
    height: 80px;
    left: 34px;
    top: 260px;
    width: 778px;
    font-size: .7em;
    border: 1px solid #000000;
}
</style>