<template>
  <div>
        <table id="element-to-pdf">
            <tbody>
                <tr>
                    <p>
                        O orçamento do Estado é um instrumento apresentado sob forma
                        de lei, proposto pelo Governo e aprovado pela Assembleia Nacional, 
                        que comporta a descrição detalhada de todas as receitas e despesas 
                        do estado, de acordo com os princípios de anualidade (o orçamento corresponde
                        a um ano), unidade (apresentação de todas receitas e despesas em um único documento)
                        e universidade (integram todas entidades públicas) e observa os seguintes elementos;
                    </p>
                </tr>
                <tr>
                    <p>
                        <ul>
                            <li>Elementos econômicos: constitui uma previsão de atividade financeira anual e realizar</li>
                            <li>Elementos políticos: constitui uma autorização política concedida pela Assembleia Nacional mediante aprovação da proposta elaborada e submetida pelo Governo</li>
                            <li>Elementos jurídicos: constitui um instrumento sob forma de Lei, que limita os poderes financeiros do Estado no que respeita à realização das despesas e a obtenção das receitas</li>
                        </ul>
                    </p>
                </tr>
                <tr>
                    <p>
                        Para o ano de <span><b>{{new Date().getFullYear()}}</b></span>, foi aprovado o orçamento no valor total
                        em <b>AKZ 784.169.892,59</b> (SETECENTOS E OITENTA E QUATRO MILHÕES, 
                        CENTO E SESSENTA E NOVE MIL OITOCENTOS E NOVENTA E DOIS KWANZAS
                        E CINQUENTA E NOVE CÊNTIMOS), a favor desta Missão Diplomática, 
                        distribuído nas categorias de despesas, conforme os dados abaixo;
                    </p>
                </tr>
                <tr>
                    <p>Pessoal &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; 429.096.206,59</p>
                    <p>Contribuições do Empregador  &emsp; &emsp; &emsp; &emsp; &emsp; &nbsp; &nbsp; 23.000.000,00</p>
                    <p>Bens e Serviços &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &nbsp;249.372.686,00</p>
                    <p>Subsidios e Transferencias Corrente &emsp; &emsp; &emsp; &emsp; &nbsp; &nbsp; 700.000,00</p>
                    <p>Investimento &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; 82.000.000,00</p>
                    <p><b>TOTAL &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &nbsp; 784.169.892,59</b></p>
                </tr>
                <tr>
                    <p><b>2. Despesas</b></p>
                    <p>
                        As entidades públicas devem respeitar os limites definidos
                        no quadro parcelar, que representa um instrumento guia sob 
                        forma de Lei, do processo de execução da despesa.
                    </p>
                    <p>
                        As despesas do Estado, obedecem ao ciclo definidos na Lei 15/10
                        de 14 de Junho, <b>“Lei Quadro do Orçamento Geral do Estado”</b>, que 
                        determina que nenhuma despesa deve ser paga sem que tenha inscrição 
                        orçamental, cabimentação na programação financeira e satisfaça o 
                        princípio da economia, eficiência e eficácia.
                    </p>
                    <p>
                        A cabimentação das despesas desta Missão Diplomática corresponde ao valor total em <b>AKZ 0,00</b>(Zero).
                    </p>
                    <p>
                        A conversão da cabimentação para a moeda em Kwanza, teve como câmbio médio de referência <b>1 USD = 0,00 AKZ</b>;
                    </p>
                </tr>
                <tr>
                    <p><b>3. Comparação entre o Orçamento e as Despesas Previstas</b></p>
                    <p>
                        O orçamento disponível para execução é superior às despesas previstas, 
                        com um superavit orçamental no valor total <b>AKZ 784.169.892,59</b> 
                        (SETECENTOS E OITENTA E QUATRO MILHÕES, CENTO E SESSENTA E NOVE MIL 
                        OITOCENTOS E NOVENTA E DOIS KWANZAS E CINQUENTA E NOVE CÊNTIMOS).
                    </p>
                </tr>
                <tr>
                    <p>Toquio,</p>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.holderrelatorio{
    box-sizing: border-box;
    position: absolute;
    padding: 10px 33px;
    width: 75%;
    height: 791px;
    left: 251px;
    top: 310px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.icon_download{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 942px;
    top: 289px;
    cursor: pointer;
}

.icon_print{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 982px;
    top: 289px;
    cursor: pointer;
}
p, ul, li{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.65em;
    text-align: justify;
}

.element-to-pdf{
    background-color: #ffffff;
    border: none;
}
tbody, tr, td{
    background-color: #ffffff;
    border: none;
}
</style>