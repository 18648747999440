<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">RECEITAS</h1>
            </div>
            <div class="menu_configuracoes">
                <router-link to="/Receitas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_missao_diplomatica">
                        RECEITAS 
                    </div>
                </router-link>
                <router-link to="/Despesas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_orgaos">
                        DESPESAS
                    </div>
                </router-link>
                <router-link to="/Transferencias" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_contas">
                        TRANSFERÊNCIAS
                    </div>
                </router-link>
                <router-link to="/Salario" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_salarios">
                        SALÁRIOS
                    </div>
                </router-link>
            </div>
        </div>
        <div>
            <router-link to="/FormularioReceitas" style="text-decoration: none; color: inherit;"> 
                <span class="formulario_funcionario"><span class="dot_funcionario"></span>Registar Receitas</span>
            </router-link>
        </div>
        <div class="contas-list">
            <h2 class="dados_contas">LISTA DE RECEITAS REGISTADAS</h2>
            <div class="table_contas">
                <table>
                    <thead>
                        <tr>
                        <th scope="col">Doc. Número</th>
                        <th scope="col">Beneficiário</th>
                        <th scope="col">Descrição</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Data</th>
                        <th scope="col">SIGFE</th>
                        <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody v-if="data && data.length > 0">
                        <tr v-for="f in data" :key="f.id">
                            <td>{{ f.n_doc_receita }}</td>
                            <td>{{ f.beneficiario_receita }}</td>
                            <td>{{ f.descricao_receita }}</td>
                            <td>{{ f.valor_receita }}</td>
                            <td>{{ f.data_entrada_receita }}</td>
                            <td>{{ f.sigfe_receita }}</td>
                            <td>
                                <router-link to="/GuiaReceita" style="text-decoration: none; color: inherit;">
                                    <span title="Operação de Tesouraria" class="icon_relatorio"><i class="fa-solid fa-file"></i></span>
                                </router-link>
                                <span title="Eliminar" @click="handleDelete(f.id)" class="icon_delete"><i class="fas fa-trash-alt"></i></span>
                                <router-link :to="`/editreceitas/${f.id}`" style="text-decoration: none; color: inherit;">
                                    <span title="Editar" class="icon_edit"><i class="fas fa-edit"></i></span>
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="error">{{ error2 }}</div>
            </div>
        </div>
        <div class="contas-list1">
            <h2 class="dados_contas"></h2>
            <div class="table_contas">
                <table>
                    <tr>
                        <form>
                            <label class="filtro_orgao_despesas">Orgão</label>
                            <select required class="input_filtro_orgao_despesas">
                                <option disabled value="">--Selecione Orgão--</option>
                                <option value="Pendente">Teste 1</option>
                                <option value="Confirmado">Teste 2</option>
                            </select>
                            <label class="filtro_movimentos_despesas">Movimento</label>
                            <select required class="input_filtro_movimento_despesas">
                                <option disabled value="">--Selecione o Movimento--</option>
                                <option value="Pendente">Pendente</option>
                                <option value="Confirmado">Confirmado</option>
                            </select>
                            <label class="filtro_contas_despesas">Conta</label>
                            <select required class="input_filtro_contas_despesas">
                                <option disabled value="">--Selecione a Conta--</option>
                                <option value="Pendente">Conta 1</option>
                                <option value="Confirmado">Conta 2</option>
                            </select>
                            <label class="filtro_sigfe_despesas">Sigfe</label>
                            <select required class="input_filtro_sigfe_despesas">
                                <option disabled value="">--Selecione a Conta--</option>
                                <option value="Pendente">Activo</option>
                                <option value="Confirmado">Inactivo</option>
                            </select>
                            <button class="buscar_despesas">BUSCAR</button>
                        </form>
                    </tr>
                </table>
                <div class="error">{{ error2 }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'
import { projectFirestore } from '../firebase/config'
export default {
    components: { NavbarAppHome, Sidebar },

    setup() {
        const { addDoc, error } = useCollection('conta')
        const { error2, documents } = getCollection('receitas')
        const { user } = getUser()
        console.log(user, "useruser");
        const data = ref([])
        const router = useRouter()
        const filteredDocuments = ref([]);
        let new_obj = ref();
        const isEdit = ref(false);
        const isEditingId = ref(null);
        const n_doc_receita = ref('')
        const beneficiario_receita = ref('')
        const natureza_receita = ref('')
        const data_entrada_receita = ref('')
        const valor_receita = ref('')
        const moeda_receita = ref('')
        const cambio_receita = ref('')
        const moeda_usd_receita = ref('')
        const descricao_receita = ref('')
        const sigfe_receita = ref('')
        const pago_estado = ref('')
        const filterDocuments = () => {
            if (documents.value) {
                filteredDocuments.value = documents.value.filter(
                (doc) => doc.userId === user.value.uid
                );
                data.value = filteredDocuments.value;
            }
        };
        filterDocuments();
  
        watch([documents, user], () => {
            filterDocuments();
        });
        const handleSubmit = async () => {
            const receita = {
                n_doc_receita : n_doc_receita.value,
                beneficiario_receita : beneficiario_receita.value,
                natureza_receita : natureza_receita.value,
                data_entrada_receita : data_entrada_receita.value,
                valor_receita : valor_receita.value,
                moeda_receita : moeda_receita.value,
                cambio_receita : cambio_receita.value,
                moeda_usd_receita : moeda_usd_receita.value,
                descricao_receita : descricao_receita.value,
                sigfe_receita : sigfe_receita.value,
                pago_estado : pago_estado.value,
                userId: user.value.uid,
            }
            await addDoc(receita)
            if (!error.value) {
                n_doc_receita = ''
                beneficiario_receita = ''
                natureza_receita = ''
                data_entrada_receita = ''
                valor_receita = ''
                moeda_receita = ''
                cambio_receita = ''
                moeda_usd_receita = ''
                descricao_receita = ''
                sigfe_receita = ''
                pago_estado = ''
            }
        }

        const handleDelete = async (id) => {
            try {
                const docRef = projectFirestore.collection("receitas").doc(id);
                await docRef.delete();
                console.log("Document successfully deleted.");
            } catch (error) {
                console.log("Error deleting document:", error.message);
            }
        };
        const handleEdit = async (id) => {
            try {
                const docRef = projectFirestore.collection("receitas").doc(id);
                await docRef.get().then(async (doc) => {
                if (doc.exists) {
                    let id = await doc.id;
                    new_obj.value = await { ...doc.data(), id };
                    n_doc_receita = new_obj.value.n_doc_receita;
                    beneficiario_receita = new_obj.value.beneficiario_receita;
                    natureza_receita = new_obj.value.natureza_receita;
                    data_entrada_receita = new_obj.value.data_entrada_receita;
                    valor_receita = new_obj.value.valor_receita;
                    moeda_receita = new_obj.value.moeda_receita;
                    cambio_receita = new_obj.value.cambio_receita;
                    moeda_usd_receita = new_obj.value.moeda_usd_receita;
                    descricao_receita = new_obj.value.descricao_receita;
                    sigfe_receita = new_obj.value.sigfe_receita;
                    pago_estado = new_obj.value.pago_estado;
                    
                }
                });
                isEdit.value = true;
                isEditingId.value = id;
            } catch (error) {
                console.log("Error deleting document:", error.message);
            }
            };
  
        const submitEdit = async () => {
            alert("submitEditsubmitEdit");
            const docRef = projectFirestore
                .collection("receitas")
                .doc(isEditingId.value);
            await docRef.update({
                n_doc_receita : n_doc_receita.value,
                beneficiario_receita : beneficiario_receita.value,
                natureza_receita : natureza_receita.value,
                data_entrada_receita : data_entrada_receita.value,
                valor_receita : valor_receita.value,
                moeda_receita : moeda_receita.value,
                cambio_receita : cambio_receita.value,
                moeda_usd_receita : moeda_usd_receita.value,
                descricao_receita : descricao_receita.value,
                sigfe_receita : sigfe_receita.value,
                pago_estado : value.pago_estado.value,
                userId: user.value.uid,
            });
            isEdit.value = false;
            n_doc_receita.value = null;
            beneficiario_receita.value = null;
            natureza_receita.value = null;
            data_entrada_receita.value = null;
            valor_receita.value = null;
            moeda_receita.value = null;
            cambio_receita.value = null;
            moeda_usd_receita.value = null;
            descricao_receita.value = null;
            sigfe_receita.value = null;
            pago_estado.value = null;
            };

        watch(user, () => {
            if  (!user.value) {
                router.push({ name: 'Login'})
            }
        })
        
        

        return {  error, 
                  error2, 
                  documents,
                  n_doc_receita,
                  beneficiario_receita,
                  natureza_receita,
                  data_entrada_receita,
                  valor_receita,
                  moeda_receita,
                  cambio_receita,
                  moeda_usd_receita,
                  descricao_receita,
                  sigfe_receita,
                  pago_estado,
                  handleDelete,
                  handleEdit,
                  submitEdit,
                  filteredDocuments,
                  handleSubmit,
                  data,
                 }
    }
    
}
</script>

<style>
.formulario_funcionario{
    position: absolute;
    width: 236px;
    height: 24px;
    left: 235px;
    top: 290px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #333333;
}

.dot_funcionario{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 27px;
    top: 1px;
    background-color: rgb(25, 226, 25);
    border-radius: 15px;
}
.contas-list{
    position: absolute;
    width: 980px;
    height: auto;
    left: 231px;
    top: 400px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
    border: 1px solid rgb(198, 192, 192);
}
.dados_contas{
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    top: 10px;
    margin: 20px 10px 10px 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
}
.table_contas{
    position: absolute;
    top: 60px;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

/* general styling */
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.25;
}

.icon_delete{
    color:red;
    padding: 10px;
    cursor: pointer;
}

.icon_edit{
    color: rgb(31, 227, 31);
    cursor: pointer;
}
.menu_tabelas{
    padding: 20px;
    color: black;
    margin-bottom: 10px;
}

.contas-list1{
    position: absolute;
    width: 580px;
    height: auto;
    left: 1100px;
    top: 400px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
    border: 1px solid rgb(198, 192, 192);
}
.filtro_orgao_despesas{
    position: absolute;
    width: 148px;
    height: 20px;
    left: 208px;
    top: 25px;

    font-family: 'Signika';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: underline;

    color: #51B8D5;
}

.input_filtro_orgao_despesas{
    position: absolute;
    width: 300px;
    height: 40px;
    left: 260px;
    top: 50px;
    border: 1px solid #51B8D5;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: underline;

    color: #000000;
}

.filtro_movimentos_despesas{
    position: absolute;
    width: 148px;
    height: 20px;
    left: 228px;
    top: 95px;

    font-family: 'Signika';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: underline;

    color: #51B8D5;
}

.input_filtro_movimento_despesas{
    position: absolute;
    width: 300px;
    height: 40px;
    left: 260px;
    top: 120px;
    border: 1px solid #51B8D5;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #000000;
}

.filtro_contas_despesas{
    position: absolute;
    width: 148px;
    height: 20px;
    left: 208px;
    top: 170px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: underline;

    color: #51B8D5;
}

.input_filtro_contas_despesas{
    position: absolute;
    width: 300px;
    height: 40px;
    left: 260px;
    top: 197px;
    border: 1px solid #51B8D5;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #000000;
}
.filtro_sigfe_despesas{
    position: absolute;
    width: 148px;
    height: 20px;
    left: 208px;
    top: 240px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: underline;

    color: #51B8D5;
}

.input_filtro_sigfe_despesas{
    position: absolute;
    width: 300px;
    height: 40px;
    left: 260px;
    top: 265px;
    border: 1px solid #51B8D5;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #000000;
}

.buscar_despesas{
    box-sizing: border-box;

    position: absolute;
    width: 103px;
    height: 30px;
    left: 370px;
    top: 341px;

    background: #FFFFFF;
    border: 1px solid #51B8D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
}
.btn_salarios{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 950px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.btn_salarios:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
</style>