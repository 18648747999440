<template>
    <div class="float-container">
        <div class="escolha_plano">Escolha um plano que funcione para si</div>
        <div class="float-child">
            <div class="green">
                <h3 class="title_plano">Plano Anual</h3>
                <div class="price"><span class="dollar">$</span>800<span class="month">/Ano</span></div>
                <div class="price-footer">
                    <router-link to="/Price" style="text-decoration: none; color: inherit;"><a class="order-btn" href="">Comprar<i class="fa fa-arrow-right"></i></a></router-link> 
                </div>
                <div class="price-body">
                    <ul>
                        <li>Rastreie receitas e despesas</li>
                        <li> Envie faturas e orçamentos personalizados</li>
                        <li>Conecte seu banco</li>
                        <li>Insights e relatórios</li>
                        <li>Gerenciar funcionários</li>
                        <li>Multi Moeda</li>
                        <li>Transações recorrentes</li>
                        <li>Rastrear o estoque</li>
                        <li>Rastreie projetos e locais</li>
                        <li>Gerenciar orçamentos</li>
                        <li>Para um usuários</li>
                        <li><b>Tribunal de contas</b></li>
                    </ul>
                </div>
            </div>
        </div>
        
        <div class="float-child">
            <div class="blue">
                <h3 class="title_plano">Plano Anual</h3>
                <div class="price"><span class="dollar">$</span>1800<span class="month">/Ano</span></div>
                <div class="price-footer">
                    <router-link to="/Price" style="text-decoration: none; color: inherit;"><a class="order-btn" href="">Comprar<i class="fa fa-arrow-right"></i></a></router-link> 
                </div>
                <div class="price-body">
                    <ul>
                        <li>Rastreie receitas e despesas</li>
                        <li> Envie faturas e orçamentos personalizados</li>
                        <li>Conecte seu banco</li>
                        <li>Insights e relatórios</li>
                        <li>Gerenciar funcionários</li>
                        <li>Multi Moeda</li>
                        <li>Transações recorrentes</li>
                        <li>Rastrear o estoque</li>
                        <li>Rastreie projetos e locais</li>
                        <li>Gerenciar orçamentos</li>
                        <li>Para um usuários</li>
                        <li><b>Tribunal de contas</b></li>
                    </ul>
                </div>
            </div>
        </div>
    
    </div>
</template>

<script>
  export default {
    name: 'PriceComponent',

    data: () => ({
      
    }),
  }
</script>

<style lang="scss">
.escolha_plano {
    width: 100%;
    align-items: center;
}
.float-container {
    position: absolute;
    border: 3px solid #fff;
    padding: 20px;
    width: 100%;
    align-items: center;
    margin: 10px 40px;
}

.float-child {
    width: 40%;
    float: left;
    padding: 20px;
    border: 2px solid rgb(234, 234, 234);
    margin: 40px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}  
.title_plano{
    font-size: 1.5vw;
	margin: 5px;
    padding: 5px;
	font-weight: 700;
    text-align: left;
  }
  .price {
    font-size: 50px;
    line-height: 60px;
    color: #282828;
    font-weight: 600;
    margin-bottom: 25px;
    text-align: left;
  }
.dollar {
    font-size: 33px;
    line-height: 33px;
    position: relative;
    top: -12px;
}
.month {
    font-size: 22px;
    line-height: 23px;
}
.price-footer {
    margin-top: 10px;
    .order-btn {
        display: inline-block;
        width: 165px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border: 2px solid #00344C;
        font-size: 14px;
        text-transform: uppercase;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 25px;
        color: white;
        background-color: #00344C;
        //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
        transition: .3s ease-in;
        font-weight: 600;
        i {
            font-size: 13px;
            padding-left: 2px;
        }
        &:hover {
            background-color: #00344C;
            color: white;
            border-color: white;
        }
    }
}
.price-body ul {
    margin: 0;
    padding: 0;
    li {
        list-style: none;
        display: block;
        color: black;
        margin: 27px 0;
        list-style-type: circle;
        text-align: left;
        &:first-child {
            margin-top: 30px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>