<template>
    <header>
        <img class="logo" src="../assets/logo_prescom.jpg" alt="logotipo Prescon">
        <nav>
            <router-link :to="{ name: 'LandingPage'}">HOME</router-link>
            <router-link :to="{ name: 'Price'}">PREÇOS</router-link>
            <router-link :to="{ name: 'Apoio'}">APOIO</router-link>
            <router-link :to="{ name: 'Login'}">LOGIN/REGISTRAR</router-link>
        </nav>
    </header>
</template>

<script>
export default {

}
</script>

<style>
header {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    padding: 0px;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(221, 219, 219);
}

header img{
    width: 200px;
    height: 80px;
    padding: 0px 60px;
}

header a {
    color: #000;
    text-decoration: none;
    margin-left: 10px;
    padding-right: 60px;
}

header a.router-link-active {
    color: rgb(208, 223, 241);
    font-weight: bold;
    text-decoration: underline;
}
</style>