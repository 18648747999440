<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">BALANÇO MENSAL</h1>
            </div>
        </div>
        <div>
    
        <div>
            <span class="icon_download">
                <i class="fa-solid fa-download" @click="generatePDF"></i>
            </span>
            <span class="icon_print">
                <i class="fa-solid fa-print"></i>
            </span>
             <router-link to="/Relatorios" style="text-decoration: none; color: inherit;">
                <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
            </router-link>
            <div class="holderrelatorio">
                <table class="element-to-pdf">
                    <tr>
                        <td>
                            <img src="../assets/Insignia.jpg" alt="Insignia da Republica">
                            <p class="ministerio">REPÚBLICA DE ANGOLA </p>
                            <p class="ministerio">MINISTÉRIO DAS RELAÇÕES EXTERIORES </p>
                            <p class="missaoDiplomatica">EMBAIXADA DA REPÚBLICA DE ANGOLA NO JAPÃO</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="titulorequisicao">
                            <b>BALANÇO MENSAL</b>
                        </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Mês Fevereiro 2022
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                    </tr>
                     <tr>
                        <td></td>
                    </tr>
                    <table class="sector">
                        <tr>
                            <td><b>Sector de Imprensa</b></td>
                        </tr>
                    </table>
                    <table class="cambio">
                        <tr>
                            <td>Câmbio: YJP:114,38</td>
                        </tr>
                    </table>
                </table>
                <table class="balanco_contas">
                    <tr>
                        <td class="descricao">SALDOS CONTABILÍSTICOS</td>
                    </tr>
                </table>
                
                <table class="balanco_contas1">
                    <tr>
                        <td class="descricao">SALDO DO MÊS ANTERIOR</td>
                        <td class="ml">JPY</td>
                        <td class="mg">USD</td>
                        <td class="om">EUR</td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="subtotal"><b>SUB-TOTAL</b></td>
                        <td class="sub_ml"></td>
                        <td class="sub_mg"></td>
                        <td class="sub_om"></td>
                    </tr>
                </table>
                <table class="balanco_contas2">
                    <tr>
                        <td class="descricao">RECEBIMENTOS</td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="subtotal"><b>SUB-TOTAL</b></td>
                        <td class="sub_ml"></td>
                        <td class="sub_mg"></td>
                        <td class="sub_om"></td>
                    </tr>
                </table>
                <table class="balanco_contas3">
                    <tr>
                        <td class="subtotal"><b>SALDO 1</b></td>
                        <td class="sub_ml"></td>
                        <td class="sub_mg"></td>
                        <td class="sub_om"></td>
                    </tr>
                </table>
                <table class="balanco_contas4">
                    <tr>
                        <td class="descricao">SAÍDAS</td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="subtotal"><b>SUB-TOTAL</b></td>
                        <td class="sub_ml"></td>
                        <td class="sub_mg"></td>
                        <td class="sub_om"></td>
                    </tr>
                </table>
                <table class="balanco_contas5">
                    <tr>
                        <td class="descricao">SALDO DO MÊS SEGUINTE</td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="subtotal"><b>SUB-TOTAL</b></td>
                        <td class="sub_ml"></td>
                        <td class="sub_mg"></td>
                        <td class="sub_om"></td>
                    </tr>
                </table>
                <table class="balanco_contas6">
                    <tr>
                        <td class="subtotal"><b>SALDO 2</b></td>
                        <td class="sub_ml"></td>
                        <td class="sub_mg"></td>
                        <td class="sub_om"></td>
                    </tr>
                </table>
                <table class="balanco_contas7">
                    <tr>
                        <td class="subtotal"><b>DIFERENÇA</b></td>
                        <td class="sub_ml"></td>
                        <td class="sub_mg"></td>
                        <td class="sub_om"></td>
                    </tr>
                </table>
                <table class="balanco_contas8">
                    <tr>
                        <td class="descricao">CHEQUES EM CIRCULAÇÃO</td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                    <tr>
                        <td class="descricao"></td>
                        <td class="ml"></td>
                        <td class="mg"></td>
                        <td class="om"></td>
                    </tr>
                </table>
                <table class="subtabela7">
                    <tr>
                        <td rowspan="2">Data: 01/04/2022</td>
                        <td>A Adida Financeira</td>
                        <td>O Embaixador</td>
                    </tr>
                    <tr>
                        <td>___________________________________</td>
                        <td>___________________________________</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import RelatorioOrcamentoContent from '../components/RelatorioOrcamentoContent.vue'
export default {
    components: { NavbarAppHome, Sidebar, RelatorioOrcamentoContent },

}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 80%;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: -15%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.main-nav {
    text-align: center;
    margin: 80px 30px 20px 140px;
}
.main-nav a{
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
    color: #999;
    font-size: 18px;
}
a.router-link-active{
    border-bottom: 2px solid #00ce89;
    padding-bottom: 4px;
}

.titulorelatorio{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 180px;
    top: 233px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    text-decoration-line: underline;

    color: #4E4E4E;
}

.holderrelatorio{
    box-sizing: border-box;
    position: absolute;
    padding: 10px 33px;
    width: 1070px;
    height: 1391px;
    left: 251px;
    top: 310px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.icon_download{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 942px;
    top: 289px;
    cursor: pointer;
}

.icon_print{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 982px;
    top: 289px;
    cursor: pointer;
}
.icon_back{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 1020px;
    top: 289px;
    cursor: pointer;
}
p, ul, li{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.65em;
    text-align: justify;
}
.repubica{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
.ministerio{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
.missaoDiplomatica{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
img{
    width: 200px;
    height: 100px;
}
.titulorequisicao{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
table {
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .1em 0 .75em;
}

table tr {
  background-color: #ffffff;
  border: none;
  padding: .35em;
  line-height: 5px;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.unique{
     border: 1px solid #ccc;
     line-height: 5px;
     width: 300px;
}
.unique2{
     border: none;
     line-height: 5px;
     width: 450px;
     
}
.textright{
    text-align: right;
}
.movemnetos_debito{
    border: 2px solid #000000;
    width: 900px;
}
.tipoconta{
    border: 2px solid #000000;
    width: 300px;
    height: 80px;
    text-align: center;
    font-size: 0.8em;
    background-color: rgb(185, 184, 184);
}
.tipoconta2{
    border: 2px solid #000000;
    width: 300px;
    height: 80px;
    text-align: center;
    font-size: 0.8em;
    background-color: rgb(185, 184, 184);
    line-height: 20px;
}
.titulotabela{
    border: 2px solid #000000;
    text-align: center;
    font-size: 0.8em;
    height: 40px;
    line-height: 20px;
    background-color: rgb(251, 208, 208);
}
.titulotabela2{
    border: 2px solid #000000;
    text-align: center;
    font-size: 0.8em;
    height: 40px;
    line-height: 20px;
    background-color: rgb(222, 251, 208);
}
.moedagestao{
    border: 2px solid #000000;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(251, 208, 208);
}
.moedalocal{
    border: 2px solid #000000;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(251, 208, 208);
}
.outramoeda{
    border: 2px solid #000000;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(251, 208, 208);
}
.moedanacional{
    border: 2px solid #000000;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(251, 208, 208);
}
.moedagestao2{
    border: 2px solid #000000;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(222, 251, 208);
}
.moedalocal2{
    border: 2px solid #000000;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(222, 251, 208);
}
.outramoeda2{
    border: 2px solid #000000;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(222, 251, 208);
}
.moedanacional2{
    border: 2px solid #000000;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(222, 251, 208);
}
.moedagestao3{
    border: 2px solid #000000;
    height: 22px;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(222, 251, 208);
}
.moedalocal3{
    border: 2px solid #000000;
    height: 22px;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(222, 251, 208);
}
.outramoeda3{
    border: 2px solid #000000;
    height: 22px;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(222, 251, 208);
}
.moedanacional3{
    border: 2px solid #000000;
    height: 22px;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(222, 251, 208);
}
.somatitulo{
    text-align: right;
    font-size: 1em;
    background-color: rgb(185, 184, 184);
}
.soma{
    border: 2px solid #000000;
    border: 2px solid #000000;
    text-align: center;
    font-size: 1em;
    background-color: rgb(185, 184, 184);

}
.normalcell{
    border: 2px solid #000000;
    text-align: left;
    font-size: 0.7em;
}
.element-to-pdf{
    border: 1px solid #000000;
    width: 1000px;
}
.sector{
    position: absolute;
    top: 280px;
    left: 50px;
    width: 200px;
    font-size: 0.9em;
}
.cambio{
    position: absolute;
    top: 280px;
    left: 800px;
    width: 200px;
    font-size: 0.9em;
}

.subtabela6{
    position: absolute;
    width: 300px;
    left: 10px;
    top: 1100px;
    font-size: .7em;
}
.subtabela7{
    position: absolute;
    height: 80px;
    left: 35px;
    top: 1180px;
    width: 1000px;
    font-size: .7em;
    border: 1px solid #000000;
}
.balanco_contas{
    border: 2px solid #000000;
    position: absolute;
    top: 351px;
    text-align: left;
    width: 1000px;
    background-color: green;
}
.balanco_contas1{
    border: 2px solid #000000;
    position: absolute;
    top: 378px;
    width: 1000px;
}
.balanco_contas2{
    border: 2px solid #000000;
    position: absolute;
    top: 520px;
    width: 1000px;
}
.balanco_contas3{
    border: 2px solid #000000;
    position: absolute;
    top: 660px;
    width: 1000px;
}
.balanco_contas4{
    border: 2px solid #000000;
    position: absolute;
    top: 700px;
    width: 1000px;
}
.balanco_contas5{
    border: 2px solid #000000;
    position: absolute;
    top: 840px;
    width: 1000px;
}
.balanco_contas6{
    border: 2px solid #000000;
    position: absolute;
    top: 980px;
    width: 1000px;
    background-color: rgb(185, 184, 184);
}
.balanco_contas7{
    border: 2px solid #000000;
    position: absolute;
    top: 1020px;
    width: 1000px;
    background-color: rgb(185, 184, 184);
}
.balanco_contas8{
    border: 2px solid #000000;
    position: absolute;
    top: 1060px;
    width: 1000px;
}
.descricao{
    width: 150px;
    border: 1px solid #000000;
    text-align: left;
    font-size: 0.9em;
}
.ml{
    width: 50px;
    border: 1px solid #000000;
    text-align: left;
    font-size: 0.9em;
}
.mg{
    width: 50px;
    border: 1px solid #000000;
    text-align: left;
    font-size: 0.9em;
}
.om{
    width: 50px;
    border: 1px solid #000000;
    text-align: left;
    font-size: 0.9em;
}
.subtotal{
    width: 150px;
    border: 1px solid #000000;
    text-align: right;
    font-size: 0.9em;
    background-color: rgb(175, 174, 174);
}
.sub_ml{
    width: 50px;
    border: 1px solid #000000;
    text-align: right;
    font-size: 0.9em;
    background-color: rgb(175, 174, 174);
}
.sub_mg{
    width: 50px;
    border: 1px solid #000000;
    text-align: right;
    font-size: 0.9em;
    background-color: rgb(175, 174, 174);
}
.sub_om{
    width: 50px;
    border: 1px solid #000000;
    text-align: right;
    font-size: 0.9em;
    background-color: rgb(175, 174, 174);
}
</style>