<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">GESTÃO DOCUMENTAL</h1>
            </div>
            <div>
                <router-link to="/AdicionarDocumentos" style="text-decoration: none; color: inherit;"> 
                    <span class="formulario_contas"><span class="dot_contas"></span>Adicionar Documento</span>
                </router-link>
            </div>
        </div>
        <div class="contas-list">
            <h2 class="dados_contas">LISTA DE DOCUMENTOS</h2>
            <div class="table_contas">
                <table>
                    <thead>
                        <tr>
                        <th scope="col">Nº</th>
                        <th scope="col">NOME</th>
                        <th scope="col">DATA DE RECEPÇÃO</th>
                        <th scope="col">Orgão</th>
                         <th scope="col">Dep.</th>
                        <th scope="col">DESCRIÇÃO</th>
                        <th scope="col">ESTADO</th>
                        <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr >
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                                <router-link to="/RequisicaoDeVerbas" style="text-decoration: none; color: inherit;">
                                    <span title="Requisição & Termos" class="icon_relatorio"><i class="fa-solid fa-file"></i></span>
                                </router-link>
                                <span title="Eliminar" class="icon_delete"><i class="fas fa-trash-alt"></i></span>
                                <router-link to="/EditarContas" style="text-decoration: none; color: inherit;">
                                    <span title="Editar" class="icon_edit"><i class="fas fa-edit"></i></span>
                                </router-link>
                                 
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
export default {
    components: { NavbarAppHome, Sidebar }
}
</script>

<style>
.formulario_contas{
    position: absolute;
    width: 236px;
    height: 24px;
    left: 285px;
    top: 200px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #333333;
}
.dot_contas{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 5px;
    top: 1px;
    background-color: rgb(25, 226, 25);
    border-radius: 15px;
}
</style>