<template>
    <div>
      <div>
        <NavbarAppHome />
      </div>
      <div>
        <Sidebar />
      </div>
      <div class="cabecalho_configuracoes">
        <div class="titulo_configuracoes">
          <h1 class="conteudo_titulo">FORMULÁRIO EDITAR SALÁRIO FUNCIONÁRIOS LOCAL</h1>
        </div>
        </div>
            <div class="menu_configuracoes">
                <router-link to="/Receitas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_missao_diplomatica">
                        RECEITAS 
                    </div>
                </router-link>
                <router-link to="/Despesas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_orgaos">
                        DESPESAS
                    </div>
                </router-link>
                <router-link to="/Transferencias" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_contas">
                        TRANSFERÊNCIAS
                    </div>
                </router-link>
                <router-link to="/Salario" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_salarios">
                        SALÁRIOS
                    </div>
                </router-link>
            </div>
      <div class="form_container">
        <h1 class="titulo_formulario">EDITAR SALÁRIO FUNCIONÁRIOS LOCAL</h1>
        <router-link
          to="/TabelaSalarioPessoalLocal"
          style="text-decoration: none; color: inherit"
        >
          <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
        </router-link>
        <form @submit.prevent="isEdit ? submitEdit() : handleSubmit">
            <label class="nome_funcionario">Afecto a</label>
          <select
            required
            v-model="nome_funcionario"
            class="input_nome_funcionario"
            @change="handleWorkerChange(nome_funcionario)"
          >
            <option disabled value="">Por favor escolha uma opção</option>
            <option
              v-for="funcionarios in funcionarioData"
              :key="funcionarios.id"
              :value="funcionarios.nome_funcionario"
            >
              {{ funcionarios.nome_funcionario }}
            </option>
          </select>
          <label class="tipo_funcao_funcionario">Categoria</label>
          <input
            required
            v-model="tipo_funcionario"
            class="input_tipo_funcionario"
            type="text"
          />
          <label class="data_nascimento_funcionario">Data de nascimento</label>
          <input
            required
            v-model="data_nascimento_funcionario"
            class="input_data_nascimento_funcionario"
            type="date"
          />
          <label class="dias_trabalho">Dias uteis de trabalho</label>
          <input
            required
            v-model="dias_trabalho"
            class="input_dias_trabalho"
            type="text"
          />
          <label class="dias_trabalho1">Qt. Dias de trabalho</label>
          <input
            required
            v-model="qt_dias_trabalho"
            class="input_dias_trabalho1"
            type="text"
          />
          <label class="horas_trabalho1">Horas extras</label>
          <input
            v-model="horas_trabalho"
            class="input_horas_trabalho1"
            type="text"
          />
          <label class="orgao_funcionario">Órgão</label>
          <select
            required
            v-model="orgao_funcionario"
            class="input_orgao_funcionario"
          >
            <option disabled value="">Por favor escolha uma opção</option>
            <option
              v-for="orgao in filteredDocuments"
              :key="orgao.id"
              :value="orgao.nome_orgao"
              v-show="orgao.sigfe_orgao"
            >
              {{ orgao.nome_orgao }}
            </option>
          </select>
          <label class="registar_salario">REGISTAR SALÁRIO</label>
          <label class="salario_base">Salário Base</label>
          <input
            required
            v-model="salario_base"
            class="input_salario_base"
            type="number"
            @click="clearInput('salario_base')"
            @focusout="handleKeyUp"
          />
          <input
              v-model="estado"
              type="hidden"
              class="input_estado_check"
              true-value="Activo"
              false-value="Inactivo"
            />
          <label class="irt_funcionario">IRT</label>
          <input
            v-model="irt_funcionario"
            class="input_irt_funcionario"
            type="number"
            @click="clearInput('irt_funcionario')"
            @focusout="floatValue"
          />
          <label class="suplemento_salario">Suplemento do Salário</label>
          <input
            required
            v-model="suplemento_salario"
            class="input_suplemento_salario"
            type="number"
            @click="clearInput('suplemento_salario')"
            @focusout="suplementoValue"
          />
          <label class="s_social_funcionario">S.Social</label>
          <input
            required
            v-model="s_social_funcionario"
            class="input_s_social_funcionario"
            type="number"
            @click="clearInput('s_social_funcionario')"
            @focusout="suplementoValue"
          />
          <label class="salario_iliquido">Salário Ilíquido</label>
          <div class="input_salario_iliquido">{{ salario_iliquido }}</div>
          <label class="vencimento_funcionario">Salário Líquido</label>
          <div class="input_vencimento_funcionario">
            {{ vencimento_funcionario }}
          </div>
          <label class="outros_funcionario">Outros</label>
          <input
            v-model="outros_funcionario"
            class="input_outros_funcionario"
            type="number"
            @click="clearInput('outros_funcionario')"
            @focusout="outeos_float"
          />
          <label class="total_descontos_funcionario"
            >TOTAL DOS DESCONTOS =
          </label>
          <div class="input_total_descontos_funcionario">
            {{ total_descontos_funcionario }}
          </div>
          <label class="contribuicao_funcionario"
            >Contribuição do empregador
          </label>
          <input
            v-model="contribuicao_funcionario"
            class="input_contribuicao_funcionario"
            type="number"
            @click="clearInput('contribuicao_funcionario')"
            @focusout="outeos_float"
          />
          <label class="fonte_recurso_funcionario">Fonte de Recurso</label>
          <select
            required
            v-model="fonte_recurso_funcionario"
            class="input_fonte_recurso_funcionario"
          >
            <option disabled value="">Por favor escolha uma opção</option>
            <option value="ROT">Recursos ordinarios do tesouro</option>
            <option value="RP">Recursos próprios</option>
          </select>
          <label class="moeda_funcionario">Moeda</label>
          <select
            required
            v-model="moeda_funcionario"
            class="input_moeda_funcionario"
          >
            <option disabled value="">Por favor escolha uma opção</option>
            <option
              v-for="moe in moedaDatas"
              :key="moe.id"
              :value="moe.moeda_local"
            >
              {{ moe.moeda_local }}
            </option>
            <option
              v-for="moe in moedaDatas"
              :key="moe.id"
              :value="moe.moeda_gestao"
            >
              {{ moe.moeda_gestao }}
            </option>
            <option
              v-for="moe in moedaDatas"
              :key="moe.id"
              :value="moe.outra_moeda"
            >
              {{ moe.outra_moeda }}
            </option>
          </select>  
          <!-- <select
            required
            v-model="moeda_funcionario"
            class="input_moeda_funcionario"
          >
            <option disabled value="">Por favor escolha uma opção</option>
            <option v-for="moe in moeda" :key="moe.id" :value="moe.moeda_local">
              {{ moe.moeda_local }}
            </option>
            <option v-for="moe in moeda" :key="moe.id" :value="moe.moeda_gestao">
              {{ moe.moeda_gestao }}
            </option>
            <option v-for="moe in moeda" :key="moe.id" :value="moe.outra_moeda">
              {{ moe.outra_moeda }}
            </option>
            <option value="USD">USD</option>
            <option value="Euro">Euro</option>
            <option value="KZ">KZ</option>
            <option value="Yen">Yen</option>
            <option value="Libra">Libra</option>
          </select> -->
          <label class="data_funcionario">Data </label>
          <input
            required
            v-model="data_funcionario"
            class="input_data_funcionario"
            type="date"
          />
          <label class="cambio_mg_funcionario">Câmbio USD</label>
          <input
            required
            v-model="cambio_mg_funcionario"
            class="input_cambio_mg_funcionario"
            type="number"
            @click="clearInput('cambio_mg_funcionario')"
            @focusout="cambio_float"
          />
          <label class="cambio_kz_funcionario">Câmbio Kz</label>
          <input
            v-model="cambio_kz_funcionario"
            class="input_cambio_kz_funcionario"
            type="number"
            @click="clearInput('cambio_kz_funcionario')"
            @focusout="cambio_float"
          />
          <label class="observacoes_funcionario">Observações</label>
          <textarea
            v-model="observacoes_funcionario"
            class="input_observacoes_funcionario"
            rows="4"
            cols="50"
          ></textarea>
          <!-- <label class="input_file_data_lable">File</label>
          <input @change="handleFileChange" class="input_file_data" type="file" /> -->
          <button class="salvar_funcionario" @click.prevent="submitForm">SALVAR</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, watch, computed, reactive } from "vue";
  import NavbarAppHome from "../components/NavbarAppHome.vue";
  import Sidebar from "../components/Sidebar.vue";
  import useCollection from "../composables/useCollection";
  import getCollection from "../composables/getCollection";
  import getDocument from "../composables/getDocument";
  import getUser from "../composables/getUser";
  import { useRoute, useRouter } from "vue-router";
  import { projectFirestore } from "../firebase/config";
  import { onMounted } from "vue";
  import ConfiguracaoNav from '../components/ConfiguracaoNav.vue'
  import ListaMoeda from '../components/ListaMoeda.vue'
  export default {
    components: { NavbarAppHome, Sidebar,ConfiguracaoNav,ListaMoeda },
  
    setup() {
      // const { addDoc, error } = useCollection("funcionario");
      const { addDoc, error } = useCollection("salariofuncionariolocal");
      const { error2, documents } = getCollection("orgaos");
      const { error2: error_moeda, documents: moeda } = getCollection("moeda");
      const { error3 : error_funcionario, documents: funcionarios } = getCollection('funcionariolocal');
      const { user } = getUser();
      const route = useRoute()
      const router = useRouter();
      const filteredDocuments = ref([]);
      const moedaDatas = ref([]);
      const funcionarioData = ref([]);
      const orgaoDocuments = ref([])
      const estado = ref(true);
      const user_update_id = ref("");
      const user_id = user.value.uid;
      const nome_funcionario = ref("");
      const tipo_funcionario = ref("");
      const data_nascimento_funcionario = ref("");
      const dias_trabalho = ref("");
      const qt_dias_trabalho = ref("");
      const horas_trabalho = ref("");
      const orgao_funcionario = ref("");
      const salario_base = ref("0.00");
      const suplemento_salario = ref("0.00");
      const salario_iliquido = computed(() =>{
        const data = parseFloat(salario_base.value) + parseFloat(suplemento_salario.value)
        return data.toFixed(2)
      });
      const handleInputChange = (inputElement, precision) => {
        inputElement.value = parseFloat(inputElement.value).toFixed(precision);
      }
      const handleKeyUp = ()=> {
        handleInputChange(salario_base, 2);
      }
      const floatValue = () => {
        handleInputChange(irt_funcionario, 2);
      }
      const suplementoValue =()=> {
        handleInputChange(suplemento_salario, 2);
      }
      const outros_float = () =>{
        handleInputChange(outros_funcionario,2)
      }
      const cambio_float =() => {
        handleInputChange(cambio_kz_funcionario,2)
      }
      const irt_funcionario = ref("0.00");
      const cativos_funcionario = ref(0);
      const abono_familiar_funcionario = ref(0);
      const outros_funcionario = ref("0.00");
      const perc1 = 3 / 100;
      const perc2 = 8 / 100;
      const total_descontos_funcionario = computed(() =>{
        const data = parseFloat(irt_funcionario.value) +
          parseFloat(s_social_funcionario.value) +
          parseFloat(cativos_funcionario.value) +
          parseFloat(outros_funcionario.value)
          return data.toFixed(2);
      });
      const s_social_funcionario =  ref("0.00");
      const vencimento_funcionario = computed(() =>{
  
       const data =  parseFloat(salario_iliquido.value) +
        parseFloat(abono_familiar_funcionario.value) -
        parseFloat(total_descontos_funcionario.value)
        return data.toFixed(2);
      });
      const contribuicao_funcionario =  ref("0.00");
      const fonte_recurso_funcionario = ref("");
      const moeda_funcionario = ref("");
      const data_funcionario = ref("");
      const cambio_mg_funcionario = ref("0.00");
      const cambio_kz_funcionario = ref("0.00");
      const observacoes_funcionario = ref("");
      const isEditingId = computed(() => route.params.id);
        const docRef = getCollection("salariofuncionariolocal");
        if (docRef.value) {
        filteredDocuments.value = docRef.value.filter(
            isEditingId.value === user.value.uid
        );
        data.value = filteredDocuments.value;
        }

        const isEdit = ref(false);
        isEditingId.value = false;
        isEdit.value = false;
        
        const fetchCollectionData = async () => {
            try {
                const collectionRef = projectFirestore.collection("salariofuncionariolocal");
                const snapshot = await collectionRef.get();
                const dataaaa = snapshot.docs.map((doc) => {
                return { ...doc.data(), id: doc.id };
                });
                const filtered = dataaaa.filter((dataaaa1) => dataaaa1.id === isEditingId.value);
                filtered.forEach((data1) => {
                    nome_funcionario.value = data1.nome_funcionario;
                    tipo_funcionario.value = data1.tipo_funcionario;
                    data_nascimento_funcionario.value = data1.data_nascimento_funcionario;
                    dias_trabalho.value = data1.dias_trabalho;
                    qt_dias_trabalho.value = data1.qt_dias_trabalho;
                    horas_trabalho.value = data1.horas_trabalho;
                    orgao_funcionario.value = data1.orgao_funcionario;
                    salario_base.value = data1.salario_base;
                    irt_funcionario.value = data1.irt_funcionario;
                    suplemento_salario.value = data1.suplemento_salario;
                    s_social_funcionario.value = data1.s_social_funcionario;
                    salario_iliquido.value = data1.salario_iliquido;
                    cativos_funcionario.value = data1.cativos_funcionario;
                    vencimento_funcionario.value = data1.vencimento_funcionario;
                    outros_funcionario.value =  data1.outros_funcionario;
                    total_descontos_funcionario.value = data1.total_descontos_funcionario;
                    contribuicao_funcionario.value = data1.contribuicao_funcionario;
                    abono_familiar_funcionario.value = data1.abono_familiar_funcionario;
                    fonte_recurso_funcionario.value = data1.fonte_recurso_funcionario;
                    moeda_funcionario.value = data1.moeda_funcionario;
                    data_funcionario.value = data1.data_funcionario;
                    cambio_mg_funcionario.value = data1.cambio_mg_funcionario;
                    cambio_kz_funcionario.value = data1.cambio_kz_funcionario;
                    observacoes_funcionario.value = data1.observacoes_funcionario;
                    user_update_id.value = data1.id;
                });
            } catch (error) {
                console.error("Error fetching collection data:", error);
            }
        };

        onMounted(fetchCollectionData);
        watch(user, () => {
            if (!user.value) {
                router.push({ name: "Login" });
            }
        });
  
    const filterDocuments = () => {
        if (documents.value) {
          filteredDocuments.value = documents.value.filter(
            (doc) => {
             return doc.userId === user.value.uid
            }
          );
        }
      };
      filterDocuments();
  
      watch([documents, user], () => {
        filterDocuments();
      });
  
      watch([funcionarios, user], () => {
        FuncionarioFilter();
      });
      watch(user, () => {
        if (!user.value) {
          router.push({ name: "Login" });
        }
      });

      const FuncionarioFilter = () => {
        if (funcionarios.value) {
            funcionarioData.value = funcionarios.value.filter(
            (doc) => {
             return doc.userId === user.value.uid
            }
          );
        }
      };
      FuncionarioFilter();
  
      watch([funcionarios, user], () => {
        FuncionarioFilter();
      });
//=================================== moeda data =================================== 
      const MoedaFilter = () => {
        if (moeda.value) {
          moedaDatas.value = moeda.value.filter(
            (doc) => {
             return doc.userId === user.value.uid
            }
          );
        }
      };
      MoedaFilter();
  
      watch([moeda, user], () => {
        MoedaFilter();
      });

      const handleEdit = async (id) => {
        try {
            const docRef = projectFirestore.collection("salariofuncionariolocal").doc(id);
            await docRef.get().then(async (doc) => {
                if (doc.exists) {
                    let id = await doc.id;
                    new_obj.value = await { ...doc.data(), id };
                    nome_funcionario.value = new_obj.value.nome_funcionario;
                    tipo_funcionario.value = new_obj.value.tipo_funcionario;
                    data_nascimento_funcionario.value = new_obj.value.data_nascimento_funcionario;
                    dias_trabalho.value = new_obj.value.dias_trabalho;
                    qt_dias_trabalho.value = new_obj.value.qt_dias_trabalho;
                    horas_trabalho.value = new_obj.value.horas_trabalho;
                    orgao_funcionario.value = new_obj.value.orgao_funcionario;
                    salario_base.value = new_obj.value.salario_base;
                    irt_funcionario.value = new_obj.value.irt_funcionario;
                    suplemento_salario.value = new_obj.value.suplemento_salario;
                    s_social_funcionario.value = new_obj.value.s_social_funcionario;
                    salario_iliquido.value = new_obj.value.salario_iliquido;
                    cativos_funcionario.value = new_obj.value.cativos_funcionario;
                    vencimento_funcionario.value = new_obj.value.vencimento_funcionario;
                    outros_funcionario.value =  new_obj.value.outros_funcionario.value,
                    total_descontos_funcionario.value = new_obj.value.total_descontos_funcionario;
                    contribuicao_funcionario.value = new_obj.value.contribuicao_funcionario.value;
                    abono_familiar_funcionario.value = new_obj.value.abono_familiar_funcionario;
                    fonte_recurso_funcionario.value = new_obj.value.fonte_recurso_funcionario;
                    moeda_funcionario.value = new_obj.value.moeda_funcionario;
                    data_funcionario.value = new_obj.value.data_funcionario;
                    cambio_mg_funcionario.value = new_obj.value.cambio_mg_funcionario;
                    cambio_kz_funcionario.value = new_obj.value.cambio_kz_funcionario;
                    observacoes_funcionario.value = new_obj.value.observacoes_funcionario;
                }
            });
            isEdit.value = true;
            isEditingId.value = id;
        } catch (error) {
          console.log("Error deleting document:", error.message);
        }
    };
    const submitEdit = async () => {
        alert("submitEditsubmitEdit");
        const docRef = projectFirestore
          .collection("salariofuncionariolocal")
          .doc(isEditingId.value);
        await docRef.update({
            nome_funcionario: nome_funcionario.value,
            tipo_funcionario: tipo_funcionario.value,
            data_nascimento_funcionario: data_nascimento_funcionario.value,
            dias_trabalho: dias_trabalho.value,
            qt_dias_trabalho: qt_dias_trabalho.value,
            horas_trabalho: horas_trabalho.value,
            orgao_funcionario: orgao_funcionario.value,
            salario_base: salario_base.value,
            irt_funcionario: irt_funcionario.value,
            suplemento_salario: suplemento_salario.value,
            s_social_funcionario: s_social_funcionario.value,
            salario_iliquido: salario_iliquido.value,
            cativos_funcionario: cativos_funcionario.value,
            vencimento_funcionario: vencimento_funcionario.value,
            outros_funcionario: outros_funcionario.value,
            total_descontos_funcionario: total_descontos_funcionario.value,
            contribuicao_funcionario: contribuicao_funcionario.value,
            abono_familiar_funcionario: abono_familiar_funcionario.value,
            fonte_recurso_funcionario: fonte_recurso_funcionario.value,
            moeda_funcionario: moeda_funcionario.value,
            data_funcionario: data_funcionario.value,
            cambio_mg_funcionario: cambio_mg_funcionario.value,
            cambio_kz_funcionario: cambio_kz_funcionario.value,
            observacoes_funcionario: observacoes_funcionario.value,
            userId: user.value.uid,
        });
        isEdit.value = false;
        nome_funcionario.value = null;
        tipo_funcionario.value = null;
        data_nascimento_funcionario.value = null;
        dias_trabalho.value = null;
        qt_dias_trabalho.value = null;
        horas_trabalho.value = null;
        orgao_funcionario.value = null;
        salario_base.value = null;
        irt_funcionario.value = null;
        suplemento_salario.value = null;
        s_social_funcionario.value = null;
        salario_iliquido.value = null;
        cativos_funcionario.value = null;
        vencimento_funcionario.value = null;
        outros_funcionario.value = null;
        total_descontos_funcionario.value = null;
        contribuicao_funcionario.value = null;
        abono_familiar_funcionario.value = null;
        fonte_recurso_funcionario.value = null;
        moeda_funcionario.value = null;
        data_funcionario.value = null;
        cambio_mg_funcionario.value = null;
        cambio_kz_funcionario.value = null;
        observacoes_funcionario.value = null;
        estado.value =true;
      };
      // const handleFileChange = (event) => {
      //   loader.value = true;
      //   const file = event.target.files[0];
      //   const storageRef = storage.ref();
      //   const fileRef = storageRef.child(file.name);
      //   fileRef
      //     .put(file)
      //     .then((snapshot) => {
      //       fileRef
      //         .getDownloadURL()
      //         .then((url) => {
      //           file_input.value = url;
      //           console.log("url", url);
      //           loader.value = false;
      //         })
      //         .catch((error) => {
      //           loader.value = false;
      //           console.error("Error getting download URL:", error);
      //         });
      //     })
      //     .catch((error) => {
      //       loader.value = false;
      //       console.error("Error uploading file:", error);
      //     });
      // };
  
      const submitForm = async () => {
        try {
            const docRef = projectFirestore
                .collection("salariofuncionariolocal")
                .doc(user_update_id.value);
            await docRef.update({
                nome_funcionario: nome_funcionario.value,
                tipo_funcionario: tipo_funcionario.value,
                data_nascimento_funcionario: data_nascimento_funcionario.value,
                dias_trabalho: dias_trabalho.value,
                qt_dias_trabalho: qt_dias_trabalho.value,
                horas_trabalho: horas_trabalho.value,
                orgao_funcionario: orgao_funcionario.value,
                salario_base: salario_base.value,
                irt_funcionario: irt_funcionario.value,
                suplemento_salario: suplemento_salario.value,
                s_social_funcionario: s_social_funcionario.value,
                salario_iliquido: salario_iliquido.value,
                cativos_funcionario: cativos_funcionario.value,
                vencimento_funcionario: vencimento_funcionario.value,
                outros_funcionario: outros_funcionario.value,
                total_descontos_funcionario: total_descontos_funcionario.value,
                contribuicao_funcionario: contribuicao_funcionario.value,
                abono_familiar_funcionario: abono_familiar_funcionario.value,
                fonte_recurso_funcionario: fonte_recurso_funcionario.value,
                moeda_funcionario: moeda_funcionario.value,
                data_funcionario: data_funcionario.value,
                cambio_mg_funcionario: cambio_mg_funcionario.value,
                cambio_kz_funcionario: cambio_kz_funcionario.value,
                observacoes_funcionario: observacoes_funcionario.value,
            });
    
            router.push(`/TabelaSalarioPessoalLocal`);
    
            alert("updated successfully");
            } catch (err) {
            alert("updated ====");
            console.log("err==>", err);
            }
      };

      // Define a method to clear input fields based on field name
    const clearInput = (fieldName) => {
      switch (fieldName) {
        case 'salario_base':
          salario_base.value = '';
          break;
        case 'irt_funcionario':
          irt_funcionario.value = '';
          break;
        case 'suplemento_salario':
          suplemento_salario.value = '';  
          break;
        case 's_social_funcionario':
          s_social_funcionario.value = '';
          break;
        case 'salario_iliquido':
          salario_iliquido.value = '';
          break;
        case 'cativos_funcionario':
          cativos_funcionario.value = '';
          break;
        case 'vencimento_funcionario':
          vencimento_funcionario.value = '';
          break;
        case 'outros_funcionario':
          outros_funcionario.value = '';
          break;
        case 'total_descontos_funcionario':
          total_descontos_funcionario.value = '';
          break;
        case 'contribuicao_funcionario':
          contribuicao_funcionario.value = '';
          break;
        case 'abono_familiar_funcionario':
          abono_familiar_funcionario.value = '';
          break;
        case 'cambio_mg_funcionario':
          cambio_mg_funcionario.value = '';
          break;
        case 'cambio_kz_funcionario': 
          cambio_kz_funcionario.value = '';
          break;
        // Add cases for other fields if needed
        default:
          break;
      }
    };
    const handleWorkerChange = (selectedWorker) => {
      // Fetch worker details from 'funcionariocentral' based on selected worker
      const selectedWorkerDetails = funcionarios.value.find(
        (worker) => worker.nome_funcionario === selectedWorker
      );

      if (selectedWorkerDetails) {
        // Update fields with fetched worker details
        tipo_funcionario.value = selectedWorkerDetails.tipo_funcionario;
        data_nascimento_funcionario.value = selectedWorkerDetails.data_nascimento_funcionario;
        orgao_funcionario.value = selectedWorkerDetails.orgao_funcionario;
        // Update other fields as needed...
      }
    };
  
      return {
        estado,
        handleEdit,
        submitEdit,
        isEdit,
        isEditingId,
        cambio_float,
        handleKeyUp,
        floatValue,
        suplementoValue,
        outros_float,
        nome_funcionario,
        tipo_funcionario,
        data_nascimento_funcionario,
        dias_trabalho,
        qt_dias_trabalho,
        horas_trabalho,
        orgao_funcionario,
        salario_base,
        irt_funcionario,
        suplemento_salario,
        s_social_funcionario,
        salario_iliquido,
        cativos_funcionario,
        vencimento_funcionario,
        outros_funcionario,
        total_descontos_funcionario,
        contribuicao_funcionario,
        abono_familiar_funcionario,
        fonte_recurso_funcionario,
        moeda_funcionario,
        data_funcionario,
        cambio_mg_funcionario,
        cambio_kz_funcionario,
        observacoes_funcionario,
        error,
        error2,
        submitForm,
        documents,
        moeda,
        filteredDocuments,
        funcionarioData,
        funcionarios,
        moedaDatas,
        clearInput,
        handleWorkerChange,
      };
    },
  };
  </script>
  
  <style scoped>
  .cabecalho_configuracoes {
    width: 100%;
    left: 180px;
  }
  .titulo_configuracoes {
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344c;
  }
  .conteudo_titulo {
    position: absolute;
    width: 1271px;
    height: 20px;
    left: 0%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
  }
  .menu_configuracoes {
    width: 100%;
  }
  
  .btn_missao_diplomatica {
    position: absolute;
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #ffffff;
    border: 3px solid #51b8d5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #51b8d5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    vertical-align: middle;
  }
  .btn_missao_diplomatica:hover {
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #00344c;
    border: 8px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
  }
  .missao_diplomatica {
    position: absolute;
    width: 215px;
    height: 46px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51b8d5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  
  .missao_diplomatica:hover {
    color: white;
  }
  
  .btn_orgaos {
    position: absolute;
    width: 216px;
    height: 84px;
    left: 485px;
    top: 160px;
    background: #ffffff;
    border: 3px solid #51b8d5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
    vertical-align: middle;
  }
  
  .btn_orgaos:hover {
    background: #00344c;
    color: white;
    background: #00344c;
    border: 8px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .orgaos {
    position: absolute;
    width: 209px;
    height: 20px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  
  .orgaos:hover {
    color: white;
  }
  
  .btn_contas {
    position: absolute;
    width: 214px;
    height: 84px;
    left: 717px;
    top: 160px;
    background: #ffffff;
    border: 3px solid #51b8d5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  
  .btn_contas:hover {
    background: #00344c;
    color: white;
    background: #00344c;
    border: 8px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .contas {
    position: absolute;
    width: 198px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  
  .contas:hover {
    color: white;
  }
  
  .btn_funcionarios_empresas {
    position: absolute;
    width: 217px;
    height: 84px;
    left: 945px;
    top: 160px;
    background: #ffffff;
    border: 3px solid #51b8d5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  
  .btn_funcionarios_empresas:hover {
    background: #00344c;
    color: white;
    background: #00344c;
    border: 8px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .funcionarios_empresas {
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  
  .funcionarios_empresas:hover {
    color: white;
  }
  
  .btn_naturezas {
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1176px;
    top: 160px;
    background: #ffffff;
    border: 3px solid #51b8d5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  
  .btn_naturezas:hover {
    background: #00344c;
    color: white;
    background: #00344c;
    border: 8px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .naturezas {
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  
  .naturezas:hover {
    color: white;
  }
  
  .form_container {
    box-sizing: border-box;
    position: absolute;
    width: 908px;
    height: 1200px;
    left: 266px;
    top: 270px;
    background: #ffffff;
    border: 2px solid #51b8d5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .titulo_formulario {
    position: absolute;
    width: 907px;
    height: 20px;
    left: 1px;
    top: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4e4e4e;
  }
  
  .icon_back {
    position: absolute;
    width: 5px;
    height: 8px;
    left: 800px;
    top: 40px;
  }
  
  .nome_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: -150px;
    top: 70px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  
  .tipo_funcao_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 355px;
    top: 70px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_nome_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 444px;
    height: 30px;
    left: 40px;
    top: 95px;
    font-family: "Inter";
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  .input_tipo_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 95px;
    font-family: "Inter";
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .data_nascimento_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: -115px;
    top: 140px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_data_nascimento_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 165px;
    font-family: "Inter";
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .dias_trabalho {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 395px;
    top: 140px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_dias_trabalho {
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 165px;
    font-family: "Inter";
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .dias_trabalho1 {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 390px;
    top: 200px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }

  .input_dias_trabalho1 {
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 225px;
    font-family: "Inter";
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .horas_trabalho1 {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 368px;
    top: 260px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }

  .input_horas_trabalho1 {
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 285px;
    font-family: "Inter";
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .orgao_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: -160px;
    top: 205px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_orgao_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 230px;
    font-family: "Inter";
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .referencia_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 342px;
    top: 205px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_referencia_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 230px;
    font-family: "Inter";
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .registar_salario {
    position: absolute;
    width: 908px;
    height: 20px;
    left: 0px;
    top: 330px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #51b8d5;
  }
  .salario_base {
    position: absolute;
    width: 330px;
    height: 32px;
    left: -80px;
    top: 360px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_salario_base {
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 385px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .irt_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 350px;
    top: 387px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_irt_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 30px;
    left: 600px;
    top: 385px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  .suplemento_salario {
    position: absolute;
    width: 330px;
    height: 32px;
    left: -50px;
    top: 420px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_suplemento_salario {
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 445px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .s_social_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 365px;
    top: 445px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_s_social_funcionario {
    box-sizing: border-box;
    position: absolute;
    padding: 7px;
    width: 145px;
    height: 30px;
    left: 625px;
    top: 445px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .salario_iliquido {
    position: absolute;
    width: 330px;
    height: 32px;
    left: -75px;
    top: 480px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_salario_iliquido {
    box-sizing: border-box;
    position: absolute;
    padding: 7px;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 505px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .cativos_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 360px;
    top: 385px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_cativos_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 145px;
    height: 30px;
    left: 625px;
    top: 385px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .vencimento_funcionario {
    position: absolute;
    width: 330px;
    height: 32px;
    left: -78px;
    top: 538px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_vencimento_funcionario {
    box-sizing: border-box;
    position: absolute;
    padding: 7px;
    width: 230px;
    height: 30px;
    left: 40px;
    top: 560px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .outros_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 375px;
    top: 505px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_outros_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 130px;
    height: 30px;
    left: 640px;
    top: 505px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .total_descontos_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 315px;
    top: 560px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_total_descontos_funcionario {
    box-sizing: border-box;
    position: absolute;
    padding: 7px;
    width: 130px;
    height: 30px;
    left: 640px;
    top: 560px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .contribuicao_funcionario {
    position: absolute;
    width: 330px;
    height: 32px;
    left: -30px;
    top: 620px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_contribuicao_funcionario {
    box-sizing: border-box;
    position: absolute;
    padding: 7px;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 645px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .abono_familiar_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: -135px;
    top: 480px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_abono_familiar_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 230px;
    height: 30px;
    left: 40px;
    top: 505px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .fonte_recurso_funcionario {
    position: absolute;
    width: 330px;
    height: 32px;
    left: -65px;
    top: 680px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_fonte_recurso_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 705px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .moeda_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 340px;
    top: 620px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_moeda_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 230px;
    height: 30px;
    left: 540px;
    top: 645px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .data_funcionario {
    position: absolute;
    width: 330px;
    height: 32px;
    left: -105px;
    top: 740px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_data_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 765px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .cambio_mg_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 255px;
    top: 680px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_cambio_mg_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 30px;
    left: 400px;
    top: 705px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .cambio_kz_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 460px;
    top: 680px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_cambio_kz_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 30px;
    left: 600px;
    top: 705px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .observacoes_funcionario {
    position: absolute;
    width: 330px;
    height: 32px;
    left: -80px;
    top: 805px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_observacoes_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 730px;
    height: 100px;
    left: 40px;
    top: 825px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  .check_pessoal_central {
    position: absolute;
    width: 396px;
    height: 32px;
    left: -75px;
    top: 948px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #00344c;
  }
  .input_pessoal_central {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 40px;
    top: 950px;
    background: #9b4600;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
  }
  .check_pessoal_local {
    position: absolute;
    width: 396px;
    height: 32px;
    left: 80px;
    top: 948px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #00344c;
  }
  .input_pessoal_local {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 205px;
    top: 950px;
    background: #9b4600;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
  }
  
  .salvar_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 132px;
    height: 30px;
    left: 641px;
    top: 1000px;
    color: #fff;
    background: #51b8d5;
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .btn_moedas {
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1410px;
    top: 160px;
    background: #ffffff;
    border: 3px solid #51b8d5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  .btn_moedas:hover {
    background: #00344c;
    color: white;
    background: #00344c;
    border: 8px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .moedas {
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  .moedas:hover {
    color: white;
  }
  
  .input_file_data_lable {
    position: absolute;
    width: 444px;
    height: 32px;
    left: -150px;
    top: 935px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  
  .input_file_data {
    box-sizing: border-box;
    position: absolute;
    width: 444px;
    height: 30px;
    left: 40px;
    top: 960px;
    font-family: "Inter";
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .moeda111 {
    position: absolute;
    top: 416px;
  }
  </style>