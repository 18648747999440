<template>
    <form @submit.prevent="handleSubmit">
        <select required  class="input_moeda_conta">
            <option disabled value="">Por favor escolha uma opção</option>
            <option v-for="moeda in documents" :key="moeda.id" :value="moeda.moeda_gestao">{{ moeda.moeda_gestao }}</option>
            <option v-for="moeda in documents" :key="moeda.id" :value="moeda.moeda_local">{{ moeda.moeda_local }}</option>
            <option v-for="moeda in documents" :key="moeda.id" :value="moeda.outra_moeda">{{ moeda.outra_moeda }}</option>
        </select>
    </form>
</template>

<script>
import { ref, watch, computed, reactive } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getDocument from '../composables/getDocument'
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'
export default {
    components: { NavbarAppHome, Sidebar },

    setup() {
        const { error2, documents } = getCollection('moeda')

        const { user } = getUser()
        const router = useRouter()

        watch(user, () => {
            if  (!user.value) {
                router.push({ name: 'Login'})
            }
        })

       
        return { error2, documents }
    }
    
}
</script>

<style scoped>
.input_moeda_conta{
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 230px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>