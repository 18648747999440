<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">FORMULÁRIO EMPRESAS</h1>
            </div>
            <div class="menu_configuracoes">
                <router-link to="/MissaoDiplomatica" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_missao_diplomatica">
                        MISSÃO<br> DIPLOMATICA 
                    </div>
                </router-link>
                <router-link to="/Orgaos" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_orgaos">
                        ORGÃOS
                    </div>
                </router-link>
                <router-link to="/Moedas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_moedas">
                        MOEDAS
                    </div>
                </router-link>
                <router-link to="/Cambio" style="text-decoration: none; color: inherit;"> 
                    <div class="cambio">
                        CÂMBIO
                    </div>
                </router-link>
                <router-link to="/Contas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_contas">
                        CONTAS
                    </div>
                </router-link>
                <router-link to="/FuncionariosEmpresas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_funcionarios_empresas">
                        FUNCIONÁRIOS E<br> EMPRESAS
                    </div>
                </router-link>
                <router-link to="/Naturezas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_naturezas">
                        NATUREZAS
                    </div>
                </router-link>
            </div>
        </div>
        <div class="form_container">
            <h1 class="titulo_formulario">REGISTAR EMPRESAS</h1>
            <router-link to="/FuncionariosEmpresas" style="text-decoration: none; color: inherit;">
                <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
            </router-link>
            <form @submit.prevent="handleSubmit">
                <label class="nome_empresa">Nome</label>
                <input required v-model="nome_empresa" class="input_nome_empresa" type="text">
                <label class="tipo_actividades_empresas">Contribuinte</label>
                <input required v-model="tipo_actividades_empresas" class="input_actividades_empresas" type="text">
                <label class="data_registro_empresas">Data de Registro</label>
                <input required v-model="data_registro_empresas" class="input_data_registro_empresas" type="date">
                <button class="salvar_funcionario">SALVAR</button>

            </form>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'
export default {
    components: { NavbarAppHome, Sidebar },

    setup() {
        const { addDoc, error } = useCollection('empresa')
        const { error2, documents } = getCollection('empresa')

        const { user } = getUser()
        const router = useRouter()

        watch(user, () => {
            if  (!user.value) {
                router.push({ name: 'Login'})
            }
        })

        const nome_empresa = ref('')
        const tipo_actividades_empresas = ref('')
        const data_registro_empresas = ref('')
        const referencia_empresa = ref('')
        const observacoes_empresas = ref('')

        const handleSubmit = async () => {
            const empresa = {
                nome_empresa: nome_empresa.value,
                tipo_actividades_empresas: tipo_actividades_empresas.value,
                referencia_empresa: referencia_empresa.value,
                data_registro_empresas: data_registro_empresas.value,
                observacoes_empresas: observacoes_empresas.value,
                userId: user.value.uid

            }
            await addDoc(empresa)
            if (!error.value) {
                nome_empresa.value = ''
                tipo_actividades_empresas.value = ''
                referencia_empresa.value = ''
                data_registro_empresas.value = ''
                observacoes_empresas.value = ''
            }
            alert('DADOS INSERIDOS COM SUCESSO!')
        }
        return { nome_empresa, tipo_actividades_empresas, referencia_empresa, data_registro_empresas, 
                observacoes_empresas, error, handleSubmit, error2, documents }
    }
    
}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 100%;
    left: 180px;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: 0%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.menu_configuracoes{
    width: 100%;
}
.btn_missao_diplomatica{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    vertical-align: middle;
}
.btn_missao_diplomatica:hover {
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
}
.missao_diplomatica{
    position: absolute;
    width: 215px;
    height: 46px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.missao_diplomatica:hover {
  color: white;
}

.btn_orgaos{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 485px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
    vertical-align: middle;
}

.btn_orgaos:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.orgaos{
    position: absolute;
    width: 209px;
    height: 20px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.orgaos:hover {
  color: white;
}

.btn_contas{
    position: absolute;
    width: 214px;
    height: 84px;
    left: 1190px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.btn_contas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.contas{
    position: absolute;
    width: 198px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.contas:hover {
    color: white;
}
.btn_funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 1425px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_funcionarios_empresas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.cambio{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.cambio:hover {
    color: white;
}
.cambio{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 955px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.cambio:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.funcionarios_empresas:hover {
    color: white;
}

.btn_naturezas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1660px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_naturezas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.naturezas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.naturezas:hover {
    color: white;
}
.btn_moedas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 720px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.btn_moedas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.moedas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.moedas:hover {
    color: white;
}
.form_container{
    box-sizing: border-box;
    position: absolute;
    width: 908px;
    height: 500px;
    left: 266px;
    top: 270px;
    background: #FFFFFF;
    border: 2px solid #51B8D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.titulo_formulario{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 1px;
    top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
}

.icon_back{
    position: absolute;
    width: 5px;
    height: 8px;
    left: 800px;
    top: 40px;
}

.nome_empresa{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -160px;
    top: 70px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}

.tipo_actividades_empresas{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 365px;
    top: 70px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;

}
.input_nome_empresa{
    box-sizing: border-box;
    position: absolute;
    width: 444px;
    height: 30px;
    left: 40px;
    top: 95px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.input_actividades_empresas{
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 95px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.data_registro_empresas{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -125px;
    top: 140px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_data_registro_empresas{
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 165px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.referencia_funcionario{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 342px;
    top: 140px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_referencia_funcionario{
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 165px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.observacoes_empresas{
    position: absolute;
    width: 330px;
    height: 32px;
    left: -80px;
    top: 205px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_observacoes_empresas{
    box-sizing: border-box;
    position: absolute;
    width: 730px;
    height: 100px;
    left: 40px;
    top: 230px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.salvar_funcionario{
    box-sizing: border-box;
    position: absolute;
    width: 132px;
    height: 30px;
    left: 641px;
    top: 250px;
    color: #fff;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>