<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">SALÁRIOS</h1>
            </div>
            <div class="menu_configuracoes">
                <router-link to="/Receitas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_missao_diplomatica">
                        RECEITAS 
                    </div>
                </router-link>
                <router-link to="/Despesas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_orgaos">
                        DESPESAS
                    </div>
                </router-link>
                <router-link to="/Transferencias" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_contas">
                        TRANSFERÊNCIAS
                    </div>
                </router-link>
                <router-link to="/Salario" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_salarios">
                        SALÁRIOS
                    </div>
                </router-link>
            </div>
        </div>
        <div>
            <router-link to="/FormularioSalarioPessoalCentral" style="text-decoration: none; color: inherit;"> 
                <span class="formulario_funcionario"><span class="dot_funcionario"></span>Registar Salario Pessoal Central</span>
            </router-link>
            <router-link to="/FormularioSalarioPessoalLocal" style="text-decoration: none; color: inherit;"> 
                <span class="formulario_funcionario_local"><span class="dot_funcionario_local"></span>Registar Salario Pessoal Local</span>
            </router-link>
        </div>
        <div class="contas-list">
            <router-link to="/TabelaSalarioPessoalCentral" style="text-decoration: none; color: inherit;">
                <span class="menu_tabelas1">Tabela Salarios Pessoal Central</span>
            </router-link>
            <router-link to="/TabelaSalarioPessoalLocal" style="text-decoration: none; color: inherit;">
                <span class="menu_tabelas1local">Tabela Salarios Pessoal Local</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'
export default {
    components: { NavbarAppHome, Sidebar },

    setup() {
        const { addDoc, error } = useCollection('conta')
        const { error2, documents } = getCollection('transferencia')

        const { user } = getUser()
        const router = useRouter()

        watch(user, () => {
            if  (!user.value) {
                router.push({ name: 'Login'})
            }
        })
        const nome_funcionario = ref('')
        const tipo_funcionario = ref('')
        const data_nascimento_funcionario = ref('')
        const dias_trabalho = ref('')
        const orgao_funcionario = ref('')
        const referencia_funcionario = ref('')
        const salario_base = ref('')
        const irt_funcionario = ref('')
        const suplemento_salario = ref('')
        const s_social_funcionario = ref('')
        const salario_iliquido = ref('')
        const cativos_funcionario = ref('')
        const vencimento_funcionario = ref('')
        const outros_funcionario = ref('')
        const total_descontos_funcionario = ref('')
        const contribuicao_funcionario = ref('')
        const abono_familiar_funcionario = ref('')
        const fonte_recurso_funcionario = ref('')
        const moeda_funcionario = ref('')
        const data_funcionario = ref('')
        const cambio_mg_funcionario = ref('')
        const cambio_kz_funcionario = ref('')
        const observacoes_funcionario = ref('')
        const check_pessoal_central = ref('')
        const check_pessoal_local = ref('')

        const handleSubmit = async () => {
            const funcionario = {
                nome_funcionario: nome_funcionario.value,
                tipo_funcionario: tipo_funcionario.value,
                data_nascimento_funcionario: data_nascimento_funcionario.value,
                dias_trabalho: dias_trabalho.value,
                orgao_funcionario: orgao_funcionario.value,
                referencia_funcionario: referencia_funcionario.value,
                salario_base: salario_base.value,
                irt_funcionario: irt_funcionario.value,
                suplemento_salario: suplemento_salario.value,
                s_social_funcionario: suplemento_salario.value,
                salario_iliquido: salario_iliquido.value,
                cativos_funcionario: cativos_funcionario.value,
                vencimento_funcionario: vencimento_funcionario.value,
                outros_funcionario: outros_funcionario.value,
                total_descontos_funcionario: total_descontos_funcionario.value,
                contribuicao_funcionario: contribuicao_funcionario.value,
                abono_familiar_funcionario: contribuicao_funcionario.value,
                fonte_recurso_funcionario: fonte_recurso_funcionario.value,
                moeda_funcionario: moeda_funcionario.value,
                data_funcionario: data_funcionario.value,
                cambio_mg_funcionario: cambio_mg_funcionario.value,
                cambio_kz_funcionario: cambio_kz_funcionario.value,
                observacoes_funcionario: observacoes_funcionario.value,
                check_pessoal_central: check_pessoal_central.value,
                check_pessoal_local: check_pessoal_local.value,
                userId: user.value.uid

            }
            await addDoc(funcionario)
            if (!error.value) {
                nome_funcionario.value = ''
                tipo_funcionario.value = ''
                data_nascimento_funcionario.value = ''
                dias_trabalho.value = ''
                orgao_funcionario.value = ''
                referencia_funcionario.value = ''
                salario_base.value = ''
                irt_funcionario.value = ''
                suplemento_salario.value = ''
                s_social_funcionario.value = ''
                salario_iliquido.value = ''
                cativos_funcionario.value = ''
                vencimento_funcionario.value = ''
                outros_funcionario.value = ''
                total_descontos_funcionario.value = ''
                contribuicao_funcionario.value = ''
                abono_familiar_funcionario.value = ''
                fonte_recurso_funcionario.value = ''
                moeda_funcionario.value = ''
                data_funcionario.value = ''
                cambio_mg_funcionario.value = ''
                cambio_kz_funcionario.value = ''
                observacoes_funcionario.value = ''
                check_pessoal_central.value = ''
                check_pessoal_local.value = ''
            }
            const empresa = {
                nome_empresa: nome_empresa.value,
                tipo_actividades_empresas: tipo_actividades_empresas.value,
                referencia_empresa: referencia_empresa.value,
                data_registro_empresas: data_registro_empresas.value,
                observacoes_empresas: observacoes_empresas.value,
                userId: user.value.uid

            }
            await addDoc(empresa)
            if (!error.value) {
                nome_empresa.value = ''
                tipo_actividades_empresas.value = ''
                referencia_empresa.value = ''
                data_registro_empresas.value = ''
                observacoes_empresas.value = ''
            }
            const contrato = {
                beneficiario_contrato: beneficiario_contrato.value,
                departamento_contrato: departamento_contrato.value,
                contrato_afecto: contrato_afecto.value,
                tipo_contrato: tipo_contrato.value,
                descricoes_contrato: descricoes_contrato.value,
                valor_mensal_contrato: valor_mensal_contrato.value,
                moeda_contrato: moeda_contrato.value,
                forma_cobranca_contrato: forma_cobranca_contrato.value,
                tipo_valor_contrato: tipo_valor_contrato.value,
                caucao_contrato: caucao_contrato.value,
                data_inicio_contrato: data_inicio_contrato.value,
                data_termino_contrato: data_termino_contrato.value,
                observacoes_contrato: observacoes_contrato.value,
                userId: user.value.uid

            }
            await addDoc(contrato)
            if (!error.value) {
                beneficiario_contrato.value = ''
                departamento_contrato.value = ''
                contrato_afecto.value = ''
                tipo_contrato.value = ''
                descricoes_contrato.value = ''
                valor_mensal_contrato.value = ''
                moeda_contrato.value = ''
                forma_cobranca_contrato.value = ''
                tipo_valor_contrato.value = ''
                caucao_contrato.value = ''
                data_inicio_contrato.value = ''
                data_termino_contrato.value = ''
                observacoes_contrato.value = ''
            }
        }
        const nome_empresa = ref('')
        const tipo_actividades_empresas = ref('')
        const data_registro_empresas= ref('')
        const observacoes_empresas = ref('')
        const referencia_empresa = ref('')

        const beneficiario_contrato = ref('')
        const departamento_contrato = ref('')
        const contrato_afecto = ref('')
        const tipo_contrato = ref('')
        const descricoes_contrato = ref('')
        const valor_mensal_contrato = ref('')
        const moeda_contrato = ref('')
        const forma_cobranca_contrato = ref('')
        const tipo_valor_contrato = ref('')
        const caucao_contrato = ref('')
        const data_inicio_contrato = ref('')
        const data_termino_contrato = ref('')
        const observacoes_contrato = ref('')
        

        return { nome_funcionario, tipo_funcionario, data_nascimento_funcionario, dias_trabalho,
                orgao_funcionario, salario_base, irt_funcionario, suplemento_salario, s_social_funcionario,
                salario_iliquido, cativos_funcionario, vencimento_funcionario, outros_funcionario,
                total_descontos_funcionario, contribuicao_funcionario, abono_familiar_funcionario,
                fonte_recurso_funcionario, moeda_funcionario, data_funcionario, cambio_mg_funcionario,
                cambio_kz_funcionario, observacoes_funcionario, check_pessoal_central, check_pessoal_local,
                nome_empresa, tipo_actividades_empresas, data_registro_empresas, referencia_funcionario,
                observacoes_empresas, beneficiario_contrato, departamento_contrato, contrato_afecto,
                tipo_contrato, descricoes_contrato, valor_mensal_contrato, referencia_empresa,
                moeda_contrato, forma_cobranca_contrato, tipo_valor_contrato,
                caucao_contrato, data_inicio_contrato, data_termino_contrato,
                observacoes_contrato, error, handleSubmit, error2, documents}
    }
    
}
</script>

<style scoped>
.formulario_funcionario{
    position: absolute;
    width: 236px;
    height: 24px;
    left: 265px;
    top: 290px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #333333;
}

.dot_funcionario{
    position: absolute;
    width: 24px;
    height: 24px;
    left: -15px;
    top: 1px;
    background-color: rgb(25, 226, 25);
    border-radius: 15px;
}

.dot_funcionario_local{
    position: absolute;
    width: 24px;
    height: 24px;
    left: -20px;
    top: 1px;
    background-color: rgb(25, 226, 25);
    border-radius: 15px;
}

.formulario_funcionario_local{
    position: absolute;
    width: 236px;
    height: 24px;
    left: 540px;
    top: 290px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #333333;
}
.contas-list{
    position: absolute;
    width: 980px;
    height: auto;
    left: 231px;
    top: 400px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
    border: 1px solid rgb(198, 192, 192);
}
.dados_contas{
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    top: 10px;
    margin: 20px 10px 10px 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
}
.table_contas{
    position: absolute;
    top: 60px;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

/* general styling */
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.25;
}

.icon_delete{
    color:red;
    padding: 10px;
    cursor: pointer;
}

.icon_edit{
    color: rgb(31, 227, 31);
    cursor: pointer;
}
.menu_tabelas{
    padding: 20px;
    color: black;
    margin-bottom: 10px;
}

.btn_salarios{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 950px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.btn_salarios:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.menu_tabelas1{
    position: absolute;
    padding: 20px;
    color: black;
    margin-bottom: 10px;
    left:0px;
}

.menu_tabelas1local{
    position: absolute;
    padding: 20px;
    color: black;
    margin-bottom: 10px;
    left:250px;
}
.menu_tabelas1:hover {
    background: #00344C;
    color: white;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.menu_tabelas1local:hover {
    background: #00344C;
    color: white;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
</style>