<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">CONTROL DOCUMENTAL</h1>
            </div>
        </div>
        <div class="form_container">
            <h1 class="titulo_formulario">ADICIONAR DOCUMENTOS</h1>
            <router-link to="/GestaoDocumental" style="text-decoration: none; color: inherit;">
                <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
            </router-link>
            <form @submit.prevent="handleSubmit">
                <label class="moeda_cambio">Nº</label>
                <input v-model="moeda_cambio" class="input_moeda_cambio" type="text">
                <label class="cambio">Nome</label>
                <input v-model="cambio_dados" class="input_cambio" type="text">
                <label class="data_operacao_cambio">Data de Recepção</label>
                <input v-model="data_operacao_cambio" class="input_operacao_cambio" type="text">
                <label class="orgao_documental">Orgão</label>
                <select required v-model="orgao_documental" class="input_orgao_documental">
                    <option disabled value="">Por favor escolha uma opção</option>
                    <option v-for="orgao in documents" :key="orgao.id" :value="orgao.nome_orgao">{{ orgao.nome_orgao }}</option>
                </select>
                <label class="departamento_documental">Departamento</label>
                <input v-model="departamento_documental" class="input_departamento_documental" type="text">
                <label class="estado_documental">Estado</label>
                <select required v-model="estado_documental" class="input_estado_documental">
                    <option disabled value="">Por favor escolha uma opção</option>
                    <option value="">Em Andamento</option>
                    <option value="">Pendente</option>
                    <option value="">Concluido</option>
                </select>
                <label class="Descricao_documental">Descrição</label>
                <textarea v-model="observacoes_funcionario" class="input_descricao_documental" rows="4" cols="50"></textarea>
                <label class="ficheiro" for="myfile">Select a file:</label>
                <input class="ficheiro_input" type="file" id="myfile" name="myfile" />
                <button class="salvar_funcionario">SALVAR</button>
            </form>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'
export default {
    components: { NavbarAppHome, Sidebar },

    setup() {
        const { addDoc, error } = useCollection('cambio')
        const { error2, documents } = getCollection('contrato')

        const { user } = getUser()
        const router = useRouter()

        watch(user, () => {
            if  (!user.value) {
                router.push({ name: 'Login'})
            }
        })

        const moeda_cambio = ref('')
        const cambio_dados = ref('')
        const data_operacao_cambio = ref('')

        const handleSubmit = async () => {
            const cambio = {
                moeda_cambio: moeda_cambio.value,
                cambio_dados: cambio_dados.value,
                data_operacao_cambio: data_operacao_cambio.value,
                userId: user.value.uid

            }
            await addDoc(cambio)
            if (!error.value) { 
                moeda_cambio.value = ''
                cambio_dados.value = ''
                data_operacao_cambio.value = ''
            }
        }
        return {  moeda_cambio, cambio_dados,  data_operacao_cambio,
                  error, handleSubmit, error2, documents }
    }
    
}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 100%;
    left: 180px;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: 0%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.menu_configuracoes{
    width: 100%;
}

.btn_missao_diplomatica{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    vertical-align: middle;
}
.btn_missao_diplomatica:hover {
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
}
.missao_diplomatica{
    position: absolute;
    width: 215px;
    height: 46px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.missao_diplomatica:hover {
  color: white;
}

.btn_orgaos{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 485px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
    vertical-align: middle;
}

.btn_orgaos:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.orgaos{
    position: absolute;
    width: 209px;
    height: 20px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.orgaos:hover {
  color: white;
}

.btn_contas{
    position: absolute;
    width: 214px;
    height: 84px;
    left: 717px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_contas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.contas{
    position: absolute;
    width: 198px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.contas:hover {
    color: white;
}

.btn_funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 945px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_funcionarios_empresas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.funcionarios_empresas:hover {
    color: white;
}

.btn_naturezas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1176px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_naturezas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.naturezas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.naturezas:hover {
    color: white;
}

.form_container{
    box-sizing: border-box;
    position: absolute;
    width: 908px;
    height: 100%;
    left: 266px;
    top: 270px;
    background: #FFFFFF;
    border: 2px solid #51B8D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.titulo_formulario{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 1px;
    top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
}

.icon_back{
    position: absolute;
    width: 5px;
    height: 8px;
    left: 800px;
    top: 40px;
}
.moeda_cambio{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -125px;
    top: 77px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_moeda_cambio{
    position: absolute;
    width: 154px;
    height: 30px;
    left: 76px;
    top: 103px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.cambio{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 65px;
    top: 76px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #51B8D5;
}
.input_cambio{
    position: absolute;
    width: 301px;
    height: 30px;
    left: 259px;
    top: 103px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.data_operacao_cambio{
    position: absolute;
    width: 215px;
    height: 32px;
    left: 550px;
    top: 75px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_operacao_cambio{
    position: absolute;
    width: 216px;
    height: 30px;
    left: 600px;
    top: 103px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.salvar_funcionario{
    box-sizing: border-box;
    position: absolute;
    width: 132px;
    height: 30px;
    left: 690px;
    top: 400px;
    color: #fff;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.ficheiro{
    position: absolute;
    width: 215px;
    height: 32px;
    left: 10px;
    top: 380px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.orgao_documental{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -125px;
    top: 157px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_orgao_documental{
    position: absolute;
    width: 254px;
    height: 30px;
    left: 76px;
    top: 190px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.departamento_documental{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 175px;
    top: 157px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #51B8D5;
}
.input_departamento_documental{
    position: absolute;
    width: 301px;
    height: 25px;
    left: 350px;
    top: 190px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.estado_documental{
    position: absolute;
    width: 215px;
    height: 32px;
    left: 580px;
    top: 157px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_estado_documental{
    position: absolute;
    width: 216px;
    height: 30px;
    left: 670px;
    top: 190px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.Descricao_documental{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -115px;
    top: 247px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_descricao_documental{
    position: absolute;
    width: 600px;
    height: 85px;
    left: 76px;
    top: 280px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.ficheiro_input{
    position: absolute;
    width: 216px;
    height: 30px;
    left: 80px;
    top: 400px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>