<template>
    <div>
      <div>
        <NavbarAppHome />
      </div>
      <div>
        <Sidebar />
      </div>
      <div class="cabecalho_configuracoes">
        <div class="titulo_configuracoes">
          <h1 class="conteudo_titulo">FORMULÁRIO FUNCIONÁRIOS CENTRAL</h1>
        </div>
        <ConfiguracaoNav/>
      </div>
      <div class="form_container">
        <h1 class="titulo_formulario">REGISTAR FUNCIONÁRIOS CENTRAL</h1>
        <router-link
          to="/FuncionariosEmpresas"
          style="text-decoration: none; color: inherit"
        >
          <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
        </router-link>
        <form @submit.prevent="handleSubmit">
          <label class="nome_funcionario">Nome Funcionario</label>
          <input
            required
            v-model="nome_funcionario"
            class="input_nome_funcionario"
            type="text"
          />
          <label class="tipo_funcao_funcionario">Categoria</label>
          <input
            required
            v-model="tipo_funcionario"
            class="input_tipo_funcionario"
            type="text"
          />
          <label class="data_nascimento_funcionario">Data de nascimento</label>
          <input
            required
            v-model="data_nascimento_funcionario"
            class="input_data_nascimento_funcionario"
            type="date"
          />
          <label class="dias_trabalho">Diploma de Nomeação</label>
          <input
            required
            v-model="diploma_de_nomeacao"
            class="input_dias_trabalho"
            type="text"
          />
          <label class="orgao_funcionario">Órgão</label>
          <select
            required
            v-model="orgao_funcionario"
            class="input_orgao_funcionario"
          >
            <option disabled value="">Por favor escolha uma opção</option>
            <option
              v-for="orgao in filteredDocuments"
              :key="orgao.id"
              :value="orgao.nome_orgao"
              v-show="orgao.sigfe_orgao"
            >
              {{ orgao.nome_orgao }}
            </option>
          </select>
          <label class="registar_abono">REGISTAR DEPENDENTE (ABONO DE FAMILIA)</label>
          <label class="nome_abono1">Nome Dependente 1</label>
          <input
            v-model="nome_abono1"
            class="input_nome_abono1"
            type="text"
            step="any"
          />
          <label class="nome_abono2">Nome Dependente 2</label>
          <input
            v-model="nome_abono2"
            class="input_nome_abono2"
            type="text"
            step="any"
          />
          <label class="nome_abono3">Nome Dependente 3</label>
          <input
            v-model="nome_abono3"
            class="input_nome_abono3"
            type="text"
            step="any"
          />
          <input
              v-model="estado"
              type="hidden"
              class="input_estado_check"
              true-value="Activo"
              false-value="Inactivo"
            />
          <label class="data_funcionario">Inicio de Funções</label>
          <input
            required
            v-model="data_funcionario"
            class="input_data_funcionario"
            type="date"
          />
          <label class="observacoes_funcionario">Observações</label>
          <textarea
            v-model="observacoes_funcionario"
            class="input_observacoes_funcionario"
            rows="4"
            cols="50"
          ></textarea>
          <label class="input_file_data_lable">Ficheiro</label>
          <input @change="handleFileChange" class="input_file_data" type="file" />
          <button class="salvar_funcionario">SALVAR</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, watch, computed, reactive } from "vue";
  import NavbarAppHome from "../components/NavbarAppHome.vue";
  import Sidebar from "../components/Sidebar.vue";
  import useCollection from "../composables/useCollection";
  import getCollection from "../composables/getCollection";
  import getDocument from "../composables/getDocument";
  import getUser from "../composables/getUser";
  import { useRouter } from "vue-router";
  import { storage } from '../firebase/config';
  import ConfiguracaoNav from '../components/ConfiguracaoNav.vue'
  export default {
    components: { NavbarAppHome, Sidebar,ConfiguracaoNav },
  
    setup() {
      // const { addDoc, error } = useCollection("funcionario");funcionariolocal
      const { addDoc, error } = useCollection("funcionariocentral");
      const { error2, documents } = getCollection("orgaos");
      const { error2 :error_moeda, documents: moeda } = getCollection("moeda");
  
      const { user } = getUser();
      const router = useRouter();
      const filteredDocuments = ref([]);
      const moedaDatas = ref([]);
      const data = ref([]);
      const estado = ref(true);
  
      watch(user, () => {
        if (!user.value) {
          router.push({ name: "Login" });
        }
      });
  
      const filterDocuments = () => {
        if (documents.value) {
          filteredDocuments.value = documents.value.filter(
            (doc) => {
             return doc.userId === user.value.uid
            }
          );
        }
      };
      filterDocuments();
  
      watch([documents, user], () => {
        filterDocuments();
      });
  //=================================== moeda data =================================== 
      const MoedaFilter = () => {
        if (moeda.value) {
          moedaDatas.value = moeda.value.filter(
            (doc) => {
             return doc.userId === user.value.uid
            }
          );
        }
      };
      MoedaFilter();
  
      watch([moeda, user], () => {
        MoedaFilter();
      });
  
      const nome_funcionario = ref("");
      const tipo_funcionario = ref("");
      const data_nascimento_funcionario = ref("");
      const diploma_de_nomeacao = ref("");
      const orgao_funcionario = ref("");
      const nome_abono1 = ref("");
      const nome_abono2 = ref("");
      const nome_abono3 = ref("");
      const handleInputChange = (inputElement, precision) => {
        inputElement.value = parseFloat(inputElement.value).toFixed(precision);
      }
      const data_funcionario = ref("");
      const observacoes_funcionario = ref("");
      const file_input = ref("");
      const loader = ref(false);
  
      // const handleFileChange = (event) => {
      //   loader.value = true;
      //   const file = event.target.files[0];
      //   const storageRef = storage.ref();
      //   const fileRef = storageRef.child(file.name);
      //   fileRef
      //     .put(file)
      //     .then((snapshot) => {
      //       fileRef
      //         .getDownloadURL()
      //         .then((url) => {
      //         file_input.value = url;
      //         console.log('url',url);
      //           loader.value = false;
      //         })
      //         .catch((error) => {
      //           loader.value = false;
      //           console.error("Error getting download URL:", error);
      //         });
      //     })
      //     .catch((error) => {
      //       loader.value = false;
      //       console.error("Error uploading file:", error);
      //     });
      // };
  
      const handleSubmit = async () => {
        const funcionario = {
          nome_funcionario: nome_funcionario.value,
          tipo_funcionario: tipo_funcionario.value,
          data_nascimento_funcionario: data_nascimento_funcionario.value,
          diploma_de_nomeacao: diploma_de_nomeacao.value,
          orgao_funcionario: orgao_funcionario.value,
          nome_abono1: nome_abono1.value,
          nome_abono2: nome_abono2.value,
          nome_abono3: nome_abono3.value,
          data_funcionario: data_funcionario.value,
          observacoes_funcionario: observacoes_funcionario.value,
          userId: user.value.uid,
          estado: estado.value ? estado.value : 'false',
          // file : file_input.value
        };
        console.log("funcionario===ffff===>>>>", funcionario);
        await addDoc(funcionario);
        if (!error.value) {
          nome_funcionario.value = "";
          tipo_funcionario.value = "";
          data_nascimento_funcionario.value = "";
          diploma_de_nomeacao.value = "";
          orgao_funcionario.value = "";
          nome_abono1.value = "";
          nome_abono2.value = "";
          nome_abono3.value = "";
          data_funcionario.value = "";
          observacoes_funcionario.value = "";
          estado.value =true;
        }
        alert("DADOS INSERIDOS COM SUCESSO!");
      };
  
      return {
        estado,
        nome_funcionario,
        tipo_funcionario,
        data_nascimento_funcionario,
        diploma_de_nomeacao,
        orgao_funcionario,
        nome_abono1,
        nome_abono2,
        nome_abono3,
        data_funcionario,
        observacoes_funcionario,
        error,
        handleSubmit,
        error2,
        documents,
        moeda,
        filteredDocuments,
        moedaDatas
      };
    },
  };
  </script>
  
  <style scoped>
  .cabecalho_configuracoes {
    width: 100%;
    left: 180px;
  }
  .titulo_configuracoes {
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344c;
  }
  .conteudo_titulo {
    position: absolute;
    width: 1271px;
    height: 20px;
    left: 0%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
  }
  .menu_configuracoes {
    width: 100%;
  }
  
  .btn_missao_diplomatica {
    position: absolute;
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #ffffff;
    border: 3px solid #51b8d5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #51b8d5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    vertical-align: middle;
  }
  .btn_missao_diplomatica:hover {
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #00344c;
    border: 8px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
  }
  .missao_diplomatica {
    position: absolute;
    width: 215px;
    height: 46px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51b8d5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  
  .missao_diplomatica:hover {
    color: white;
  }
  
  .btn_orgaos {
    position: absolute;
    width: 216px;
    height: 84px;
    left: 485px;
    top: 160px;
    background: #ffffff;
    border: 3px solid #51b8d5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
    vertical-align: middle;
  }
  
  .btn_orgaos:hover {
    background: #00344c;
    color: white;
    background: #00344c;
    border: 8px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .orgaos {
    position: absolute;
    width: 209px;
    height: 20px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  
  .orgaos:hover {
    color: white;
  }
  
  .btn_contas {
    position: absolute;
    width: 214px;
    height: 84px;
    left: 717px;
    top: 160px;
    background: #ffffff;
    border: 3px solid #51b8d5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  
  .btn_contas:hover {
    background: #00344c;
    color: white;
    background: #00344c;
    border: 8px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .contas {
    position: absolute;
    width: 198px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  
  .contas:hover {
    color: white;
  }
  
  .btn_funcionarios_empresas {
    position: absolute;
    width: 217px;
    height: 84px;
    left: 945px;
    top: 160px;
    background: #ffffff;
    border: 3px solid #51b8d5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  
  .btn_funcionarios_empresas:hover {
    background: #00344c;
    color: white;
    background: #00344c;
    border: 8px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .funcionarios_empresas {
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  
  .funcionarios_empresas:hover {
    color: white;
  }
  
  .btn_naturezas {
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1176px;
    top: 160px;
    background: #ffffff;
    border: 3px solid #51b8d5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  
  .btn_naturezas:hover {
    background: #00344c;
    color: white;
    background: #00344c;
    border: 8px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .naturezas {
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  
  .naturezas:hover {
    color: white;
  }
  
  .form_container {
    box-sizing: border-box;
    position: absolute;
    width: 908px;
    height: 880px;
    left: 266px;
    top: 270px;
    background: #ffffff;
    border: 2px solid #51b8d5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .titulo_formulario {
    position: absolute;
    width: 907px;
    height: 20px;
    left: 1px;
    top: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4e4e4e;
  }
  
  .icon_back {
    position: absolute;
    width: 5px;
    height: 8px;
    left: 800px;
    top: 40px;
  }
  
  .nome_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: -150px;
    top: 70px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  
  .tipo_funcao_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 355px;
    top: 70px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_nome_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 444px;
    height: 30px;
    left: 40px;
    top: 95px;
    font-family: "Inter";
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  .input_tipo_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 95px;
    font-family: "Inter";
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .data_nascimento_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: -115px;
    top: 140px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_data_nascimento_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 165px;
    font-family: "Inter";
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .dias_trabalho {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 400px;
    top: 140px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_dias_trabalho {
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 165px;
    font-family: "Inter";
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .orgao_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: -160px;
    top: 205px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_orgao_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 230px;
    font-family: "Inter";
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .referencia_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 342px;
    top: 205px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_referencia_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 230px;
    font-family: "Inter";
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .registar_abono {
    position: absolute;
    width: 908px;
    height: 20px;
    left: 0px;
    top: 270px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #51b8d5;
  }
  .nome_abono1 {
    position: absolute;
    width: 330px;
    height: 32px;
    left: -60px;
    top: 300px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_nome_abono1 {
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 325px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .nome_abono2 {
    position: absolute;
    width: 330px;
    height: 32px;
    left: 450px;
    top: 300px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_nome_abono2 {
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 550px;
    top: 325px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .nome_abono3 {
    position: absolute;
    width: 330px;
    height: 32px;
    left: -60px;
    top: 360px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_nome_abono3 {
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 385px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .irt_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 350px;
    top: 327px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_irt_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 30px;
    left: 600px;
    top: 325px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  .suplemento_salario {
    position: absolute;
    width: 330px;
    height: 32px;
    left: -50px;
    top: 360px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_suplemento_salario {
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 385px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .s_social_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 365px;
    top: 445px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_s_social_funcionario {
    box-sizing: border-box;
    position: absolute;
    padding: 7px;
    width: 145px;
    height: 30px;
    left: 625px;
    top: 445px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .salario_iliquido {
    position: absolute;
    width: 330px;
    height: 32px;
    left: -75px;
    top: 420px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_salario_iliquido {
    box-sizing: border-box;
    position: absolute;
    padding: 7px;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 445px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .cativos_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 360px;
    top: 385px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_cativos_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 145px;
    height: 30px;
    left: 625px;
    top: 385px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .vencimento_funcionario {
    position: absolute;
    width: 330px;
    height: 32px;
    left: -85px;
    top: 537px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_vencimento_funcionario {
    box-sizing: border-box;
    position: absolute;
    padding: 7px;
    width: 230px;
    height: 30px;
    left: 40px;
    top: 560px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .outros_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 375px;
    top: 505px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_outros_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 130px;
    height: 30px;
    left: 640px;
    top: 505px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .total_descontos_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 315px;
    top: 563px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_total_descontos_funcionario {
    box-sizing: border-box;
    position: absolute;
    padding: 7px;
    width: 130px;
    height: 30px;
    left: 640px;
    top: 560px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .contribuicao_funcionario {
    position: absolute;
    width: 330px;
    height: 32px;
    left: -30px;
    top: 620px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_contribuicao_funcionario {
    box-sizing: border-box;
    position: absolute;
    padding: 7px;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 645px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .abono_familiar_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: -135px;
    top: 430px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_abono_familiar_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 230px;
    height: 30px;
    left: 40px;
    top: 453px;
    font-family: "Inter";
    font-size: 16px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .fonte_recurso_funcionario {
    position: absolute;
    width: 330px;
    height: 32px;
    left: -65px;
    top: 680px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_fonte_recurso_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 705px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .moeda_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 340px;
    top: 620px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_moeda_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 230px;
    height: 30px;
    left: 540px;
    top: 645px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .data_funcionario {
    position: absolute;
    width: 330px;
    height: 32px;
    left: 440px;
    top: 360px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_data_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 550px;
    top: 385px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .cambio_mg_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 255px;
    top: 680px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_cambio_mg_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 30px;
    left: 400px;
    top: 705px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .cambio_kz_funcionario {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 460px;
    top: 680px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_cambio_kz_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 30px;
    left: 600px;
    top: 705px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .observacoes_funcionario {
    position: absolute;
    width: 330px;
    height: 32px;
    left: -80px;
    top: 495px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_observacoes_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 730px;
    height: 100px;
    left: 40px;
    top: 520px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  .check_pessoal_central {
    position: absolute;
    width: 396px;
    height: 32px;
    left: -75px;
    top: 948px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #00344c;
  }
  .input_pessoal_central {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 40px;
    top: 950px;
    background: #9b4600;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
  }
  .check_pessoal_local {
    position: absolute;
    width: 396px;
    height: 32px;
    left: 80px;
    top: 948px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #00344c;
  }
  .input_pessoal_local {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 205px;
    top: 950px;
    background: #9b4600;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
  }
  
  .salvar_funcionario {
    box-sizing: border-box;
    position: absolute;
    width: 132px;
    height: 30px;
    left: 641px;
    top: 730px;
    color: #fff;
    background: #51b8d5;
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .btn_moedas {
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1410px;
    top: 160px;
    background: #ffffff;
    border: 3px solid #51b8d5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  .btn_moedas:hover {
    background: #00344c;
    color: white;
    background: #00344c;
    border: 8px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .moedas {
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51b8d5;
    cursor: pointer;
  }
  .moedas:hover {
    color: white;
  }
  
  .input_file_data_lable {
    position: absolute;
    width: 444px;
    height: 32px;
    left: -150px;
    top: 650px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  
  .input_file_data {
      box-sizing: border-box;
    position: absolute;
    width: 444px;
    height: 30px;
    left: 40px;
    top: 680px;
    font-family: "Inter";
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  </style>