<template>
    <div>
        <input type="checkbox">
        <div class="toggle">
            <span class="top_line common"></span>
            <span class="middle_line common"></span>
            <span class="bottom_line common"></span>
        </div>
        <div class="slide">
            <h1>MENU</h1>
            <ul>
                <li><router-link :to="{ name: 'AppHome'}"><i class="fas fa-cogs"></i>DASHBOARD</router-link></li>
                <li><router-link :to="{ name: 'Perfil'}"><i class="fas fa-user-circle"></i>PERFIL</router-link></li>
                <li><router-link :to="{ name: 'Fechamentos'}"><i class="fas fa-info-circle"></i>FECHAMENTOS</router-link></li>
                <li><router-link :to="{ name: 'Testes'}"><i class="fas fa-info-circle"></i>TESTE</router-link></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background-color: #f2f2f2;
}

.slide {
    height: 200%;
    width: 180px;
    left: 0px;
    top: 90px;
    position: absolute;
    background-color: rgb(248, 249, 249);
    transition: 0.5s ease;
    box-shadow: 0 0 15px rgba(0,0,0, 0.5);
    bottom: 0;

}

h1 {
    color: darkcyan;
    font-weight: 800;
    text-align: right;
    padding: 10px 0;
    padding-right: 30px;
    pointer-events: none;

}

ul li {
    list-style: none;
    justify-content: left;
    left: 0px;
}

ul li a {
    color: #011a41;
    font-weight: 500;
    padding: 5px 0;
    justify-content: left;
    display: block;
    text-transform: capitalize;
    text-decoration: none;
    transition: 0.2s ease-out;
    left: 0;
}

ul li:hover a{
    color: #fff;
    background-color: darkcyan;
}

ul li a i {
    width: 40px;
    text-align: left;
    left: 0;
}

input {
    display: none;
    visibility: hidden;
    -webkit-appearance: none;
}

.toggle {
    position: absolute;
    height: 30px;
    width: 30px;
    top: 102px;
    left: 15px;
    z-index: 1;
    cursor: pointer;
    border-radius: 2px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 10px rgba(0,0,0, 0.3);
}

.toggle .common {
    position: absolute;
    height: 2px;
    width: 20px;
    background-color: darkcyan;
    border-radius: 40px;
    transition: 0.3s ease;
}

.toggle .top_line {
    top: 30%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.toggle .middle_line {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.toggle .bottom_line {
    top: 70%;
    left: 50%;
    transform: translate(-50%,-50%);
}

input:checked ~ .toggle .top_line{
    left: 2px;
    top: 14px;
    width: 25px;
    transform: rotate(45deg);
}
input:checked ~ .toggle .bottom_line{
    left: 2px;
    top: 14px;
    width: 25px;
    transform: rotate(-45deg);
}
input:checked ~ .toggle .middle_line{
    opacity: 0;
    transform: translateX(20px);
}
</style>