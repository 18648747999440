<template>
  <div>
      <PriceComponent />
  </div>
</template>

<script>
import PriceComponent from '../components/PriceComponent.vue'
export default {
  components: { PriceComponent }
}
</script>

<style>

</style>