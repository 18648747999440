<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">TRIBUNAL DE CONTAS</h1>
            </div>
            <div class="menu_configuracoes">
                <router-link to="/Modelos" style="text-decoration: none; color: inherit;">
                    <div class="btn_missao_diplomatica">
                        MODELOS
                    </div>
                </router-link>
                <router-link to="/Movimentos" style="text-decoration: none; color: inherit;">
                    <div class="btn_orgaos">
                        MOVIMENTOS
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
export default {
    components: { NavbarAppHome, Sidebar }
}
</script>

<style>

</style>