<template>
  <div>
    <div>
      <NavbarAppHome />
    </div>
    <div>
      <Sidebar />
    </div>
    <div class="cabecalho_configuracoes">
      <div class="titulo_configuracoes">
        <h1 class="conteudo_titulo">TRANSFERÊNCIAS</h1>
      </div>
      <div class="menu_configuracoes">
        <router-link
          to="/Receitas"
          style="text-decoration: none; color: inherit"
        >
          <div class="btn_missao_diplomatica">RECEITAS</div>
        </router-link>
        <router-link
          to="/Despesas"
          style="text-decoration: none; color: inherit"
        >
          <div class="btn_orgaos">DESPESAS</div>
        </router-link>
        <router-link
          to="/Transferencias"
          style="text-decoration: none; color: inherit"
        >
          <div class="btn_contas">TRANSFERÊNCIAS</div>
        </router-link>
        <router-link to="/Cambio" style="text-decoration: none; color: inherit">
          <div class="btn_funcionarios_empresas">CÂMBIO</div>
        </router-link>
      </div>
    </div>
    <div class="form_container">
      <h1 class="titulo_formulario">EDITAR TRANSFERÊNCIAS</h1>
      <router-link
        to="/Transferencias"
        style="text-decoration: none; color: inherit"
      >
        <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
      </router-link>
      <form @submit.prevent="isEdit ? submitEdit() : handleSubmit">
        <label class="situacao_transferencia">Situação</label>
        <input
          required
          v-model="situacao_transferencia"
          class="input_situacao_transferencia"
          type="text"
        />
        <label class="data_registro_transferencia">Data de Registro</label>
        <input
          required
          v-model="data_registro_transferencia"
          class="input_data_registro_transferencia"
          type="date"
        />
        <label class="departamento_origem_transferencia"
          >Departamento de Origem</label
        >
        <select
          required
          v-model="orgao_origem_transferencia"
          class="input_departamento_origem_transferencia"
        >
          <option disabled value="">--Selecione o Departamento--</option>
          <option value="Teste 1">Teste 1</option>
          <option value="Teste 2">Teste 2</option>
        </select>
        <label class="conta_origem_transferencia">Nº Conta Origem</label>
        <select
          required
          v-model="conta_origem_transferencia"
          class="input_conta_origem_transferencia"
        >
          <option disabled value="">--Selecione a Conta--</option>
          <option value="conta teste 1">conta teste 1</option>
          <option value="conta teste 2">conta teste 2</option>
        </select>
        <label class="valor_origem_transferencia">Valor Origem</label>
        <input
          required
          v-model="valor_origem_transferencia"
          class="input_valor_origem_transferencia"
          type="text"
        />
        <label class="departamento_destino_transferencia"
          >Departamento de Destino</label
        >
        <select
          required
          v-model="orgao_destino_transferencia"
          class="input_departamento_destino_transferencia"
        >
          <option disabled value="">--Selecione o Departamento--</option>
          <option value="Teste 3">Teste 3</option>
          <option value="Teste 4">Teste 4</option>
        </select>
        <label class="conta_destino_transferencia">Nº Conta destino</label>
        <select
          required
          v-model="conta_destino_transferencia"
          class="input_conta_destino_transferencia"
        >
          <option disabled value="">--Selecione a Conta--</option>
          <option value="conta teste 3">conta teste 3</option>
          <option value="conta teste 4">conta teste 4</option>
        </select>
        <label class="valor_destino_transferencia">Valor Destino</label>
        <input
          required
          v-model="valor_destino_transferencia"
          class="input_valor_destino_transferencia"
          type="text"
        />
        <label class="descricao_transferencia">Descrição</label>
        <textarea
          v-model="descricao_transferencia"
          class="input_descricao_transferencia"
        ></textarea>
        <button class="salvar_funcionario" @click.prevent="submitForm">
          SALVAR
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import NavbarAppHome from "../components/NavbarAppHome.vue";
import Sidebar from "../components/Sidebar.vue";
import useCollection from "../composables/useCollection";
import getCollection from "../composables/getCollection";
import getUser from "../composables/getUser";
import { useRouter } from "vue-router";
import { projectFirestore } from "../firebase/config";
import { onMounted } from "vue";

export default {
  components: { NavbarAppHome, Sidebar },

  setup() {
    const { addDoc, error } = useCollection("transferencia");
    const { error2, documents } = getCollection("contrato");
    const { user } = getUser();
    const router = useRouter();
    const user_update_id = ref("");
    const user_id = user.value.uid;
    const isEditingId = computed(() => route.value.params.id);
    const situacao_transferencia = ref("");
    const data_registro_transferencia = ref("");
    const orgao_origem_transferencia = ref("");
    const conta_origem_transferencia = ref("");
    const valor_origem_transferencia = ref("");
    const orgao_destino_transferencia = ref("");
    const conta_destino_transferencia = ref("");
    const valor_destino_transferencia = ref("");
    const descricao_transferencia = ref("");

    const docRef = getCollection("transferencia");
    if (docRef.value) {
      filteredDocuments.value = docRef.value.filter(
        isEditingId.value === user.value.uid
      );
      data.value = filteredDocuments.value;
    }

    const isEdit = ref(false);
    isEditingId.value = false;
    isEdit.value = false;

    const fetchCollectionData = async () => {
      try {
        const collectionRef = projectFirestore.collection("transferencia");
        const snapshot = await collectionRef.get();
        const data = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        const filtered = data.filter((data) => data.userId === user_id);
        filtered.forEach((data) => {
          situacao_transferencia.value = data.situacao_transferencia;
          data_registro_transferencia.value = data.data_registro_transferencia;
          orgao_origem_transferencia.value = data.orgao_origem_transferencia;
          conta_origem_transferencia.value = data.conta_origem_transferencia;
          valor_origem_transferencia.value = data.valor_origem_transferencia;
          orgao_destino_transferencia.value = data.orgao_destino_transferencia;
          conta_destino_transferencia.value = data.conta_destino_transferencia;
          valor_destino_transferencia.value = data.valor_destino_transferencia;
          descricao_transferencia.value = data.descricao_transferencia;
          user_update_id.value = data.id;
        });
      } catch (error) {
        console.error("Error fetching collection data:", error);
      }
    };

    onMounted(fetchCollectionData);

    watch(user, () => {
      if (!user.value) {
        router.push({ name: "Login" });
      }
    });

    const handleEdit = async (id) => {
      try {
        const docRef = projectFirestore.collection("transferencia").doc(id);
        await docRef.get().then(async (doc) => {
          if (doc.exists) {
            let id = await doc.id;
            new_obj.value = await { ...doc.data(), id };
            situacao_transferencia.value = new_obj.value.situacao_transferencia;
            data_registro_transferencia.value =
              new_obj.value.data_registro_transferencia;
            orgao_origem_transferencia.value =
              new_obj.value.orgao_origem_transferencia;
            conta_origem_transferencia.value =
              new_obj.value.conta_origem_transferencia;
            valor_origem_transferencia.value =
              new_obj.value.valor_origem_transferencia;
            orgao_destino_transferencia.value =
              new_obj.value.orgao_destino_transferencia;
            conta_destino_transferencia.value =
              new_obj.value.conta_destino_transferencia;
            valor_destino_transferencia.value =
              new_obj.value.valor_destino_transferencia;
            descricao_transferencia.value =
              new_obj.value.descricao_transferencia;
          }
        });
        isEdit.value = true;
        isEditingId.value = id;
      } catch (error) {
        console.log("Error deleting document:", error.message);
      }
    };

    const submitEdit = async () => {
      alert("submitEditsubmitEdit");
      const docRef = projectFirestore
        .collection("transferencia")
        .doc(isEditingId.value);
      await docRef.update({
        situacao_transferencia: situacao_transferencia.value,
        data_registro_transferencia: data_registro_transferencia.value,
        orgao_origem_transferencia: orgao_origem_transferencia.value,
        conta_origem_transferencia: conta_origem_transferencia.value,
        valor_origem_transferencia: valor_origem_transferencia.value,
        orgao_destino_transferencia: orgao_destino_transferencia.value,
        conta_destino_transferencia: conta_destino_transferencia.value,
        valor_destino_transferencia: valor_destino_transferencia.value,
        descricao_transferencia: descricao_transferencia.value,
        userId: user.value.uid,
      });
      isEdit.value = false;
      situacao_transferencia.value = null;
      data_registro_transferencia.value = null;
      orgao_origem_transferencia.value = null;
      conta_origem_transferencia.value = null;
      valor_origem_transferencia.value = null;
      orgao_destino_transferencia.value = null;
      conta_destino_transferencia.value = null;
      valor_destino_transferencia.value = null;
      descricao_transferencia.value = null;
    };

    const submitForm = async () => {
      try {
        const docRef = projectFirestore
          .collection("transferencia")
          .doc(user_update_id.value);
        await docRef.update({
          situacao_transferencia: situacao_transferencia.value,
          data_registro_transferencia: data_registro_transferencia.value,
          orgao_origem_transferencia: orgao_origem_transferencia.value,
          conta_origem_transferencia: conta_origem_transferencia.value,
          valor_origem_transferencia: valor_origem_transferencia.value,
          orgao_destino_transferencia: orgao_destino_transferencia.value,
          conta_destino_transferencia: conta_destino_transferencia.value,
          valor_destino_transferencia: valor_destino_transferencia.value,
          descricao_transferencia: descricao_transferencia.value,
        });

        router.push(`/transferencias`);

        alert("updated successfully");
      } catch (err) {
        alert("updated ====");
        console.log("err==>", err);
      }
    };

    return {
      situacao_transferencia,
      data_registro_transferencia,
      orgao_origem_transferencia,
      conta_origem_transferencia,
      valor_origem_transferencia,
      orgao_destino_transferencia,
      conta_destino_transferencia,
      valor_destino_transferencia,
      descricao_transferencia,
      error,
      error2,
      documents,
      submitEdit,
      isEdit,
      isEditingId,
      user_id,
      submitForm,
    };
  },
};
</script>

<style scoped>
.cabecalho_configuracoes {
  width: 100%;
  left: 180px;
}
.titulo_configuracoes {
  position: absolute;
  color: white;
  width: 100%;
  height: 60px;
  left: 180px;
  top: 90px;
  background: #00344c;
}
.conteudo_titulo {
  position: absolute;
  width: 1271px;
  height: 20px;
  left: 0%;
  top: -10px;
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
}
.menu_configuracoes {
  width: 100%;
}

.btn_missao_diplomatica {
  position: absolute;
  width: 216px;
  height: 84px;
  left: 250px;
  top: 160px;
  background: #ffffff;
  border: 3px solid #51b8d5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #51b8d5;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  vertical-align: middle;
}
.btn_missao_diplomatica:hover {
  width: 216px;
  height: 84px;
  left: 250px;
  top: 160px;
  background: #00344c;
  border: 8px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: white;
}
.missao_diplomatica {
  position: absolute;
  width: 215px;
  height: 46px;
  left: 0px;
  top: 25%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #51b8d5;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.missao_diplomatica:hover {
  color: white;
}

.btn_orgaos {
  position: absolute;
  width: 216px;
  height: 84px;
  left: 485px;
  top: 160px;
  background: #ffffff;
  border: 3px solid #51b8d5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
  vertical-align: middle;
}

.btn_orgaos:hover {
  background: #00344c;
  color: white;
  background: #00344c;
  border: 8px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.orgaos {
  position: absolute;
  width: 209px;
  height: 20px;
  left: 0px;
  top: 40%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.orgaos:hover {
  color: white;
}

.btn_contas {
  position: absolute;
  width: 214px;
  height: 84px;
  left: 717px;
  top: 160px;
  background: #ffffff;
  border: 3px solid #51b8d5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.btn_contas:hover {
  background: #00344c;
  color: white;
  background: #00344c;
  border: 8px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.contas {
  position: absolute;
  width: 198px;
  height: 22px;
  left: 0px;
  top: 40%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.contas:hover {
  color: white;
}

.btn_funcionarios_empresas {
  position: absolute;
  width: 217px;
  height: 84px;
  left: 945px;
  top: 160px;
  background: #ffffff;
  border: 3px solid #51b8d5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.btn_funcionarios_empresas:hover {
  background: #00344c;
  color: white;
  background: #00344c;
  border: 8px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.funcionarios_empresas {
  position: absolute;
  width: 217px;
  height: 44px;
  left: 0px;
  top: 25%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.funcionarios_empresas:hover {
  color: white;
}

.btn_naturezas {
  position: absolute;
  width: 216px;
  height: 84px;
  left: 1176px;
  top: 160px;
  background: #ffffff;
  border: 3px solid #51b8d5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.btn_naturezas:hover {
  background: #00344c;
  color: white;
  background: #00344c;
  border: 8px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.naturezas {
  position: absolute;
  width: 216px;
  height: 22px;
  left: 0px;
  top: 40%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  color: #51b8d5;
  cursor: pointer;
}

.naturezas:hover {
  color: white;
}

.form_container {
  box-sizing: border-box;
  position: absolute;
  width: 908px;
  height: 100%;
  left: 266px;
  top: 270px;
  background: #ffffff;
  border: 2px solid #51b8d5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.titulo_formulario {
  position: absolute;
  width: 907px;
  height: 20px;
  left: 1px;
  top: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: #4e4e4e;
}

.icon_back {
  position: absolute;
  width: 5px;
  height: 8px;
  left: 800px;
  top: 40px;
}
.situacao_transferencia {
  position: absolute;
  width: 444px;
  height: 32px;
  left: -105px;
  top: 108px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}

.input_situacao_transferencia {
  box-sizing: border-box;
  position: absolute;
  width: 441px;
  height: 30px;
  left: 90px;
  top: 135px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.data_registro_transferencia {
  position: absolute;
  width: 214px;
  height: 32px;
  left: 500px;
  top: 108px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_data_registro_transferencia {
  box-sizing: border-box;
  position: absolute;
  width: 227px;
  height: 30px;
  left: 555px;
  top: 135px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.departamento_origem_transferencia {
  position: absolute;
  width: 214px;
  height: 32px;
  left: 65px;
  top: 194px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}

.input_departamento_origem_transferencia {
  box-sizing: border-box;
  position: absolute;
  width: 695px;
  height: 30px;
  left: 90px;
  top: 220px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.conta_origem_transferencia {
  position: absolute;
  width: 251px;
  height: 32px;
  left: 20px;
  top: 262px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}

.input_conta_origem_transferencia {
  box-sizing: border-box;
  position: absolute;
  width: 439px;
  height: 30px;
  left: 90px;
  top: 288px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.valor_origem_transferencia {
  position: absolute;
  width: 176px;
  height: 32px;
  left: 508px;
  top: 262px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_valor_origem_transferencia {
  box-sizing: border-box;
  position: absolute;
  width: 227px;
  height: 30px;
  left: 555px;
  top: 288px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.departamento_destino_transferencia {
  position: absolute;
  width: 214px;
  height: 32px;
  left: 65px;
  top: 330px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}

.input_departamento_destino_transferencia {
  box-sizing: border-box;
  position: absolute;
  width: 690px;
  height: 30px;
  left: 90px;
  top: 355px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.conta_destino_transferencia {
  position: absolute;
  width: 251px;
  height: 32px;
  left: 20px;
  top: 400px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}

.input_conta_destino_transferencia {
  box-sizing: border-box;
  position: absolute;
  width: 439px;
  height: 30px;
  left: 90px;
  top: 425px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.valor_destino_transferencia {
  position: absolute;
  width: 176px;
  height: 32px;
  left: 508px;
  top: 400px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}
.input_valor_destino_transferencia {
  box-sizing: border-box;
  position: absolute;
  width: 227px;
  height: 30px;
  left: 555px;
  top: 425px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.descricao_transferencia {
  position: absolute;
  width: 133px;
  height: 32px;
  left: 55px;
  top: 470px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #51b8d5;
}

.input_descricao_transferencia {
  box-sizing: border-box;
  position: absolute;
  width: 690px;
  height: 54px;
  left: 90px;
  top: 493px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.salvar_funcionario {
  box-sizing: border-box;
  position: absolute;
  width: 132px;
  height: 30px;
  left: 650px;
  top: 580px;
  color: #fff;
  background: #51b8d5;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
