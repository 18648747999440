<template>
    <div class="login_principal">
        <h1>Login</h1>
        <div v-if="showLogin">
            <LoginForm @login="enterApp"/>
        </div>
        <div v-else>
            <SignupForm @signup="enterLogin"/>
        </div>
    </div>
</template>

<script>
import SignupForm from '../components/SignupForm.vue'
import LoginForm from '../components/LoginForm.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
    components: { SignupForm, LoginForm },
    setup() {
        const showLogin = ref(true)
        const router = useRouter()

        const enterApp = () => {
            router.push({ name: 'AppHome' })
        }
        const enterLogin = () => {
            router.push({ name: 'AppHome' })
        }

        return { showLogin, enterApp, enterLogin }
    }
}
</script>

<style>
.login_principal{
  position: absolute;
  width: 100%;
  height: 1024px;
  left: 0px;
  top: 0px;
  background: #00344C;
}
</style>