import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'


const getCollection = (collection) => {
    const documents = ref(null)
    const error2 = ref(null)
    

    let collectionRef = projectFirestore.collection(collection)

    collectionRef.onSnapshot(async (snap) => {
        let results = []
        snap.docs.forEach(doc => {
            results.push({ ...doc.data(), id: doc.id })
        })
        documents.value = await results
        error2.value = null
    }, (err) => {
        console.log(err.message)
        documents.value = null
        error2.value = 'Não localizou os dados desejados.'
    })

    return { documents, error2 }
}

export default getCollection