<template>
    <div>
      <div>
        <NavbarAppHome />
      </div>
      <div>
        <Sidebar />
      </div>
      <div class="cabecalho_configuracoes">
        <ConfiguracaoNav/>
        <div class="titulo_configuracoes">
          <h1 class="conteudo_titulo">CONFIGURAÇÕES - MOEDAS</h1>
        </div>
      </div>
      <div>
        <h1>MISSÃO DIPLOMATICA</h1>
      </div>
      <div class="missao_container">
        <div class="formulario_missao">
          <h1>REGISTAR MOEDAS</h1>
          <form @submit.prevent="isEdit ? submitEdit() : handleSubmit()">
            <label class="nome_missao">Moeda Local</label>
            <input
              required
              v-model="moeda_local"
              type="text"
              class="input_misao"
            />
            <label class="cidade_missao">Moeda Gestão</label>
            <input
              required
              v-model="moeda_gestao"
              class="input_cidade_missao"
              type="text"
            />
            <label class="endereco_missao">Outra Moeda</label>
            <input
              v-model="outra_moeda"
              class="input_endereco_missao"
              type="text"
            />

            <!-- Moeda Adicional section -->
            <div v-for="(moeda_adicional, index) in moedasAdicionais" :key="index" class="moedas_extras" :style="{ top: `${calculateTopPosition(index)}px` }">
              <label class="moeda_adicional">Moeda Adicional {{ index + 1 }}</label>
              <input class="input_moeda_adicional" v-model="moedasAdicionais[index]" type="text" />
            </div>
      
            <!-- Button to add more Moeda Adicional -->
            <button @click.prevent="addMoedaAdicional" class="add_moeda_adicional">Moeda Adicional</button>
            <button class="salvar_missao">SALVAR</button>
          </form>
        </div>
        <div class="missao-list">
          <h2 class="dados_misso">DADOS MOEDAS</h2>
          <div class="table_missao">
            <table>
              <thead>
                <tr>
                  <th scope="col">Moeda Local</th>
                  <th scope="col">Moeda Gestão</th>
                  <th scope="col">Outra Moeda</th>
                  <th scope="col">Moedas Adicionais</th> <!-- New column -->
                  <th scope="col">Ações</th> <!-- Actions column -->
                </tr>
              </thead>
              <tbody v-if="data && data.length > 0">
                <tr v-for="m in data" :key="m.id">
                  <td>{{ m.moeda_local }}</td>
                  <td>{{ m.moeda_gestao }}</td>
                  <td>{{ m.outra_moeda }}</td>
                  <td>
                    <!-- Display Moedas Adicionais -->
                    <span v-for="(moeda, index) in m.moeda_adicional_extra" :key="index">
                      {{ moeda }} <!-- Assuming moeda_adicional_extra is an array of strings -->
                      <!-- Add comma or separator if needed -->
                      <span v-if="index < m.moeda_adicional_extra.length - 1">, </span>
                    </span>
                  </td>
                  <td>
                    <button title="Eliminar" class="icon_delete" @click="handleDelete(m.id)">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                    <button title="Editar" class="icon_edit" @click="handleEdit(m.id)">
                      <i class="fas fa-edit"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="error">{{ error2 }}</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, watch, computed } from "vue";
  import NavbarAppHome from "../components/NavbarAppHome.vue";
  import Sidebar from "../components/Sidebar.vue";
  import useCollection from "../composables/useCollection";
  import getCollection from "../composables/getCollection";
  import ConfiguracaoNav from '../components/ConfiguracaoNav.vue'
  import getUser from "../composables/getUser";
  import { useRouter } from "vue-router";
  import { projectFirestore } from "../firebase/config";
  
  export default {
    components: { NavbarAppHome, Sidebar, ConfiguracaoNav },
    setup(props) {
      const { user } = getUser();
      console.log(user, "useruser");
      const { addDoc, error } = useCollection("moeda");
      const { error2, documents } = getCollection("moeda");
      const data = ref([]);
      const router = useRouter();
      const filteredDocuments = ref([]);
      const moeda_local = ref("");
      const moeda_gestao = ref("");
      const outra_moeda = ref("");
      const moeda_adicional_extra = ref("");
      let new_obj = ref();
      const isEdit = ref(false);
      const isEditingId = ref(null);
      const moedasAdicionais = ref([]);

      const addMoedaAdicional = () => {
        moedasAdicionais.value.push("");
      };
  
      const filterDocuments = () => {
        if (documents.value) {
          filteredDocuments.value = documents.value.filter(
            (doc) => doc.userId === user.value.uid
          );
          data.value = filteredDocuments.value;
        }
      };
  
      filterDocuments();
  
      watch([documents, user], () => {
        filterDocuments();
      });
  
      const handleDelete = async (id) => {
        try {
          const docRef = projectFirestore.collection("moeda").doc(id);
          await docRef.delete();
          console.log("Document successfully deleted.");
        } catch (error) {
          console.log("Error deleting document:", error.message);
        }
      };
  
      const handleSubmit = async () => {
        // Check if there are existing documents with the same user ID
        const existingCurrencies = data.value.filter(doc => doc.userId === user.value.uid);

        // If there are existing documents, prevent the user from adding new currencies
        if (existingCurrencies.length > 0) {
            alert("Já existem moedas cadastradas, edite caso queira fazer alguma alteração!");
            return; // Exit the function without adding new currency
        }

        // Proceed with adding the currency if there are no existing documents
        const missao = {
            moeda_local: moeda_local.value,
            moeda_gestao: moeda_gestao.value,
            outra_moeda: outra_moeda.value,
            moeda_adicional_extra: moedasAdicionais.value,
            userId: user.value.uid,
        };

        const res = await addDoc(missao);

        if (!error.value) {
            moeda_local.value = "";
            moeda_gestao.value = "";
            outra_moeda.value = "";
            moedasAdicionais.value = [];
        }

        alert("DADOS INSERIDOS COM SUCESSO!");
    };
  
      const handleEdit = async (id) => {
        try {
          const docRef = projectFirestore.collection("moeda").doc(id);
          await docRef.get().then(async (doc) => {
            if (doc.exists) {
              let id = await doc.id;
              new_obj.value = await { ...doc.data(), id };
              moeda_local.value = new_obj.value.moeda_local;
              moeda_gestao.value = new_obj.value.moeda_gestao;
              outra_moeda.value = new_obj.value.outra_moeda;
              // Set the moedasAdicionais array to the fetched value
              moedasAdicionais.value = new_obj.value.moeda_adicional_extra || []; // Ensure it's an array
            }
          });
          isEdit.value = true;
          isEditingId.value = id;
        } catch (error) {
          console.log("Error deleting document:", error.message);
        }
      };
  
      const submitEdit = async () => {
        alert("submitEditsubmitEdit");
        const docRef = projectFirestore.collection("moeda").doc(isEditingId.value);
        await docRef.update({
          moeda_local: moeda_local.value,
          moeda_gestao: moeda_gestao.value,
          outra_moeda: outra_moeda.value,
          moeda_adicional_extra: moedasAdicionais.value, // Store the array of additional currencies
          userId: user.value.uid,
        });
        isEdit.value = false;
        moeda_local.value = null;
        moeda_gestao.value = null;
        outra_moeda.value = null;
        moedasAdicionais.value = []; // Reset the additional currencies array
      };
      // Variable to keep track of the number of additional currency fields
          const numAdditionalFields = ref(0);

      // Function to calculate the top position of the new field
        const calculateTopPosition = (index) => {
          // Calculate top position based on index and number of additional fields
          return 270 + (index + 1) * 75 + numAdditionalFields.value * 40;
        };
  
      return {
        moeda_local,
        moeda_gestao,
        outra_moeda,
        moeda_adicional_extra,
        error,
        handleSubmit,
        handleDelete,
        error2,
        filteredDocuments,
        data,
        handleEdit,
        submitEdit,
        isEdit,
        isEditingId,
        moedasAdicionais,
        addMoedaAdicional,
        numAdditionalFields,
        calculateTopPosition,
      };
    },
  };
  </script>
  
  <style scoped>
  .cabecalho_configuracoes {
    width: 100%;
    left: 180px;
  }
  .titulo_configuracoes {
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344c;
  }
  .conteudo_titulo {
    position: absolute;
    width: 1271px;
    height: 20px;
    left: 0%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
  }
  .menu_configuracoes {
    width: 100%;
  }
  
  .btn_missao_diplomatica{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    vertical-align: middle;
}
.btn_missao_diplomatica:hover {
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
}
.missao_diplomatica{
    position: absolute;
    width: 215px;
    height: 46px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.missao_diplomatica:hover {
  color: white;
}

.btn_orgaos{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 485px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
    vertical-align: middle;
}

.btn_orgaos:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.orgaos{
    position: absolute;
    width: 209px;
    height: 20px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.orgaos:hover {
  color: white;
}

.btn_contas{
    position: absolute;
    width: 214px;
    height: 84px;
    left: 1190px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.btn_contas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.contas{
    position: absolute;
    width: 198px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.contas:hover {
    color: white;
}
.btn_funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 1425px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_funcionarios_empresas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.cambio{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.cambio:hover {
    color: white;
}
.cambio{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 955px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.cambio:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.funcionarios_empresas:hover {
    color: white;
}

.btn_naturezas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1660px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_naturezas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.naturezas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.naturezas:hover {
    color: white;
}
.btn_moedas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 720px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.btn_moedas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.moedas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.moedas:hover {
    color: white;
}
  
  .missao_container {
    display: inline-block;
    margin: 100px 0 0 0;
  }
  form {
    max-width: 480px;
    text-align: left;
  }
  input,
  textarea {
    display: block;
    margin: 10px 0;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #eee;
  }
  
  .nome_missao {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 220px;
    top: 350px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_misao {
    box-sizing: border-box;
  
    position: absolute;
    width: 562px;
    height: 30px;
    left: 220px;
    top: 365px;
  
    background: #ffffff;
    border: 0.5px solid #c4c4c4;
  }
  .cidade_missao {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 220px;
    top: 420px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_cidade_missao {
    box-sizing: border-box;
    position: absolute;
    width: 562px;
    height: 30px;
    left: 220px;
    top: 435px;
    background: #ffffff;
    border: 0.5px solid #c4c4c4;
  }
  .endereco_missao {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 220px;
    top: 485px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_endereco_missao {
    box-sizing: border-box;
    position: absolute;
    width: 562px;
    height: 30px;
    left: 220px;
    top: 505px;
    background: #ffffff;
    border: 0.5px solid #c4c4c4;
  }
  
  .inicio_gestao {
    position: absolute;
    width: 522px;
    height: 32px;
    left: 959px;
    top: 350px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_inicio_gestao {
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    position: absolute;
    width: 262px;
    height: 30px;
    left: 959px;
    top: 365px;
    background: #ffffff;
    color: rgba(85, 82, 82, 0.25);
    font-size: 18px;
    border: 0.5px solid #c4c4c4;
  }
  .fim_gestao {
    position: absolute;
    width: 522px;
    height: 32px;
    left: 959px;
    top: 485px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51b8d5;
  }
  .input_fim_gestao {
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    position: absolute;
    width: 262px;
    height: 30px;
    left: 959px;
    top: 505px;
    background: #ffffff;
    color: rgba(85, 82, 82, 0.25);
    font-size: 18px;
    border: 0.5px solid #c4c4c4;
  }
  .salvar_missao {
    box-sizing: border-box;
    position: absolute;
    width: 132px;
    height: 30px;
    left: 800px;
    top: 590px;
    color: #fff;
    background: #51b8d5;
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: bold;
  }
  .missao-list {
    position: absolute;
    width: 980px;
    height: auto;
    left: 231px;
    top: 650px;
  
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4e4e4e;
    border: 1px solid rgb(198, 192, 192);
  }
  .dados_misso {
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    top: 0px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
  
    text-align: center;
    text-decoration-line: underline;
  
    color: #4e4e4e;
  }
  .table_missao {
    position: absolute;
    top: 60px;
  }
  
  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }
  
  table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }
  
  table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 0.35em;
  }
  
  table th,
  table td {
    padding: 0.625em;
    text-align: center;
  }
  
  table th {
    font-size: 0.85em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  
  @media screen and (max-width: 600px) {
    table {
      border: 0;
    }
  
    table caption {
      font-size: 1.3em;
    }
  
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
    }
  
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 0.8em;
      text-align: right;
    }
  
    table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
  
    table td:last-child {
      border-bottom: 0;
    }
  }

  /* general styling */
  body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.25;
  }
  
  .icon_delete {
    color: red;
    padding: 10px;
    cursor: pointer;
    border: none;
    padding: 12px 16px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .icon_edit {
    color: rgb(31, 227, 31);
    border: none;
    padding: 12px 16px;
    font-size: 16px;
    cursor: pointer;
  }
  .add_moeda_adicional {
  margin-top: 10px;
}

.moedas_extras{
  position: absolute;
  width: 444px;
  height: 32px;
  left: 420px;
  top: 350px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;
}

.moeda_adicional{
  position: absolute;
  width: 444px;
  height: 32px;
  left: 420px;
  top: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #51b8d5;}

.input_moeda_adicional{
  position: absolute;
  width: 444px;
  height: 30px;
  left: 420px;
  top: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  background: #ffffff;
  border: 0.5px solid #c4c4c4;
  letter-spacing: -0.011em;
}
.add_moeda_adicional{
  position: absolute;
  width: 200px;
  height: 32px;
  left: 970px;
  top: 580px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.011em;
  box-sizing: border-box;
  color: #fff;
  background: #51b8d5;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
  </style>