<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">RECEITAS</h1>
            </div>
            <div class="menu_configuracoes">
                <router-link to="/Receitas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_missao_diplomatica">
                        RECEITAS 
                    </div>
                </router-link>
                <router-link to="/Despesas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_orgaos">
                        DESPESAS
                    </div>
                </router-link>
                <router-link to="/Transferencias" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_contas">
                        TRANSFERÊNCIAS
                    </div>
                </router-link>
                <router-link to="/Salario" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_salarios">
                        SALÁRIOS
                    </div>
                </router-link>
            </div>
        </div>
        <div class="form_container">
            <h1 class="titulo_formulario">REGISTAR RECEITA</h1>
            <router-link to="/Receitas" style="text-decoration: none; color: inherit;">
                <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
            </router-link>
            <form @submit.prevent="handleSubmit">
                <label class="orgao_despesas">Orgão</label>
                <select required v-model="orgao_receitas" class="input_orgao_despesas">
                    <option disabled value="">--Selecione Orgão--</option>
                    <option 
                        v-for="orgao in filteredDocuments"
                        :key="orgao.id" 
                        :value="orgao.nome_orgao" 
                        v-show="orgao.sigfe_orgao">
                        {{ orgao.nome_orgao }}
                    </option>
                </select>
                <label class="n_doc_receitas">Nº Doc</label>
                <input required v-model="n_doc_receita" class="input_n_doc_receitas" type="text">
                <label class="beneficiario_receitas">Beneficiário</label>
                <input required v-model="beneficiario_receita" class="input_beneficiario_receitas" type="text">
                <label class="natureza_receita">Natureza da Receita</label>
                <select required v-model="natureza_receita" class="input_natureza_receita">
                    <option disabled value="">--Selecione a Natureza--</option>
                    <option value="Vencimento do pessoal civil do quadro">Recursos Ordinarios do Tesouro</option>
                    <option value="Vencimentos de outro pessoal civil">Emolumento Consulares</option>
                    <option value="Subsídios Do Pessoal Civil"> IRT</option>
                    <option value="Décimo terceiro mês do pessoal civil">Segurança Social</option>
                    <option value="Outras remunerações variáveis do pessoal civil">Cativos</option>
                    <option value="Contribuição do empregador">Juros</option>
                    <option value="Contribuições do empregador p/ a segurança social">Outras Receitas</option>
                </select>
                <label class="data_entrada_receita">Data de Entrada</label>
                <input required v-model="data_entrada_receita" class="input_data_entrada_receita" type="date">
                <label class="valor_mensal_contrato">Valor</label>
                <input required v-model="valor_receita" class="input_valor_mensal_contrato" type="text">
                <label class="moeda_contrato">Moeda</label>
                <select required v-model="moeda_receita" class="input_moeda_contrato">
                    <option disabled value="">--Escolha uma moeda--</option>
                    <option
                        v-for="moe in moedaDatas"
                        :key="moe.id"
                        :value="moe.moeda_local"
                        >
                        {{ moe.moeda_local }}
                        </option>
                        <option
                        v-for="moe in moedaDatas"
                        :key="moe.id"
                        :value="moe.moeda_gestao"
                        >
                        {{ moe.moeda_gestao }}
                        </option>
                        <option
                        v-for="moe in moedaDatas"
                        :key="moe.id"
                        :value="moe.outra_moeda"
                        >
                        {{ moe.outra_moeda }}
                    </option>
                </select>
                <label class="forma_cobranca_contrato">Câmbio {MOEDA DE GESTAO}</label>
                <input required v-model="cambio_receita" class="input_forma_cobranca_contrato" type="text">
                <label class="tipo_valor_contrato">Moeda KZ</label>
                <input required v-model="moeda_usd_receita" class="input_tipo_valor_contrato" type="text">
                <label class="observacoes_contrato">Descrição</label>
                <textarea v-model="descricao_receita" class="input_observacoes_contrato" rows="4" cols="50"></textarea>
                <label class="sigfe_despesa">SIGFE</label>
                <input v-model="sigfe_receita" type="checkbox" class="input_sigfe_despesas">
                <label class="pago_estado">PAGO AO ESTADO</label>
                <input v-model="pago_estado" type="checkbox" class="input_pago_estado">
                <button class="salvar_funcionario">SALVAR</button>

            </form>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'
export default {
    components: { NavbarAppHome, Sidebar },

    setup() {
        const { addDoc, error } = useCollection('receitas')
        const { error2, documents } = getCollection('orgaos')
        const { error2 :error_moeda, documents: moeda } = getCollection("moeda");

        const { user } = getUser()
        const router = useRouter()
        const filteredDocuments = ref([]);
        const moedaDatas = ref([]);
        watch(user, () => {
            if  (!user.value) {
                router.push({ name: 'Login'})
            }
        })

        const filterDocuments = () => {
            if (documents.value) {
            filteredDocuments.value = documents.value.filter(
                (doc) => {
                return doc.userId === user.value.uid
                }
            );
            }
        };
        filterDocuments();
    
        watch([documents, user], () => {
            filterDocuments();
        });
        //=================================== moeda data =================================== 
        const MoedaFilter = () => {
            if (moeda.value) {
            moedaDatas.value = moeda.value.filter(
                (doc) => {
                return doc.userId === user.value.uid
                }
            );
            }
        };
        MoedaFilter();
    
        watch([moeda, user], () => {
            MoedaFilter();
        });

        const n_doc_receita = ref('')
        const orgao_receitas = ref('')
        const beneficiario_receita = ref('')
        const natureza_receita = ref('')
        const data_entrada_receita = ref('')
        const valor_receita = ref('')
        const moeda_receita = ref('')
        const cambio_receita = ref('')
        const moeda_usd_receita = ref('')
        const descricao_receita = ref('')
        const sigfe_receita = ref('')
        const pago_estado = ref('')
        

        const handleSubmit = async () => {
            const despesa = {
                n_doc_receita: n_doc_receita.value,
                orgao_receitas: orgao_receitas.value,
                beneficiario_receita: beneficiario_receita.value, 
                natureza_receita: natureza_receita.value,
                data_entrada_receita: data_entrada_receita.value,
                valor_receita: valor_receita.value,
                moeda_receita: moeda_receita.value,
                cambio_receita: cambio_receita.value,
                moeda_usd_receita: moeda_usd_receita.value,
                descricao_receita: descricao_receita.value,
                sigfe_receita: sigfe_receita.value,
                pago_estado: pago_estado.value,
                userId: user.value.uid

            }
            await addDoc(despesa)
            if (!error.value) { 
                n_doc_receita.value = ''
                orgao_receitas.value = ''
                beneficiario_receita.value = ''
                natureza_receita.value = ''
                data_entrada_receita.value = ''
                valor_receita.value = ''
                moeda_receita.value = ''
                cambio_receita.value = ''
                moeda_usd_receita.value = ''
                descricao_receita.value = ''
                sigfe_receita.value = ''
                pago_estado.value = ''
            }
            alert('DADOS INSERIDOS COM SUCESSO!')
        }
        return { n_doc_receita, orgao_receitas, beneficiario_receita, natureza_receita,
                data_entrada_receita, valor_receita, moeda_receita, cambio_receita,
                moeda_usd_receita, descricao_receita, sigfe_receita, pago_estado, error, 
                handleSubmit, error2, documents, filteredDocuments, moedaDatas, moeda }
    }
    
}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 100%;
    left: 180px;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: 0%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.menu_configuracoes{
    width: 100%;
}

.btn_missao_diplomatica{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    vertical-align: middle;
}
.btn_missao_diplomatica:hover {
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
}
.missao_diplomatica{
    position: absolute;
    width: 215px;
    height: 46px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.missao_diplomatica:hover {
  color: white;
}

.btn_orgaos{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 485px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
    vertical-align: middle;
}

.btn_orgaos:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.orgaos{
    position: absolute;
    width: 209px;
    height: 20px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.orgaos:hover {
  color: white;
}

.btn_contas{
    position: absolute;
    width: 214px;
    height: 84px;
    left: 717px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_contas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.contas{
    position: absolute;
    width: 198px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.contas:hover {
    color: white;
}

.btn_funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 945px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_funcionarios_empresas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.funcionarios_empresas:hover {
    color: white;
}

.btn_naturezas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1176px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_naturezas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.naturezas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.naturezas:hover {
    color: white;
}

.form_container{
    box-sizing: border-box;
    position: absolute;
    width: 908px;
    height: 100%;
    left: 266px;
    top: 270px;
    background: #FFFFFF;
    border: 2px solid #51B8D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.titulo_formulario{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 1px;
    top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
}

.icon_back{
    position: absolute;
    width: 5px;
    height: 8px;
    left: 800px;
    top: 40px;
}
.orgao_despesas{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -160px;
    top: 70px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_orgao_despesas{
    box-sizing: border-box;
    position: absolute;
    width: 144px;
    height: 30px;
    left: 40px;
    top: 95px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.n_doc_receitas{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 42px;
    top: 70px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}

.beneficiario_receitas{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 246px;
    top: 70px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;

}
.input_n_doc_receitas{
    box-sizing: border-box;
    position: absolute;
    width: 144px;
    height: 30px;
    left: 240px;
    top: 95px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.input_beneficiario_receitas{
    box-sizing: border-box;
    position: absolute;
    width: 345px;
    height: 30px;
    left: 428px;
    top: 95px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.natureza_receita{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -120px;
    top: 140px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_natureza_receita{
    box-sizing: border-box;
    position: absolute;
    width: 490px;
    height: 30px;
    left: 40px;
    top: 165px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.data_entrada_receita{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 378px;
    top: 140px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_data_entrada_receita{
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 165px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.valor_mensal_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -140px;
    top: 205px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_valor_mensal_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 220px;
    height: 30px;
    left: 40px;
    top: 230px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.moeda_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 90px;
    top: 205px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_moeda_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 482px;
    height: 30px;
    left: 290px;
    top: 230px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.forma_cobranca_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -120px;
    top: 270px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_forma_cobranca_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 250px;
    height: 30px;
    left: 40px;
    top: 295px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.tipo_valor_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 135px;
    top: 270px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_tipo_valor_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 200px;
    height: 30px;
    left: 315px;
    top: 295px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.caucao_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 355px;
    top: 420px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_caucao_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 220px;
    height: 30px;
    left: 550px;
    top: 445px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.data_inicio_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -145px;
    top: 490px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_data_inicio_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 250px;
    height: 30px;
    left: 40px;
    top: 515px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.data_termino_contrato{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 375px;
    top: 490px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_data_termino_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 515px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.observacoes_contrato{
    position: absolute;
    width: 330px;
    height: 32px;
    left: -80px;
    top: 330px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_observacoes_contrato{
    box-sizing: border-box;
    position: absolute;
    width: 730px;
    height: 100px;
    left: 40px;
    top: 357px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.salvar_funcionario{
    box-sizing: border-box;
    position: absolute;
    width: 132px;
    height: 30px;
    left: 641px;
    top: 540px;
    color: #fff;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.sigfe_despesa{
    position: absolute;
    width: 59px;
    height: 32px;
    left: 80px;
    top: 480px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #00344C;
}
.input_sigfe_despesas{
    position: absolute;
    width: 15px;
    height: 15px;
    left: 53px;
    top: 480px;
    background: #51B8D5;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
}
.pago_estado{
    position: absolute;
    width: 207px;
    height: 32px;
    left: 190px;
    top: 480px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #00344C;
}
.input_pago_estado{
    position: absolute;
    width: 15px;
    height: 15px;
    left: 190px;
    top: 480px;
    background: #FFCC00;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
}
</style>