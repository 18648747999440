<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">LIVRO DE CONTROLO DE EXECUÇÃO ORÇAMENTAL</h1>
            </div>
        </div>
        <div>
    
        <div>
            <span class="icon_download">
                <i class="fa-solid fa-download" @click="generatePDF"></i>
            </span>
            <span class="icon_print">
                <i class="fa-solid fa-print"></i>
            </span>
             <router-link to="/Relatorios" style="text-decoration: none; color: inherit;">
                <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
            </router-link>
            <div class="holderrelatorio">
                <table class="livro_controlo">
                    <tr>
                        <td rowspan="3" colspan="2" class="top_tabela">
                            REPÚBLICA DE ANGOLA<br>
                            MINISTÉRIO DAS FINANÇAS <br>
                            DIREÇÃO NACIONAL DE CONTABILIDADE <br>
                        </td>
                        <td rowspan="3" colspan="5" class="top_tabela">
                            LIVRO DE CONTROLO DA EXECUÇÃO ORÇAMENTAL <br>
                            DISPOSIÇÃO DE FUNDOS
                        </td>
                    </tr>
                    <tr>
                        <td class="top_tabela">
                            DATA <br>
                            15/03/2022
                        </td>
                    </tr>
                    <tr>
                        <td class="top_tabela">
                            Folha N.
                        </td>
                    </tr>
                    <tr>
                        <td class="codigo_top_tabela">CODIGO</td>
                        <td colspan="6" class="top_tabela_central">NOME DO O.D</td>
                        <td class="top_tabela">PROVÍNCIA</td>
                    </tr>
                    <tr>
                        <td colspan="8" class="celu_top_tabela">CÉLULA ORÇAMENTAL:</td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" >DATA</td>
                        <td class="top_tabela" >N. DOC</td>
                        <td class="top_tabela" >N. CHEQUE</td>
                        <td class="top_tabela" >DOTAÇÃO</td>
                        <td class="top_tabela" >DESPESAS CABIMENTADA</td>
                        <td class="top_tabela" >DESPESA ANULADA</td>
                        <td class="top_tabela" >DESPESA PAGA</td>
                        <td class="top_tabela" >SALDO ORÇAMENTAL</td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                    </tr>
                    <tr>
                        <td class="top_tabela1" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                        <td class="top_tabela" ></td>
                    </tr>
                </table>
            </div>
            
        </div>
    </div>
    </div>
</template>

<script>
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import RelatorioOrcamentoContent from '../components/RelatorioOrcamentoContent.vue'
export default {
    components: { NavbarAppHome, Sidebar, RelatorioOrcamentoContent },

}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 80%;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: -15%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.main-nav {
    text-align: center;
    margin: 80px 30px 20px 140px;
}
.main-nav a{
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
    color: #999;
    font-size: 18px;
}
a.router-link-active{
    border-bottom: 2px solid #00ce89;
    padding-bottom: 4px;
}

.titulorelatorio{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 180px;
    top: 233px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    text-decoration-line: underline;

    color: #4E4E4E;
}

.holderrelatorio{
    box-sizing: border-box;
    position: absolute;
    padding: 10px 33px;
    width: 75%;
    height: 1091px;
    left: 251px;
    top: 310px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.icon_download{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 942px;
    top: 289px;
    cursor: pointer;
}

.icon_print{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 982px;
    top: 289px;
    cursor: pointer;
}
.icon_back{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 1020px;
    top: 289px;
    cursor: pointer;
}
p, ul, li{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.65em;
    text-align: justify;
}
.repubica{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
.ministerio{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
.missaoDiplomatica{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
img{
    width: 200px;
    height: 100px;
}
.titulorequisicao{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
table {
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .1em 0 .75em;
}

table tr {
  background-color: #ffffff;
  border: none;
  padding: .35em;
  line-height: 5px;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.unique{
     border: 1px solid #ccc;
     line-height: 5px;
     width: 300px;
}
.unique2{
     border: none;
     line-height: 5px;
     width: 450px;
     
}
.textright{
    text-align: right;
}

.livro_controlo{
    border: 1px solid rgb(0, 0, 0);
}
.top_tabela{
    border: 1px solid rgb(0, 0, 0);
    line-height: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.5em;
    height: 20px;
}
.top_tabela1{
    border: 1px solid rgb(0, 0, 0);
    line-height: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.5em;
    width: 100px;
}
.codigo_top_tabela{
    border: 1px solid rgb(0, 0, 0);
    line-height: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.5em;
    width: 20px;
    text-align: left;
}
.celu_top_tabela{
    border: 1px solid rgb(0, 0, 0);
    line-height: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.5em;
    width: 20px;
    text-align: left;
}
.top_tabela_central{
    border: 1px solid rgb(0, 0, 0);
    line-height: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.5em;
    width: 20px;
    text-align: left;
}
</style>