<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">MOVIMENTOS - AGOSTO</h1>
            </div>
            <div class="menu_configuracoes">
                <router-link to="/Modelos" style="text-decoration: none; color: inherit;">
                    <div class="btn_missao_diplomatica">
                        MODELOS
                    </div>
                </router-link>
                <router-link to="/Movimentos" style="text-decoration: none; color: inherit;">
                    <div class="btn_orgaos">
                        MOVIMENTOS
                    </div>
                </router-link>
            </div>
            <div class="linha"></div>
        </div>
        <div>
            <nav class="main-nav">
                <router-link :to="{ name: 'Janeiro' }">JANEIRO</router-link>
                <router-link :to="{ name: 'Fevereiro' }">FEVEREIRO</router-link>
                <router-link :to="{ name: 'Marco' }">MARÇO</router-link>
                <router-link :to="{ name: 'Abril' }">ABRIL</router-link>
                <router-link :to="{ name: 'Maio' }">MAIO</router-link>
                <router-link :to="{ name: 'Junho' }">JUNHO</router-link>
                <router-link :to="{ name: 'Julho' }">JULHO</router-link>
                <router-link :to="{ name: 'Agosto' }">AGOSTO</router-link>
                <router-link :to="{ name: 'Setembro' }">SETEMBRO</router-link>
                <router-link :to="{ name: 'Outubro' }">OUTUBRO</router-link>
                <router-link :to="{ name: 'Novembro' }">NOVEMBRO</router-link>
                <router-link :to="{ name: 'Dezembro' }">DEZEMBRO</router-link>
            </nav>
        </div>
        <div>
            <div class="table_contas">
                <table>
                    <tr>
                        <td rowspan="4" class="md_nome" scope="col">EMBAIXADA DA REPUBLICA DE ANGOLA NO JAPÃO</td>
                        <td class="resume" scope="col">Moeda</td>
                        <td class="resume" scope="col">Saldo Inicial</td>
                        <td class="resume" scope="col">Entradas</td>
                        <td class="resume" scope="col">Saidas</td>
                        <td class="resume" scope="col">S.Final - Autom.</td>
                        <td class="resume" scope="col">S.Final - Real</td>
                        <td class="resume" scope="col">Verificacão</td>
                    </tr>
                    <tr class="data_top">
                        <td>USD</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="data_top">
                        <td>JPY</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="data_top">
                        <td>EURO</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                </table>
                <table class="receitas_1">
                    <tr class="resume">
                        <td class="detalhes_receitas"><b>RECEITAS</b></td>
                        <td>USD</td>
                        <td>JPY</td>
                        <td>EURO</td>
                    </tr>
                    <tr class="data_top">
                        <td class="detalhes_total">TOTAL</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">Recurso do Tesouro</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">Emolumento Consulares</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">Descontos - IRT</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">Descontos - Segurança Social</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">Descontos - 10% Cativos</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">Juros</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">Outras Receitas</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                </table>
                <table class="receitas_1">
                    <tr class="resume">
                        <td class="detalhes_receitas"><b>CONVERSÕES</b></td>
                        <td>USD</td>
                        <td>JPY</td>
                        <td>EURO</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">Entrada</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">Saida</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                </table>
                <table class="receitas_1">
                    <tr>
                        <td colspan="4" class="detalhes_receitas1"><b>IMPORTANCIAS ENTRE O ESTADO E OUTROS ENTES</b></td>
                    </tr>
                    <tr class="resume">
                        <td class="detalhes_receitas"></td>
                        <td>USD</td>
                        <td>JPY</td>
                        <td>EURO</td>
                    </tr>
                    <tr class="data_top">
                        <td class="detalhes_total">TOTAL</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">Rec. Emol.</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">Outras Receitas</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">IRT</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">S. Social</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">10% Cativos</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                </table>
                <table class="receitas_1">
                    <tr class="resume">
                        <td class="detalhes_receitas"><b>Abono de Familiar</b></td>
                        <td>USD</td>
                        <td>JPY</td>
                        <td>EURO</td>
                    </tr>
                    <tr class="data_top">
                        <td class="detalhes_total">TOTAL</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1">-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                </table>
                <table class="receitas_1">
                    <tr class="resume">
                        <td class="detalhes_receitas"><b>INVESTIMENTO</b></td>
                        <td>USD</td>
                        <td>JPY</td>
                        <td>EURO</td>
                    </tr>
                    <tr class="data_top">
                        <td class="detalhes_total">TOTAL</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                </table>
                <table class="despesas">
                    <tr>
                        <td class="detalhes_despesas"><b>DESPESAS</b></td>
                        <td class="despesas_cabecalho" colspan="3"><b>Recursos Ordinarios do Tesouro</b></td>
                        <td class="despesas_cabecalho" colspan="3"><b>Recursos Próprios</b></td>
                    </tr>
                    <tr class="resume">
                        <td class="detalhes_despesas_2">Natureza de Despesas</td>
                        <td class="receitas_detalhes">USD</td>
                        <td class="receitas_detalhes">JPY</td>
                        <td class="receitas_detalhes">EURO</td>
                        <td class="receitas_detalhes">USD</td>
                        <td class="receitas_detalhes">JPY</td>
                        <td class="receitas_detalhes">EURO</td>
                    </tr>
                    <tr class="data_top">
                        <td class="detalhes_total_despesas">TOTAL</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="cabecalho">Vencimento do Pessoal do Quadro</td>
                        <td class="cabecalho">-</td>
                        <td class="cabecalho">-</td>
                        <td class="cabecalho">-</td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Salario Liquido</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">IRT</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">SS</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">10% Cativos</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="sub_cabecalho">Salario Iliquido</td>
                        <td class="sub_cabecalho">-</td>
                        <td class="sub_cabecalho">-</td>
                        <td class="sub_cabecalho">-</td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="cabecalho">Vencimento de Outro Pessoal Civil</td>
                        <td class="cabecalho"></td>
                        <td class="cabecalho"></td>
                        <td class="cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Salario Liquido</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Descontos</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="sub_cabecalho">Salario Iliquido</td>
                        <td class="sub_cabecalho">-</td>
                        <td class="sub_cabecalho">-</td>
                        <td class="sub_cabecalho">-</td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas"><b>Outras Despesas</b></td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Abono de Familia</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Salario do Pessoal Civil</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Decimo Terceiro Mes do Pessoal Civil</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Outras Remunerações Variaveis Ou Eventuais do P. Civil</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Contribuição do Empregador para  a Segurança Social</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                        <td class="sub_cabecalho"></td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="cabecalho">Bens e Servicos</td>
                        <td class="cabecalho"></td>
                        <td class="cabecalho"></td>
                        <td class="cabecalho"></td>
                        <td class="cabecalho"></td>
                        <td class="cabecalho"></td>
                        <td class="cabecalho"></td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Combustíveis E Lubrificantes</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Viveres E Generos Alimenticios</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Seguro</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Outros Materias de Consumo Corrente</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Outros Materias e Utensilios Duradouros</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Serviços de Telecomunicação</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Serviços de Saúde</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Serviços de Ensino e Formação</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Serviços de Água e Electricidade</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Serviços de Hospedagem e Alimentação</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Serviços de Estudo, Fiscalização e Consultoria</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Serviço de Protecção e Vigilância</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Serviços Bancários</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Serviços de Processamento de Dados</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Serviços de Limpeza e Saneamento</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Serviços de Manutenção e Conservação</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Bilhete de Passagem</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Subsidio de Deslocação</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Serviços de Transportação de Pessoas e Bens</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Encargos Aduanerios e Portuarios</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Renda de Imoveis</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="detalhes_1_despesas">Outros Serviços</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr class="dados_pequenos">
                        <td class="sub_cabecalho">Sub-Total</td>
                        <td class="sub_cabecalho">-</td>
                        <td class="sub_cabecalho">-</td>
                        <td class="sub_cabecalho">-</td>
                        <td class="sub_cabecalho">-</td>
                        <td class="sub_cabecalho">-</td>
                        <td class="sub_cabecalho">-</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
export default {
    components: { NavbarAppHome, Sidebar }
}
</script>

<style scoped>
.main-nav {
    position: absolute;
    text-align: center;
    left: 247px;
    top: 260px;
}
.main-nav a{
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
    color: #51B8D5;
    font-size: 0.8em;
    top: 280px;
}
a.router-link-active{
    border-bottom: 2px solid #51B8D5;
    padding-bottom: 4px;
}
.linha{
    position: absolute;
    width: 1100px;
    height: 0px;
    left: 252px;
    top: 255px;
    border: 1px solid #51B8D5;
}

.table_contas{
    position: absolute;
    top: 300px;
    left: 252px;
    width: 1100px;
}

table {
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .1em 0 .75em;
}

table tr {
  background-color: #ffffff;
  border: none;
  padding: .35em;
  line-height: 5px;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}
.md_nome{
    background-color: rgb(241, 241, 241);
    width: 300px;
    font-size: 0.06em;
    height: 30px;
    font-family: 'Inter';
    font-style: normal;
    border: 2px  solid #cfcfcf;
}
.resume{
    background-color: rgb(144, 201, 239);
    width: 100px;
    height: 30px;
    font-size: 0.06em;
    border: 2px solid #cfcfcf;
    text-align: center;
}
.data_top{
    background-color: rgb(241, 241, 241);
    width: 100px;
    font-size: 0.7em;
    height: 20px;
    font-family: 'Inter';
    font-style: normal;
}
.receitas_1{
    width: 300px;
    font-size: 0.7em;
    margin: 20px 10px 10px 0px;
}
.dados_pequenos{
    font-size: 0.5em;
    font-family: 'Inter';
    font-style: normal;
}
.detalhes_1{
    width: 120px;
    height: 10px;
    text-align: left;
}
.detalhes_receitas{
    width: 120px;
    height: 10px;
    text-align: center;
    color: red;
    background-color: rgb(144, 201, 239);
}
.detalhes_receitas1{
    width: 200px;
    height: 10px;
    text-align: center;
    color: red;
    background-color: rgb(144, 201, 239);
    border: 2px solid #cfcfcf;
    font-size: 10px;
}
.detalhes_total{
    width: 120px;
    height: 10px;
    text-align: right;
}
.despesas{
    position: absolute;
    width: 750px;
    font-size: 0.7em;
    margin: 20px 10px 10px 0px;
    top: 95px;
    left: 350px;
}
.receitas_detalhes{
    width: 120px;
    height: 10px;
    background-color: rgb(144, 201, 239);
}
.despesas_cabecalho{
    text-align: center;
    font-size: 12px;
    background-color: rgb(144, 201, 239);
}
.detalhes_despesas{
    width: 300px;
    height: 10px;
    text-align: center;
    color: red;
    background-color: rgb(144, 201, 239);
}

.detalhes_despesas_2{
    width: 300px;
    height: 10px;
    text-align: center;
    background-color: rgb(144, 201, 239);
}

.detalhes_total_despesas{
    width: 300px;
    height: 10px;
    text-align: center;
}

.detalhes_1_despesas{
    width: 300px;
    height: 5px;
    text-align: left;
    font-size: 10px;
    font-family: 'Inter';
    font-style: normal;
}

.cabecalho{
    width: 300px;
    height: 5px;
    text-align: left;
    font-size: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    background-color: rgb(28, 152, 234);
}
.sub_cabecalho{
    width: 300px;
    height: 5px;
    text-align: left;
    font-size: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    background-color: rgb(156, 157, 158);
}
</style>