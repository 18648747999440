<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <ConfiguracaoNav/>
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">CONFIGURAÇÕES - ORGÃOS</h1>
            </div>
        </div>
        <div>
            <div class="formulario_orgaos">
                <h1>REGISTAR ORGÃOS</h1>
                <form @submit.prevent="isEdit ? submitEdit() : handleSubmit()">
                    <label class="nome_orgao">Nome do Órgão</label>
                    <input required v-model="nome_orgao" type="text" class="input_orgao">
                    <label class="departamento_orgao">Departamento</label>
                    <input v-model="departamento_orgao" class="input_departamento_orgao" type="text">
                    <label class="nr_fiscal">Nr. Fiscal</label>
                    <input v-model="nr_fiscal" type="text" class="input_nr_fiscal">
                    <label class="nr_contribuinte">Nr. Contribuinte</label>
                    <input v-model="nr_contribuinte" class="input_nr_contribuinte" type="text">
                    <label class="ramo_actividade">Ramo de Actividade</label>
                    <input v-model="ramo_actividade" type="text" class="input_ramo_actividade">
                    <label class="tipo_departamento">Tipo de Propriedade</label>
                    <input v-model="tipo_departamento" class="input_tipo_departamento" type="text">
                    <input v-model="sigfe_orgao" type="hidden" class="input_estado_check" true-value="Activo" false-value="Inactivo"/>
                    <label class="check_sigfe_orgao">SIGFE</label>
                    <input v-model="sigfe" type="checkbox" class="input_sigfe_check" true-value="Activo" false-value="Inactivo">
                    <button class="salvar_orgao">SALVAR</button>
                </form>
            </div>
        </div>
        <div class="missao-list">
            <h2 class="dados_misso">LISTA DE ÓRGÃOS REGISTADOS</h2>
            <div class="table_missao">
                <table>
                <thead>
                    <tr>
                        <th scope="col">Órgãos</th>
                        <th scope="col">Departamento</th>
                        <th scope="col">SIGFE</th>
                        <th scope="col">Estado</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody v-if="data && data.length > 0">
                    <tr v-for="m in data" :key="m.id">
                        <td>{{ m.nome_orgao }}</td>
                        <td>{{ m.departamento_orgao }}</td>
                        <td>{{ m.sigfe }}</td>
                        <div @click="togglActive(m.id, m.sigfe_orgao)" style="text-align: -webkit-center;">
                            <td v-if="m.sigfe_orgao==true"><button type="button" class="btn btn-success">Activo</button></td>
                            <td v-else><button class="btn btn-danger"  type="button">Inactivo</button></td>
                        </div>
                        <td><button title="Eliminar" class="icon_delete" @click="handleDelete(m.id)"><i class="fas fa-trash-alt"></i></button><button title="Editar" class="icon_edit" @click="handleEdit(m.id)"><i class="fas fa-edit"></i></button></td>
                    </tr>
                </tbody>
            </table>
            <div class="error">{{ error2 }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import useDocument from '../composables/useDocument'
import getCollection from '../composables/getCollection'
import getUser from '../composables/getUser'
import ConfiguracaoNav from '../components/ConfiguracaoNav.vue'
import { useRouter } from 'vue-router'
import { projectFirestore } from '../firebase/config'
export default {
    components: { NavbarAppHome, Sidebar, ConfiguracaoNav },

    setup() {
        const { user } = getUser()
        console.log(user, "useruser");
        const { addDoc, error } = useCollection('orgaos')
        const { error2, documents } = getCollection('orgaos')
        const data = ref([]);
        const filteredDocuments = ref([]);
        const router = useRouter()
        const nome_orgao = ref('')
        const departamento_orgao = ref('')
        const sigfe_orgao = ref(true);
        const sigfe = ref("")
        const nr_fiscal = ref('')
        const nr_contribuinte = ref('')
        const ramo_actividade = ref('')
        const tipo_departamento = ref('')
        let new_obj = ref();
        const isEdit = ref(false);
        const isEditingId = ref(null);

        const filterDocuments = () => {
            if (documents.value) {
                filteredDocuments.value = documents.value.filter(
                (doc) => doc.userId === user.value.uid
                );
                data.value = filteredDocuments.value;
            }
        };

        const togglActive = async (item, dataActive) => {
            try {
                let collectionRef = await projectFirestore
                .collection("orgaos")
                .doc(item)
                .update({ sigfe_orgao: !dataActive});
            } catch (error) {
                console.log("Error updating document:", error);
            }
            };

        filterDocuments();

        watch([documents, user], () => {
            filterDocuments();
        });

        const handleDelete = async (id) => {
            try {
                const docRef = projectFirestore.collection("orgaos").doc(id);
                await docRef.delete();
                console.log("Document successfully deleted.");
            } catch (error) {
                console.log("Error deleting document:", error.message);
            }
        };
        const handleSubmit = async () => {
            const orgao = {
                nome_orgao: nome_orgao.value,
                departamento_orgao: departamento_orgao.value,
                nr_fiscal: nr_fiscal.value,
                nr_contribuinte: nr_contribuinte.value,
                ramo_actividade: ramo_actividade.value,
                tipo_departamento: tipo_departamento.value,
                sigfe_orgao: sigfe_orgao.value ? sigfe_orgao.value : 'false',
                sigfe: sigfe.value ? sigfe.value:'Inactivo',
                userId: user.value.uid,
            };
            await addDoc(orgao)
            if (!error.value) {
                nome_orgao.value = ''
                departamento_orgao.value = ''
                nr_fiscal.value = ''
                nr_contribuinte.value = ''
                ramo_actividade.value = ''
                tipo_departamento.value = ''
                sigfe_orgao.value =true;
                sigfe.value="";
            }
            alert('DADOS INSERIDOS COM SUCESSO!')
        };
        const handleEdit = async (id) => {
            try {
                const docRef = projectFirestore.collection("orgaos").doc(id);
                await docRef.get().then(async (doc) => {
                if (doc.exists) {
                    let id = await doc.id;
                    new_obj.value = await { ...doc.data(), id };
                    nome_orgao.value = new_obj.value.nome_orgao;
                    departamento_orgao.value = new_obj.value.departamento_orgao;
                    nr_fiscal.value = new_obj.value.nr_fiscal;
                    nr_contribuinte.value = new_obj.value.nr_contribuinte;
                    ramo_actividade.value = new_obj.value.ramo_actividade;
                    tipo_departamento.value = new_obj.value.tipo_departamento;
                    sigfe_orgao.value = new_obj.value.sigfe_orgao;
                    sigfe.value = new_obj.value.sigfe;
                }
                });
                isEdit.value = true;
                isEditingId.value = id;
            } catch (error) {
                console.log("Error deleting document:", error.message);
            }
            };

        const submitEdit = async () => {
            alert("submitEditsubmitEdit");
            const docRef = projectFirestore
                .collection("orgaos")
                .doc(isEditingId.value);
            await docRef.update({
                nome_orgao: nome_orgao.value,
                departamento_orgao: departamento_orgao.value,
                nr_fiscal: nr_fiscal.value,
                nr_contribuinte: nr_contribuinte.value,
                ramo_actividade: ramo_actividade.value,
                tipo_departamento: tipo_departamento.value,
                sigfe_orgao: sigfe_orgao.value,
                sigfe:sigfe.value,
                userId: user.value.uid,
            });
            isEdit.value = false;
            nome_orgao.value = null;
            departamento_orgao.value = null;
            nr_fiscal.value = null;
            nr_contribuinte.value = null;
            ramo_actividade.value = null;
            tipo_departamento.value = null;
            sigfe_orgao.value = null;
            sigfe.value=null;
            };
        return {
            nome_orgao,
            departamento_orgao,
            nr_fiscal,
            nr_contribuinte,
            ramo_actividade,
            tipo_departamento,
            sigfe_orgao,
            sigfe,
            error,
            handleSubmit,
            projectFirestore,
            error2,
            filteredDocuments,
            data,
            handleEdit,
            submitEdit,
            isEdit,
            isEditingId,
            handleDelete,
            togglActive
        };
    },
};
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 100%;
    left: 180px;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: 0%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.menu_configuracoes{
    width: 100%;
}
.btn_missao_diplomatica{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    vertical-align: middle;
}
.btn_missao_diplomatica:hover {
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
}
.missao_diplomatica{
    position: absolute;
    width: 215px;
    height: 46px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.missao_diplomatica:hover {
  color: white;
}

.btn_orgaos{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 485px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
    vertical-align: middle;
}

.btn_orgaos:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.orgaos{
    position: absolute;
    width: 209px;
    height: 20px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.orgaos:hover {
  color: white;
}

.btn_contas{
    position: absolute;
    width: 214px;
    height: 84px;
    left: 1190px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.btn_contas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.contas{
    position: absolute;
    width: 198px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.contas:hover {
    color: white;
}
.btn_funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 1425px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_funcionarios_empresas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.cambio{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.cambio:hover {
    color: white;
}
.cambio{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 955px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.cambio:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.funcionarios_empresas:hover {
    color: white;
}

.btn_naturezas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1660px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_naturezas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.naturezas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.naturezas:hover {
    color: white;
}
.btn_moedas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 720px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.btn_moedas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.moedas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.moedas:hover {
    color: white;
}

.formulario_orgaos {
    display: absolute;
    margin: 250px 0 0 0;
    top: 60%;
}
h1 {
    position: absolute;
    width: 281px;
    height: 20px;
    left: 190px;
    top: 295px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    text-decoration-line: underline;
    color: #4E4E4E;
}
.nome_orgao {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 80px;
    top: 340px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_orgao {
    box-sizing: border-box;
    position: absolute;
    width: 400px;
    height: 30px;
    left: 250px;
    top: 365px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.departamento_orgao {
    position: absolute;
    width: 444px;
    height: 32px;
    left: 540px;
    top: 340px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}

.input_departamento_orgao {
    box-sizing: border-box;
    position: absolute;
    width: 346px;
    height: 30px;
    left: 720px;
    top: 365px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.nr_fiscal{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 60px;
    top: 410px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_nr_fiscal{
    box-sizing: border-box;
    position: absolute;
    width: 400px;
    height: 30px;
    left: 250px;
    top: 435px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.nr_contribuinte{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 548px;
    top: 410px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_nr_contribuinte{
    box-sizing: border-box;
    position: absolute;
    width: 346px;
    height: 30px;
    left: 720px;
    top: 435px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.ramo_actividade{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 95px;
    top: 480px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_ramo_actividade{
    box-sizing: border-box;
    position: absolute;
    width: 400px;
    height: 30px;
    left: 250px;
    top: 505px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.tipo_departamento{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 559px;
    top: 480px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_tipo_departamento{
    box-sizing: border-box;
    position: absolute;
    width: 346px;
    height: 30px;
    left: 720px;
    top: 505px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.salvar_orgao {
    box-sizing: border-box;
    position: absolute;
    width: 132px;
    height: 30px;
    left: 1110px;
    top: 505px;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
}

.check_sigfe_orgao{
    position: absolute;
    width: 56px;
    height: 32px;
    left: 1137px;
    top: 450px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #00344C;
}

.input_sigfe_check{
    position: absolute;
    width: 15px;
    height: 15px;
    left: 1107px;
    top: 450px;

    background: #51B8D5;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
}

.missao-list{
    position: absolute;
    width: 980px;
    height: auto;
    left: 231px;
    top: 650px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
    border: 1px solid rgb(198, 192, 192);
}
.dados_misso{
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    top: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;

    text-align: center;
    text-decoration-line: underline;

    color: #4E4E4E;
}
.table_missao{
    position: absolute;
    top: 60px;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}
/* general styling */
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.25;
}

.icon_delete{
    color:red;
    padding: 10px;
    cursor: pointer;
    border: none;
    padding: 12px 16px;
    font-size: 16px;
    cursor: pointer;
}

.icon_edit{
    color: rgb(31, 227, 31);
    border: none;
    padding: 12px 16px;
    font-size: 16px;
    cursor: pointer;
}

.btn {
    --bs-btn-padding-x: 0.75rem;
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: var(--bs-border-radius);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 hsla(0,0%,100%,0.15),0 1px 1px rgba(0,0,0,0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb),.5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color)
}

.btn-check+.btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color)
}

.btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg)
}

.btn-check:focus-visible+.btn,.btn:focus-visible {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow)
}

.btn-check:checked+.btn,.btn.active,.btn.show,.btn:first-child:active,:not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color)
}

.btn-check:checked+.btn:focus-visible,.btn.active:focus-visible,.btn.show:focus-visible,.btn:first-child:active:focus-visible,:not(.btn-check)+.btn:active:focus-visible {
    box-shadow: var(--bs-btn-focus-box-shadow)
}

.btn.disabled,.btn:disabled,fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity)
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #0d6efd;
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd
}

.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #6c757d;
    --bs-btn-border-color: #6c757d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130,138,145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d
}
.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #198754;
    --bs-btn-border-color: #198754;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #157347;
    --bs-btn-hover-border-color: #146c43;
    --bs-btn-focus-shadow-rgb: 60,153,110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #146c43;
    --bs-btn-active-border-color: #13653f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #198754;
    --bs-btn-disabled-border-color: #198754
}
.btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: #dc3545;
    --bs-btn-border-color: #dc3545;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #bb2d3b;
    --bs-btn-hover-border-color: #b02a37;
    --bs-btn-focus-shadow-rgb: 225,83,97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #b02a37;
    --bs-btn-active-border-color: #a52834;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #dc3545;
    --bs-btn-disabled-border-color: #dc3545
}
</style>