<template>
    <header v-if="user">
        <img class="logo" src="../assets/logo_prescom.jpg" alt="logotipo Prescon">
        <div class="nomeMissao">
            {{nome_missao}}
        </div>
        <div class="usuarioConta">
            <p>Bem Vindo(a) <br>{{ user.displayName }}</p>
        </div>
        <div>
            <button class="logout_button" @click="handleClick">Logout</button>
        </div>
    </header>
</template>

<script>
import useLogout from '../composables/useLogout'
import getUser from '../composables/getUser'
import { useRouter } from "vue-router";
import { ref, watch } from "vue";
import getCollection from "../composables/getCollection";
export default {
    setup() {
        const router = useRouter();
        const { logout, error } = useLogout()
        const { user } = getUser()
        const { error2, documents } = getCollection("missao_diplomatica");
        const nome_missao = ref("");
        const filteredDocuments = ref([]);

        watch(documents, async () => {
      if (documents.value) {
        filteredDocuments.value = documents.value.filter(
          (doc) => doc.userId === user.value.uid
        );
        filteredDocuments.value.sort((a, b) => a.today_date - b.today_date);
        const obj = JSON.parse(JSON.stringify(filteredDocuments.value));
        if(obj !== undefined){
          const entries = Object.entries(obj);
          const [firstKey, firstValue] = entries[0];
          nome_missao.value = firstValue.nome_missao;
        } else {
          nome_missao.value = null;
        }
      }
    });

    const handleSubmit = async () => {
      const missao = {
        nome_missao: nome_missao.value,
        userId: user.value.uid,
      };
      const res = await addDoc(missao);
      if (!error.value) {
        nome_missao.value = "";
      }
      alert("DADOS INSERIDOS COM SUCESSO!");
    };

        const handleClick = async () => {
            await logout()
            if (!error.value) {
                console.log('user logged out')
            }
        }

        return { handleClick, user, nome_missao }
    }

}
</script>

<style>
header {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    padding: 0px;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(221, 219, 219);
}

header img{
    width: 200px;
    height: 80px;
    padding: 0px 60px;
}

header a {
    color: #000;
    text-decoration: none;
    margin-left: 10px;
    padding-right: 60px;
}

header a.router-link-active {
    color: rgb(208, 223, 241);
    font-weight: bold;
    text-decoration: underline;
}

.logout_button{
    background: rgb(234, 65, 65);
    color: white;
    width: 80px;
    height: 40px;
    font-weight: bold;
    border-radius: 20px;
    border: 0;
    padding: 10px 20px;
    cursor: pointer;
}
.usuarioConta{
     font-weight: bold;
     margin: -150px -250px -150px -150px;
}
</style>