<template>
    <div class="pagination">
      <button @click="goToPage(currentPage - 1)" :disabled="currentPage === 1">Previous</button>
      <span>{{ currentPage }} / {{ totalPages }}</span>
      <button @click="goToPage(currentPage + 1)" :disabled="currentPage === totalPages">Next</button>
    </div>
  </template>
  
  <script>
  import { ref, toRefs } from "vue";
  
  export default {
    props: {
      totalItems: Number,
      itemsPerPage: Number,
      currentPage: Number,
    },
    setup(props, { emit }) {
      const { totalItems, itemsPerPage, currentPage } = toRefs(props);
      const totalPages = ref(Math.ceil(totalItems.value / itemsPerPage.value));
  
      const goToPage = (page) => {
        if (page >= 1 && page <= totalPages.value) {
          emit("update:currentPage", page);
        }
      };
  
      return {
        totalPages,
        goToPage,
      };
    },
  };
  </script>
  
  <style scoped>
    /* Add your pagination styles here */
  </style>