<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">MODELO 4</h1>
            </div>
        </div>
        <div>
    
        <div>
            <span class="icon_download">
                <i class="fa-solid fa-download" @click="generatePDF"></i>
            </span>
            <span class="icon_print">
                <i class="fa-solid fa-print"></i>
            </span>
             <router-link to="/Relatorios" style="text-decoration: none; color: inherit;">
                <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
            </router-link>
            <div class="holderrelatorio">
                <table class="element-to-pdf">
                    <tr>
                        <td>
                            <img src="../assets/Insignia.jpg" alt="Insignia da Republica">
                            <p class="ministerio">REPÚBLICA DE ANGOLA </p>
                            <p class="ministerio">MINISTÉRIO DAS RELAÇÕES EXTERIORES </p>
                            <p class="missaoDiplomatica">EMBAIXADA DA REPÚBLICA DE ANGOLA NO JAPÃO</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="titulorequisicao">
                                <b>OPERAÇÕES DE TESOURARIA</b>
                            </p>
                            <p class="titulorequisicao">
                                <b>Pague-se</b>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="titulorequisicao">
                                <b>O EMBAIXADOR</b>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="titulorequisicao">
                                <b> NOME_EMBAIXADOR </b>
                            </p>
                        </td>
                    </tr>
                </table>
                <table class="moeda_local">
                    <tr>
                        <td> Moeda Local</td>
                    </tr>
                    <tr class="alinhamento">
                        <td>USD</td>
                    </tr>
                </table>
                <table class="cambio">
                    <tr>
                        <td>Câmbio</td>
                    </tr>
                    <tr class="alinhamento">
                        <td></td>
                    </tr>
                </table>
                <table class="moeda_nacional">
                    <tr>
                        <td colspan="2">Moeda Nacional</td>
                    </tr>
                    <tr class="alinhamento">
                        <td>Kz</td>
                        <td>0,00</td>
                    </tr>
                </table>
                <table class="info_data">
                    <tr>
                        <td class="alinhamento1"><b>Nº</b></td>
                        <td class="alinhamento1"><b></b></td>
                        <td class="alinhamento1"><b>Ano:</b></td>
                        <td class="alinhamento1"><b>2023</b></td>
                        <td class="alinhamento1"><b>Mês de:</b></td>
                        <td class="alinhamento1"><b>SETEMBRO</b></td>
                    </tr>
                </table>
                <table class="tabela_principal">
                    <tr>
                        <td class="alinhamento1">Nome: __________________________________________</td>
                    </tr>
                    <tr>
                        <td class="alinhamento1">Categoria:_______________________________________</td>
                        <td class="alinhamento1">Letra:___________________________________________</td>
                    </tr>
                    <tr>
                        <td class="alinhamento1">Diploma de nomeação:______________________________</td>
                        <td class="alinhamento1">DR:___________________________________________</td>
                    </tr>
                    <tr>
                        <td class="alinhamento1">Visto do tribunal administrativo:__________________</td>
                        <td class="alinhamento1">DR:___________________________________________</td>
                    </tr>
                </table>
                <table class="tabela_principal2">
                    <tr>
                        <td class="alinhamento2"></td>
                        <td class="alinhamento3">USD</td>
                        <td class="alinhamento3">Moeda Nacional</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2">Vencimento</td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2">Suplemento de vencimento</td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2">Abono de familia</td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2">Outras Remunerações Variavéis do P. Civil</td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2">Décimo Terceiro (13º)</td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2">Ajudas de custo de embarque</td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2">Subsídios de deslocação</td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2">Subsídio de instalação</td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2">Subsídio de representação</td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2">Subsídio de Férias</td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento5"></td>
                        <td class="alinhamento6">-</td>
                        <td class="alinhamento6">-</td>
                    </tr>
                </table>
                <table class="data_emicao">
                    <tr>
                        <td>Tokyo, 23 Setembro 2023</td>
                    </tr>
                </table>
                <table class="assinatura">
                    <tr>
                        <td>O Encarregado do Serviço</td>
                    </tr>
                    <tr>
                        <td>____________________________</td>
                    </tr>
                </table>
                <table class="rodape">
                    <tr>
                        <td class="alinhamento_rodape-l">Lugar para o carimbo da tesouraria</td>
                        <td class="alinhamento_rodape-r">
                            Cheque Nº     ________________<br>
                            <b>Recebi</b><br>
                            _______________________
                        </td>
                    </tr>
                </table>
                <table class="tabela_principal3">
                    <tr>
                        <td colspan="3"><b>DESCONTOS</b></td>
                    </tr>
                    <tr>
                        <td colspan="3"><b>Guia Nº ___________</b></td>
                    </tr>
                    <tr>
                        <td class="alinhamento5"><b>Receita própria</b></td>
                        <td class="alinhamento3"><b>USD</b></td>
                        <td class="alinhamento3"><b>Kwanza</b></td>
                    </tr>
                    <tr>
                        <td class="alinhamento2">Recolhimento de Caução</td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2"></td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2"></td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2"></td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2"></td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2"></td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento5"></td>
                        <td class="alinhamento6">-</td>
                        <td class="alinhamento6">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2"><b>Operações de tesouraria</b></td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2">IRT</td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2">INSS</td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2"></td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento2"></td>
                        <td class="alinhamento4">-</td>
                        <td class="alinhamento4">-</td>
                    </tr>
                    <tr>
                        <td class="alinhamento5"></td>
                        <td class="alinhamento6">-</td>
                        <td class="alinhamento6">-</td>
                    </tr>
                    <tr>
                        <td colspan="3"><b>OBSERVAÇÕES</b></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import RelatorioOrcamentoContent from '../components/RelatorioOrcamentoContent.vue'
export default {
    components: { NavbarAppHome, Sidebar, RelatorioOrcamentoContent },

}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 80%;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: -15%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.main-nav {
    text-align: center;
    margin: 80px 30px 20px 140px;
}
.main-nav a{
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
    color: #999;
    font-size: 18px;
}
a.router-link-active{
    border-bottom: 2px solid #00ce89;
    padding-bottom: 4px;
}

.titulorelatorio{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 180px;
    top: 233px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    text-decoration-line: underline;

    color: #4E4E4E;
}

.holderrelatorio{
    box-sizing: border-box;
    position: absolute;
    padding: 10px 33px;
    width: 1070px;
    height: 2300px;
    left: 251px;
    top: 310px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.icon_download{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 942px;
    top: 289px;
    cursor: pointer;
}

.icon_print{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 982px;
    top: 289px;
    cursor: pointer;
}
.icon_back{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 1020px;
    top: 289px;
    cursor: pointer;
}
p, ul, li{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.65em;
    text-align: justify;
}
.repubica{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
.ministerio{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
.missaoDiplomatica{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
img{
    width: 200px;
    height: 100px;
}
.titulorequisicao{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
}
table {
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .1em 0 .75em;
}

table tr {
  background-color: #ffffff;
  border: none;
  padding: .35em;
  line-height: 5px;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.unique{
     border: 1px solid #ccc;
     line-height: 5px;
     width: 300px;
}
.unique2{
     border: none;
     line-height: 5px;
     width: 450px;
     
}
.textright{
    text-align: right;
}
.movemnetos_debito{
    border: 2px solid #000000;
    width: 900px;
}
.tipoconta{
    border: 2px solid #000000;
    width: 300px;
    height: 80px;
    text-align: center;
    font-size: 0.8em;
    background-color: rgb(185, 184, 184);
}
.tipoconta2{
    border: 2px solid #000000;
    width: 300px;
    height: 80px;
    text-align: center;
    font-size: 0.8em;
    background-color: rgb(185, 184, 184);
    line-height: 20px;
}
.titulotabela{
    border: 2px solid #000000;
    text-align: center;
    font-size: 0.8em;
    height: 40px;
    line-height: 20px;
    background-color: rgb(251, 208, 208);
}
.titulotabela2{
    border: 2px solid #000000;
    text-align: center;
    font-size: 0.8em;
    height: 40px;
    line-height: 20px;
    background-color: rgb(222, 251, 208);
}
.moedagestao{
    border: 2px solid #000000;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(251, 208, 208);
}
.moedalocal{
    border: 2px solid #000000;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(251, 208, 208);
}
.outramoeda{
    border: 2px solid #000000;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(251, 208, 208);
}
.moedanacional{
    border: 2px solid #000000;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(251, 208, 208);
}
.moedagestao2{
    border: 2px solid #000000;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(222, 251, 208);
}
.moedalocal2{
    border: 2px solid #000000;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(222, 251, 208);
}
.outramoeda2{
    border: 2px solid #000000;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(222, 251, 208);
}
.moedanacional2{
    border: 2px solid #000000;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(222, 251, 208);
}
.moedagestao3{
    border: 2px solid #000000;
    height: 22px;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(222, 251, 208);
}
.moedalocal3{
    border: 2px solid #000000;
    height: 22px;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(222, 251, 208);
}
.outramoeda3{
    border: 2px solid #000000;
    height: 22px;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(222, 251, 208);
}
.moedanacional3{
    border: 2px solid #000000;
    height: 22px;
    width: 200px;
    text-align: center;
    font-size: 0.7em;
    background-color: rgb(222, 251, 208);
}
.somatitulo{
    text-align: right;
    font-size: 1em;
    background-color: rgb(185, 184, 184);
}
.soma{
    border: 2px solid #000000;
    border: 2px solid #000000;
    text-align: center;
    font-size: 1em;
    background-color: rgb(185, 184, 184);

}
.normalcell{
    border: 2px solid #000000;
    text-align: left;
    font-size: 0.7em;
}
.element-to-pdf{
    width: 1000px;
}
.sector{
    position: absolute;
    top: 280px;
    left: 50px;
    width: 200px;
    font-size: 0.9em;
}
.cambio{
    position: absolute;
    top: 280px;
    left: 800px;
    width: 200px;
    font-size: 0.9em;
}

.subtabela6{
    position: absolute;
    width: 300px;
    left: 10px;
    top: 1100px;
    font-size: .7em;
}
.subtabela7{
    position: absolute;
    height: 80px;
    left: 35px;
    top: 1180px;
    width: 1000px;
    font-size: .7em;
    border: 1px solid #000000;
}
.balanco_contas{
    border: 2px solid #000000;
    position: absolute;
    top: 351px;
    text-align: left;
    width: 1000px;
    background-color: green;
}
.balanco_contas1{
    border: 2px solid #000000;
    position: absolute;
    top: 378px;
    width: 1000px;
}
.balanco_contas2{
    border: 2px solid #000000;
    position: absolute;
    top: 520px;
    width: 1000px;
}
.balanco_contas3{
    border: 2px solid #000000;
    position: absolute;
    top: 660px;
    width: 1000px;
}
.balanco_contas4{
    border: 2px solid #000000;
    position: absolute;
    top: 700px;
    width: 1000px;
}
.balanco_contas5{
    border: 2px solid #000000;
    position: absolute;
    top: 840px;
    width: 1000px;
}
.balanco_contas6{
    border: 2px solid #000000;
    position: absolute;
    top: 980px;
    width: 1000px;
    background-color: rgb(185, 184, 184);
}
.balanco_contas7{
    border: 2px solid #000000;
    position: absolute;
    top: 1020px;
    width: 1000px;
    background-color: rgb(185, 184, 184);
}
.balanco_contas8{
    border: 2px solid #000000;
    position: absolute;
    top: 1060px;
    width: 1000px;
}
.descricao{
    width: 150px;
    border: 1px solid #000000;
    text-align: left;
    font-size: 0.9em;
}
.ml{
    width: 50px;
    border: 1px solid #000000;
    text-align: left;
    font-size: 0.9em;
}
.mg{
    width: 50px;
    border: 1px solid #000000;
    text-align: left;
    font-size: 0.9em;
}
.om{
    width: 50px;
    border: 1px solid #000000;
    text-align: left;
    font-size: 0.9em;
}
.subtotal{
    width: 150px;
    border: 1px solid #000000;
    text-align: right;
    font-size: 0.9em;
    background-color: rgb(175, 174, 174);
}
.sub_ml{
    width: 50px;
    border: 1px solid #000000;
    text-align: right;
    font-size: 0.9em;
    background-color: rgb(175, 174, 174);
}
.sub_mg{
    width: 50px;
    border: 1px solid #000000;
    text-align: right;
    font-size: 0.9em;
    background-color: rgb(175, 174, 174);
}
.sub_om{
    width: 50px;
    border: 1px solid #000000;
    text-align: right;
    font-size: 0.9em;
    background-color: rgb(175, 174, 174);
}
.moeda_local{
    width: 200px;
    height: 50px;
    position: absolute;
    left: 30px;
    top: 400px;
}
.alinhamento{
    width: 200px;
    height: 50px;
    border: 1px solid #000000;
    
}
.cambio{
    width: 200px;
    height: 50px;
    position: absolute;
    left: 425px;
    top: 400px;
}
.moeda_nacional{
    width: 200px;
    height: 50px;
    position: absolute;
    left: 825px;
    top: 400px;
}
.info_data{
    position: absolute;
    top: 500px;
    left: 30px;
    border-bottom: 2px double;
    text-align: left;
    width: 1000px;
}
.alinhamento1{
    text-align: left;
    font-size: 0.9em;
    height: 15px;
}
.alinhamento2{
    width: 250px;
    text-align: left;
    font-size: 0.9em;
    height: 15px;
    border-bottom: 1px solid #000000;
    border-right: 200px solid white;
}
.alinhamento3{
    text-align: center;
    font-size: 0.9em;
    height: 15px;
    width: 120px;
    border-bottom: 1px solid #000000;
}
.alinhamento4{
    text-align: center;
    font-size: 0.9em;
    height: 15px;
}
.alinhamento5{
    text-align: center;
    font-size: 0.9em;
    height: 15px;
    border-right: 200px solid white;
}
.alinhamento6{
    text-align: center;
    font-size: 0.9em;
    height: 15px;
    border-top: 2px double;
    border-bottom: 2px double;
}
.tabela_principal{
    position: absolute;
    top: 540px;
    left: 30px;
    border-bottom: 2px double;
    text-align: left;
    width: 1000px;
}
.tabela_principal2{
    position: absolute;
    top: 680px;
    left: 30px;
    text-align: left;
    width: 1000px;
}
.data_emicao{
    width: 300px;
    position: absolute;
    top: 1100px;
    left: 10px;
    text-align: left;
}
.assinatura{
    width: 300px;
    position: absolute;
    top: 1150px;
    left: 700px;
    text-align: left;
}
.rodape{
    position: absolute;
    top: 1250px;
    left: 30px;
    text-align: center;
    width: 1000px;
    border-top: 2px double;
}
.alinhamento_rodape-r{
    border-left: 1px solid black;
    text-align: center;
    font-size: 0.9em;
    line-height: 30px;
}
.alinhamento_rodape-l{
    text-align: center;
    font-size: 0.9em;
}

.tabela_principal3{
    position: absolute;
    top: 1400px;
    left: 30px;
    text-align: left;
    width: 1000px;
}
</style>