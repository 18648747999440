<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">FOLHA DE SALARIO PESSOAL DE RECRUTAMENTO LOCAL</h1>
            </div>
        </div>
        <div>
    
        <div>
            <span class="icon_download">
                <i class="fa-solid fa-download" @click="generatePDF"></i>
            </span>
            <span class="icon_print">
                <i class="fa-solid fa-print"></i>
            </span>
             <router-link to="/Relatorios" style="text-decoration: none; color: inherit;">
                <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
            </router-link>
            <div class="holderrelatorio">
                <table id="element-to-pdf">
                        <tr>
                            <td class="insignia">
                                <img src="../assets/Insignia.jpg" alt="Insignia da Republica">
                            </td>
                            <td>
                                <p class="repubica">REPÚBLICA DE ANGOLA</p>
                                <p class="ministerio">MINISTÉRIO DAS RELAÇÕES EXTERIORES </p>
                                <p class="missaoDiplomatica">EMBAIXADA DA REPÚBLICA DE ANGOLA NO JAPÃO</p>
                            </td>
                            <td class="resumo_top">
                                <p><b>&emsp; &emsp; &emsp;&emsp; &emsp; &emsp; &emsp;&emsp; &emsp; &emsp; &emsp;Base de Calculo</b></p>
                                <p>&emsp; &emsp; &emsp;1 Câmbio &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; USD = ¥(yen)&emsp; &emsp; &emsp; &emsp;0</p>
                                <p>&emsp; &emsp; &emsp;2 Câmbio &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; USD = KZ&emsp; &emsp; &emsp; &emsp; &emsp;    0</p>
                                <p>&emsp; &emsp; &emsp;3 Dias úteis do mês &emsp; &emsp; &emsp;&emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; 22</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="mes_salario">
                                <p><b>Mês: &emsp; &emsp; Janeiro 2023</b></p>
                            </td>
                            <td>
                                <p class="titulorequisicao">
                                    <b>Folha Mensal de Salario do Pessoal de Recrutamento Local</b>
                                </p>
                            </td>
                        </tr>
                        <table class="subtabela1">
                            <tr>
                                <td class="leftcells">Nr. Fiscal</td>
                                <td class="rightcells">5000272299</td>
                            </tr>
                            <tr>
                                <td class="leftcells">Ramo de Actividade</td>
                                <td class="rightcells">Actividade Diplomatica Nacional</td>
                            </tr>
                        </table>
                        <table class="subtabela2">
                            <tr>
                                <td class="leftcells">Nr. Contribuinte</td>
                                <td class="rightcells">Tipo de Propriedade</td>
                            </tr>
                            <tr>
                                <td class="leftcells">5145364</td>
                                <td class="rightcells">Estatal</td>
                            </tr>
                        </table>
                        <table class="subtabela3">
                            <tr>
                                <td class="col_numero" rowspan="2">N</td>
                                <td class="col_nome" rowspan="2">Nome</td>
                                <td class="col_categoria" rowspan="2">Categoria</td>
                                <td class="col_categoria" rowspan="2">Categ. Ocup</td>
                                <td class="col_categoria" rowspan="2">Local Trab</td>
                                <td class="col_dias" rowspan="2">Qt Dias<br> Trabalho</td>
                                <td class="col_salario" rowspan="2">¥(Yen)<br> Salario</td>
                                <td class="col_nascimento" rowspan="2">Data <br>  de Nascimento</td>
                                <td class="col_salario" rowspan="2">Salario por tempo trab)<br> Salario</td>
                                <td class="col_supl" rowspan="2">Horas Extras</td>
                                <td class="col_total" rowspan="2">Total <br> Rem Iliquida</td>
                                <td class="col_descontos" colspan="3">Descontos Salariais</td>
                                <td class="col_abono" rowspan="2">Total dos descontos</td>
                                <td class="col_liquido" rowspan="2">Liquido a Receber</td>
                                <td class="col_obs" rowspan="2">Assinatura</td>
                            </tr>
                            <tr>
                                <td class="sub_col_descontos">INSS</td>
                                <td class="sub_col_descontos">IRT</td>
                                <td class="sub_col_descontos">Outros</td>
                            </tr>
                            <tr v-for="f in data" :key="f.id">
                                <td class="normal_cells">numero</td>
                                <td class="normal_cells">{{ f.nome_funcionario }}</td>
                                <td class="normal_cells">{{ f.tipo_funcionario }}</td>
                                <td class="normal_cells">-</td>
                                <td class="normal_cells">-</td>
                                <td class="normal_cells">{{ f.qt_dias_trabalho }}</td>
                                <td class="normal_cells">{{ f.salario_base }}</td>
                                <td class="normal_cells">{{ f.data_nascimento_funcionario }}</td>
                                <td class="normal_cells">-</td>
                                <td class="normal_cells">{{ f.horas_trabalho }}</td>
                                <td class="normal_cells">{{ f.salario_iliquido }}</td>
                                <td class="normal_cells">{{ f.s_social_funcionario }}</td>
                                <td class="normal_cells">{{ f.irt_funcionario }}</td>
                                <td class="normal_cells">{{ f.outros_funcionario }}</td>
                                <td class="normal_cells">{{ f.total_descontos_funcionario }}</td>
                                <td class="normal_cells">{{ f.vencimento_funcionario }}</td>
                                <td class="normal_cells"></td>
                            </tr>
                            <tr>
                                <td class="sub_col_descontos">-</td>
                                <td class="sub_col_descontos">-</td>
                                <td class="sub_col_descontos">-</td>
                                <td class="sub_col_descontos">-</td>
                                <td class="sub_col_descontos">-</td>
                                <td class="sub_col_descontos">-</td>
                                <td class="sub_col_descontos">-</td>
                                <td class="sub_col_descontos">-</td>
                                <td class="sub_col_descontos">-</td>
                                <td class="sub_col_descontos">-</td>
                                <td class="sub_col_descontos">-</td>
                                <td class="sub_col_descontos">-</td>
                                <td class="sub_col_descontos">-</td>
                                <td class="sub_col_descontos">-</td>
                                <td class="sub_col_descontos">-</td>
                                <td class="sub_col_descontos">-</td>
                                <td class="sub_col_descontos">-</td>
                            </tr>
                        </table>
                        <table class="subtabela5">
                            <tr>
                                <td class="sub_col_tabela4_1"></td>
                                <td class="sub_col_tabela4_1">Yen</td>
                                <td class="sub_col_tabela4_1">KWANZA</td>
                            </tr>
                            <tr>
                                <td class="sub_col_tabela4_1">Vencimento de Outro Pessoal Civil</td>
                                <td class="sub_col_tabela4_1">-</td>
                                <td class="sub_col_tabela4_1">-</td>
                            </tr>
                            <tr>
                                <td class="sub_col_tabela4_1">Descontos</td>
                                <td class="sub_col_tabela4_1">-</td>
                                <td class="sub_col_tabela4_1">-</td>
                            </tr>
                             <tr>
                                <td class="sub_col_tabela4_1"><b>Total</b></td>
                                <td class="sub_col_tabela4_1"></td>
                                <td class="sub_col_tabela4_1"></td>
                            </tr>
                        </table>
                        <table class="subtabela6">
                            <tr>
                                <td>Toquio, aos 10 de Dezembro de 2021</td>
                            </tr>
                        </table>
                         <table class="subtabela7">
                            <tr>
                                <td>A Adida Financeira</td>
                                <td>O Embaixador</td>
                            </tr>
                            <tr>
                                <td>___________________________________</td>
                                <td>___________________________________</td>
                            </tr>
                        </table>
                </table>
            </div>
            
        </div>
    </div>
    </div>
</template>

<script>
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import RelatorioOrcamentoContent from '../components/RelatorioOrcamentoContent.vue'
import getUser from '../composables/getUser'
import { useRouter, useRoute } from "vue-router";
import { ref, watch } from "vue";
import getCollection from "../composables/getCollection";
import useCollection from "../composables/useCollection"
export default {
    components: { NavbarAppHome, Sidebar, RelatorioOrcamentoContent },
    setup() {
        const router = useRouter();
        const { user } = getUser();
        const { addDoc, error } = useCollection("conta");
        const { error2, documents } = getCollection("salariofuncionariolocal", ["userId", "==", user.value.uid,]);
        const data = ref([]);
        const filteredDocuments = ref([]);

        const filterDocuments = () => {
            if (documents.value) {
            filteredDocuments.value = documents.value.filter(
                (doc) => doc.userId === user.value.uid
            );
            data.value = filteredDocuments.value;
            }
        };
        filterDocuments();
    
        watch([documents, user], () => {
            filterDocuments();
        });

        watch(user, () => {
        if (!user.value) {
            router.push({ name: "Login" });
        }
        });

        
        return {
        user,
        filterDocuments,
        data
        };
    },
}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 80%;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: -15%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.main-nav {
    text-align: center;
    margin: 80px 30px 20px 140px;
}
.main-nav a{
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
    color: #999;
    font-size: 18px;
}
a.router-link-active{
    border-bottom: 2px solid #00ce89;
    padding-bottom: 4px;
}

.titulorelatorio{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 180px;
    top: 233px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    text-decoration-line: underline;

    color: #4E4E4E;
}

.holderrelatorio{
    box-sizing: border-box;
    position: absolute;
    padding: 10px 33px;
    width: 110%;
    height: 1000px;
    left: 251px;
    top: 310px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.icon_download{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 942px;
    top: 289px;
    cursor: pointer;
}

.icon_print{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 982px;
    top: 289px;
    cursor: pointer;
}
.icon_back{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 1020px;
    top: 289px;
    cursor: pointer;
}
p, ul, li{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.65em;
    text-align: justify;
}
.repubica{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: left;
}
.ministerio{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: left;
}
.missaoDiplomatica{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: left;
}
img{
    position: absolute;
    left: 150px;
    top: 45px;
    width: 50px;
    height: 50px;
}
.insignia{
    width: 140px;
}
.resumo_top{
    background: rgb(209, 207, 207);
}
.resumo_header{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
}
.mes_salario{
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.1em;
    background: rgb(209, 207, 207);
    width: 200px;
    top: 230px;
}
.titulorequisicao{
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75em;
    text-align: center;
    left: 350px;
    top: 240px;
}
table {
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}
.subtabela1{
    width: 400px;
    border: 1px solid #C4C4C4;
    position: absolute;
    left: 35px;
    top: 130px;
}
.subtabela2{
    width: 300px;
    border: 1px solid #C4C4C4;
    position: absolute;
    left: 500px;
    top: 130px;
}

.subtabela3{
    border: 1px solid #C4C4C4;
    position: absolute;
    left: 0px;
    top: 300px;
    font-size: .7em;
}
.col_numero{
    height: 20px;
    border: 1px solid #0f0e0e;
    width: 15px;
    background: #C4C4C4;
    line-height: 10px;

}
.col_nome{
    height: 20px;
    border: 1px solid #0f0e0e;
    width: 120px;
    background: #C4C4C4;
    line-height: 10px;

}
.col_categoria{
    height: 20px;
    border: 1px solid #0f0e0e;
    width: 50px;
    background: #C4C4C4;
    line-height: 10px;

}
.col_dias{
    height: 20px;
    font-size: .7em;
    border: 1px solid #0f0e0e;
    width: 30px;
    background: #C4C4C4;
    line-height: 10px;

    
}
.col_salario{
    height: 20px;
    border: 1px solid #0f0e0e;
    width: 50px;
    background: #C4C4C4;
    line-height: 10px;

}
.col_nascimento{
    height: 20px;
    border: 1px solid #0f0e0e;
    width: 50px;
    background: #C4C4C4;
    line-height: 10px;

}
.col_supl{
    height: 20px;
    border: 1px solid #0f0e0e;
    width: 30px;
    background: #C4C4C4;
    line-height: 10px;

}
.col_total{
    height: 20px;
    border: 1px solid #0f0e0e;
    width: 50px;
    background: #C4C4C4;
    line-height: 10px;

}
.col_descontos{
    height: 20px;
    border: 1px solid #0f0e0e;
    width: 100px;
    background: #C4C4C4;
    line-height: 10px;

}
.sub_col_descontos{
    height: 20px;
    border: 1px solid #0f0e0e;
    background: #C4C4C4;
    line-height: 10px;
    font-size: 0.4em;
}
.col_abono{
    height: 20px;
    border: 1px solid #0f0e0e;
    width: 50px;
    background: #C4C4C4;
    line-height: 10px;

}
.sub_col_abono{
    height: 20px;
    border: 1px solid #0f0e0e;
    background: #C4C4C4;
    line-height: 10px;
}
.col_liquido{
    height: 20px;
    border: 1px solid #0f0e0e;
    width: 50px;
    background: #C4C4C4;
    line-height: 10px;


}
.col_obs{
    height: 20px;
    border: 1px solid #0f0e0e;
    width: 50px;
    background: #C4C4C4;
    line-height: 10px;
}
.normal_cells{
    border: 1px solid #0f0e0e;
    font-size: .7em;
}
.subtabela4{
    border: 1px solid #C4C4C4;
    position: absolute;
    width: 900px;
    left: 0px;
    top: 700px;
    font-size: .7em;
}
.sub_col_tabela4{
    border: 1px solid #0f0e0e;
    background: #C4C4C4;
    line-height: 10px;
}

.subtabela5{
    border: 1px solid #0f0e0e;
    position: absolute;
    width: 600px;
    left: 500px;
    top: 720px;
    font-size: .7em;
}
.sub_col_tabela5{
    height: 20px;
    border: 1px solid #0f0e0e;
    background: #C4C4C4;
    line-height: 10px;
    text-align: right;
}
.sub_col_tabela4_1{
    border: 1px solid #0f0e0e;
    background: #C4C4C4;
    line-height: 10px;
    text-align: left;
}
.sub_col_tabela5_1{
    border: 1px solid #0f0e0e;
    position: absolute;
    background: #C4C4C4;
    width: 200px;
    font-size: .7em;
    line-height: 15px;
}
.subtabela6{
    position: absolute;
    width: 300px;
    left: 10px;
    top: 820px;
    font-size: .7em;
}
.subtabela7{
    position: absolute;
    width: 600px;
    left: 50px;
    top: 900px;
    width: 500;
    font-size: .7em;
}
.leftcells{
    font-size: .7em;
    text-align: left;
    height: 20px;
    border: 1px solid #080808;
}
.rightcells{
    font-size: .7em;
    text-align: left;
    height: 20px;
    background: #C4C4C4;
    border: 1px solid #080808;
}

table caption {
  font-size: 1.5em;
  margin: .1em 0 .75em;
}

table tr {
  background-color: #ffffff;
  border: none;
  padding: .35em;
  line-height: 5px;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.unique{
     border: 1px solid #ccc;
     line-height: 5px;
     width: 300px;
}
.unique2{
     border: none;
     line-height: 5px;
     width: 450px;
     
}
.textright{
    text-align: right;
}
</style>