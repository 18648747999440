<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">FORMULÁRIO EDITAR NATUREZAS DESPESAS</h1>
            </div>
            <div class="menu_configuracoes">
                <router-link to="/MissaoDiplomatica" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_missao_diplomatica">
                        MISSÃO<br> DIPLOMATICA 
                    </div>
                </router-link>
                <router-link to="/Orgaos" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_orgaos">
                        ORGÃOS
                    </div>
                </router-link>
                <router-link to="/Moedas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_moedas">
                        MOEDAS
                    </div>
                </router-link>
                <router-link to="/Cambio" style="text-decoration: none; color: inherit;"> 
                    <div class="cambio">
                        CÂMBIO
                    </div>
                </router-link>
                <router-link to="/Contas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_contas">
                        CONTAS
                    </div>
                </router-link>
                <router-link to="/FuncionariosEmpresas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_funcionarios_empresas">
                        FUNCIONÁRIOS E<br> EMPRESAS
                    </div>
                </router-link>
                <router-link to="/Naturezas" style="text-decoration: none; color: inherit;"> 
                    <div class="btn_naturezas">
                        NATUREZAS
                    </div>
                </router-link>
            </div>
        </div>
        <div class="form_container">
            <h1 class="titulo_formulario">EDITAR NATUREZAS DESPESAS</h1>
            <router-link to="/SelecionarTipoNatureza" style="text-decoration: none; color: inherit;">
                <span class="icon_back"><i class="fas fa-arrow-rotate-left"></i></span>
            </router-link>
            <form @submit.prevent="isEdit ? submitEdit() : handleSubmit">
                <label class="natureza_mae">Categoria</label>
                <select required v-model="natureza_mae" class="input_natureza_mae">
                    <option disabled value="">Fundos a</option>
                    <option value="Pessoal">Pessoal</option>
                    <option value="Contribuição do Empregador">Contribuição do Empregador</option>
                    <option value="Bens e Serviços">Bens e Serviços</option>
                    <option value="Investimento">Investimento</option>
                </select>
                <label class="codigo_natureza">Código</label>
                <input required v-model="codigo_naturezas" class="input_codigo_natureza" type="text">
                <label class="categoria_natureza">Natureza</label>
                <select required v-model="categoria_natureza" class="input_categoria_natureza">
                    <option disabled value="">Selecione a categoria</option>
                    <option disabled value="pessoal">Pessoal</option>
                    <option value="Vencimento do pessoal civil do quadro">Vencimento do pessoal civil do quadro</option>
                    <option value="Vencimentos de outro pessoal civil">Vencimentos de outro pessoal civil</option>
                    <option value="Subsídios Do Pessoal Civil">Subsídios Do Pessoal Civil</option>
                    <option value="Décimo terceiro mês do pessoal civil">Décimo terceiro mês do pessoal civil</option>
                    <option value="Outras remunerações variáveis do pessoal civil">Outras remunerações variáveis do pessoal civil</option>
                    <option disabled value="Contribuição do empregador">Contribuição do empregador</option>
                    <option value="Contribuições do empregador p/ a segurança social">Contribuições do empregador p/ a segurança social</option>
                    <option value="Transferências correntes para segurança social">Transferências correntes para segurança social</option>
                    <option disabled value="Bens e Serviços">Bens e Serviços</option>
                    <option value="Combustíveis E Lubrificantes">Combustíveis E Lubrificantes</option>
                    <option value="Viveres E Géneros Alimentícios">Viveres E Géneros Alimentícios</option>
                    <option value="Outros materiais De Consumo Corrente<">Outros materiais De Consumo Corrente</option>
                    <option value="Outros Materiais E Utensílios Duradouros">Outros Materiais E Utensílios Duradouros</option>
                    <option value="Serviços De Telecomunicação">Serviços De Telecomunicação</option>
                    <option value="Serviços De Saúde">Serviços De Saúde</option>
                    <option value="Serviços De Ensino E Formação">Serviços De Ensino E Formação</option>
                    <option value="Serviços De Água e Electricidade">Serviços De Água e Electricidade</option>
                    <option value="Serviços de Hospedagem e Alimentação">Serviços de Hospedagem e Alimentação</option>
                    <option value="Serviços de Estudo, Fiscalização e Consultoria">Serviços de Estudo, Fiscalização e Consultoria</option>
                    <option value="Serviço de Protecção e Vigilância">Serviço de Protecção e Vigilância</option>
                    <option value="Serviços Bancários">Serviços Bancários</option>
                    <option value="Serviços de Processamento de Dados">Serviços de Processamento de Dados</option>
                    <option value="Serviços de Limpeza e Saneamento">Serviços de Limpeza e Saneamento</option>
                    <option value="Serviços de Manutenção e Conservação">Serviços de Manutenção e Conservação</option>
                    <option value="Bilhete de Passagem">Bilhete de Passagem</option>
                    <option value="Subsidio de Deslocação">Subsidio de Deslocação</option>
                    <option value="Serviços de Transportação de Pessoas e Bens">Serviços de Transportação de Pessoas e Bens</option>
                    <option value="Encargos Aduanerios e Portuarios">Encargos Aduanerios e Portuarios</option>
                    <option value="Renda de Imoveis">Renda de Imoveis</option>
                    <option value="Outros Serviços">Outros Serviços</option>
                    <option disabled value="Investimento">Investimento</option>
                    <option value="Aquisicao de Mobilirio">Aquisicao de Mobiliario</option>
                    <option value="Equipamentos e Processamento de Dados">Equipamentos e Processamento de Dados</option>
                    <option value="Meios e Equipamentos de Transporte">Meios e Equipamentos de Transporte</option>
                    <option value="Bens de Capital Fixo">Bens de Capital Fixo</option>
                </select>
                <label class="natureza_adicional">Natureza Adicional</label>
                <input v-model="natureza_adicional" class="input_natureza_adicional" type="text">
                <button class="salvar_funcionario" @click.prevent="submitForm">SALVAR</button>
            </form>
        </div>
    </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getUser from '../composables/getUser'
import { onMounted } from "vue";
import { useRoute, useRouter } from 'vue-router'
import { projectFirestore } from "../firebase/config";
export default {
    components: { NavbarAppHome, Sidebar },

    setup() {
        const { addDoc, error } = useCollection('natureza')
        const { error2, documents } = getCollection('natureza')
        const { user } = getUser()
        const route = useRoute()
        const router = useRouter()
        const user_update_id = ref("");
        const user_id = user.value.uid;
        const estado = ref(true);
        const filteredDocuments = ref([]);
        watch(user, () => {
            if  (!user.value) {
                router.push({ name: 'Login'})
            }
        })
        const natureza_mae = ref('')
        const codigo_naturezas = ref('')
        const categoria_natureza = ref('')
        const natureza_adicional = ref('')
        const data = ref([]);
        const isEditingId = computed(() => route.params.id);
        const docRef = getCollection("natureza");
            if (docRef.value) {
                filteredDocuments.value = docRef.value.filter(
                    isEditingId.value === user.value.uid
                );
            data.value = filteredDocuments.value;
        }
        const isEdit = ref(false);
        isEditingId.value = false;
        isEdit.value = false;
        const fetchCollectionData = async () => {
            try {
                const collectionRef = projectFirestore.collection("natureza");
                const snapshot = await collectionRef.get();
                const dataaaa = snapshot.docs.map((doc) => {
                return { ...doc.data(), id: doc.id };
                });
                const filtered = dataaaa.filter((dataaaa1) => dataaaa1.id === isEditingId.value);
                filtered.forEach((data1) => {
                    natureza_mae.value = data1.natureza_mae;
                    codigo_naturezas.value = data1.codigo_naturezas;
                    categoria_natureza.value = data1.categoria_natureza;
                    natureza_adicional.value = data1.natureza_adicional;
                    user_update_id.value = data1.id;
                });
            } catch (error) {
                console.error("Error fetching collection data:", error);
            }
        };

        onMounted(fetchCollectionData);
        watch(user, () => {
            if (!user.value) {
                router.push({ name: "Login" });
            }
        });

        const filterDocuments = () => {
            if (documents.value) {
                filteredDocuments.value = documents.value.filter(
                (doc) => {
                    return doc.userId === user.value.uid
                }
                );
            }
            };
            filterDocuments();

            watch([documents, user], () => {
                filterDocuments();
            });
            const handleEdit = async (id) => {
                try {
                    const docRef = projectFirestore.collection("natureza").doc(id);
                    await docRef.get().then(async (doc) => {
                        if (doc.exists) {
                            let id = await doc.id;
                            new_obj.value = await { ...doc.data(), id };
                            natureza_mae.value =  new_obj.value.natureza_mae;
                            codigo_naturezas.value =  new_obj.value.codigo_naturezas;
                            categoria_natureza.value =  new_obj.value.categoria_natureza;
                            natureza_adicional.value =  new_obj.value.natureza_adicional;
                        }
                    });
                    isEdit.value = true;
                    isEditingId.value = id;
                } catch (error) {
                console.log("Error deleting document:", error.message);
                }
            };
            const submitEdit = async () => {
                alert("submitEditsubmitEdit");
                const docRef = projectFirestore
                .collection("natureza")
                .doc(isEditingId.value);
                await docRef.update({
                    natureza_mae: natureza_mae.value,
                    codigo_naturezas: codigo_naturezas.value,
                    categoria_natureza: categoria_natureza.value,
                    natureza_adicional: natureza_adicional.value,
                    userId: user.value.uid,
                });
                isEdit.value = false;
                natureza_mae = null;
                codigo_naturezas = null;
                categoria_natureza = null;
                natureza_adicional = null;
            };
            const submitForm = async () => {
                try {
                    const docRef = projectFirestore
                        .collection("natureza")
                        .doc(user_update_id.value);
                    await docRef.update({
                        natureza_mae: natureza_mae.value,
                        codigo_naturezas: codigo_naturezas.value,
                        categoria_natureza: categoria_natureza.value,
                        natureza_adicional: natureza_adicional.value,
                    });
            
                    router.push(`/TabelaNaturezasDespesas`);
            
                    alert("updated successfully");
                    } catch (err) {
                    alert("updated ====");
                    console.log("err==>", err);
                    }
            };

        
        return { 
                estado,
                handleEdit,
                submitEdit,
                isEdit,
                isEditingId,
                data,
                user_id,
                submitForm,
                natureza_mae,
                codigo_naturezas,
                categoria_natureza,
                natureza_adicional,
                error, 
                error2,
                documents,
                filteredDocuments
            }
    }
    
}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 100%;
    left: 180px;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: 0%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.menu_configuracoes{
    width: 100%;
}

.btn_missao_diplomatica{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    vertical-align: middle;
}
.btn_missao_diplomatica:hover {
    width: 216px;
    height: 84px;
    left: 250px;
    top: 160px;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
}
.missao_diplomatica{
    position: absolute;
    width: 215px;
    height: 46px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.missao_diplomatica:hover {
  color: white;
}

.btn_orgaos{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 485px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
    vertical-align: middle;
}

.btn_orgaos:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.orgaos{
    position: absolute;
    width: 209px;
    height: 20px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.orgaos:hover {
  color: white;
}

.btn_contas{
    position: absolute;
    width: 214px;
    height: 84px;
    left: 1190px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.btn_contas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.contas{
    position: absolute;
    width: 198px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.contas:hover {
    color: white;
}
.btn_funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 1425px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_funcionarios_empresas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.cambio{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.cambio:hover {
    color: white;
}
.cambio{
    position: absolute;
    width: 217px;
    height: 84px;
    left: 955px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.cambio:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.funcionarios_empresas{
    position: absolute;
    width: 217px;
    height: 44px;
    left: 0px;
    top: 25%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.funcionarios_empresas:hover {
    color: white;
}

.btn_naturezas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 1660px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.btn_naturezas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.naturezas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}

.naturezas:hover {
    color: white;
}
.btn_moedas{
    position: absolute;
    width: 216px;
    height: 84px;
    left: 720px;
    top: 160px;
    background: #FFFFFF;
    border: 3px solid #51B8D5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.btn_moedas:hover {
    background: #00344C;
    color: white;
    background: #00344C;
    border: 8px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.moedas{
    position: absolute;
    width: 216px;
    height: 22px;
    left: 0px;
    top: 40%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #51B8D5;
    cursor: pointer;
}
.moedas:hover {
    color: white;
}

.form_container{
    box-sizing: border-box;
    position: absolute;
    width: 908px;
    height: 50%;
    left: 266px;
    top: 270px;
    background: #FFFFFF;
    border: 2px solid #51B8D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.titulo_formulario{
    position: absolute;
    width: 907px;
    height: 20px;
    left: 1px;
    top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
}

.icon_back{
    position: absolute;
    width: 5px;
    height: 8px;
    left: 800px;
    top: 40px;
}

.natureza_mae{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -140px;
    top: 70px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}

.codigo_natureza{
    position: absolute;
    width: 444px;
    height: 32px;
    left: 350px;
    top: 70px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;

}
.input_natureza_mae{
    box-sizing: border-box;
    position: absolute;
    width: 444px;
    height: 30px;
    left: 40px;
    top: 95px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.input_codigo_natureza{
    box-sizing: border-box;
    position: absolute;
    width: 223px;
    height: 30px;
    left: 550px;
    top: 95px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.data_registro_empresas{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -125px;
    top: 140px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_data_registro_empresas{
    box-sizing: border-box;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 40px;
    top: 165px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.categoria_natureza{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -150px;
    top: 140px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_categoria_natureza{
    box-sizing: border-box;
    position: absolute;
    width: 733px;
    height: 30px;
    left: 40px;
    top: 165px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.observacoes_empresas{
    position: absolute;
    width: 330px;
    height: 32px;
    left: -80px;
    top: 205px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_observacoes_empresas{
    box-sizing: border-box;
    position: absolute;
    width: 730px;
    height: 100px;
    left: 40px;
    top: 230px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.natureza_adicional{
    position: absolute;
    width: 444px;
    height: 32px;
    left: -120px;
    top: 210px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #51B8D5;
}
.input_natureza_adicional{
    box-sizing: border-box;
    position: absolute;
    width: 400px;
    height: 30px;
    left: 40px;
    top: 240px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.salvar_funcionario{
    box-sizing: border-box;
    position: absolute;
    width: 132px;
    height: 30px;
    left: 641px;
    top: 350px;
    color: #fff;
    background: #51B8D5;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>