<template>
  <div>
    <div class="Comprar_Prescon">
      <div class="Container">
        <h3 class="Comprar_PresCon_App">
          Comprar PresCon App
        </h3>
        <p class="Já_tem_uma_conta_PresCon_Online">
          Já tem uma conta PresCon Online?<br> 
        </p>
        <span class="Sign_in_aqui"><router-link to="/Login" style="text-decoration: none; color: inherit;">Sign-in aqui</router-link></span> 
        <form @submit.prevent="handleSubmit">
          <label class="Endereço_de_e_mail">Endereço de e-mail (ID de usuário)</label>
          <input class="Rec_Endereco" required v-model="email" type="email">
          <label class="Primeiro_nome">Primeiro nome</label>
          <input class="Rec_Primeiro_nome" required v-model="primeiroNome" type="text">
          <label class="Ultimo_nome">Último nome</label>
          <input class="Rec_Ultimo_nome" required v-model="ultimoNome" type="text">
          <label class="Telefone">Telefone</label>
          <input class="Rec_Telefone" required v-model="nTelefone" type="text">
          <label class="Palavra_de_passe">Palavra de passe</label>
          <input class="Rec_Palavra_Passe" v-model="password" required type="password">
          <label class="Confirmar_palavra_passe">Confirmar palavra de passe</label>
          <input class="Rec_Confirmar_palavra_passe" v-model="confirmarPassword" type="password">
          <div class="error" > {{ error }}</div>
          <button class="Rectangle_13">Inscrever</button>
        </form>
      </div>
    </div>
    <div class="plano">
      <h4 class="titulo_plano">Seu planos online Prescon</h4>
      <p class="dolar_sign">$</p>
      <p class="valor">800 <span class="unidade">.00</span> <span class="tempo">/Ano</span></p>
      <ul class="itens_plano">
        <li>Backups de dados automáticos</li>
        <li>Segurança e criptografia em nível de banco </li>
        <li>Acesse dados de todos os seus dispositivos</li>
      </ul>
      <p class="conteudo_plano"> *If you have an existing QuickBooks Online account, you can add / sign up for a new company using your
           existing sign in details. This single sign in allows you to view and manage multiple companies, including 
           your existing account.
      </p>
      <router-link to="/" style="text-decoration: none; color: inherit;"><button class="escolhaPlano">Escolher Plano</button></router-link>
      
      <h4 class="titulo_plano2">Seu planos online Prescon</h4>
      <p class="dolar_sign2">$</p>
      <p class="valor2">1800 <span class="unidade2">.00</span> <span class="tempo2">/Ano</span></p>
      <ul class="itens_plano2">
        <li>Backups de dados automáticos</li>
        <li>Segurança e criptografia em nível de banco </li>
        <li>Acesse dados de todos os seus dispositivos</li>
      </ul>
      <p class="conteudo_plano2"> *If you have an existing QuickBooks Online account, you can add / sign up for a new company using your
           existing sign in details. This single sign in allows you to view and manage multiple companies, including 
           your existing account.
      </p>
      <button class="escolhaPlano2">Escolher Plano</button>
    </div>
    
  </div>
</template>

<script>
import { ref } from 'vue'
import useSignup from '../composables/useSignup'
export default {
    setup(props, context) {
      const { error, signup } = useSignup()

      // refs
      const email = ref('')
      const primeiroNome = ref('')
      const ultimoNome = ref('')
      const nTelefone = ref('')
      const password = ref('')
      const confirmarPassword = ref('')

      const handleSubmit = async () => {
          await signup(email.value, primeiroNome.value, ultimoNome.value, nTelefone.value, password.value, confirmarPassword.value)
          if (!error.value) {
            context.emit('signup')
          }
      }

      return { email, primeiroNome, ultimoNome, nTelefone, password, confirmarPassword, handleSubmit, error }
    }
}
</script>

<style>
.Comprar_Prescon{
  position: relative;
  width: 448px;
  height: 860px;
  margin: 50px 0px 0px 250px;
}

.Rectangle_6{
  position: absolute;
  left: 0.22%;
  right: 0.22%;
  top: 0.12%;
  bottom: 0.12%;
}


.Container{
  margin: 100px 0px 0px 0px;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #FFFFFF;
  border: 1px solid rgba(229, 229, 229, 0.68);
}


.Vector_1{
  position: absolute;
  left: 14.51%;
  right: 69.53%;
  top: 16.51%;
  bottom: 83.49%;
  border: 1px solid #51B8D5;
}

.Rec_Endereco{
  position: absolute;
  left: 14.73%;
  right: 14.96%;
  top: 23.37%;
  bottom: 71.74%;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 3px;
  width: 350px;
}

.Rec_Primeiro_nome{
  position: absolute;
  left: 14.73%;
  right: 14.96%;
  top: 33.26%;
  bottom: 61.86%;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 3px;
  width: 350px;
}
.Rec_Ultimo_nome{ 
  position: absolute;
  left: 14.73%;
  right: 14.96%;
  top: 43.14%;
  bottom: 51.98%;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 3px;
  width: 350px;
}

.Rec_Telefone{
  position: absolute;
  left: 14.73%;
  right: 14.96%;
  top: 53.02%;
  bottom: 42.09%;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 3px;
  width: 350px;
}

.Rec_Palavra_Passe{
  position: absolute;
  left: 14.73%;
  right: 14.96%;
  top: 62.91%;
  bottom: 32.21%;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 3px;
  width: 350px;
  }

.Rec_Confirmar_palavra_passe{ 
  position: absolute;
  left: 15.18%;
  right: 14.51%;
  top: 72.79%;
  bottom: 22.33%;
  background: #E5E5E5;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 3px;
  width: 350px;
}

.Comprar_PresCon_App{
  position: absolute;
  left: 0%;
  right: 20.09%;
  top: 2.44%;
  bottom: 87.67%;
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}


.Já_tem_uma_conta_PresCon_Online {

  position: absolute;
  left: 14.96%;
  right: 40.18%;
  top: 9%;
  bottom: 85.58%;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}


.aceitar{
  position: absolute;
  left: 15.4%;
  right: 14.51%;
  top: 86.4%;
  bottom: 7.21%;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #4E4E4E;
}


.Sign_in_aqui{
  position: absolute;
  left: 14.96%;
  right: 68.97%;
  top: 14.65%;
  bottom: 83.26%;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #51B8D5;
  cursor: pointer;
}


.Botao_Mais_um_passo{
  position: absolute;
  width: 315px;
  height: 42px;
  left: 67px;
  top: 683px;
}

.error{ 
    color: #ff3f80;
    font-size: 18px;
    font-weight: bold;
}

.Rectangle_13{
  position: absolute;
  left: 14.96%;
  right: 14.73%;
  top: 650px;
  background: #00344C;
  border-radius: 3px;
  color: white;
  width: 350px;
  height: 30px;
  cursor: pointer;
}

.Mais_Um_Passo{
  position: absolute;
  left: 27.68%;
  right: 27.46%;
  top: 80.35%;
  bottom: 17.56%;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
}


.Endereço_de_e_mail{
  position: absolute;
  left: 14.51%;
  right: 40.18%;
  top: 20.47%;
  bottom: 77.56%;
  font-family: Signika;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}



.Primeiro_nome {
  position: absolute;
  left: 14.51%;
  right: 66.29%;
  top: 30.35%;
  bottom: 67.67%;
  font-family: Signika;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4E4E4E;
}


.Ultimo_nome{
  position: absolute;
  left: 14.51%;
  right: 68.53%;
  top: 40.23%;
  bottom: 57.79%;
  font-family: Signika;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4E4E4E;
}

.Telefone{
  position: absolute;
  left: 14.51%;
  right: 74.33%;
  top: 50.12%;
  bottom: 47.91%;
  font-family: Signika;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4E4E4E;
}


.Palavra_de_passe{
  position: absolute;
  left: 14.51%;
  right: 63.62%;
  top: 60%;
  bottom: 36.05%;
  font-family: Signika;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4E4E4E;
}

.Confirmar_palavra_passe{
  position: absolute;
  left: 14.96%;
  right: 48.66%;
  top: 69.88%;
  bottom: 26.16%;
  font-family: Signika;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4E4E4E;
}

.Confirmar_palavra_de_passe{
  position: absolute;
  left: 14.96%;
  right: 48.66%;
  top: 87%;
  bottom: 26.16%;
  font-family: Signika;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4E4E4E;
  width: 350px;
}
.plano{
  position: relative;
  width: 447px;
  height: 760px;
  left: 700px;
  top: -760px;
  background: #E6F4F1;
}

.titulo_plano{
  position: absolute;
  width: 296px;
  height: 42px;
  left: 15%;
  top: 3%;
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.titulo_plano2{
  position: absolute;
  width: 296px;
  height: 642px;
  left: 15%;
  top: 52.44%;
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.dolar_sign{
  position: absolute;
  width: 28px;
  height: 42px;
  left: 20%;
  top: 7%;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  color: #000000;
}
.dolar_sign2{
  position: absolute;
  width: 28px;
  height: 42px;
  left: 20%;
  top: 56%;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  color: #000000;
}

.valor{
  position: absolute;
  width: 80px;
  height: 57px;
  left: 25.5%;
  top: 6%;
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 59px;
  color: #000000;
}
.valor2{
  position: absolute;
  width: 80px;
  height: 57px;
  left: 25.5%;
  top: 55%;
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 59px;
  color: #000000;
}

.unidade{
  position: absolute;
  width: 65px;
  height: 57px;
  left: 78%;
  top: 20%;
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}
.unidade2{
  position: absolute;
  width: 65px;
  height: 57px;
  left: 105%;
  top: 23%;
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}

.tempo{
  position: absolute;
  width: 48px;
  height: 30px;
  left: 138%;
  top: 25%;
  font-family: Signika;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  color: #4E4E4E;
}
.tempo2{
  position: absolute;
  width: 48px;
  height: 30px;
  left: 165%;
  top: 29%;
  font-family: Signika;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  color: #4E4E4E;
}
.itens_plano{
  position: absolute;
  width: 282px;
  height: 102px;
  left: 13%;
  top: 20%;
  text-align: left;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.itens_plano2{
  position: absolute;
  width: 282px;
  height: 102px;
  left: 13%;
  top: 68%;
  text-align: left;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.conteudo_plano{
  position: absolute;
  width: 344px;
  height: 121px;
  left: 13%;
  top: 30%;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.conteudo_plano2{
  position: absolute;
  width: 344px;
  height: 121px;
  left: 13%;
  top: 78%;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.escolhaPlano{
  position: absolute;
  width: 150px;
  height: 30px;
  left: 150px;
  top: 335px;
  color: white;
  background: #51B8D5;
  border-radius: 20px;
}
.escolhaPlano2{
  position: absolute;
  width: 150px;
  height: 30px;
  left: 150px;
  top: 705px;
  color: white;
  background: #51B8D5;
  border-radius: 20px;
}

.divider{
  position: absolute;
  width: 391.5px;
  height: 0px;
  left: 25px;
  top: 400px;
  border: 1px solid #4E4E4E;
}
</style>