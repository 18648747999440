<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">ORÇAMENTO</h1>
            </div>
            <div class="menu_configuracoes">
               
            </div>
        </div>
        <div>
            <nav class="main-nav">
                <router-link :to="{ name: 'Orcamento' }">ORÇAMENTOS</router-link>
                <router-link :to="{ name: 'RegistarOrcamento' }">REGISTAR ORÇAMENTO</router-link>
                <router-link :to="{ name: 'RegistarVencimento' }">REGISTAR VENCIMENTO</router-link>
                <router-link :to="{ name: 'TabelaVencimento' }">TABELA VENCIMENTO</router-link>
                <router-link :to="{ name: 'RelatorioOrcamento' }">RELATÓRIO</router-link>
                <router-link :to="{ name: 'EditarOrcamento' }">EDITAR</router-link>
            </nav>
        </div>
        <div class="contas-list">
            <h2 class="dados_contas">LISTA DE CONTRATOS</h2>
            <div class="table_contas">
                <table>
                <thead>
                    <tr>
                        <th scope="col">Natureza</th>
                        <th scope="col">Aprovado</th>
                        <th scope="col">Cativação</th>
                        <th scope="col">Execução</th>
                        <th scope="col">Cabimentação</th>
                        <th scope="col">Saldo</th>
                        <th scope="col">Grau de Execução</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody v-if="data && data.length > 0">
                    <tr v-for="o in data" :key="o.id">
                        <td>{{ o.categoria_natureza }}</td>
                        <td>{{ o.aprovado_kz }}</td>
                        <td>{{ o.cativacao_kz }}</td>
                        <td>{{ o.em_execucao }}</td>
                        <td>{{  }}</td>
                        <td>{{  }}</td>
                        <td>{{  }}</td>
                        <td>
                            <span title="Eliminar" class="icon_delete" @click="handleDelete(n.id)"><i class="fas fa-trash-alt"></i></span>
                             <router-link to="/FormularioNaturezas" style="text-decoration: none; color: inherit;">
                                <span title="Editar" class="icon_edit" @click="handleEdit(n.id)"><i class="fas fa-edit"></i></span>
                             </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="error">{{ error2 }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, watch } from 'vue'
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
import useCollection from '../composables/useCollection'
import getCollection from '../composables/getCollection'
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'
import { projectFirestore } from '../firebase/config'
export default {
    components: { NavbarAppHome, Sidebar },

    setup() {
        const { addDoc, error } = useCollection('orcamento')
        const { error2, documents } = getCollection('orcamento')
        const { documents1 } = getCollection('cabimentacao')
        const { user } = getUser()
        console.log(user, "useruser");
        const data = ref([])
        const router = useRouter()
        const filteredDocuments = ref([]);
        let new_obj = ref();
        const isEdit = ref(false);
        const isEditingId = ref(null);
        const filterDocuments = () => {
            if (documents.value) {
                filteredDocuments.value = documents.value.filter(
                (doc) => doc.userId === user.value.uid
                );
                data.value = filteredDocuments.value;
            }
        };
        filterDocuments();
        watch([documents, user], () => {
            filterDocuments();
        });
        const handleDelete = async (id) => {
            try {
                const docRef = projectFirestore.collection("natureza").doc(id);
                await docRef.delete();
                console.log("Document successfully deleted.");
            } catch (error) {
                console.log("Error deleting document:", error.message);
            }
        };
        const handleEdit = async (id) => {
            try {
                const docRef = projectFirestore.collection("natureza").doc(id);
                await docRef.get().then(async (doc) => {
                if (doc.exists) {
                    let id = await doc.id;
                    new_obj.value = await { ...doc.data(), id };
                    natureza_mae.value = new_obj.value.natureza_mae;
                    codigo_naturezas.value = new_obj.value.codigo_naturezas;
                    categoria_natureza.value = new_obj.value.categoria_natureza;
                    natureza_adicional.value = new_obj.value.natureza_adicional;
                }
                });
                isEdit.value = true;
                isEditingId.value = id;
            } catch (error) {
                console.log("Error deleting document:", error.message);
            }
            };

        const submitEdit = async () => {
            alert("submitEditsubmitEdit");
            const docRef = projectFirestore
                .collection("natureza")
                .doc(isEditingId.value);
            await docRef.update({
                natureza_mae: natureza_mae.value,
                codigo_naturezas: codigo_naturezas.value,
                categoria_natureza : categoria_natureza.value,
                natureza_adicional : natureza_adicional.value, 
                userId: user.value.uid,
            });
            isEdit.value = false;
            natureza_mae.value = null;
            codigo_naturezas.value = null;
            categoria_natureza.value = null;
            natureza_adicional.value = null;
            };

        
        const fonte_recurso = ref('')
        const categoria_natureza = ref('')
        const aprovado_kz = ref('')
        const cativacao_kz = ref('')
        const em_execucao = ref('')

        const handleSubmit = async () => {
            const orcamento = {
                fonte_recurso: fonte_recurso.value,
                categoria_natureza: categoria_natureza.value,
                aprovado_kz: aprovado_kz.value,
                cativacao_kz: cativacao_kz.value,
                em_execucao: em_execucao.value,
                userId: user.value.uid

            }
            await addDoc(orcamento)
            if (!error.value) {
                fonte_recurso.value = ''
                categoria_natureza.value = ''
                aprovado_kz.value = ''
                cativacao_kz.value = ''
                em_execucao.value = ''
            }
        }
        return { fonte_recurso, categoria_natureza, aprovado_kz, cativacao_kz, em_execucao, error, handleSubmit, error2, documents, documents1 }
    }
    
}
</script>

<style scoped>
.cabecalho_configuracoes{
    width: 80%;
}
.titulo_configuracoes{
    position: absolute;
    color: white;
    width: 100%;
    height: 60px;
    left: 180px;
    top: 90px;
    background: #00344C;
}
.conteudo_titulo{
    position: absolute;
    width: 1271px;
    height: 20px;
    left: -15%;
    top: -10px;
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
}
.main-nav {
    text-align: center;
    margin: 80px 30px 20px 140px;
}
.main-nav a{
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
    color: #999;
    font-size: 18px;
}
a.router-link-active{
    border-bottom: 2px solid #00ce89;
    padding-bottom: 4px;
}
.contas-list{
    position: absolute;
    width: 980px;
    height: auto;
    left: 231px;
    top: 400px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
    border: 1px solid rgb(198, 192, 192);
}
.dados_contas{
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    top: 10px;
    margin: 20px 10px 10px 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
}
.table_contas{
    position: absolute;
    top: 60px;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

/* general styling */
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.25;
}

.icon_delete{
    color:red;
    padding: 10px;
    cursor: pointer;
}

.icon_edit{
    color: rgb(31, 227, 31);
    cursor: pointer;
}
.menu_tabelas{
    padding: 20px;
    color: black;
    margin-bottom: 10px;
}
</style>