<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div class="cabecalho_configuracoes">
            <div class="titulo_configuracoes">
                <h1 class="conteudo_titulo">RELATÓRIOS</h1>
            </div>
        </div>
        <div>
            <nav class="main-nav">
                <router-link :to="{ name: 'RelatorioPessoal' }">RELATORIOS PESSOAL</router-link>
                <router-link :to="{ name: 'RelatorioContaCorrente' }">RELATÓRIOS CONTA CORRENTE</router-link>
                <router-link :to="{ name: 'RelatorioOperacaoTesouraria' }">RELATÓRIOS OPERAÇAO DE TESOURARIA</router-link>
                <router-link :to="{ name: 'OutrosRelatorios' }">OUTROS RELATÓRIOS</router-link>
            </nav>
        </div>
        <div>
            <h1>Lista Mensal Folha de Salario Pessoal do Local</h1>
            <div>
                <table class="datatable_relatorios">
                    <thead>
                        <tr>
                        <th>Data</th>
                        <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item2, index) in months" :key="index">
                        <td class="lista_itens">Folha de salário referente ao mês de {{ item2.month }} de {{ item2.year }}</td>
                        <td><span title="Editar" class="icon_edit" @click="navigateToMonth(item2)"><i class="fas fa-eye"></i></span></td>
                        </tr>
                    </tbody>
                </table>
                <!-- Pagination controls -->
                <div class="pagination">
                    <button @click="goToPage(currentPage - 1)" :disabled="currentPage === 1">Previous</button>
                    <span>{{ currentPage }} / {{ totalPages }}</span>
                    <button @click="goToPage(currentPage + 1)" :disabled="currentPage === totalPages">Next</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch, computed, reactive, onMounted, getCurrentInstance } from "vue";
import NavbarAppHome from "../components/NavbarAppHome.vue";
import Sidebar from "../components/Sidebar.vue";
import Pagination from "../components/Pagination.vue";
import useCollection from "../composables/useCollection";
import getCollection from "../composables/getCollection";
import getDocument from "../composables/getDocument";
import getUser from "../composables/getUser";
import { useRouter, useRoute } from "vue-router";
import { storage } from '../firebase/config';
import ConfiguracaoNav from '../components/ConfiguracaoNav.vue'
import { projectFirestore } from "../firebase/config";

export default {
    components: { NavbarAppHome, Sidebar, Pagination },
    setup() {
        /*const { addDoc, error } = useCollection("funcionariocentral");*/
        const { error2, documents } = getCollection("salariofuncionariolocal");
        const { user } = getUser();
        const router = useRouter();
        const route = useRoute();
        const filteredDocuments = ref([]);
        const documentField = ref(null);
        const documentId = ref(route.params.id);
        const itemId = ref(route.params.itemId);

    // Fetch item details using itemId from Firebase or other data source
    


        watch(user, () => {
            if (!user.value) {
            router.push({ name: "Login" });
            }
        });
    
        const filterDocuments = () => {
            if (filteredDocuments.value) {
                filteredDocuments.value = filteredDocuments.value.filter((doc) => {
                return doc.userId === user.value.uid;
                });
            }
            };

    
    //generating monthly information
    const months = ref([]);

    const generateMonthsData = () => {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      if (months.value.length === 0) {
        // Initialize with October 2022
        let year = 2022;
        let month = 9; // 0-based index, where 9 is October

        while (year < currentYear || (year === currentYear && month <= currentMonth)) {
          const monthName = new Intl.DateTimeFormat('pt-BR', { month: "long" }).format(new Date(year, month, 1));
          months.value.push({
            month: monthName,
            year: year,
          });

          if (month === 11) {
            month = 0;
            year++;
          } else {
            month++;
          }
        }
      } else {
        // Add new months if it's a new month in the current year
        if (currentYear > months.value[months.value.length - 1].year || currentMonth > months.value[months.value.length - 1].month) {
          const lastMonth = months.value[months.value.length - 1];
          let year = lastMonth.year;
          let month = lastMonth.month;

          do {
            if (month === 11) {
              month = 0;
              year++;
            } else {
              month++;
            }

            const monthName = new Intl.DateTimeFormat("en-US", { month: "long" }).format(new Date(year, month, 1));
            months.value.push({
              month: monthName,
              year: year,
            });
          } while (!(year === currentYear && month === currentMonth));
        }
      }
    };

    onMounted(() => {
      generateMonthsData();
    });

    watch(
      () => {
        generateMonthsData();
      },
      { immediate: true }
    );
    // Pagination of the data table
    // Define a computed property for the data to display (monthsToDisplay)
    const monthsToDisplay = computed(() => {
      return months.value; // Replace this with your actual data
    });

    // Pagination of the data to display
    const itemsPerPage = ref(5);
    const currentPage = ref(1);

    // Calculate the total number of pages
    const totalPages = computed(() => Math.ceil(monthsToDisplay.value.length / itemsPerPage.value));

    // Calculate the index range for the current page
    const startIndex = computed(() => (currentPage.value - 1) * itemsPerPage.value);
    const endIndex = computed(() => startIndex.value + itemsPerPage.value);

    // Slice the data array to get the current page data
    const paginatedData = computed(() => monthsToDisplay.value.slice(startIndex.value, endIndex.value));

    // Function to navigate to the next page
    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    // Define the navigateToMonth function here, without nesting
    const navigateToMonth = (selectedMonth) => {
      const destinationPageName = "FolhaSalLocal";
      const month = selectedMonth.month;
      const year = selectedMonth.year;

      const routeParams = {
        name: destinationPageName,
        params: {
          id: documentId.value,
          month: month,
          year: year,
        },
      };

      router.push(routeParams);
    };

        
    watch([months, documents, user], () => {
      filterDocuments();
      generateMonthsData();
    });
    return{
        error2,
        documents,
        filteredDocuments,
        documentId,
        documentField,
        itemId,
        months,
        itemsPerPage,
        currentPage,
        totalPages,
        paginatedData,
        goToPage,
        monthsToDisplay,
        navigateToMonth,
        }
    }
}
</script>

<style scoped>
.main-nav {
    text-align: center;
    margin: 80px 30px 20px 140px;
}
.main-nav a{
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
    color: #999;
    font-size: 18px;
}
a.router-link-active{
    border-bottom: 2px solid #00ce89;
    padding-bottom: 4px;
}
.dados_contas{
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    top: 195px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    text-decoration-line: underline;
    color: #4E4E4E;
  }
.datatable_relatorios{
    position: absolute;
    top: 260px;
    left: 300px;
    text-align: left;
}
.lista_itens {
    text-align: left;
}
.lista_relatorios{
    position: absolute;
    width: 321px;
    height: 20px;
    left: 266px;
    top: 200px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #4E4E4E;
}

.container_rel{
    box-sizing: border-box;
    position: absolute;
    width: 925px;
    height: 780px;
    left: 265px;
    top: 250px;
    background: #FFFFFF;
    border: 1px solid #4E4E4E;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.folha_sal_pessoal_quadro{
    position: absolute;
    width: 240px;
    height: 25px;
    left: 40px;
    top: 30px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #4E4E4E;
    box-sizing: border-box;
    border: 1px solid #51B8D5;
    border-radius: 50px;
    }

.folha_sal_pessoal_local{
    box-sizing: border-box;
    position: absolute;
    width: 220px;
    height: 25px;
    left: 40px;
    top: 60px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}

.resumo_folha_sal{
    box-sizing: border-box;
    position: absolute;
    width: 180px;
    height: 25px;
    left: 40px;
    top: 150px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.balanco_das_contas{
    box-sizing: border-box;
    position: absolute;
    width: 160px;
    height: 25px;
    left: 40px;
    top: 30px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}

.operacoes_do_caixa{
    box-sizing: border-box;
    position: absolute;
    width: 280px;
    height: 25px;
    left: 40px;
    top: 120px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.relacoes_despesas_pagas{
    box-sizing: border-box;
    position: absolute;
    width: 205px;
    height: 25px;
    left: 40px;
    top: 150px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.conta_corrente_banco{
    box-sizing: border-box;
    position: absolute;
    width: 205px;
    height: 25px;
    left: 40px;
    top: 180px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.movimento_do_caixa{
    box-sizing: border-box;
    position: absolute;
    width: 165px;
    height: 25px;
    left: 40px;
    top: 210px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.relacao_recursos_recebidos{
    box-sizing: border-box;
    position: absolute;
    width: 230px;
    height: 25px;
    left: 40px;
    top: 60px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.despesas_a_pagar{
    box-sizing: border-box;
    position: absolute;
    width: 150px;
    height: 25px;
    left: 40px;
    top: 90px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.programacao_financeira{
    box-sizing: border-box;
    position: absolute;
    width: 190px;
    height: 25px;
    left: 40px;
    top: 120px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.balanco_mensal{
    box-sizing: border-box;
    position: absolute;
    width: 140px;
    height: 25px;
    left: 40px;
    top: 150px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.relacao_de_cheques_cir_pagos{
    box-sizing: border-box;
    position: absolute;
    width: 290px;
    height: 25px;
    left: 40px;
    top: 180px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.relacao_de_cheques_cir{
    box-sizing: border-box;
    position: absolute;
    width: 250px;
    height: 25px;
    left: 40px;
    top: 210px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.conta_corrente_ML{
    box-sizing: border-box;
    position: absolute;
    width: 145px;
    height: 25px;
    left: 40px;
    top: 420px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.conta_corrente_MG{
    box-sizing: border-box;
    position: absolute;
    width: 440px;
    height: 25px;
    left: 40px;
    top: 270px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.livro_controlo{
    box-sizing: border-box;
    position: absolute;
    width: 260px;
    height: 25px;
    left: 40px;
    top: 240px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.requiscao_verbas{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 25px;
    left: 40px;
    top: 300px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.transferencia_interna{
    box-sizing: border-box;
    position: absolute;
    width: 170px;
    height: 25px;
    left: 40px;
    top: 540px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.reldespagascon{
    box-sizing: border-box;
    position: absolute;
    width: 295px;
    height: 25px;
    left: 40px;
    top: 330px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.reldespagasrecpro{
    box-sizing: border-box;
    position: absolute;
    width: 305px;
    height: 25px;
    left: 40px;
    top: 3600px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.reldespagastes{
    box-sizing: border-box;
    position: absolute;
    width: 265px;
    height: 25px;
    left: 40px;
    top: 360px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.reloutrassaidas{
    box-sizing: border-box;
    position: absolute;
    width: 210px;
    height: 25px;
    left: 40px;
    top: 390px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
    }
.livroassentamentofuncionarios{
    box-sizing: border-box;
    position: absolute;
    width: 250px;
    height: 25px;
    left: 40px;
    top: 420px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
.relatoriomodelo_4{
    box-sizing: border-box;
    position: absolute;
    width: 112px;
    height: 25px;
    left: 40px;
    top: 720px;
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #51B8D5;
    border-radius: 50px;
}
</style>