<template>
    <div>
        <div>
            <NavbarAppHome />
        </div>
        <div>
            <Sidebar />
        </div>
        <div>
            <table>
                <caption>FECHAMENTOS</caption>
                <thead>
                    <tr>
                    <th scope="col">Mes</th>
                    <th scope="col">Ano</th>
                    <th scope="col">Estado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td data-label="Account">Janeiro</td>
                        <td data-label="Due Date">2022</td>
                        <td data-label="Amount"><div class="icons"><i class="fas fa-lock-open"></i></div></td>
                    </tr>
                    <tr>
                        <td data-label="Account">Fevereiro</td>
                        <td data-label="Due Date">2022</td>
                        <td data-label="Amount"><div class="icons"><i class="fas fa-lock-open"></i></div></td>
                    </tr>
                    <tr>
                        <td data-label="Account">Março</td>
                        <td data-label="Due Date">2022</td>
                        <td data-label="Amount"><div class="icons"><i class="fas fa-lock-open"></i></div></td>
                    </tr>
                    <tr>
                        <td data-label="Account">Abril</td>
                        <td data-label="Due Date">2022</td>
                        <td data-label="Amount"><div class="icons"><i class="fas fa-lock-open"></i></div></td>
                    </tr>
                    <tr>
                        <td data-label="Account">Maio</td>
                        <td data-label="Due Date">2022</td>
                        <td data-label="Amount"><div class="icons"><i class="fas fa-lock-open"></i></div></td>
                    </tr>
                    <tr>
                        <td data-label="Account">Junho</td>
                        <td data-label="Due Date">2022</td>
                        <td data-label="Amount"><div class="icons"><i class="fas fa-lock-open"></i></div></td>
                    </tr>
                    <tr>
                        <td data-label="Account">Julho</td>
                        <td data-label="Due Date">2022</td>
                        <td data-label="Amount"><div class="icons"><i class="fas fa-lock-open"></i></div></td>
                    </tr>
                    <tr>
                        <td data-label="Account">Agosto</td>
                        <td data-label="Due Date">2022</td>
                        <td data-label="Amount"><div class="icons"><i class="fas fa-lock-open"></i></div></td>
                    </tr>
                    <tr>
                        <td data-label="Account">Setembro</td>
                        <td data-label="Due Date">2022</td>
                        <td data-label="Amount"><div class="icons"><i class="fas fa-lock-open"></i></div></td>
                    </tr>
                    <tr>
                        <td data-label="Account">Outubro</td>
                        <td data-label="Due Date">2022</td>
                        <td data-label="Amount"><div class="icons"><i class="fas fa-lock-open"></i></div></td>
                    </tr>
                    <tr>
                        <td data-label="Account">Novembro</td>
                        <td data-label="Due Date">2022</td>
                        <td data-label="Amount"><div class="icons"><i class="fas fa-lock-open"></i></div></td>
                    </tr>
                    <tr>
                        <td data-label="Account">Dezembro</td>
                        <td data-label="Due Date">2022</td>
                        <td data-label="Amount"><div class="icons"><i class="fas fa-lock-open"></i></div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import NavbarAppHome from '../components/NavbarAppHome.vue'
import Sidebar from '../components/Sidebar.vue'
export default {
    components: { NavbarAppHome, Sidebar }
}
</script>

<style scoped>

table {
  border: 1px solid #fff;
  border-collapse: collapse;
  margin: 10px 50px 10px 230px;
  padding: 0;
  width: 80%;
  table-layout: fixed;
  box-shadow: 0 0 15px rgba(0,0,0, 0.5);
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #fff;
  border: 1px  solid rgb(155, 210, 238);
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: left;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

/* general styling */
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.25;
}

.icons{
    color: rgb(31, 227, 31);
    align-items: center;
    cursor: pointer;
    margin-left: 20px;
}
</style>