<template>
	<div>
	  <div>
		<NavbarAppHome />
	  </div>
	  <div>
		<Sidebar />
	  </div>
	  <div class="profile">
		<div class="profile-pic">
		  <div class="header-color"></div>
		  <img :src="imageUrl" alt="Profile Picture" />
		</div>
		<div class="title">
		  <h1>{{ primeiroNome + " " + ultimoNome }}</h1>
		</div>
		<div class="description">
		  <p>Tel: {{ telefone }}</p>
		  <p>Email: {{ email }}</p>
		  <p>Endereco: {{ endereco }}</p>
		</div>
		<div>
		  <button @click="updateData(user_id)">update</button>
		</div>
	  </div>
	</div>
  </template>
  
  <script>
  import NavbarAppHome from "../components/NavbarAppHome.vue";
  import Sidebar from "../components/Sidebar.vue";
  import { projectAuth, projectFirestore } from "../firebase/config";
  import { ref, onUnmounted, watch, onMounted } from "vue";
  import getUser from "../composables/getUser";
  import { useRouter } from "vue-router";
  
  export default {
	components: { NavbarAppHome, Sidebar },
	setup() {
	  const router = useRouter();
	  const { user } = getUser();
	  const data = ref([]);
	  const filteredDocuments = ref([]);
	  const email = ref("");
	  const telefone = ref("");
	  const user_id = user.value.uid;
	  const imageUrl = ref(null);
	  const primeiroNome = ref(null);
	  const ultimoNome = ref(null);
	  const endereco = ref(null);
  
	  const fetchCollectionData = async () => {
		try {
		  const collectionRef = projectFirestore.collection("userdata");
		  const snapshot = await collectionRef.get();
		  const data = snapshot.docs.map((doc) => doc.data());
		  const filtered = data.filter((item) => item.userId === user_id);
		  filtered.forEach((item) => {
			console.log(item, "itemitem");
			telefone.value = item.telefone;
			email.value = item.email;
			primeiroNome.value = item.primeiroNome;
			imageUrl.value = item.imageUrl
			  ? item.imageUrl
			  : "https://firebasestorage.googleapis.com/v0/b/prescom-md.appspot.com/o/60111.jpg?alt=media&token=f7a94d55-62a8-42b0-8f7f-fa9822813833";
			ultimoNome.value = item.ultimoNome;
			endereco.value = item.endereco ? item.endereco : '-';
		  });
		} catch (error) {
		  console.error("Error fetching collection data:", error);
		}
	  };
	  onMounted(fetchCollectionData);
  
	  const updateData = (item) => {
		console.log("item=-----------", item);
		try {
		  router.push(`/updateprofile/${item}`);
		} catch (error) {
		  console.error("Error updating data:", error);
		}
	  };
  
	  return {
		email,
		telefone,
		filteredDocuments,
		data,
		user_id,
		updateData,
		primeiroNome,
		ultimoNome,
		imageUrl,
		endereco,
	  };
	},
  };
  </script>
  
  <style lang="scss" scoped>
  .profile {
	margin: 20px 45%;
	height: 710px;
	width: 400px;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	border-radius: 20px;
	box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-y: scroll;
  }
  
  ::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
  }
  
  .header-color {
	border-radius: 20px 20px 0 0;
	padding-bottom: 150px;
	width: 400px;
	background: #4f759b;
  }
  
  .profile-pic img {
	height: 200px;
	width: 200px;
	border-radius: 50%;
	border: 10px solid #ffffff;
	margin-top: -100px;
  }
  
  .title {
	margin-bottom: 25px;
  }
  
  h1 {
	font-size: 32px;
	font-weight: 700;
	color: #131b23;
	margin-bottom: 10px;
	letter-spacing: 0.025em;
	text-decoration: none;
  }
  
  h2 {
	font-size: 18px;
	letter-spacing: 0.01em;
	color: #131b23;
	span {
	  color: #4f759b;
	  font-weight: 700;
	}
  }
  
  .description {
	margin-bottom: 25px;
	color: #131b23;
	letter-spacing: 0.01em;
  
	p:not(:last-child) {
	  margin-bottom: 5px;
	}
  }
  
  button {
	font-family: "Open Sans", sans-serif;
	color: #ffffff;
	background: #4f759b;
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0.025em;
	border: none;
	border-radius: 15px;
	min-height: 35px;
	width: 100px;
	margin-bottom: 25px;
	transition: all 0.2s ease;
	cursor: pointer;
  }
  
  button:hover {
	width: 115px;
	background: #4f759be0;
  }
  
  .images-container {
	width: 350px;
  
	.image {
	  margin-bottom: 25px;
  
	  img {
		width: 100%;
		border-radius: 5px;
		margin-bottom: 5px;
	  }
  
	  i {
		color: #9b1d20;
		display: flex;
		align-items: center;
		margin-left: 10px;
  
		span {
		  margin-left: 5px;
		  font-family: "Open Sans", sans-serif;
		  font-size: 14px;
		  font-weight: 400;
		  color: #000000;
		}
	  }
	}
  }
  </style>